
// const StyledDialog = styled(Dialog)`
//   .MuiBackdrop-root {
//     background-color: lightgreen;
//   }
// `;
const profileStyle = theme => ({
 

  img: {
      width:"100%",
      height:"100%",
      borderRadius: "50%",
      //border: "3px solid #538c0d"
    },
    inputAdornment: {
      marginRight:"8px",
      position:"relative",

    },
    inputAdornmentIconSuccess: {
      color:"#4BB543 !important"

    },
    inputAdornmentIconError: {
      color:"#F32013 !important"

    },
 
 
    
    imgcontainer:{
      width: "80px",
      height: "80px",
      position:"relative",
      cursor:"pointer",
      textAlign:"center"
      
    },
    drawerPaper: {
      border: "none",
      position: "fixed",
      top: "0",
      bottom: "0",
      right: "0",
      zIndex: "1",
    
      width: 400,
      height: "100%",
      background:"#232323"
   
    },
    drawerPapereditimage: {
      border: "none",
      position: "fixed",
      top: "0",
      bottom: "0",
      right: "0",
      zIndex: "1",
    
      width: "100%",
      height: "100%",
      background:"#232323"
   
    },
  
    featureButton:{
      
          background: "none !important",
          border: "none",
          padding: "0 !important",
          color:"#fff",
          borderTop:"none",         
          textDecoration: "underline",
          cursor: "pointer",
          "&:disabled":{
            color:"#8C8C8C",
            cursor:"default"
          }
        

    },
    saveDisableButton: {
      height:"42px",
      padding: "10px 60px",    
      marginRight: "25px",
      background: "linear-gradient(0deg, rgba(41, 41, 41, 1) 0%, rgba(96, 96, 96, 1) 100%)",
      borderStyle: "solid",
      border: "0",
      borderTop: "1px solid #6b6b6b",
      fontWeight: "600",
      color:"#fff",     
      cursor: "pointer",
      "&:disabled":{
        color:"#8C8C8C",
        cursor:"default"
      }
    },
    colorchange:{
border:"2px solid red"
    },
    drawerbtn: {
      height:"42px",
      padding: "10px 60px",
      color: "#fff",    
      marginRight: "25px",
      background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
      borderStyle: "solid",
      border: "0",
      borderTop: "1px solid #a5a5a5",
      fontWeight: "600",
      cursor: "pointer",
      "&:disabled":{
        color:"#8C8C8C",
        cursor:"default",

       
      }
    
    },
    // MuiBackdropRoot:{
    //   backgroundColor: "rgba(0, 0, 0, 0.05)"
    // },
    editWidgetRoot:{
      background: "#535353" 
    },
    MuiBackdrop: {
      root: {
        background: 'rgba(0,0,0,0.2)'
      }
    },

});
export default profileStyle;