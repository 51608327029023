import React from "react";
import { useState, useEffect } from "react";
//Components imported locally //
import EditWidget from "../editWidget";
import BootstrapTooltip from "../../../assets/widgets/tooltip/tooltip";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
// Assets Import //
import profileGreen from "../../../assets/img/profileGreen.png";
import profileWhite from "../../../assets/img/profileWhite.png";
// CSS Style sheets imported locally //
import styles from "../../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function //
export default function UserSystemBoolean(props) {
  const initialData = props.initialCbusEvent.get(
    "usio_var" + props.widget.params._attributes.usio_var
  );
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [sysBool, setSysBool] = useState(
    props.sysBool == undefined ? 0 : Math.floor(props.sysBool)
  );

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };
  const handleClick = (click) => {
    if (click == 1) {
      setSysBool("1");
      let on = {
        cmd:
          '<cbus_cmd app="257" command="cbusSetUserSystemIOBool" val="1" sio_index="' +
          props.widget.params._attributes.usio_var +
          '" nooofparams="0"/>',
      };
      props.handleSocketEventChange(on);
      
    } else if (click == 0) {
      setSysBool("0");
      let off = {
        cmd:
        '<cbus_cmd app="257" command="cbusSetUserSystemIOBool" val="0" sio_index="' +
        props.widget.params._attributes.usio_var +
        '" nooofparams="0"/>',
      };
      props.handleSocketEventChange(off);
      
    }
  };

  useEffect(() => {
    let data = props.data.Project.UserVariables.UserVariable;
    if(data){
      for(let i=0;i<data.length;i++){
        if(data[i]._attributes.id == props.widget.params._attributes.usio_var){
          let intitialdatavalue = {
            value: data[i]._attributes.BooleanValue
          }
          props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, intitialdatavalue);
          let ioData=new Map(props.initialCbusEvent);
          props.socketEventDataChanger(ioData);
          setSysBool(data[i]._attributes.BooleanValue);
        }
      }
    }
  },[]);


  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_resp") {
        if (props.cbusEvent.attributes.command === "cbusSetUserSystemIOBool") {
          if (
            props.cbusEvent.attributes.sio_index ===
            props.widget.params._attributes.usio_var
          ) {
            setSysBool(props.cbusEvent.attributes.value);
            if (initialData) {
              initialData.value = props.cbusEvent.attributes.value;
              props.initialCbusEvent.set(
                "usio_var" + props.widget.params._attributes.usio_var,
                initialData
              );
              let ioData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(ioData);
            }
          }
        }
      }
    }
    if (initialData) {
      setSysBool(initialData.value);
    }
  }, [props.cbusEvent]);

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  return (
    <>
      {" "}
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true" 
                  className={classes.headerIconButton}
                >
                  <img src={sysBool==="1"?profileGreen:profileWhite} className={classes.headerIconLogic} />
                </IconButton>
                <div className={classes.header1}>
                  <Typography variant="h6" className={sysBool==="1"?`${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `}>
                    {name}
                  </Typography>
                </div> 
                <Button
                  variant="contained"
                  className={sysBool === "0" ?classes.controlPanelIconButton:classes.controlPanelIconButtonActive }
                  style={{ width: "30%",paddingTop:'0%', paddingBottom:'0%'  }}
                  onClick={sysBool === "0" ? ()=>handleClick(1):()=>handleClick(0)}
                >
                  <Typography className={classes.topButtonText} variant="h6">
                    {sysBool === "0"
                      ? props.widget.params._attributes.officon
                      : props.widget.params._attributes.onicon}
                  </Typography>
                </Button>
              </div>
            </BootstrapTooltip>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
