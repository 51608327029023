import ReactSpeedometer from "react-d3-speedometer"
import { useState, useEffect } from "react";
import { connect } from "react-redux";

function Co2WeekStatsTotalSpeedo(props) {
  const [meterValue, setMeterValue] =useState("");

   //CarbonFootPrint Value
   const carbonFootValue = props.energy.Project.Energy._attributes.CarbonFootprint;

  let minMeterValue = (Number(props.widget.params._attributes.max_weekly_gen_w)/1000)*carbonFootValue;

  let maxMeterValue = (Number(props.widget.params._attributes.max_weekly_con_w)/1000)*carbonFootValue;

  let meterData = ((meterValue/1000)*carbonFootValue).toFixed(2);

  let meterResult = meterData >= 9999 ? 9999 : meterData;

  let meterText = ((meterValue/1000)*carbonFootValue).toFixed(2).replace("-", " ");

  useEffect(() => {
    if (props.powerStatTotal) {
        if (props.powerStatTotal.name === "cbusPowerTotals") {
         setMeterValue(props.powerStatTotal.current_total_power_week);
      }
    }
  }, [props.powerStatTotal]);

    return (
        <div  style={{ marginTop: '10px'}}>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxSegmentLabels={1}
                minValue={-minMeterValue}
                maxValue={maxMeterValue}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                customSegmentStops={[-minMeterValue, 0, maxMeterValue]}
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterText} kg CO2`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={170}
                height={110}
               dimensionUnit="px"
                
/>
        </div>
    );
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(Co2WeekStatsTotalSpeedo);