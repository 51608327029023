import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import KWHPowerStatsTotalSet from './PowerStatsTotalSet/KWHPowerStatsTotalSet';
import DollarPowerStatsTotalSet from './PowerStatsTotalSet/DollarPowerStatsTotalSet';
import CO2PowerStatsTotalSet from './PowerStatsTotalSet/CO2PowerStatsTotalSet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import XMLParser from "react-xml-parser";
import { updateSocketEvent } from "./../../../../services/xmlService";

// style sheet imported locally // 
import styles from '../../../../assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);

//function
const PowerStatsTotal = (props) => {
  const classes = useStyles();
  // const initialData1 = props.initialCbusEvent.get(
  //   "powerTotal1"
  // );
  // const initialData2 = props.initialCbusEvent.get(
  //   "powerTotal2"
  // );
  const initialData = props.initialCbusEvent.get(
    "cbusPowerTotals"
  );

  const initialDataCharts = props.initialCbusEvent.get(
    "cbusPowerHistoryTotals"
  );
  const [totalPowerData, setTotalPowerData] = useState("");
  const [totalPowerDataCharts, setTotalPowerDataCharts] = useState("");
  const [powerData, setPowerData] =useState("");
  const defaultTab = props.widget.params._attributes.default_units;
  const [value, setValue] = React.useState(defaultTab === "0" ? 0 : defaultTab === "1" ? 2 : 1);

  

  // useEffect(() => {
  //   let meterIntialEvent = {
  //     cmd:
  //       '<cbus_cmd app="0x102" command="cbusGetPowerHistoryTotals" />',
  //   };
  //   props.handleSocketEventChange(meterIntialEvent);
  // }, []);

  const handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
    };

  useEffect(() => {
    let powerCmd = {
      cmd: "<cbus_cmd app=\"0x102\" command=\"cbusGetPowerHistoryTotals\" />"
    }
    handleSocketEventChange(powerCmd);
  }, []);

  useEffect(() => {
    // if (initialData1 && initialData2) {
    //   let totalInitialData = new XMLParser().parseFromString(initialData1 + initialData2);
    //   setTotalPowerData(totalInitialData);
    //   props.initialCbusEvent.set("cbusPowerHistoryTotals", totalInitialData);
    //   let powerData=new Map(props.initialCbusEvent);
    //   props.socketEventDataChanger(powerData);
    // }
    if (initialData) {
      setTotalPowerData(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if(initialDataCharts){
      setTotalPowerDataCharts(initialDataCharts);
    }
  }, [initialDataCharts]);

  // useEffect(() => {
  //   if (props.cbusEvent) {
  //     if (props.cbusEvent.name === "cbus_event") {
  //       if (props.cbusEvent.attributes.name === "cbusPowerTotals") {
  //         // setPowerData(props.cbusEvent.attributes);
  //         if(initialData){
  //           props.initialCbusEvent.set("cbusPowerHistoryTotals", props.cbusEvent.attributes);
  //           let powerData=new Map(props.initialCbusEvent);
  //           props.socketEventDataChanger(powerData);
  //         }
  //       }
  //     }
  //   }
  //   if(initialData){
  //     setPowerData(initialData);
  //   }
  // }, [props.cbusEvent]);

  const TabPanel = (prop) => {
    const { children, value, index, ...other } = prop;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        style={{ width: "50%" }}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}

      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const name = props.widget.params._attributes.label != undefined ? props.widget.params._attributes.label : "";

  return (
    <>
      { props.widget !== undefined &&
        <Grid container items lg={11} spacing={1} className={classes.powerStatsLayout}>
          <Grid items md={12} className={classes.powerStatsTabs}>
            <Grid items md={8}>
              <Typography align="left" className={classes.powerStatsLayoutHeaderText}>{name} </Typography>
            </Grid>
            <Grid items md={4}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                inkBarStyle={{ background: 'green' }}
                TabIndicatorProps={{ style: { background: '#87d200' } }}
              >
                <Tab
                  variant="contained"
                  label={props.language.kWh}
                  className={classes.PowerTab}
                  {...a11yProps(0)}
                  style={{ fontSize: '16px', minWidth: '10px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
                />

                <Tab
                  label={props.widget.params._attributes.currency_units}
                  className={classes.PowerTab}
                  {...a11yProps(1)}
                  style={{ fontSize: '16px', minWidth: '10px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
                />
                <Tab
                  label={props.language.co2}
                  className={classes.PowerTab}
                  {...a11yProps(2)}
                  style={{ fontSize: '16px', minWidth: '10px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
                />
              </Tabs>

            </Grid>
          </Grid>

          <Grid style={{ marginTop: '10px'}} items md={12}>
            { value==0 &&
              <KWHPowerStatsTotalSet
                data={props.widget}
                widget={props.widget}
                powerStatTotal={totalPowerData}
                totalPowerDataCharts={totalPowerDataCharts}
                energy={props.data}
                language={props.language}
              />
            }
            { value==1 &&
              <DollarPowerStatsTotalSet
                data={props.widget}
                widget={props.widget}
                powerStatTotal={totalPowerData}
                totalPowerDataCharts={totalPowerDataCharts}
                energy={props.data}
                language={props.language}
              />
            }
            { value==2 &&
              <CO2PowerStatsTotalSet
                data={props.widget}
                widget={props.widget}
                powerStatTotal={totalPowerData}
                totalPowerDataCharts={totalPowerDataCharts}
                energy={props.data}
                language={props.language}
              />
            }
          </Grid>
        </Grid>
      }

    </>
  );
}

export default PowerStatsTotal;
