import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

const MonthCo2RateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] = useState("");
  const [rateInfo, setRateInfo] =useState("");

  let arrayOfAnalog = [];

  let arrayofPulse = [];

  let combineArray = [];

  if(props.energy.Project.MeasurementAppData !==undefined){
  props.energy.Project.MeasurementAppData.MeasurementChannel.map(obj => { 
    if(obj._attributes.Generator!==undefined){
      arrayOfAnalog.push(obj._attributes.Generator);
    }
  } ); }

  if(props.energy.Project.Energy.PulsePowerMeters !==undefined){
  props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter.map(obj => { 
    if(obj._attributes.Generator!==undefined){
      arrayofPulse.push(obj._attributes.Generator);
    }
  } ); }

  combineArray = [...arrayOfAnalog, ...arrayofPulse];

  //CarbonFootPrint Value
  const carbonFootValue = props.energy.Project.Energy._attributes.CarbonFootprint;

   //estimated values
  let meterData = ((meterValue/1000)*carbonFootValue).toFixed(2);

  let meterResult = meterData >= 9999 ? 9999 : meterData;

   //consumption and genetated values
  let meterDataInfo = (rateInfo/1000).toFixed(2);

  let meterResult1 = meterDataInfo >= 9999 ? 9999 : meterDataInfo;

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.predicted_power_month);
       }
    }
  }, [props.powerData]);

  useEffect(() => {
    if (props.powerDataConsumed !== undefined ) {
       if(props.powerDataConsumed.meter===props.widget.params._attributes.meterno){
        let monthlyEnergy = props.powerDataConsumed.monthly_energy_history.split(",").reverse();
        let monthlyValue = monthlyEnergy[2] * carbonFootValue;
        setRateInfo(monthlyValue);
       }
    }
  }, [props.powerDataConsumed]);
    
    
  return (
    <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    {combineArray[props.widget.params._attributes.meterno] === "1" ? "Rate of Generation" :
    combineArray[props.widget.params._attributes.meterno] === "0" ? "Rate of Consumption" :
    "Rate of Consumption/Generation" }
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this month<span style={{wordSpacing:'0px'}}> {meterResult} kg CO2</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {combineArray[props.widget.params._attributes.meterno] === "1" ? "Generated last month" :
    combineArray[props.widget.params._attributes.meterno] === "0" ? "Consumed last month" :
    "Generated last month" } < span style={{wordSpacing:'0px'}}> {meterResult1} kg CO2</span>
    </Typography>
      </div>
  )
}

export default MonthCo2RateConsumption;
