import React, { Component } from 'react';
// import Carousel from 'react-elastic-carousel';
import '../style.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Co2MonthSpeedoTotal from '../GaugeSet/co2/Co2MonthSpeedoTotal';
import Co2NowSpeedoTotal from '../GaugeSet/co2/Co2NowSpeedoTotal';
import Co2TodaySpeedoTotal from '../GaugeSet/co2/Co2TodaySpeedoTotal';
import Co2WeekSpeedoTotal from '../GaugeSet/co2/Co2WeekSpeedoTotal';



export default class CO2CardSliderSetTotal extends Component {
    

    render() 
      {
  
      return (
        <div>
      <AwesomeSlider
      organicArrows={false}
      fillParent={false}
     >
         <div>
          <Co2NowSpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
            <div>
          <Co2TodaySpeedoTotal
           totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
            <div>
          <Co2WeekSpeedoTotal
           totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
          <div>
          <Co2MonthSpeedoTotal
           totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>

</AwesomeSlider>
        </div>
      )
    }
  }

