import React,{useState, useEffect} from "react";
import "assets/css/profileStyle.css";
import styles from "assets/js/all-styles/views/editscenestyle.js";
// Material-UI // 
import { makeStyles } from "@material-ui/core/styles";
import {AirbnbThumbComponent} from 'components/Widgets/Lighting/slider.js'
import IOSSwitch from 'components/Widgets/Lighting/switch.js';
import Editsceneslive from "./Editsceneslive";
import { getAppConfigData } from "redux/actions/action";
import { updateXml } from "../../services/xmlService";

const useStyles = makeStyles(styles);
const Editscenedrawer = ({data,scenesedit, sceneKey, toggleDrawer, handleLiveChange, livechanges, setLivechanges, handleSocketEventChange, language, cbusEvent , initialCbusEvent, socketEventDataChanger, setCancel, isCancel, setScenesedit}) => {  
const classes = useStyles();
const [listofIteminArray, setListofIteminArray] = React.useState(data.Project.Scenes.Scene);
let newData = data.Project.Scenes.Scene;
const [listofItem, setListofItem] = React.useState();

useEffect(() => {
  setListofItem(JSON.parse(JSON.stringify(newData)));
}, [data.Project.Scenes.Scene]);
const [state, setState] = React.useState({
  hours: "00",
  min: "00",
  sec: "00",
});
const [levelvalueupdate, setLevelvalueupdate] = React.useState();
const [returnValueUpdate, setReturnValueUpdate] = React.useState();
const [iosSwitch, setIosSwitch] = React.useState({ checked: true });
let [totalDuration, setTotalDuration] = React.useState(0);
const [mainarraykey, setMainarraykey] =  React.useState(0);
const [cancelbtn, setCancelbtn] = React.useState(false);
const [changedListofItem, setChangedListofItem] = React.useState();
const handleIOSSwitch = (event) => {
  setIosSwitch(event.target.checked);
};
const handleSwitchChange = (event) => {
  setState({ ...state, [event.target.name]: event.target.value });
};
const handleChangedItem = (list) =>{
  setChangedListofItem(list);
}
const [editSceneGroups, setEditSceneGroups] = React.useState([]);
React.useEffect(() => {
    if (scenesedit !== undefined) {
      if (scenesedit._attributes.Level === "255") {
        setLivechanges(true);
      }
      setLivechanges(false);
      if (!Array.isArray(scenesedit.Items.Item)) {
        setEditSceneGroups(editSceneGroups => [scenesedit.Items.Item]);
      } else {

        setEditSceneGroups(scenesedit.Items.Item);
      }
    }
}, [scenesedit, listofIteminArray, isCancel])

const handleEditSave = () => {
  let sceneData = [];
  let sceneitemcount = 0;
  let sceneitem = changedListofItem._attributes;
  if (!Array.isArray(changedListofItem.Items.Item)) {
    let dataArray = [changedListofItem.Items.Item];
    sceneData = sceneDataGrouping(dataArray);
    sceneitemcount = 1;
  } else {
    sceneData = sceneDataGrouping(changedListofItem.Items.Item);
    sceneitemcount = changedListofItem.Items.Item.length;
  }
 
  let editSceneCmd = {
    "cmd": '<scene-edit id="'+ sceneitem.id +'" scene_item_count="'+ sceneitemcount+'" name="'+ sceneitem.Name +'" any_net="'+ sceneitem.AnyNet +'" network="'+ sceneitem.Network +'" app_no="'+ sceneitem.AppNo +'" group_add="'+ sceneitem.GroupAdd +'" level="'+ sceneitem.Level +'" scene_data="'+ sceneData +'" />'
  }

  console.log(editSceneCmd, "editSceneCmd");
  
  handleSocketEventChange(editSceneCmd);
  toggleDrawer(false, scenesedit, sceneKey).call();
  setCancel(!isCancel);
  data.Project.Scenes.Scene = listofItem;

  // updateXml(data)
  // .then((result) => {
  //     if (result.status === 200) {
  //         getAppConfigData();
  //     }
  // })
  // .catch((error) => {
      
  // });

};

const sceneDataGrouping = (items) =>{
  let sceneData = '';
  for(let i=0; i<items.length;i++){
    let sdata = ',' + (items[i]._attributes.Network == undefined ? 0 : items[i]._attributes.Network)+ ',' + (items[i]._attributes.AppNo == undefined ? 0 : items[i]._attributes.AppNo) + ',' + (items[i]._attributes.GroupAdd == undefined ? 0 : items[i]._attributes.GroupAdd) + ',' + (items[i]._attributes.Level == undefined ? 0 : items[i]._attributes.Level) + ',' + (items[i]._attributes.RampRate == undefined ? 0 : items[i]._attributes.RampRate)+ ',' + (items[i]._attributes.Duration == undefined ? 0 : items[i]._attributes.Duration) + ',' + (items[i]._attributes.ReturnLevel == undefined ? 0 : items[i]._attributes.ReturnLevel);
    sceneData += sdata;
  }
   return sceneData
}

const handleEditCancel = () => {
  setCancel(!isCancel);
  toggleDrawer(false, scenesedit, sceneKey).call();
};

const handleReset = () => {
    if (livechanges) {
      editSceneGroups.map(({ _attributes }, key) => {
        let levelChange = { cmd: '<cbus_cmd app="' + _attributes.AppNo + '" command="cbusSetLevel"  network="' + _attributes.Network + '" numaddresses="1" group="' + _attributes.GroupAdd + '" level="' + _attributes.Level + '" ramp="' + _attributes.RampRate + '" />' };
        handleSocketEventChange(levelChange);
      })
    } else {
      setCancel(!isCancel);
    }
}

return (
    <>
      {scenesedit &&
        <>
          <div style={{ background: "#3b3b3b" }}>
            <div style={{ padding: "25px 10px 5px 15px" }}>
              <p style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>
                {language.editScene}
              </p>
              <p style={{ color: "#707070", fontWeight: "600", margin: "0" }}>

                {scenesedit._attributes.Name}
              </p>
            </div>
            <hr style={{ border: "1px solid black", margin: "0", borderTopColor: "transparent" }} />
            <div style={{ display: "flex", padding: "15px", justifyContent: "space-between" }}>
              <button className={classes.drawerbtn}
                onClick={handleEditCancel}
              >
                {language.cancel}
              </button>
              <button
                className={classes.drawerbtn}
                onClick={handleEditSave}
              >
                {language.save}
              </button>
            </div>
            <hr style={{ border: "1px solid black", margin: "0", borderTopColor: "transparent" }} />
            <div style={{ display: "flex", margin: "15px" }}>
              <span style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>{language.liveChanges}</span>
              <span style={{ marginLeft: "56%" }}>
                <IOSSwitch ThumbComponent={AirbnbThumbComponent} checked={livechanges} onChange={handleLiveChange} name="checkedC" />
              </span>
            </div>
            <div style={{ display: "flex", padding: "15px", justifyContent: "space-between" }}>
              <button className={classes.drawerbtn}
                onClick={() => handleReset()}

              >
                Reset
              </button>
            </div>
          </div>
        </>
      }
      {scenesedit && editSceneGroups.map(({ _attributes }, key) => (
        <Editsceneslive data={data} handleChangedItem={ handleChangedItem } mainarraykey={key} getAppConfigData={getAppConfigData} _attributes={_attributes} livechanges={livechanges} handleSocketEventChange={handleSocketEventChange} language={language} setReturnValueUpdate={setReturnValueUpdate} setLevelvalueupdate={setLevelvalueupdate} setTotalDuration={setTotalDuration} cbusEvent={cbusEvent} initialCbusEvent={initialCbusEvent} socketEventDataChanger={socketEventDataChanger} listofItem={listofItem} sceneKey={sceneKey} cancelbtn={cancelbtn} isCancel={isCancel} />
      ))}
    </>
  );
}

export default Editscenedrawer;