import React from "react";
import { useState, useEffect } from "react";
// Assets Import //
import fanWhite from "../../assets/img/fanWhite.png";
import fanGreen from "../../assets/img/fanGreen.png";
//Components imported locally //
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// material-ui //
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Tooltip,
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// CSS Style sheets imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function //
export default function FanController(props) {
  const initialData=props.initialCbusEvent.get("0x"+Number(props.widget.params._attributes.app).toString(16));
  const classes = useStyles();
  const theme = useTheme();
  const [count, setCount] = useState(0);
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [speed, setSpeed] = useState("off");

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  let speeds = props.widget.params._attributes.speeds;
  let speedsArray = speeds.split("|");
  const speedCount = (speedsArray.length) / 2;
  const speedLabel0 =props.language.off
  const speedLabel1 = speedsArray[0]; //Low
  const speedLabel2 = speedsArray[2]; //Medium
  const speedLabel3 = speedsArray[4]; //High
  const speedLabel4 = speedsArray[6]; //Fast
  const speedLabel5 = speedsArray[8]; //Faster

  const speedValue1 = speedsArray[1];
  const speedValue2 = speedsArray[3];
  const speedValue3 = speedsArray[5];
  const speedValue4 = speedsArray[7];
  const speedValue5 = speedsArray[9];

  // const handleButton = (buttonNo) => {
  //   if (buttonNo == 1) {
  //     setCount(count - 1);
  //     handleClick(count - 1);
  //   } else if (buttonNo == 2) {
  //     setCount(count + 1);
  //     handleClick(count + 1);
  //   }
  // };

  const handleButton = (buttonNo) => {
    if (buttonNo == 1) {
      if (count>0){
      setCount(count - 1);
      handleClick(count - 1);}
      else {
        setCount(speedCount);
        handleClick(speedCount)
      }
    } else if (buttonNo == 2) {
      if (count<speedCount){
      setCount(count + 1);
      handleClick(count + 1);
      }
      else {
        setCount(0);
        handleClick(0)
      }
    }
  };

  const handleClick = (count) => {
    if (count == 0) {
      setSpeed(speedLabel0);
      let off = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="0" ramp="0"/>',
      };
      
      props.handleSocketEventChange(off);
    } else if (count == 1) {
      setSpeed(speedLabel1);
      let low = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value + 
          '" level="' +
          speedValue1 +
          '" ramp="0"/>',
      };
      
      props.handleSocketEventChange(low);
    } else if (count == 2) {
      setSpeed(speedLabel2);
      let medium = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="' +
          speedValue2 +
          '" ramp="0"/>',
      };
      
      props.handleSocketEventChange(medium);
    } else if (count == 3) {
      setSpeed(speedLabel3);
      let high = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="' +
          speedValue3 +
          '" ramp="0"/>',
      };
      
      props.handleSocketEventChange(high);
    } else if (count == 4) {
      setSpeed(speedLabel4);
      let fast = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="' +
          speedValue4 +
          '" ramp="0"/>',
      };
      
      props.handleSocketEventChange(fast);
    } else if (count == 5) {
      setSpeed(speedLabel5);
      let faster = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="' +
          speedValue5 +
          '" ramp="0"/>',
      };
      
      props.handleSocketEventChange(faster);
    }
  };

  useEffect(() => {
    if(count >= 5){
      setCount(0)
    }
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
         if (props.cbusEvent.attributes.app ==="0x" + Number(props.widget.params._attributes.app).toString(16)) {
          if (props.cbusEvent.attributes.group === "0x" + Number(props.widget.params._attributes.ga).toString(16)) {
            let eventValue = parseInt(props.cbusEvent.attributes.level, 16);
            if (eventValue == 0) {
              setCount(0);
              setSpeed(speedLabel0);
            } else if (eventValue == speedValue1) {
              setCount(1);
              setSpeed(speedLabel1);
            } else if (eventValue == speedValue2) {
              setCount(2);
              setSpeed(speedLabel2);
            } else if (eventValue == speedValue3) {
              setCount(3);
              setSpeed(speedLabel3);
            } else if (eventValue == speedValue4) {
              setCount(4);
              setSpeed(speedLabel4);
            } else if (eventValue == speedValue5) {
              setCount(5);
              setSpeed(speedLabel5);
            }
            if(initialData){
              initialData[props.widget.params._attributes.ga]=props.cbusEvent.attributes.level;
              let fanData=new Map(props.initialCbusEvent);
              props.socketEventDataChanger(fanData);
            }
          }
        }
        }
      }
  }
    if (initialData) {
      let eventValue = parseInt(initialData[props.widget.params._attributes.ga], 16);
      if (eventValue == 0) {
        setCount(0);
        setSpeed(speedLabel0);
      } else if (eventValue == speedValue1) {
        setCount(1);
        setSpeed(speedLabel1);
      } else if (eventValue == speedValue2) {
        setCount(2);
        setSpeed(speedLabel2);
      } else if (eventValue == speedValue3) {
        setCount(3);
        setSpeed(speedLabel3);
      } else if (eventValue == speedValue4) {
        setCount(4);
        setSpeed(speedLabel4);
      } else if (eventValue == speedValue5) {
        setCount(5);
        setSpeed(speedLabel5);
      }
    }
  }, [props.cbusEvent]);

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
  (props.widget._attributes.location === "all" || props.widget._attributes.location ==="none")
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  return (
    <>
      {" "}
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={count > 0 ? fanGreen : fanWhite}
                    className={classes.headerIcon}
                  />
                </IconButton>
                <div className={classes.header1} style={{ paddingLeft: "5%" }}>
                  <Typography
                    variant="h6"
                    className={
                      count > 0
                        ? classes.headerText1Active
                        : classes.headerText1
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>
            <div
              className={classes.numberControlPanelActiveHvac}
              style={{ padding: "1%" }}
            >
              <IconButton
                aria-label="previous"
                className={classes.Icon2}
                //disabled={count === 0 ? true : false}
              >
                <RemoveIcon
                  className={classes.controlIcons}
                  onClick={() => handleButton(1)}
                />
              </IconButton>
              <Typography variant="h4" className={classes.controlTypo1}>
                {speed}
              </Typography>
              <IconButton
                aria-label="previous"
                className={classes.Icon2}
                //disabled={count >= 3 ? true : false}
              >
                <AddIcon
                  className={classes.controlIcons}
                  onClick={() => handleButton(2)}
                />
              </IconButton>
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
