import React from "react";
import { useState, useEffect } from "react";

// material-ui //
import { makeStyles} from "@material-ui/core/styles";
import { Typography, Grid} from '@material-ui/core';

// CSS Style sheets imported locally //
import styles from 'assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);

//function // 
export default function IOIntegerLogic(props) {

  const [state, setState] = React.useState({hours: "00",min:"00", sec:"00" });
  let [hours, setHours] = React.useState(Math.floor(Math.abs(state.Time/ 3600)));
  let [minutes, setMinutes] = React.useState(Math.floor(Math.abs(((state.Time)%3600)/60)));
  const [timevalue, settimevalue] = React.useState(state.Time>0?"timeplus":"timeminus");
  const [iosSwitch, setIosSwitch] = React.useState({ checked: true });
  const [iPAddress, setIPAddress] = React.useState(props.sysInt==undefined?0: Math.floor(props.sysInt));
  const [val, setVal] = React.useState(props.sysInt);

  

  // useEffect(() =>{
  //   setVal(Number(props.sysInt))
  
  // },[props.sysInt]);

  useEffect(() =>{
    //setVal(Number(props.sysInt).toFixed(2))
    setVal(Number(props.sysInt) == Math.floor(Number(props.sysInt)) ? Number(props.sysInt) : Number(props.sysInt).toFixed(2))
  },[props.sysInt]);

  
  //const id = props.widget._attributes.id;

  const handleIOSSwitch = (event) =>{
    setIosSwitch(event.target.checked)
  }
  const [draweropen, setDraweropen] = useState(false);

  const handleChangeIPAdreess = (event) => {
    const re = /^-?[0-9\.?b]+$/;
    if(event.target.value==="-" || event.target.value===""){
      if(Number(props.userVariable.RealMin)>=0 && event.target.value==="-" ){
        setVal("");
        props.saveChanges("");
      }else{
        setVal(event.target.value);
        props.saveChanges(event.target.value);
      }  
    }
    if (re.test(event.target.value)) {
      if(Number(event.target.value)>=Number(props.userVariable.IntegerMin) && Number(event.target.value)<=Number(props.userVariable.IntegerMax)){
        setVal(event.target.value);
        props.saveChanges(event.target.value);
      }
    }
  };

  
  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  
    const classes = useStyles();

  const toggleDrawer = (open) => {
  setDraweropen(open);
  };

  const handleChangetimevalue = (event) => {
    settimevalue(event.target.value);
  };

 

  let displayValue = () => {
    return(
      <div className={classes.editWidgetPanel} >
      <Typography variant="h6" className={classes.editWidgetLabel} >
        Value
        </Typography>
      <input
        type="text"
        value={val}
        className={classes.editWidgetInput1}
        onChange={handleChangeIPAdreess}
        name="iPAddress"
      />
      <div style={{ display: "flex", justifyContent:"space-between", color:"#fff", fontWeight:"600"}}>
     <span>{props.userVariable.IntegerMin}</span>
     <span>{props.userVariable.IntegerMax}</span>
   </div>
    </div>
    )
  }


    // Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const widgetType = props.widget._attributes.type

// 

  return (

<Grid item lg={12} md={12} sm={12} xs={12} className="mainPannelSideedit">
  

<div>{displayValue()}</div>


</Grid>

);
}