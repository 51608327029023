import React from "react";
import Grid from "@material-ui/core/Grid";
import { Radio, Divider, Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/SystemUtilities.css";
import styles from "assets/js/all-styles/views/systemUtilitiesStyle.js";
import { updateXml, updateSocketEvent, rebootDevice } from "../../services/xmlService";
import { getAppConfigData, profileUserChanger } from "redux/actions/action";
import { bindActionCreators } from "redux";

const useStyles = makeStyles(styles);
const SysUtil = (props) => {
  const initialData = props.initialCbusEvent.get("cbusTimeChanged");


  const classes = useStyles();

  let [infoWiser, setInfoWiser] = React.useState(props.data.Project.Info.Wiser);

  const currentDate = new Date();
  let currentHours = currentDate.getHours();
  let currentMinutes = currentDate.getMinutes();
  let currentSeconds = currentDate.getSeconds();

  let toDayDate = currentDate.getDate();
  let currentMonth = currentDate.getMonth() + 1;
  let currentYear = currentDate.getFullYear();
  let currentYearFirstTwo = currentYear.toString().slice(0, 2);
  let currentYearLastTwo = currentYear.toString().slice(2, 4);

  const [toolkit, setToolkit] = React.useState(
    infoWiser.PGRemoteCNI._text === "1" ? "toolkitenabled" : "toolkitdisabled"
  );
  const [projector, setProjector] = React.useState(
    infoWiser.PGRemoteProjector._text === "1"
      ? "projectorenabled"
      : "projectordisabled"
  );
  const [project, setProject] = React.useState(
    infoWiser.PGRemoteProject._text === "1"
      ? "projectenabled"
      : "projectdisabled"
  );
  const [time, setTime] = React.useState(
    currentHours < 12 ? "timeenabled" : "timedisabled"
  );
  const [dst, setDst] = React.useState("dstenabled");
  const [timehours, setTimehours] = React.useState(
    currentHours <= 12 ? currentHours : currentHours - 12
  );
  const [timeminutes, setTimeminutes] = React.useState(currentMinutes);

  const [sendTime, setSendTime] = React.useState(0);

  const [ntp, setNtp] = React.useState("ntpenabled");
  const [reqcbusdata, setReqcbusdata] = React.useState(null);

  const [ddsysutil, setDdsysutil] = React.useState(toDayDate);
  const [mmsysutil, setMmsysutil] = React.useState(currentMonth);
  const [yysysutil, setYysysutil] = React.useState(currentYearLastTwo);
  const [yyfirsttwo, setYyfirsttwo] = React.useState(currentYearFirstTwo);
  const [flicker, setFlicker] = React.useState(true);

const [sysntp, setSysntp] = React.useState(
  props.data.Project.Info.Wiser.NTP._attributes.UseNTP === "1" ? true : false
);
  const [cbustimesec, setCbustimesec] = React.useState("");
  const [alertMsg, setAlertMsg] = React.useState(false);

  React.useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusTimeChanged") {
          setFlicker(true);
          // if(initialData){
          //   initialData=props.cbusEvent;
          //   let dstData=new Map(props.initialCbusEvent);
          //   props.socketEventDataChanger(dstData);
          // }
        }
      }
    }
    if(initialData && flicker){
      setReqcbusdata(initialData);

      let cbusreqtime = initialData.attributes.time;
      let cbusreqtimesplit = cbusreqtime.split(".");
      let cbusreqmmddhhmmyyyy = cbusreqtimesplit[0];
      let cbusreqtimesec = cbusreqtimesplit[1];
      let cbusdst = initialData.attributes.dst;
      setDst(cbusdst === "1" ? "dstenabled" : "dstdisabled");

      setCbustimesec(cbusreqtimesec);
      let mm = cbusreqmmddhhmmyyyy.slice(0, 2);
      let fm = cbusreqmmddhhmmyyyy.slice(1, 2);

      //checking

      setMmsysutil(
        +cbusreqmmddhhmmyyyy.slice(0, 2) < 10
          ? "0" + cbusreqmmddhhmmyyyy.slice(1, 2)
          : +cbusreqmmddhhmmyyyy.slice(0, 2)
      );
      setDdsysutil(
        +cbusreqmmddhhmmyyyy.slice(2, 4) < 10
          ? "0" + cbusreqmmddhhmmyyyy.slice(3, 4)
          : +cbusreqmmddhhmmyyyy.slice(2, 4)
      );

      // let hourssliced = cbusreqmmddhhmmyyyy.slice(4,6);
      let hourssliced = parseInt(cbusreqmmddhhmmyyyy.slice(4, 6));

      if (hourssliced < 12) {
        setTime("timeenabled");
      } else {
        setTime("timedisabled");
      }
      
      //disabling as ntp is coming as 0 in real time when we set this is to 1 in the xml
      //setSysntp(initialData.attributes.ntp === "1" ? true : false);

      setTimehours(
        +cbusreqmmddhhmmyyyy.slice(4, 6) < 10
          ? +cbusreqmmddhhmmyyyy.slice(5, 6) === 0
            ? 12
            : +cbusreqmmddhhmmyyyy.slice(5, 6)
          : +cbusreqmmddhhmmyyyy.slice(4, 6) <= 12
          ? +cbusreqmmddhhmmyyyy.slice(4, 6)
          : +cbusreqmmddhhmmyyyy.slice(4, 6) - 12
      );
      setTimeminutes(+cbusreqmmddhhmmyyyy.slice(6, 8));

      setYysysutil(+cbusreqmmddhhmmyyyy.slice(10, 12));
      setYyfirsttwo(+cbusreqmmddhhmmyyyy.slice(8, 10));
       setFlicker(false);
    }
  }, [props.cbusEvent]);

  const handlePopupClose = () => {
    setAlertMsg(false);
  };

  const handleChangetoolkit = (event) => {
    setToolkit(event.target.value);
    setAlertMsg(true);
    

      let pData = null;
      if (event.target.value === "toolkitenabled") {
        pData = {"cmd" : "<expose-cni-request/>"};
        props.data.Project.Info.Wiser.PGRemoteCNI._text = "1"

      } else {
        pData = {"cmd" : "<hide-cni-request/>"};
        props.data.Project.Info.Wiser.PGRemoteCNI._text = "0"
      }
      updateSocketEvent(pData)
        .then((res) => {
          
        })
        .catch((error) => {
          
        });

    // updateXml(props.data)
    //   .then((result) => {
    //     if (result.status === 200) {
    //       props.getAppConfigData();
    //       rebootDevice().then((res) => {
            
    //       }).catch((error) => {
            
    //       });
    //     }
    //   })
    //   .catch((error) => {
        
    //   });
  };
  const handleChangeprojector = (event) => {
    setProjector(event.target.value);
    setAlertMsg(true);
      let pData = null;
      if (event.target.value === "projectorenabled") {
        pData = {"cmd" : "<expose-cbus-request/>"};
        props.data.Project.Info.Wiser.PGRemoteProjector._text = "1"
      } else {
        pData = {"cmd" : "<hide-cbus-request/>"};
        props.data.Project.Info.Wiser.PGRemoteProjector._text = "0"
      }
      updateSocketEvent(pData)
        .then((res) => {
          
        })
        .catch((error) => {
          
        });

    // updateXml(props.data)
    //   .then((result) => {
    //     if (result.status === 200) {
    //       props.getAppConfigData();
    //       rebootDevice().then((res) => {
            
    //       }).catch((error) => {
            
    //       });  
    //     }
    //   })
    //   .catch((error) => {
        
    //   });
  };

  const handleChangeproject = (event) => {
    setProject(event.target.value);
    setAlertMsg(true);
      let pData = null;
      if (event.target.value === "projectdisabled") {
        pData = {"cmd" : "<hide-cftp-request/>"};
        props.data.Project.Info.Wiser.PGRemoteProject._text = "0"
      } else {
        pData = {"cmd" : "<expose-cftp-request/>"};
        props.data.Project.Info.Wiser.PGRemoteProject._text = "1"
      }
      updateSocketEvent(pData)
        .then((res) => {
          
        })
        .catch((error) => {
          
        });

    // updateXml(props.data)
    //   .then((result) => {
    //     if (result.status === 200) {
    //       props.getAppConfigData();
    //       rebootDevice().then((res) => {
            
    //       }).catch((error) => {
            
    //       });
    //     }
    //   })
    //   .catch((error) => {
        
    //   });
  };

  const handleSave = (event) => {
    let modifiedhours;

    if (parseInt(timehours) < 12 && time === "timeenabled") {
      modifiedhours = Math.floor(timehours);
    } else if (parseInt(timehours) < 12 && time === "timedisabled") {
      modifiedhours = Math.floor(timehours) + 12;
    } else if (parseInt(timehours) === 12 && time === "timedisabled") {
      modifiedhours = Math.floor(timehours);
    } else {
      modifiedhours = "0";
    }

    let newdst = dst === "dstenabled" ? "1" : "0";

    let yearsin1sttwodigit = (yyfirsttwo < 10
      ? "0" + yyfirsttwo
      : yyfirsttwo
    ).toString();
    let yearsinLasttwodigit = (yysysutil < 10
      ? "0" + yysysutil
      : yysysutil
    ).toString();

    let yearnew = yearsin1sttwodigit + yearsinLasttwodigit;

    let newNTP = sysntp ? "0" : "1";

    let savedateandtime = {
      cmd:
        '<cbus_cmd app="' +
        0xdf +
        '" command="cbusSetTime" year="' +
        yearnew +
        '" month ="' +
        mmsysutil +
        '" day="' +
        ddsysutil +
        '" hours="' +
        modifiedhours +
        '" minutes="' +
        timeminutes +
        '" seconds="' +
        cbustimesec +
        '" dst="' +
        newdst +
        '" ntp="' +
        newNTP +
        '" />',
    };

    
    props.handleSocketEventChange(savedateandtime);
  };

  const handledstEnable = (event) => {
    setDst(event.target.value);
    let modifiedhours;
    let newhours = Math.floor(timehours) + 1;
    let yearsin1sttwodigit = (yyfirsttwo < 10
      ? "0" + yyfirsttwo
      : yyfirsttwo
    ).toString();
    let yearsinLasttwodigit = (yysysutil < 10
      ? "0" + yysysutil
      : yysysutil
    ).toString();

    let yearnew = yearsin1sttwodigit + yearsinLasttwodigit;
    let newMonth = mmsysutil;
    let newDate = ddsysutil;

    let newNTP = sysntp ? "0" : "1";
    let newdst = dst === "dstenabled" ? "1" : "0";

    if (timehours === 11 && time === "timedisabled") {
      modifiedhours = "0";
      let currentDay = new Date(`${+mmsysutil}/${+ddsysutil}/${+yearnew}`);

      let nextDay = new Date(currentDay);
      nextDay.setDate(currentDay.getDate() + 1);
      nextDay.toLocaleDateString();

      yearnew = nextDay.getFullYear();
      newMonth = parseInt(nextDay.getMonth()) + 1;
      newDate = nextDay.getDate();
    } else if (parseInt(timehours) === 12 && time === "timeenabled") {
      modifiedhours = "1";
    } else if (parseInt(timehours) === 12 && time === "timedisabled") {
      modifiedhours = Math.floor(timehours) + 1;
    } else if (parseInt(timehours) <= 11 && time === "timeenabled") {
      modifiedhours = Math.floor(timehours) + 1;
    } else {
      modifiedhours = Math.floor(timehours) + 13;
    }

    let dstenabledtime = {
      cmd:
        '<cbus_cmd app="' +
        0xdf +
        '" command="cbusSetTime" year="' +
        yearnew +
        '" month ="' +
        newMonth +
        '" day="' +
        newDate +
        '" hours="' +
        modifiedhours +
        '" minutes="' +
        timeminutes +
        '" seconds="' +
        cbustimesec +
        '" dst="1" ntp="' +
        newNTP +
        '" />',
    };

    
    props.handleSocketEventChange(dstenabledtime);
  };

  const handledstDisable = (event) => {
    setDst(event.target.value);
    let modifiedhours;
    let newdst = dst === "dstenabled" ? "1" : "0";

    let yearsin1sttwodigit = (yyfirsttwo < 10
      ? "0" + yyfirsttwo
      : yyfirsttwo
    ).toString();
    let yearsinLasttwodigit = (yysysutil < 10
      ? "0" + yysysutil
      : yysysutil
    ).toString();

    let yearnew = yearsin1sttwodigit + yearsinLasttwodigit;
    let newMonth = mmsysutil;
    let newDate = ddsysutil;

    let newNTP = sysntp ? "0" : "1";

    if (parseInt(timehours) === 1 && time === "timedisabled") {
      modifiedhours = "12";
    } else if (parseInt(timehours) === 12 && time === "timeenabled") {
      modifiedhours = Math.floor(timehours) + 11;

      let currentDay = new Date(`${+mmsysutil}/${+ddsysutil}/${+yearnew}`);

      let previousDay = new Date(currentDay);
      previousDay.setDate(currentDay.getDate() - 1);
      previousDay.toLocaleDateString();

      yearnew = previousDay.getFullYear();
      newMonth = parseInt(previousDay.getMonth()) + 1;
      newDate = previousDay.getDate();
    } else if (parseInt(timehours) === 12 && time === "timedisabled") {
      modifiedhours = Math.floor(timehours) - 1;
    } else if (parseInt(timehours) <= 11 && time === "timeenabled") {
      modifiedhours = Math.floor(timehours) - 1;
    } else {
      modifiedhours = Math.floor(timehours) + 11;
    }

    let dstdisabledtime = {
      cmd:
        '<cbus_cmd app="' +
        0xdf +
        '" command="cbusSetTime" year="' +
        yearnew +
        '" month ="' +
        newMonth +
        '" day="' +
        newDate +
        '" hours="' +
        modifiedhours +
        '" minutes="' +
        timeminutes +
        '" seconds="' +
        cbustimesec +
        '" dst="0" ntp="' +
        newNTP +
        '" />',
    };

    
    props.handleSocketEventChange(dstdisabledtime);
  };

  const handleChangetime = (event) => {
    setTime(event.target.value);
  };

  const handleChangeDst = (event) => {
    setDst(event.target.value);
  };

  const handleMonthChange = (event) => {
    const re = /^([0-9][0-9]{0,1}|12)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 12 && re.test(event.target.value))
    ) {
      setMmsysutil(event.target.value);
    }
  };
  const handleDayChange = (event) => {
    const re = /^([0-9][0-9]{0,1}|31)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 31 && re.test(event.target.value))
    ) {
      setDdsysutil(event.target.value);
    }
  };

  const handleYearChange = (event) => {
    const re = /^([0-9][0-9]{0,1}|99)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 99 && re.test(event.target.value))
    ) {
      setYysysutil(event.target.value);
    }
  };

  const handleHoursChange = (event) => {
    const re = /^([0-9][0-9]{0,1}|12)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 12 && re.test(event.target.value))
    ) {
      setTimehours(event.target.value);
    }
  };

  const handleMinutesChange = (event) => {
    const re = /^([0-9][0-9]{0,1}|59)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 59 && re.test(event.target.value))
    ) {
      setTimeminutes(event.target.value);
    }
  };

  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <h3 style={{ color: "#737373", fontWeight: "600" }}>
          {props.language.remoteAccess}
        </h3>
        <Divider style={{ margin: "10px 0", borderBottom: "1px solid #000" }} />
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={4} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.toolkitControl}
          </span>
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={toolkit === "toolkitenabled"}
            onChange={handleChangetoolkit}
            value="toolkitenabled"
            name="radio-button-demo"
            className={
              toolkit === "toolkitenabled" ? classes.colorSecondary : ""
            }
          />{" "}
          {props.language.enabled}
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={toolkit === "toolkitdisabled"}
            onChange={handleChangetoolkit}
            value="toolkitdisabled"
            name="radio-button-demo"
            className={
              toolkit === "toolkitdisabled" ? classes.colorSecondary : ""
            }
          />{" "}
          {props.language.disabled}
        </Grid>
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={4} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.projectorControl}
          </span>
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={projector === "projectorenabled"}
            onChange={handleChangeprojector}
            value="projectorenabled"
            name="projector"
            className={
              projector === "projectorenabled" ? classes.colorSecondary : ""
            }
          />{" "}
          {props.language.enabled}
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={projector === "projectordisabled"}
            onChange={handleChangeprojector}
            value="projectordisabled"
            name="projector"
            className={
              projector === "projectordisabled" ? classes.colorSecondary : ""
            }
          />{" "}
          {props.language.disabled}
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={4} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.projectUpdate}
          </span>
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={project === "projectenabled"}
            onChange={handleChangeproject}
            value="projectenabled"
            name="project"
            className={
              project === "projectenabled" ? classes.colorSecondary : ""
            }
          />{" "}
          {props.language.enabled}
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={project === "projectdisabled"}
            onChange={handleChangeproject}
            value="projectdisabled"
            name="project"
            className={
              project === "projectdisabled" ? classes.colorSecondary : ""
            }
          />{" "}
          {props.language.disabled}
        </Grid>
      </Grid>

      {/* <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center" , marginBottom:"10px" }}
        >


        </Grid> */}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <h3 style={{ color: "#737373", fontWeight: "600" }}>
          {props.language.dateAndTime}
        </h3>
        <Divider style={{ margin: "10px 0", borderBottom: "1px solid #000" }} />
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={4} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.date}
            <span style={{ fontWeight: "600", color: "#737373" }}>
              {" "}
              (mm/dd/yy)
            </span>
          </span>
        </Grid>

        <Grid item lg={1} md={3} sm={6} xs={3}>
        <input
            disabled={sysntp}
            value={mmsysutil}
            className={classes.sysUtilInput}
            onChange={handleMonthChange}
          />
        </Grid>

        <Grid
          item
          lg={1}
          md={3}
          sm={12}
          xs={1}
          style={{ textAlign: "center", height: "34px" }}
        >
          <span>{" / "}</span>
        </Grid>
        <Grid item lg={1} md={3} sm={12} xs={3}>
           <input
            disabled={sysntp}
            value={ddsysutil}
            className={classes.sysUtilInput}
            onChange={handleDayChange}
          />
        </Grid>

        <Grid
          item
          lg={1}
          md={3}
          sm={12}
          xs={1}
          style={{ textAlign: "center", height: "34px" }}
        >
          {" / "}
        </Grid>
        <Grid item lg={1} md={3} sm={12} xs={3}>
          <input
            disabled={sysntp}
            value={yysysutil}
            className={classes.sysUtilInput}
            onChange={handleYearChange}
          />
        </Grid>
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={4} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.time}
            <span style={{ fontWeight: "600", color: "#737373" }}>
              {" "}
              {props.language.timeDescription}
            </span>
          </span>
        </Grid>
        <Grid item lg={1} md={3} sm={6} xs={3}>
          <input
            disabled={sysntp}
            value={timehours}
            className={classes.sysUtilInput}
            onChange={handleHoursChange}
          />
        </Grid>
        <Grid
          item
          lg={1}
          md={3}
          sm={12}
          xs={1}
          style={{ textAlign: "center", height: "34px" }}
        >
          <span>{" : "}</span>
        </Grid>
        <Grid item lg={1} md={3} sm={12} xs={3}>
          <input
            disabled={sysntp}
            value={timeminutes}
            className={classes.sysUtilInput}
            onChange={handleMinutesChange}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={3}
          sm={12}
          xs={6}
          style={{ textAlign: "center", height: "34px", color: "#737373" }}
        >
          <Radio
            checked={time === "timeenabled"}
            onChange={handleChangetime}
            value="timeenabled"
            name="time"
            disabled={sysntp}
            className={time === "timeenabled" ? classes.colorSecondary : ""}
          />{" "}
          am
        </Grid>
        <Grid
          item
          lg={2}
          md={3}
          sm={12}
          xs={6}
          style={{ textAlign: "center", height: "34px", color: "#737373" }}
        >
          <Radio
            checked={time === "timedisabled"}
            onChange={handleChangetime}
            value="timedisabled"
            name="time"
            disabled={sysntp}
            className={time === "timedisabled" ? classes.colorSecondary : ""}
          />{" "}
          pm
        </Grid>
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={4} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.saveDateAndTime}
          </span>
        </Grid>
        <Grid item lg={4} md={3} sm={6} xs={3}>
          <button
            className={classes.sysUtilSaveBtn}
            disabled={sysntp}
            onClick={handleSave}
          >
            {props.language.save}
          </button>
        </Grid>
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={4} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.dstEnabled}
          </span>
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={dst === "dstenabled"}
            onChange={handledstEnable}
            value="dstenabled"
            name="dst"
            className={dst === "dstenabled" ? classes.colorSecondary : ""}
          />{" "}
          {props.language.enabled}
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12} style={{ color: "#737373" }}>
          <Radio
            checked={dst === "dstdisabled"}
            onChange={handledstDisable}
            value="dstdisabled"
            name="dst"
            className={dst === "dstdisabled" ? classes.colorSecondary : ""}
          />{" "}
          {props.language.disabled}
        </Grid>
      </Grid>

      <Dialog
        open={alertMsg}
        onClose={handlePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please wait while your device reboots"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handlePopupClose} className={classes.drawerbtn}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.reducer.data,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      profileUserChanger,
    },
    dispatch
  );

export default SysUtil;