import React from "react";
import "assets/css/SystemUtilities.css";
import styles from "assets/js/all-styles/views/systemUtilitiesStyle.js";
import { connect } from "react-redux";
import { getAppConfigData, setHeaderTitle } from "redux/actions/action";
import { bindActionCreators } from "redux";
import SetupDetails from "views/Settings/SetupDetails.js";
// import SetUpPage from "views/Settings/SetUpPage.js";


function Setup(props) {

  return (
    <div className="mainPannelSideSetUpPage">
     {props.data&&
     <>
     
       <SetupDetails language={props.language} languageNames={props.languageNames} languageName={props.languageName} data = {props.data} setHeaderTitle={props.setHeaderTitle}/>
          </>

            }
    
    </div>
  );
}


const mapStateToProps = (state) => ({
  data: state.reducer.data,
  cbusEvent: state.reducer.cbusEvent,
  languageName: state.reducer.languageName,
  languageNames: state.reducer.languageNames,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      setHeaderTitle
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Setup);