const favoriteStyle = {
    favcontainer:{
        display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
    },
    favh2:{
        margin:"0"
    },
    favh4:{
        margin:"0",
        color:"#83cb02"
    },
    
};
export default favoriteStyle;
