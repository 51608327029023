import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,  
  whiteColor, 
  drawerWidth
} from "assets/js/base-styles.js";

const headerStyle = theme => ({
  appBar: {
    [theme.breakpoints.up("md")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
      position:"relative",
      background: "radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%)",
      marginRight:"0px",
      boxShadow: "0px 6px 6px -3px rgba(34,34,34,0.5)"
      
     
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position:"relative",
      background: "radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%)",
      boxShadow: "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)"
      

    },
    position:"relative",
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",

    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: "#fff",
    border: "0",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  container: {
    ...container,
    minHeight: "50px"
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  appResponsive: {
    top: "8px"
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  homeHeader:{
    width: "100%"
  },
  homeflex:{
    display:"flex",
    flexDirection:"row",
    alignItems: "center",
   width:"100%"

  },
  headerText:{
    fontSize: "24px",
    fontWeight: "600"
  },
  ulhome:{
    listStyleType: "none",
    alignItems: "flex-start",
    width: "50%",
    paddingInlineStart:"20px"

  },
  ulhome2:{
    listStyleType: "none",
    display:"flex",
    width: "50%",
    justifyContent: "flex-end",
    paddingInlineEnd:"20px",
    paddingInlineStart:"0px"

  },
  ulhome2li1:{
    
    textTransform:"none" ,
    marginRight:"15px",
    borderRadius:"3px"
    
  },
  ulhome2img:{
    display: "flex",
    alignItems: "center",
    color:"#83cb02",
    paddingRight:"5px",
    marginRight:"5px",
    fontWeight: "600",
    fontSize: "16px"
  },
  ulhome2ang:{
    color:"#909090",
    display: "flex"
  },
  ulhome2imgsetup:{
    listStyleType: "none",
    display:"flex",
    width: "50%",
    justifyContent: "flex-end",
    paddingInlineEnd:"20px",
    paddingInlineStart:"0px",
    marginBlockStart: "0px",
    marginBlockEnd: "0px"

  },
  ulhome2setup:{
    color: "#fff",
    border: "0",
    cursor: "pointer",
    height: "31px",
    padding: "6px 24px",
    background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
    borderTop: "1px solid #a5a5a5",
    fontWeight: "600",
    borderStyle: "solid",
    fontSize:"14px"
  },

  [theme.breakpoints.down("sm")]: {
   
    ulhome:{
      width:"100% !important"
    }

  },
  mobileheader:{
    width: "100%",
    
  },
  headerimg:{
    marginLeft: "5px",
 
  },
  buttomcolor:{
    color:"#83cb02"
  },
  mobileheaderflex:{
    display:"flex"

  }

 
});

export default headerStyle;
