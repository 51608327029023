import React,{ useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import Co2NowStatsSpeedo from "../PowerStatsGaugeSet/co2/Co2NowStatsSpeedo";
import Co2TodayStatsSpeedo from "../PowerStatsGaugeSet/co2/Co2TodayStatsSpeedo";
import Co2WeekStatsSpeedo from "../PowerStatsGaugeSet/co2/Co2WeekStatsSpeedo";
import Co2MonthStatsSpeedo from "../PowerStatsGaugeSet/co2/Co2MonthStatsSpeedo";
import CO2ChartSet from "../PowerStatCharts/CO2ChartSet";

// style sheet imported locally // 
import styles from '../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

export const CO2PowerStatsSet = React.memo((props) => {
  const classes = useStyles();
  const [powerData, setPowerData] =useState();

  //  useEffect(() => {
  //   if (props.cbusEvent) {
  //     if (props.cbusEvent.name === "cbus_event") {
  //       if (props.cbusEvent.attributes.name === "cbusInstantaneousPowerHistory") {
  //         if (props.cbusEvent.attributes.meter === props.widget.params._attributes.meterno) {
  //           setPowerData(props.cbusEvent.attributes)
  //         }
  //       }
  //     }
  //   }
  //   // if(initialData){
   
  //   // }
  // }, [props.cbusEvent]);

  return (
    <div>
     <Grid container spacing={0} className={classes.powerStatsTablayout}>
       <Divider variant="fullWidth" className={classes.powerStatsTabDivider} />
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.now}
           <Co2NowStatsSpeedo 
            powerData={props.powerData}
            data={props.data} 
            widget={props.widget}
            energy={props.energy}
            meterData={props.meterData}/> 
        </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.today}
           <Co2TodayStatsSpeedo  
           powerData={props.powerData}
           data={props.data} 
           widget={props.widget}
           energy={props.energy}
           meterData={props.meterData} /> 
           </Grid>
       <Grid items md={3} className={classes.PowerStatsTabHeaderText}>
           {props.language.thisWeek}
           <Co2WeekStatsSpeedo 
            powerData={props.powerData}
            data={props.data} 
            widget={props.widget}
            energy={props.energy}
            meterData={props.meterData}/> 
           </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
       {props.language.thisMonth}
       <Co2MonthStatsSpeedo 
        powerData={props.powerData}
        data={props.data} 
        widget={props.widget} 
        energy={props.energy}
        meterData={props.meterData}/> 
       </Grid>

       <Grid items md={12}>
       <CO2ChartSet
         powerData={powerData}
         powerDataHistory={props.powerDataHistory}
         data={props.data}
         widget={props.widget}
         energy={props.energy}
         meterData={props.meterData}
       />
       </Grid>

      </Grid>
      
    </div>
  );
});

export default CO2PowerStatsSet;