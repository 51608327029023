
import {

  transition,
  boxShadow,
  defaultFont,
  infoColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb
} from "assets/js/base-styles.js";

import MenuSelectImg from "assets/img/Menu-Select-Img.png";
let drawerWidth = "100%";
const sidebarStyle = theme => ({
  colorSecondary:{
    color: "#94e405 !important",
 
    
  },
  
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    ...boxShadow,
    width: drawerWidth,
    background: "radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%) !important",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "0",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  

  logo: {
    position: "relative",
    padding: "15px 15px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)"
    }
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: whiteColor
    }
  },
  logoh6text:{
    color: "#477b01",
    textTransform:"none",
    marginBottom:"10px",
    fontWeight: "600"
  },
  
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
    marginLeft: "10px",
    marginRight: "15px"
  },

  img: {
    width:"100%",
    height:"100%",
    borderRadius: "50%",
    //border: "3px solid #538c0d"
  },
  imgcontainer:{
    width: "70px",
    height: "70px"
    
  },
  logoandtext:{
display:"flex",
flexDirection:"column",
justifyContent:"center",
alignItems:"center",
paddingTop:"20px",
borderBottom:"solid 1px rgba(0, 0, 0, 0.12)"

  },


  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8"
    }
  },
  list: {
    
    marginTop: "0px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
    
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    fontWeight:"600",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor
    },
    borderBottom:"solid 1px rgba(0, 0, 0, 0.12)"
   
  },
  itemLink: {
    width: "100%",
    transition: "all 300ms linear",   
    position: "relative",
    display: "flex",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont
  },


  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "20px",
    color: "#000",
    fontWeight: "600"

  },
 
  whiteFont: {
    color: "#000",
    fontWeight: "600"
  },
  imgcon:{
    width:"50px"

  },
  imgwidth:{
width:"100%",
height:"100%"
  },
  scheduleButton:{
    background: "linear-gradient(0deg, rgba(27, 27, 27, 1) 0%, rgba(58, 57, 58, 1) 100% )",
    borderStyle: "solid",
    
    borderTop: "1px solid #a5a5a5" ,
    borderBottom:"0",
    borderLeft:"0",
    borderRight:"0",
    margin:"15px"



  },
  scheduleButtonText: {

    margin: "12px 0",
    fontSize: "25px",
    fontWeight: "600",
    color: "#86D100",
    textTransform:"none",
    paddingLeft:"6px"
    

  },
  root: {
    display: "flex !important",
    flexDirection: "column",
    margin: "5%",
    backgroundColor: "#000000",
    background: "linear-gradient(0deg, rgba(27, 27, 27, 1) 0%, rgba(58, 57, 58, 1) 100% )",
    textTransform: "none",
    height:"90%"
  },
  paddingforschedule: {
    paddingLeft: "25px",
    cursor: "pointer !important",
    width: "100%"
  },
  scheduleButtonTextValOne: {
    margin: "12px 0",
    fontSize: "25px",
    fontWeight: "600",
    color: "#a5a5a5",
    textTransform:"Capitalize",
    paddingLeft:"6px",
    cursor: "pointer"
    
  },
  scheduleCancelbtn: {
    height:"42px",
    padding: "10px 60px",
    color: "#fff",    
    marginRight: "25px",
    background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
    borderStyle: "solid",
    border: "0",
    borderTop: "1px solid #a5a5a5",
    fontWeight: "600",
    cursor: "pointer"

  },

  scheduleSavebtn: {
    height:"42px",
    padding: "10px 32px",
    color: "#fff",    
    marginRight: "25px",
    background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
    borderStyle: "solid",
    border: "0",
    borderTop: "1px solid #a5a5a5",
    fontWeight: "600",
    cursor: "pointer"

  },

  controlPanelIconButtonActive:{
    display:'flex', 
    color:"white",
    width:"55px",
    height:"55px",
    backgroundImage: 'linear-gradient(#181818, #679319) !important',
    borderRight:'solid 2px black  !important',
    borderTop:'solid 1px #747474',
    borderBottom:'solid 2px black',
    borderRadius:'0',
    '&:hover':{
      background: 'linear-gradient( #393939, #578800) !important',
    }
  },
  iconButtonActiveSchedule:{
    display:'flex', 
    color:"#86D100",
    width:"55px",
    height:"55px",
    backgroundImage: 'linear-gradient(#060606, #252525) !important',
    borderRight:'solid 2px black  !important',
    borderTop:'solid 1px #747474',
    borderBottom:'solid 2px black',
    borderRadius:'0',
    '&:hover':{
      background: 'linear-gradient(#4C4C4C, #323232) !important',
    }
  },

  controlPanelIconButton:{
    display:'flex', 
    color:"white",
    width:"55px",
    height:"55px",
    backgroundImage: 'linear-gradient(#393939, #1b1b1b) !important',
    borderRight:'solid 2px black  !important',
    borderTop:'solid 1px #747474',
    borderBottom:'solid 2px black',
    borderRadius:'0',
    '&:hover':{
      background: 'linear-gradient(#4C4C4C, #323232) !important',
    }
  },

  controlPanelIcondayButtonActive:{
    display:'flex', 
    color:"white", 
    width:"40px",
    height:"40px",
    backgroundImage: 'linear-gradient(#181818, #679319) !important',
    borderRight:'solid 2px black  !important',
    borderTop:'solid 1px #747474',
    borderBottom:'solid 2px black',
    borderRadius:'0',
    '&:hover':{
      background: 'linear-gradient( #393939, #578800) !important',
    }
  },

  icondayButtonActiveSchedule:{
    display:'flex', 
    color:"#86D100", 
    width:"40px",
    height:"40px",
    backgroundImage: 'linear-gradient(#060606, #252525) !important',
    borderRight:'solid 2px black  !important',
    borderTop:'solid 1px #747474',
    borderBottom:'solid 2px black',
    borderRadius:'0',
    '&:hover':{
      background: 'linear-gradient(#4C4C4C, #323232) !important',
    }
  },

  controlPanelIcondayButton:{
    display:'flex', 
    color:"white",
    width:"40px",
    height:"40px",
    backgroundImage: 'linear-gradient(#393939, #1b1b1b) !important',
    borderRight:'solid 2px black  !important',
    borderTop:'solid 1px #747474',
    borderBottom:'solid 2px black',
    borderRadius:'0',
    '&:hover':{
      background: 'linear-gradient(#4C4C4C, #323232) !important',
    }
  },


  

  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh-75px)",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch"
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px"
    }
  },


  header:{
    display: "flex",
    flexDirection: "row",
    alignItems:'center',
    background: "linear-gradient(0deg, rgba(27, 27, 27, 1) 0%, rgba(58, 57, 58, 1) 100% )",
    borderTop:'solid 1px #747474',
    fontStyle: 'helvetica',
    padding:'2%',
    paddingLeft:'0',
    borderBottom:'solid 2px black',
    height:"100%"
},
});

export default sidebarStyle;
