import React from "react";
import { useState, useEffect } from "react";
import { Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import tuner from "../../assets/img/tuner.png";
import tunerWhite from "../../assets/img/tunerWhite.png";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography, Button } from "@material-ui/core";
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

export default function EditMp3(props) {
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.data.widget.params._attributes.app).toString(16)
  );
  const classes = useStyles();
  const [sourceList, setSourceList] = useState([]);

  const handleButton = (source) => {
    props.selectItem(source);
    let select = {
      cmd:
        '<cbus_cmd app="' +
        props.data.widget.params._attributes.app +
        '" command="cbusSetLevel" network="' +
        props.data.widget.params._attributes.network +
        '" numaddresses="1" group="' +
        props.data.widget.params._attributes.sourcega +
        '" level="' +
        source.sourceValue +
        '" ramp="0" />',
    };
    console.log(select);
    props.handleSocketEventChange(select);
  };

  

  useEffect(() => {
    let sourceListFull = props.data.widget.params._attributes.sources.split(
      "|"
    );
    let sourceLists = [];
    let sourceName = null;
    let sourceValue = null;

    for (let i = 0; i < sourceListFull.length; i++) {
      let source = { sourceName: null, sourceValue: null };
      if (i % 2 !== 1) {
        sourceName = sourceListFull[i];
      }
      if (i % 2 === 1) {
        sourceValue = sourceListFull[i];
      }
      if (sourceName && sourceValue) {
        source.sourceName = sourceName;
        source.sourceValue = sourceValue;
        sourceLists.push(source);
        sourceName = null;
        sourceValue = null;
      }
    }
    setSourceList(sourceLists);

  }, [props.selectedSource]);
 
  return (
    <div>
      <div className={classes.editWidgetRoot} style={{ paddingTop: "5%" }}>
      <CloseIcon
        onClick={() => {
          props.toggleDrawer1(false);
        }}
        style={{
          display: "flex",
          alignSelf: "flex-end",
          width: "10%",
          color:'white',
           }}
      />
        <div className={classes.editWidgetHeader}>
          <Typography
            variant="h4"
            style={{ color: "#A9A9A9", paddingBottom: "2%" }}
            className={classes.headerText2}
          >
            Select Source
          </Typography>
        </div>
        <Divider className={classes.editWidgetHeaderDivider} />

        <div className={classes.editWidgetPanel}>
          {sourceList.map((source, key) => (
            <Button
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0%",
                justifyContent: "end",
                justifyItems: "left",
              }}
              classsName={
                source.sourceValue == props.selectedSource
                  ? classes.bottomPanelActive
                  : classes.bottomPanel
              }
              style={
                source.sourceValue == props.selectedSource
                  ? {
                      justifyContent: "end",
                      justifyItems: "left",
                      borderRadius: "0",
                      borderTop: "solid 1px #747474",
                      borderBottom: "solid 1px black",
                    }
                  : {
                      background: "",
                      justifyContent: "end",
                      justifyItems: "left",
                      borderRadius: "0",
                    }
              }
              key={key}
              onClick={() => handleButton(source)}
            >
              <IconButton
                aria-label="previous"
                className={classes.Icon1}
                style={{
                  borderRadius: "0",
                  padding: "0%",
                  border: "none",
                  justifyItems: "left",
                  justifyContent: "left",
                }}
              >
                <img
                  src={
                    source.sourceValue == props.selectedSource
                      ? tuner
                      : tunerWhite
                  }
                  style={{ width: "30%", padding: "0%" }}
                />
              </IconButton>
              <Typography
                className={
                  source.sourceValue == props.selectedSource
                    ? classes.topButtonTextActive
                    : classes.topButtonText
                }
                variant="h5"
                style={{ justifyContent: "left" }}
              >
                {source.sourceName}
              </Typography>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
