import { APP_CONFIG_DATA,LOCATION_ID,FUNCTIONGROUP_ID, HEADER_TITLE, APP_AUTH_KEY, FEATURE_PERMISSION, CBUS_EVENT, USER_URL, TAB_CHANGE, LANGUAGES_DATA, POWER_METER_DATA, SOCKET_EVENTS_DATA, SECURITY_STATUS, CURRENT_USER, UPDATE_CURRENT_USER, UPDATE_WIDGET, AUTH_LOGIN, PASSWORD_LOGIN, UNAME_LOGIN } from "../constants/action-types";
import { API_URL } from "../../config/environment";
import axios from 'axios'
import store from '../store';
const state = store.getState();
export function getAppConfigData() {
    
    return async function(dispatch){
        const apiUrl = API_URL+'picedXml/appConfig';
        var res;
        await axios({
            method: 'get',
            url: apiUrl,
          }).then((data) => res=data.data);
        const configData = await res;
        let funGroups=[];
        let locs=[];
        let profls=[];
        if(configData.data.Project.FunctionGroups.FunctionGroup){
            if(!Array.isArray(configData.data.Project.FunctionGroups.FunctionGroup)){
                funGroups.push(configData.data.Project.FunctionGroups.FunctionGroup);
            }else{
                funGroups=configData.data.Project.FunctionGroups.FunctionGroup
            }
        }
        if(configData.data.Project.Locations.Location){
            if(!Array.isArray(configData.data.Project.Locations.Location)){
                locs.push(configData.data.Project.Locations.Location);
            }else{
                locs=configData.data.Project.Locations.Location
            }
        }
        return dispatch({
            type: APP_CONFIG_DATA, 
            data: configData.data,
            functionGroups: funGroups,
            locations: locs,
            widgets: configData.data.Project.Widgets.widget,
            profiles: configData.data.Project.ProfileData,
            appNos: configData.data.Project.AppNos.AppNo,
            // userName: configData.data.Project.Info.Wiser.User._text,
            languageName: configData.data.Project.Info.Wiser.language._attributes.name,
            wiserIpAddress: configData.data.Project.Info.Wiser.PGIPAddress._text,
            userUrl: "profile_" + configData.data.Project.Info.Wiser.User._text + "_0.jpg&"+Date.now()
        });
    } 
}

export function functionGroupIdUpdate(id) {
    return {
        type: FUNCTIONGROUP_ID, 
        data: id
    }
}
export function locationIdUpdate(id) {
    return {
        type: LOCATION_ID, 
        data: id
    }
}
export function setHeaderTitle(name){
    return {
        type: HEADER_TITLE,
        data: name
    }
}
export function getAuthKey(){
    return async function(dispatch){
        const apiUrl = API_URL+'picedXml/getAauthKey';
        var res;
        await fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => res=data);
        const configData = await res;
        return dispatch({
            type: APP_AUTH_KEY,
            authKey: configData.data.cbus_auth_data._attributes.value
        });
    }
}
export function getLanguages(name){
    return async function(dispatch){
        const apiUrl = API_URL+'picedXml/getLanguages';
        var res;
        await axios({
            method: 'get',
            url: apiUrl,
          }).then((data) => res=data.data);
        const languagesData = await res;
        let language=[];
        let languageNames=[];
        for (let i = 0; i < languagesData.languages.language.length; i++) {
            if (languagesData.languages.language[i].name._cdata === name) {
                language = languagesData.languages.language[i].strings.string;
            }
            languageNames.push(languagesData.languages.language[i].name._cdata);
        }
        const languageObject = await language.map(item => ({ [item._attributes.name]: item._cdata }));
        return dispatch({
            type: LANGUAGES_DATA,
            language: Object.assign({}, ...languageObject),
            languageNames: languageNames
        });

    }
}

export function getCurrentUserDetails(userData) {
    let userDetails = userData.split("&");
    let data = {
        remoteUser: "",
        remoteAddress: "",
        isOnLan: ""
    }
    data.remoteUser = userDetails[0].split("=")[1];
    data.remoteAddress = userDetails[1].split("=")[1];
    data.isOnLan = userDetails[1].split("=")[2];
    return {
        type: CURRENT_USER,
        data: data,
        userName: data.remoteUser
    }
}

export function cbusEventChanger(data){
    return {
        type: CBUS_EVENT,
        data: data
    }
}
export function profileUserChanger(data){
    return {
        type: USER_URL,
        data: data
    }
}
export function favouriteOrStatesChanger(data){
    return {
        type: TAB_CHANGE,
        data: data
    }
}

export function profilesUpdate(data){
    return {
        type: FEATURE_PERMISSION,
        data: data
    }
}

export function setLanguage(data){
    return {
        type: FEATURE_PERMISSION,
        data: data
    }
}

export function poweMeterEventChanger(data){
    return {
        type: POWER_METER_DATA,
        data: data
    }
}

export function socketEventDataChanger(data){
    return {
        type: SOCKET_EVENTS_DATA,
        socketEvents: data
    }
}

export function setSecurityStatus(data){
    return {
        type: SECURITY_STATUS,
        securityStatus: data
    }
}


export function updateCurrentuser(data){
    return {
        type: UPDATE_CURRENT_USER,
        remoteUserDetails: data
    }
}

export function updateWidget(data){
    return {
        type: UPDATE_WIDGET,
        widget: data
    }
}
export function updateLoginStatus(data){
    return {
        type: AUTH_LOGIN,
        authLogin: data
    }
}
export function updateLoginUname(data){
    return {
        type: UNAME_LOGIN,
        uname: data
    }
}
export function updateLoginPassword(data){
    return {
        type: PASSWORD_LOGIN,
        password: data
    }
}





