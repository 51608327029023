import React from "react";
import { useState, useEffect } from "react";
//Components imported locally //
import EditWidget from '../editWidget';
import BootstrapTooltip from '../../../assets/widgets/tooltip/tooltip'
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {Button, IconButton, Typography, Drawer, Grid} from '@material-ui/core';
import classNames from "classnames"
import StarsIcon from '@material-ui/icons/Stars'
import EditIcon from '@material-ui/icons/Edit';
// Assets imported locally //
import profileGreen from "../../../assets/img/profileGreen.png";

import styles from '../../../assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);


const UserSystemString = (props)=> {
  const initialData=props.initialCbusEvent.get("usio_var"+props.widget.params._attributes.usio_var);
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] =useState(props.favData);
  const [min, setMin] =useState(props.min==undefined?0: Math.floor(props.min));
  const [userVariable, setUserVariable] =useState();
  
  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) =>{
    setFaData(!fav);
  } 

  useEffect(() => {
    let data = props.data.Project.UserVariables.UserVariable;
    if(data){
      for(let i=0;i<data.length;i++){
        if(data[i]._attributes.id == props.widget.params._attributes.usio_var){
          setUserVariable(data[i]._attributes);
          // let intitialdatavalue = {
          //   value: data[i]._attributes.StringValue
          // }
          // props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, intitialdatavalue);
          // let ioData=new Map(props.initialCbusEvent);
          // props.socketEventDataChanger(ioData);
          setMin(min);
        }
      }
    }
  },[]);
 
  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_resp") {
        if (props.cbusEvent.attributes.command === "cbusSetUserSystemIOStr") {
          if (props.cbusEvent.attributes.sio_index === props.widget.params._attributes.usio_var) {
            //let setTemp = "29.00 &#176;C"
            if(props.cbusEvent.attributes.value.includes("&#176;")){
              setMin(props.cbusEvent.attributes.value.replace("&#176;", "°"));
            }
            else{
            setMin(props.cbusEvent.attributes.value);
            }
            if(initialData){
              initialData.value=props.cbusEvent.attributes.value;
              props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, initialData);
              let ioData=new Map(props.initialCbusEvent);
              props.socketEventDataChanger(ioData);
            }
          } 
        }
      }
    }
    if(initialData){
      if(initialData.value.includes("&#176;")){
        setMin(initialData.value.replace("&#176;", "°"));
      }
      else{
      setMin(initialData.value);
      }
    }
  }, [props.cbusEvent]);

// Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const location = props.widget._attributes.location=== "none" ? "" : props.locations[Number(props.widget._attributes.location)]._attributes.Name;
const functionGroup = props.widget._attributes.functiongroup==="none" ? "" : props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name;
 
return (
  <> { props.data !== undefined &&
  <Grid item lg={4} md={6} sm={6} xs={12}>
    <div className={classes.root} >
    <BootstrapTooltip
          title={
            <React.Fragment>
              <IconButton
               className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
                aria-label="delete"
                onClick={() => {props.handleFavorite(id, props.widget._attributes.type);updateFav(favData);}}
                
              >
              <StarsIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={()=>{ toggleDrawer(true)}}
              >
                < EditIcon />
              </IconButton>
            </React.Fragment>
          }
        >
    <div className={classes.header} >
    <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton} >
          <img src={profileGreen} alt={"bulb"} className={classes.headerIconLogic} style={{padding:'15%'}}  />
          </IconButton>
          <div className={classes.header1} > 
          <Typography variant='h6' className={`${classes.headerTextLogicIO} ${classes.textTruncation} `} >
          {name}
          </Typography>
               
          </div>
         </div>
         </BootstrapTooltip>  
         <Button
        variant="contained"
        disabled='true'
        className={classes.logicIoBottomPanel} 
         >
       <Typography className={classes.bottomPanel2ButtonText} variant='h5' >
        {min}
          </Typography>
       </Button> <Drawer
          variant="temporary"
          anchor={"right"}
          open={draweropen}
          onClose={()=>{toggleDrawer(false)}}
          modal
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
        <EditWidget saveEditedWidget={props.saveEditedWidget} data={props} toggleDrawer={toggleDrawer} />
        </Drawer>
              </div>
              </Grid>
  }
    </> 
  );
}


export default UserSystemString;