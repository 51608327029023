import ReactSpeedometer from "react-d3-speedometer"
import NowKwhRateConsumption from "../../PowerConsumption/kwh/NowKwhRateConsumption"
import { useState, useEffect } from "react";


function NowSpeedo(props) {
const [meterValue, setMeterValue] =useState("");

let meterData = (meterValue/1000).toFixed(2);

let maxMeterValue = (Number(props.widget.params._attributes.max_hourly_w)/1000);

let meterResult = meterData >= 9999 ? 9999 : meterData;

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.current_power);
       }
    }
  }, [props.powerData]);

    return (

      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
  <h6 style={{margin: '0px' }}>{props.language.now}</h6>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxValue={maxMeterValue}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterResult} kW`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a", "#f62406"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={180}
                height={120}
               dimensionUnit="px"
        />
        <NowKwhRateConsumption
          powerData={props.powerData}
          powerDataConsumed={props.powerDataConsumed}
          data={props.data}
          widget={props.widget}
          energy={props.energy}
          language={props.language}
        />
        </div>
    );
}

export default NowSpeedo;