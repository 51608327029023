/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setSecurityStatus
} from "../../redux/actions/action";

import { useState, useEffect } from "react";
import arrow from "../../assets/img/arrow.png";
import lockOpenWhite from "../../assets/img/lockOpenWhite.png";
import armedImg from "../../assets/img/armed.png";
import EditSecurityWidgetFeature from "./editSecurityWidgetFeature.js";
import { Button, Divider, Drawer, Container } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classNames from "classnames";
import styles from "../../assets/widgets/widgetStyles/commonStyles";
import securityAlert from "../../assets/img/securityAlert.png";

const useStyles = makeStyles(styles);
const EditSecurityWidget = (props) => {
  const initialArmedStatusData = props.initialCbusEvent.get(
    "cbusSecGetArmedStatus"
  );
  const initialStatusesData = props.initialCbusEvent.get("cbusSecGetStatuses");
  const initialZoneData = props.initialCbusEvent.get("cbusSecGetZoneStates");
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [zonesList, setZonesList] = useState([]);
  const [armStatus, setArmStatus] = useState("0x0");
  const [alarmCount, setAlarmCount] = useState(0);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };
  const changePassword = (input, number) => {
    let keypad = {
      cmd:
        '<cbus_cmd app="0xd0" command="cbusSecEmulateKeypad" network="' +
        props.data.widget.params._attributes.network +
        '" key="' +
        input +
        '" />',
    }; 
    props.handleSocketEventChange(keypad);
    if (number === "E") {
      setInputPassword("");
    } else {
      setInputPassword(inputPassword + number);
    }
  };

  const initialCmdArmStatus =()=>{
    let armStatus = {"cmd": '<cbus_cmd app=\"208\" command=\"cbusSecGetArmedStatus\" />'}
    props.handleSocketEventChange(armStatus);
  }
  const initialCmdZoneStatus =()=>{
    let armStatus = {"cmd": '<cbus_cmd app=\"208\" command=\"cbusSecGetZoneStates\" numaddresses=\"64\" zone_number=\"1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64\" />'}
    props.handleSocketEventChange(armStatus);
  }

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "system_armed") {
          initialCmdArmStatus();
          // setArmStatus(props.cbusEvent.attributes.arm_code);
          // if (initialArmedStatusData) {
          //   props.initialCbusEvent.set(
          //     "cbusSecGetArmedStatus",
          //     parseInt(props.cbusEvent.attributes.arm_code, 16)
          //   );
          //   let armData = new Map(props.initialCbusEvent);
          //   props.socketEventDataChanger(armData);
          // }
        }
        if (props.cbusEvent.attributes.name === "entry_delay_started") {
          props.setSecurityStatus(props.cbusEvent.attributes.name);
          setArmStatus(props.cbusEvent.attributes.name);
          if (initialArmedStatusData) {
            props.initialCbusEvent.set(
              "cbusSecGetArmedStatus",
              props.cbusEvent.attributes.name
            );
            let armData = new Map(props.initialCbusEvent);
            props.socketEventDataChanger(armData);
          }
        }
        if (props.cbusEvent.attributes.name === "exit_delay_started") {
          props.setSecurityStatus(props.cbusEvent.attributes.name);
          setArmStatus(props.cbusEvent.attributes.name);
          if (initialArmedStatusData) {
            props.initialCbusEvent.set(
              "cbusSecGetArmedStatus",
              props.cbusEvent.attributes.name
            );
            let armData = new Map(props.initialCbusEvent);
            props.socketEventDataChanger(armData);
          }
        }
        if (props.cbusEvent.attributes.name === "zone") {
          initialCmdZoneStatus();
          // if (initialZoneData) {
          //   initialZoneData[
          //     parseInt(props.cbusEvent.attributes.zone_number-1, 16)
          //   ] = props.cbusEvent.attributes.zone_state;
          //   let zData = new Map(props.initialCbusEvent);
          //   props.socketEventDataChanger(zData);
          // }
        }
        if (props.cbusEvent.attributes.name === "arm_status") {
          initialCmdZoneStatus();
          // if (initialZoneData) {
          //   initialZoneData[
          //     parseInt(props.cbusEvent.attributes.zone_number-1, 16)
          //   ] = props.cbusEvent.attributes.zone_state;
          //   let zData = new Map(props.initialCbusEvent);
          //   props.socketEventDataChanger(zData);
          // }
        }
      }
    }

    if (initialArmedStatusData) {
      if (initialArmedStatusData === "entry_delay_started") {
        setArmStatus(initialArmedStatusData);
      } else if (initialArmedStatusData === "exit_delay_started") {
        setArmStatus(initialArmedStatusData);
      } else {
        setArmStatus("0x" + Number(initialArmedStatusData).toString(16)); 
    }
    }
    if (initialStatusesData) {
      let countdata = initialStatusesData.reduce(
        (b, c) => (
          (
            b[b.findIndex((d) => d.el === c)] ||
            b[b.push({ el: c, count: 0 }) - 1]
          ).count++,
          b
        ),
        []
      );
      if(statusExists(countdata,"0x1")){
        for(let j=0;j<countdata.length;j++){
          if(countdata[j].el=="0x1"){
            setAlarmCount(countdata[j].count);
          }
        }
      }else{
        setAlarmCount(0);
      }
    }

    if (initialZoneData) {
      let zs = props.data.widget.params._attributes.zones
        .split("|")
        .filter((number, i) => i % 2 !== 1);
      let statusChangedZones = [];
      for (let i = 0; i < zs.length; i++) {
        let z = {
          zoneNo: null,
          zoneName: null,
          zoneStatus: null,
        };
        z.zoneNo = "0x" + i.toString(16);
        z.zoneName = zs[i];
        z.zoneStatus = "0x0";
        statusChangedZones.push(z);
      }
      setZonesList(statusChangedZones);
      if (statusChangedZones.length > 0) {
        for (let j = 0; j < statusChangedZones.length; j++) {
          statusChangedZones[j].zoneStatus = initialZoneData[j];
        }
      }
      setZonesList(statusChangedZones);
    }
  }, [props.cbusEvent]);

  const statusExists = (arr,element) => {
    return arr.some(function(e) {
      return e.el === element;
    }); 
  }

  const reset = (input) => {
    setInputPassword("");
  };

  const handleArm = () => {
    let arm = {
      cmd:
        '<cbus_cmd app="0xd0" command="cbusSecArmSystem" network="' +
        props.data.widget.params._attributes.network +
        '" arm_mode="' +
        "0xff" +
        '" />',
    };
    props.handleSocketEventChange(arm);
  };
  const handleMonitor = () => {
    let arm = {
      cmd:
        '<cbus_cmd app="0xd0" command="cbusSecArmSystem" network="' +
        props.data.widget.params._attributes.network +
        '" arm_mode="' +
        "0x2" +
        '" />',
    };
    props.handleSocketEventChange(arm);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: "linear-gradient(#3b3b3b, #202020)",
      }}
    >
      <CloseIcon
        onClick={() => {
          props.toggleDrawer1(false);
        }}
        style={{
          display: "flex",
          alignSelf: "flex-end",
          width: "10%",
          color: "white",
        }}
      />
      <div className={classes.editWidgetHeader} style={{ marginTop: "0%" }}>
        <Typography
          variant="h6"
          style={{ paddingLeft: "3%" }}
          className={classes.headerText1}
        >
          {props.widgetCategory === "functionGroup"
                      ? props.location
                      : props.functionGroup}
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#393939",
          borderRadius: "0 !important",
          height: "40%",
          marginTop: "2%",
          marginBottom: "0%",
          paddingTop: "0%",
          paddingBottom: "0%",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
          onClick={() => {
            toggleDrawer(true);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-start",
              flexGrow: "1",
              width: "100%",
              height: "30%",
              paddingLeft: "4%",
            }}
            className={classes.header}
          >
            <Radio
              size="medium"
              style={
                alarmCount == "0" ? { color: "#5DD300" } : { color: "red" }
              }
              checked={true}
              disabled={true}
            />
            <Typography
              variant="h5"
              style={{ color: "#c5c4c4" }}
              className={classes.headerText1}
            >
              {props.data.language.alarm} ({alarmCount})
            </Typography>
            <IconButton>
              <img
                src={arrow}
                className={classes.headerRightIcon}
                style={{ borderLeft: "2px solid #2b2b2b" }}
              />
            </IconButton>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "2%",
          paddingBottom: "2%",
        }}
      >
        <Typography
          style={{ alignSelf: "center", paddingLeft: "5%" }}
          className={classes.headerText1}
        >
          {armStatus === "0x0" && <>{props.data.language.disarmed}</>}
          {armStatus === "0x1" && <>{props.data.language.armed}</>}
          {armStatus === "0x2" && <>{props.data.language.monitor}</>}
          {armStatus === "entry_delay_started" && (
            <>{props.data.language.entryDelay}</>
          )}
          {armStatus === "exit_delay_started" && (
            <>{props.data.language.exitDelay}</>
          )}
        </Typography>
        {alarmCount > 0 && (
          <img
            style={{ width: "8%" }}
            src={securityAlert}
            className={classes.bottomPanelIcon}
          />
        )}
        {alarmCount == 0 && (
          <>
            {armStatus === "0x0" && (
              <img
                src={lockOpenWhite}
                style={{ width: "8%" }}
                className={classes.bottomPanelIcon}
              />
            )}
            {armStatus === "0x1" && (
              <img
                src={armedImg}
                style={{ width: "8%" }}
                className={classes.bottomPanelIcon}
              />
            )}
            {armStatus === "0x2" && (
              <img
                src={armedImg}
                style={{ width: "8%" }}
                className={classes.bottomPanelIcon}
              />
            )}
            {armStatus === "entry_delay_started" && (
              <img
                src={lockOpenWhite}
                style={{ width: "8%" }}
                className={classes.bottomPanelIcon}
              />
            )}
            {armStatus === "exit_delay_started" && (
              <img
                src={lockOpenWhite}
                style={{ width: "8%" }}
                className={classes.bottomPanelIcon}
              />
            )}
          </>
        )}
      </div>
      <div
        style={{
          width: "100%",
          padding: "0%",
          borderRadius: "0",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          style={{
            outine: "groove",
            boderRight: "2px solid ",
            width: "100%",
            borderRadius: "0",
          }}
          className={classes.controlPanelIconButton}
          className={ armStatus==='0x1' ? classes.securitycontrolPanelIconButtonActive: classes.controlPanelIconButton }
          onClick={() => handleArm()}
        >
          <Typography
            style={
              armStatus === "0xff"
                ? {
                    background: "linear-gradient(#4C4C4C, #323232) !important;",
                    padding: "5%",
                  }
                : {
                    background: "linear-gradient(#393939, #1b1b1b) !important;",
                    padding: "5%",
                  }
            }
            className={ armStatus==='0x1' ? classes.bottomTextSecurity: classes.bottomText}
          >
            <>{props.data.language.arm}</>
          </Typography>
        </Button>
        <Button
          style={{
            width: "100%",
            borderRadius: "0",
          }}
          className={classes.controlPanelIconButton}
          className={ armStatus==='0x2' ? classes.securitycontrolPanelIconButtonActive: classes.controlPanelIconButton }
          //className={click1?classes.controlPanelIconButtonActive: classes.controlPanelIconButton  }
          onClick={() => handleMonitor()}
        >
          <Typography
            style={
              armStatus === "0x2"
                ? {
                    background: "linear-gradient(#4C4C4C, #323232) !important;",
                    padding: "5%",
                  }
                : {
                    background: "linear-gradient(#393939, #1b1b1b) !important;",
                    padding: "5%",
                  }
            }
            className={ armStatus==='0x2' ? classes.bottomTextSecurity: classes.bottomText}
          >
            {props.data.language.monitor}
          </Typography>
        </Button>
      </div>
      <input
        type="password"
        value={inputPassword}
        style={{
          height: "35%",
          margin: "7%",
          outline: "none",
          width: "35%",
          padding: "1%",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          backgroundImage: "linear-gradient(#505050, #3b3b3b)",
          fontSize: "1.25rem",
          fontWeight: "600",
          borderRadius: "0%",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "1.2rem",
          marginRight: "10%",
          marginBottom: "8%",
        }}
      >
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Button
            style={{
              borderRadius: "100%",

              backgroundColor: "#2b2b2b",
              margin: "7%",
              color: "white",
              //padding: "25px 10px 5px 45px"
            }}
            onClick={() => changePassword("0x31", "1")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              1{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",

              margin: "7%",
              color: "white",
              // padding: "25px 10px 5px 45px"
            }}
            onClick={() => changePassword("0x32", "2")}
          >
            <Typography
              variant="h5"
              className={classes.headerText1}
              style={{ alignItems: "center" }}
            >
              {" "}
              2{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",

              margin: "7%",
              color: "white",
              //padding: "25px 10px 5px 45px"
            }}
            onClick={() => changePassword("0x33", "3")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              3{" "}
            </Typography>
          </Button>
        </ButtonGroup>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",
              margin: "7%",
              color: "white",
            }}
            disableFocusRipple="true"
            disableRipple="true"
            onClick={() => changePassword("0x34", "4")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              4{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",
              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0x35", "5")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              5{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",

              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0x36", "6")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              6{" "}
            </Typography>
          </Button>
        </ButtonGroup>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",

              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0x37", "7")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              7{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",

              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0x38", "8")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              8{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",

              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0x39", "9")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              9{" "}
            </Typography>
          </Button>
        </ButtonGroup>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",

              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0x2A", "P")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              P{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",
              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0x30", "0")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              0{" "}
            </Typography>
          </Button>
          <Button
            style={{
              borderRadius: "100%",
              backgroundColor: "#2b2b2b",
              margin: "7%",
              color: "white",
            }}
            onClick={() => changePassword("0xD", "E")}
          >
            <Typography variant="h5" className={classes.headerText1}>
              {" "}
              E{" "}
            </Typography>
          </Button>
        </ButtonGroup>
      </div>
      <Divider />
      <Container maxWidth="sm">
        <Typography
          variant="h5"
          className={classes.headerText1}
          style={{ margin: "2%" }}
        >
          {" "}
          {props.data.language.zoneStatus}{" "}
        </Typography>
        <Grid
          container
          spacing={1}
          style={{ marginLeft: "15px", fontSize: "9px", margin: "10px" }}
        >
          <Grid container item xs={12} spacing={2}>
            {zonesList.map((zone, key) => (
              <Grid item xs={6} key={key}>
                <RadioGroup
                  aria-label="zones"
                  name="zones"
                  value={zone.zoneName}
                >
                  <FormControlLabel
                    value={zone.zoneName}
                    control={
                      <Radio
                        style={
                          zone.zoneStatus === "0x0"
                            ? { color: "#5DD300" }
                            : { color: "red" }
                        }
                      />
                    }
                    label={zone.zoneName}
                  />
                </RadioGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>

      <Drawer
        variant="temporary"
        anchor={"right"}
        open={draweropen}
        onClose={() => {
          toggleDrawer(false);
        }}
        modal
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <EditSecurityWidgetFeature
          initialCbusEvent={props.initialCbusEvent}
          socketEventDataChanger={props.socketEventDataChanger}
          cbusEvent={props.cbusEvent}
          saveEditedWidget={props.saveEditedWidget}
          data={props}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </div>
  );
};

const mapStateToProps = state => ({
  securityStatus: state.reducer.securityStatus
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSecurityStatus
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(EditSecurityWidget);
