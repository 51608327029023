// export const API_URL = window.location.origin+":5000/";
// export const SOCKET_URL = window.location.origin+":5000";
// export const ICON_URL = window.location.origin+":5000/icon?name=";

export const API_URL = window.location.origin+"/";
export const SOCKET_URL = (window.location.protocol === "https:") ? ("wss://"+window.location.hostname+":"+window.location.port) : ("ws://"+window.location.hostname+":"+window.location.port);
export const ICON_URL = window.location.origin+"/icon?name=";


// export const API_URL = "http://localhost:5000/";
// export const SOCKET_URL = "http://localhost:5000";
// export const ICON_URL = "http://localhosts:5000/icon?name=";

// export const API_URL = "http://localhost/";
// export const SOCKET_URL = "ws://localhost";
// export const ICON_URL = "http://localhost/icon?name=";