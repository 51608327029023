import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import CO2HourChart from './TotalChartSets/co2/CO2TotalHourChart';
import CO2DaysChart from './TotalChartSets/co2/CO2TotalDaysChart';
import CO2WeekChart from './TotalChartSets/co2/CO2TotalWeekChart';
import CO2MonthsChart from './TotalChartSets/co2/CO2TotalMonthsChart';


const styles = {
  layout: {
    color: "#87d200",
    marginTop: "5vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
  PowerTab: {
    '&:hover': {
      color: '#87d200 !important',
      opacity: 1,
    },
    '&:focus': {
      color: '#87d200 !important',
    },
    '&:active': {
      color: '#87d200 !important',
    },
    '&$selected': {
      color: '#87d200 !important'
    },
  }
};

const useStyles = makeStyles(styles);
const CO2TotalChartSet = React.memo((props) => {
  const classes = useStyles();
  const theme = useTheme();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div style={{ width: '100%', height: 180 }}>
      <Grid container className={classes.layout} >
        <Grid items md={4}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
            TabIndicatorProps={{ style: { background: '#87d200' } }}
          >
            <Tab
              variant="contained"
              label="Hours"
              className={classes.PowerTab}
              {...a11yProps(0)}
              style={{ fontSize: '13px', minWidth: '8px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
            />

            <Tab label="Days"
              className={classes.PowerTab}
              {...a11yProps(1)}
              style={{ fontSize: '13px', minWidth: '8px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
            />
            <Tab label="Weeks"
              className={classes.PowerTab}
              {...a11yProps(2)}
              style={{ fontSize: '13px', minWidth: '8px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
            />
            <Tab label="Months"
              className={classes.PowerTab}
              {...a11yProps(3)}
              style={{ fontSize: '13px', minWidth: '8px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
            />
          </Tabs>
        </Grid>

        <Grid items md={12}>
          <TabPanel value={value} index={0} >
            <CO2HourChart powerStatTotal={props.powerStatTotal} totalPowerDataCharts={props.totalPowerDataCharts} widget={props.widget} energy={props.energy} powerData={props.powerData} data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={1} >
            <CO2DaysChart powerStatTotal={props.powerStatTotal} totalPowerDataCharts={props.totalPowerDataCharts} widget={props.widget} energy={props.energy} powerData={props.powerData} data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={2} >
            <CO2WeekChart powerStatTotal={props.powerStatTotal} totalPowerDataCharts={props.totalPowerDataCharts} widget={props.widget} energy={props.energy} powerData={props.powerData} data={props.data} />
          </TabPanel>
          <TabPanel value={value} index={3} >
            <CO2MonthsChart powerStatTotal={props.powerStatTotal} totalPowerDataCharts={props.totalPowerDataCharts} widget={props.widget} energy={props.energy} powerData={props.powerData} data={props.data} />
          </TabPanel>

        </Grid>
      </Grid>
    </div>
  )
});
export default CO2TotalChartSet;



