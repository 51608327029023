import { SOCKET_EVENTS_DATA } from "../constants/action-types";

const initialState = {
   socketEvents: new Map()
};

function eventReducer(state = initialState, action) {
  const { type, socketEvents } = action;
  switch(type){
      case SOCKET_EVENTS_DATA:
         return{
            ...state,
            socketEvents
         };
      default:
         return state
  }
}

export default eventReducer;