import ReactSpeedometer from "react-d3-speedometer";
import NowDollarRateConsumption from "../../PowerConsumption/dollar/NowDollarRateConsumption";
import { useState, useEffect } from "react";

function NowSpeedo(props) {
  
  const [meterValue, setMeterValue] =useState("");
  //const [tariffPrice, setTariffPrice] = useState([]);
  const [maxMeterValue,  setMaxMeterValue] = useState();
  const [frender,  setFrender] = useState(false);

  let exceedValue = 9999;

  //CurrencyUnit Value
  const currencyUnit = props.widget.params._attributes.currency_units;

  let arrayOfAnalog = [];

  let arrayofPulse = [];

  let combineArray = [];

  if(props.energy.Project.MeasurementAppData !==undefined){
  props.energy.Project.MeasurementAppData.MeasurementChannel.map(obj => { 
    if(obj._attributes.Tariff!==undefined){
      arrayOfAnalog.push(obj._attributes.Tariff);
    }
  } ); }

  if(props.energy.Project.Energy.PulsePowerMeters !==undefined){
  props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter.map(obj => { 
    if(obj._attributes.Tariff!==undefined){
      arrayofPulse.push(obj._attributes.Tariff);
    }
  } ); }

  combineArray = [...arrayOfAnalog, ...arrayofPulse];

  useEffect(() => {
    if (props.energy) {
      if (props.energy.Project.Energy.Tariffs) {
        if (!Array.isArray(props.energy.Project.Energy.Tariffs.Tariff)) {
          let td = props.energy.Project.Energy.Tariffs.Tariff
          let tariffPrice = td._attributes.Price;
          let value =
            (props.widget.params._attributes.max_hourly_w) / 1000 *
            tariffPrice;
          setMaxMeterValue(value.toFixed(2));
          setFrender(true);
        } else {
          let tariffData = props.energy.Project.Energy.Tariffs.Tariff;
          let td = tariffData.filter(
            (x) =>
              x._attributes.id ===
              combineArray[+props.widget.params._attributes.meterno]
          );
          let tariffPrice = td[0]._attributes.Price;
          let value =
            (props.widget.params._attributes.max_hourly_w) / 1000 *
            tariffPrice;
          setMaxMeterValue(value.toFixed(2));
          setFrender(true);
        }
      }
      setTimeout(() => {
        setFrender(false);
      }, 500)
    }
  }, [props.energy]);

  

  let meterResult = Number(meterValue) >= 9999 ? exceedValue : Number(meterValue);

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.current_cost_hour);
       }
    }
  }, [props.powerData]);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
 <h6 style={{margin: '0px'}}>{props.language.now}</h6>
            <ReactSpeedometer 
                forceRender={frender}
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxValue={maxMeterValue}
                value={ Number(meterResult) >= Number(maxMeterValue) ? Number(maxMeterValue) : Number(meterResult) }
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterResult} ${currencyUnit}`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={180}
                height={120}
                dimensionUnit="px"
        />
        <NowDollarRateConsumption
          powerData={props.powerData}
          powerDataConsumed={props.powerDataConsumed}
          data={props.data}
          widget={props.widget}
          energy={props.energy}
          language={props.language} /> 

        </div>
    );
}

export default NowSpeedo;