

const systemUtilitiesStyle = theme => ({
  grid12Center:{alignItems: "center", marginBottom:"10px" },
 
  colorSecondary:{
    color: "#94e405 !important",
   
    
  },
      
      featureButton:{
        
            background: "none !important",
            border: "none",
            padding: "0 !important",
            color:"#fff",
            borderTop:"none",         
            textDecoration: "underline",
            cursor: "pointer",
            "&:disabled":{
              color:"#8C8C8C",
              cursor:"default"
            }
          
  
      },
      drawerbtn: {
        height:"42px",
        padding: "10px 60px",
        color: "#fff",    
        marginRight: "25px",
        background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
        borderStyle: "solid",
        border: "0",
        borderTop: "1px solid #a5a5a5",
        fontWeight: "600",
        cursor: "pointer",
        "&:disabled":{
          color:"#8C8C8C",
          cursor:"default"
        }
      }
      ,

      marcheck1: { 
          input: {
        /* color:#fff; */
        height: "34px",
        background: "linear-gradient(0deg,rgba(3, 3, 3, 1) 0%, rgba(27, 27, 27, 1) 100%)",
        borderStyle: "none",
        borderBottom: "#626262 Solid 1px",
        width: "80%"
      }

    },

    ulhome2setup:{
      color: "#fff",
      border: "0",
      cursor: "pointer",
      height: "31px",
      padding: "5px 24px",
      background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
      borderTop: "1px solid #a5a5a5",
      fontWeight: "600",
      borderStyle: "solid",
      fontSize: "14px"
    },

    ulhome2setupButton:{
      color: "#fff",
      border: "0",
      cursor: "pointer",
      height: "30px",
      padding: "5px 24px",
      background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
      borderTop: "1px solid #a5a5a5",
      fontWeight: "600",
      borderStyle: "solid",
      fontSize: "14px",
      borderRadius: "0px",
      marginTop: "-3px"
    },
    setuppageinput:{
      height: "34px",
      background: "linear-gradient(0deg,rgba(3, 3, 3, 1) 0%,rgba(27, 27, 27, 1) 100%)",
      borderStyle: "none",
      borderBottom: "#626262 Solid 1px",
      width: "70%",
      fontWeight: "600",
      color: "#fff",
      paddingLeft: "20px",
   
      "&:disabled":{
        color:"#8C8C8C",
        cursor:"default",
      }

    },
    drawerPaper: {
      border: "none",
      position: "fixed",
      top: "0",
      bottom: "0",
      left: "0",
      zIndex: "1",
    
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        position: "fixed",
        height: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      
        position: "fixed",
        display: "block",
        top: "0",
        height: "100vh",
        right: "0",
        left: "0",
        zIndex: "1032",
        visibility: "visible",
        overflowY: "visible",
        borderTop: "none",
        textAlign: "left",
        paddingRight: "0px",
        paddingLeft: "0",
        
        
      }
    },

    sysUtilSaveBtn:{
      width: "100%",
      fontWeight: "600",
      border: "0",
      padding: "8px",
      height:"42px",
      padding: "10px 60px",
      background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
      borderStyle: "solid",
      border: "0",
      borderTop: "1px solid #a5a5a5",
      fontWeight: "600",
      color:"#ffffff",
      cursor: "pointer",     
   
      "&:disabled":{
     
        color:"#8C8C8C",
        cursor:"default",

      }

    },
    sysUtilInput:{
      height: "34px",
              background: "linear-gradient(0deg,rgba(3, 3, 3, 1) 0%,rgba(27, 27, 27, 1) 100%)",
              borderStyle: "none",
              borderBottom: "#626262 Solid 1px",
              width: "80%",
              fontWeight: "600",
             
              paddingLeft: "20px",

      color:"#ffffff",
      cursor: "pointer", 

      "&:disabled":{
     
        color:"#8C8C8C",
        cursor:"default",

      }

    },
    headerTextGallery: {
      color: "#bfbfbf",
      fontWeight: "600",
      fontSize: "1rem",
      textTransform: "none",
      paddingTop: "0%",
      width: "100%",
      alignItems: "left",
      justifyContent: "flex-start",
      justifyItems: "flex-start",
      // whiteSpace: "nowrap",
      //  overflow: "hidden",
      // textOverflow: "ellipsis",
    },



  
  });
  export default systemUtilitiesStyle;