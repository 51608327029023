import React from 'react';
import { useState, useEffect } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  } from 'recharts';
  import { curveCardinal } from 'd3-shape';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import XMLParser from "react-xml-parser";

const styles = {
  layout: {
    color: "#87d200",
    marginTop: "2vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
};

const useStyles = makeStyles(styles);

const KwhTotalHourChart = (props) =>{
 const [data, setData] = React.useState(0);
 const minMeterValue = Number(props.widget.params._attributes.max_hourly_gen_w)/1000;
 const maxMeterValue = Number(props.widget.params._attributes.max_hourly_con_w)/1000;
  // useEffect(() => {
  //   if (props.powerStatTotal) {
  //     if(props.powerStatTotal?.attributes?.hourly_energy_totals){
  //       let hourlyEnergy=props.powerStatTotal.attributes.hourly_energy_totals.split(",");
  //       hourlyEnergy.pop();
  //       let hoursData=[];
  //       let hourz=[];
  //       for (let i = 0; i < 24; i++) {
  //          let hour = moment(new Date())
  //           .add(60 * i, 'minutes')
  //           .format('HH');
  //         hourz.push(hour);
  //       }
  //       hourz.reverse();
  //        for (let j = 0; j < hourlyEnergy.length; j++) {
  //         let hrsData = {
  //           name: null, kwh: null
  //         }
  //          hrsData.name = hourz[j]+":00";
  //          let kwh = Math.round(hourlyEnergy[j])/1000;
  //          if(kwh>9999){
  //           hrsData.kwh = maxMeterValue;
  //          }
  //         if(kwh>0 && kwh<9999){
  //           hrsData.kwh = kwh;
  //         }
  //          if(kwh==0){
  //           hrsData.kwh = 0;
  //          }
  //          hoursData.push(hrsData);
  //        }
  //       setData(hoursData);
  //   }
  // }
  // }, []);


   useEffect(() => {
    if (props.totalPowerDataCharts) {
      if(props.totalPowerDataCharts.hourly_energy_totals){
        //let data = "41770.00,45949.99,16110.00,14640.00,-13310.00,-12100.00,-13948.19,-15564.38,-15596.01,-15586.01,-15586.01,-15574.38,-15586.01,-15596.01,-15586.01,-15574.38,-15586.01,-15586.01,-15586.01,-15596.01,-15564.38,-15586.01,-15596.01,-3761.62,";
        let hourlyEnergyReversed=props.totalPowerDataCharts.hourly_energy_totals.split(",").reverse();
        //let hourlyEnergy=data.split(",").reverse();
        //hourlyEnergy.pop();
        let hourlyEnergy = hourlyEnergyReversed.slice(1,25);
        let hoursData=[];
        let hourz=[];
        for (let i = 0; i < 24; i++) {
           let hour = moment(new Date()).add(1, 'hours')
            .add(60 * i, 'minutes')
            .format('HH');
          hourz.push(hour);
        }
        hourz.reverse();
         for (let j = 0; j < hourlyEnergy.length; j++) {
          let hrsData = {
            name: null, kwh: null
          }
           hrsData.name = hourz[j]+":00";
           let kwh = Math.round(hourlyEnergy[j])/1000;
           if(kwh>maxMeterValue){
            hrsData.kwh = maxMeterValue;
           }
          if(kwh<-minMeterValue){
            hrsData.kwh = -minMeterValue;
          }
          if(kwh<maxMeterValue && kwh>-minMeterValue){
            hrsData.kwh = kwh;
          }
           if(kwh==0){
            hrsData.kwh = 0;
           }
           hoursData.push(hrsData);
         }
        setData(hoursData.reverse());
    }
  }
  }, []);

const cardinal = curveCardinal.tension(0.2);
return (
    <div style={{ width: '120%', height: 150, fontSize: '12px' }}>
    <ResponsiveContainer>
     <AreaChart
        width={600}
        height={100}
        data={data}
        margin={{
          top: 0, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
        dataKey="name" 
         />
        <YAxis 
        ticks={[-minMeterValue,0, maxMeterValue]}
        domain={[-minMeterValue, maxMeterValue]} />
        <Tooltip />
        <Area type="monotone" dataKey=" " stroke="#000" fill="#87d200" fillOpacity={0.3} />
        <Area type={cardinal} dataKey="kwh" stroke="#000" fill="#87d200" fillOpacity={0.3} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
)
}
export default React.memo(KwhTotalHourChart);



