import React from "react";
export default function Repeattime(props) {

  let [state, setState] = React.useState({ startTime: props.data.userValue.StartTime===undefined?0:props.data.userValue.StartTime, endTime: props.data.userValue.EndTime===undefined?0:props.data.userValue.EndTime, repeatTime:props.data.userValue.Time===undefined?0:props.data.userValue.Time});

  let [hours, setHours] = React.useState(Math.floor(state.startTime/ 3600));

  let [minutes, setMinutes] = React.useState(((state.startTime)%3600)/60);

  let [endingHours, setEndingHours] = React.useState(Math.floor(state.endTime/ 3600));

  let [endingMinutes, setEndingMinutes] = React.useState(((state.endTime)%3600)/60);

  let [repeatHours, setRepeatHours] = React.useState(Math.floor(state.repeatTime/ 3600));

  let [repeatMinutes, setRepeatMinutes] = React.useState(Math.floor(((state.repeatTime)%3600)/60));
  
  let [repeatSeconds, setRepeatSeconds] = React.useState(((state.repeatTime)%60));


  const startinghourscalculation = (event)=>{
    setHours(parseInt(event.target.value));
    
    
    props.setTotalStartTimeSeconds(((parseInt(event.target.value))*3600)+(minutes*60));
    props.setTimeType("3");

  }
  
  const startingminutescalculation = (event)=>{

    setMinutes(parseInt(event.target.value));
    props.setTotalStartTimeSeconds((hours*3600)+((parseInt(event.target.value))*60));
    props.setTimeType("3");
  }


  const endinghourscalculation = (event)=>{
    setEndingHours(parseInt(event.target.value));
    props.setTotalEndTimeSeconds(((parseInt(event.target.value))*3600)+(endingMinutes*60));
    props.setTimeType("3");

  }
  
  const endingminutescalculation = (event)=>{

    setEndingMinutes(parseInt(event.target.value));
    props.setTotalEndTimeSeconds((endingHours*3600)+((parseInt(event.target.value))*60));
    props.setTimeType("3");
  }

  const repeathourscalculation = (event)=>{
    setRepeatHours(parseInt(event.target.value));
    
    props.setTotalRepeatTimeSeconds(((parseInt(event.target.value))*3600)+(repeatMinutes*60)+repeatSeconds);
    props.setTimeType("3");

  }
  
  const repeatminutescalculation = (event)=>{

    setRepeatMinutes(parseInt(event.target.value));
    props.setTotalRepeatTimeSeconds((repeatHours*3600)+((parseInt(event.target.value))*60)+repeatSeconds);
    props.setTimeType("3");
  }

  const repeatsecondscalculation = (event)=>{

    setRepeatSeconds(parseInt(event.target.value));
    props.setTotalRepeatTimeSeconds((repeatHours*3600)+(repeatMinutes*60)+(parseInt(event.target.value)));
    props.setTimeType("3");
  }





  let renderHours = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={startinghourscalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {hours}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
        </select>
      </>
    );
  };

  let renderMinutes = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={startingminutescalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {minutes}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
              <option value ="24">24</option>
              <option value ="25">25</option>
              <option value ="26">26</option>
              <option value ="27">27</option>
              <option value ="28">28</option>
              <option value ="29">29</option>
              <option value ="30">30</option>
              <option value ="31">31</option>      
              <option value ="32">32</option>
              <option value ="33">33</option>
              <option value ="34">34</option>
              <option value ="35">35</option>
              <option value ="36">36</option>
              <option value ="37">37</option>
              <option value ="38">38</option>
              <option value ="39">39</option>
              <option value ="40">40</option>
              <option value ="41">41</option>
              <option value ="42">42</option>
              <option value ="43">43</option>
              <option value ="44">44</option>
              <option value ="45">45</option>
              <option value ="46">46</option>
              <option value ="47">47</option>
              <option value ="48">48</option>
              <option value ="49">49</option>
              <option value ="50">50</option>
              <option value ="51">51</option>
              <option value ="52">52</option>
              <option value ="53">53</option>
              <option value ="54">54</option>
              <option value ="55">55</option>
              <option value ="56">56</option>
              <option value ="57">57</option>
              <option value ="58">58</option>
              <option value ="59">59</option>
             
        </select>
      </>
    );
  };

  let renderEndingHours = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={endinghourscalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {endingHours}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
        </select>
      </>
    );
  };

  let renderEndingMinutes = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={endingminutescalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {endingMinutes}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
              <option value ="24">24</option>
              <option value ="25">25</option>
              <option value ="26">26</option>
              <option value ="27">27</option>
              <option value ="28">28</option>
              <option value ="29">29</option>
              <option value ="30">30</option>
              <option value ="31">31</option>      
              <option value ="32">32</option>
              <option value ="33">33</option>
              <option value ="34">34</option>
              <option value ="35">35</option>
              <option value ="36">36</option>
              <option value ="37">37</option>
              <option value ="38">38</option>
              <option value ="39">39</option>
              <option value ="40">40</option>
              <option value ="41">41</option>
              <option value ="42">42</option>
              <option value ="43">43</option>
              <option value ="44">44</option>
              <option value ="45">45</option>
              <option value ="46">46</option>
              <option value ="47">47</option>
              <option value ="48">48</option>
              <option value ="49">49</option>
              <option value ="50">50</option>
              <option value ="51">51</option>
              <option value ="52">52</option>
              <option value ="53">53</option>
              <option value ="54">54</option>
              <option value ="55">55</option>
              <option value ="56">56</option>
              <option value ="57">57</option>
              <option value ="58">58</option>
              <option value ="59">59</option>
             
        </select>
      </>
    );
  };

  let renderRepeatHours = () => {
    return (
      <>
        <select className="selectineditschedulesdraweroptions"
          onChange={repeathourscalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {repeatHours}
        >
         

          <option value ="0">00 hours</option>
              <option value ="1">01 hours</option>      
              <option value ="2">02 hours</option>
              <option value ="3">03 hours</option>
              <option value ="4">04 hours</option>
              <option value ="5">05 hours</option>
              <option value ="6">06 hours</option>
              <option value ="7">07 hours</option>
              <option value ="8">08 hours</option>
              <option value ="9">09 hours</option>
              <option value ="10">10 hours</option>
              <option value ="11">11 hours</option>
              <option value ="12">12 hours</option>
              <option value ="13">13 hours</option>
              <option value ="14">14 hours</option>
              <option value ="15">15 hours</option>
              <option value ="16">16 hours</option>
              <option value ="17">17 hours</option>
              <option value ="18">18 hours</option>
              <option value ="19">19 hours</option>
              <option value ="20">20 hours</option>
              <option value ="21">21 hours</option>
              <option value ="22">22 hours</option>
              <option value ="23">23 hours</option>
        </select>
      </>
    );
  };

  let renderRepeatMinutes = () => {
    return (
      <>
        <select className="selectineditschedulesdraweroptions"
          onChange={repeatminutescalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {repeatMinutes}
        >
         
          <option value ="0">00 minutes</option>
              <option value ="1">01 minutes</option>      
              <option value ="2">02 minutes</option>
              <option value ="3">03 minutes</option>
              <option value ="4">04 minutes</option>
              <option value ="5">05 minutes</option>
              <option value ="6">06 minutes</option>
              <option value ="7">07 minutes</option>
              <option value ="8">08 minutes</option>
              <option value ="9">09 minutes</option>
              <option value ="10">10 minutes</option>
              <option value ="11">11 minutes</option>
              <option value ="12">12 minutes</option>
              <option value ="13">13 minutes</option>
              <option value ="14">14 minutes</option>
              <option value ="15">15 minutes</option>
              <option value ="16">16 minutes</option>
              <option value ="17">17 minutes</option>
              <option value ="18">18 minutes</option>
              <option value ="19">19 minutes</option>
              <option value ="20">20 minutes</option>
              <option value ="21">21 minutes</option>
              <option value ="22">22 minutes</option>
              <option value ="23">23 minutes</option>
              <option value ="24">24 minutes</option>
              <option value ="25">25 minutes</option>
              <option value ="26">26 minutes</option>
              <option value ="27">27 minutes</option>
              <option value ="28">28 minutes</option>
              <option value ="29">29 minutes</option>
              <option value ="30">30 minutes</option>
              <option value ="31">31 minutes</option>      
              <option value ="32">32 minutes</option>
              <option value ="33">33 minutes</option>
              <option value ="34">34 minutes</option>
              <option value ="35">35 minutes</option>
              <option value ="36">36 minutes</option>
              <option value ="37">37 minutes</option>
              <option value ="38">38 minutes</option>
              <option value ="39">39 minutes</option>
              <option value ="40">40 minutes</option>
              <option value ="41">41 minutes</option>
              <option value ="42">42 minutes</option>
              <option value ="43">43 minutes</option>
              <option value ="44">44 minutes</option>
              <option value ="45">45 minutes</option>
              <option value ="46">46 minutes</option>
              <option value ="47">47 minutes</option>
              <option value ="48">48 minutes</option>
              <option value ="49">49 minutes</option>
              <option value ="50">50 minutes</option>
              <option value ="51">51 minutes</option>
              <option value ="52">52 minutes</option>
              <option value ="53">53 minutes</option>
              <option value ="54">54 minutes</option>
              <option value ="55">55 minutes</option>
              <option value ="56">56 minutes</option>
              <option value ="57">57 minutes</option>
              <option value ="58">58 minutes</option>
              <option value ="59">59 minutes</option>
             
        </select>
      </>
    );
  };
  let renderRepeatSeconds = () => {
    return (
      <>
        <select className="selectineditschedulesdraweroptions"
          onChange={repeatsecondscalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {repeatSeconds}
        >
          
         <option value ="0">00 seconds</option>
              <option value ="1">01 seconds</option>      
              <option value ="2">02 seconds</option>
              <option value ="3">03 seconds</option>
              <option value ="4">04 seconds</option>
              <option value ="5">05 seconds</option>
              <option value ="6">06 seconds</option>
              <option value ="7">07 seconds</option>
              <option value ="8">08 seconds</option>
              <option value ="9">09 seconds</option>
              <option value ="10">10 seconds</option>
              <option value ="11">11 seconds</option>
              <option value ="12">12 seconds</option>
              <option value ="13">13 seconds</option>
              <option value ="14">14 seconds</option>
              <option value ="15">15 seconds</option>
              <option value ="16">16 seconds</option>
              <option value ="17">17 seconds</option>
              <option value ="18">18 seconds</option>
              <option value ="19">19 seconds</option>
              <option value ="20">20 seconds</option>
              <option value ="21">21 seconds</option>
              <option value ="22">22 seconds</option>
              <option value ="23">23 seconds</option>
              <option value ="24">24 seconds</option>
              <option value ="25">25 seconds</option>
              <option value ="26">26 seconds</option>
              <option value ="27">27 seconds</option>
              <option value ="28">28 seconds</option>
              <option value ="29">29 seconds</option>
              <option value ="30">30 seconds</option>
              <option value ="31">31 seconds</option>      
              <option value ="32">32 seconds</option>
              <option value ="33">33 seconds</option>
              <option value ="34">34 seconds</option>
              <option value ="35">35 seconds</option>
              <option value ="36">36 seconds</option>
              <option value ="37">37 seconds</option>
              <option value ="38">38 seconds</option>
              <option value ="39">39 seconds</option>
              <option value ="40">40 seconds</option>
              <option value ="41">41 seconds</option>
              <option value ="42">42 seconds</option>
              <option value ="43">43 seconds</option>
              <option value ="44">44 seconds</option>
              <option value ="45">45 seconds</option>
              <option value ="46">46 seconds</option>
              <option value ="47">47 seconds</option>
              <option value ="48">48 seconds</option>
              <option value ="49">49 seconds</option>
              <option value ="50">50 seconds</option>
              <option value ="51">51 seconds</option>
              <option value ="52">52 seconds</option>
              <option value ="53">53 seconds</option>
              <option value ="54">54 seconds</option>
              <option value ="55">55 seconds</option>
              <option value ="56">56 seconds</option>
              <option value ="57">57 seconds</option>
              <option value ="58">58 seconds</option>
              <option value ="59">59 seconds</option>
             
        </select>
      </>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ marginRight: "20px", color: "#fff" }}>{props.language.startingAt}</p>
        <div style={{ marginRight: "20px", color: "#fff" }}>
          {renderHours()}
        </div>
        <p style={{ marginRight: "20px", color: "#fff" }}>:</p>
        <div style={{ marginRight: "20px", color: "#fff" }}>
          {renderMinutes()}
        </div>
        <p style={{ marginRight: "20px", color: "#fff" }}>{props.language.endingAt}</p>
        <div style={{ marginRight: "20px", color: "#fff" }}>
          {renderEndingHours()}
        </div>
        <p style={{ marginRight: "20px", color: "#fff" }}>:</p>
        <div style={{ color: "#fff" }}>{renderEndingMinutes()}</div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ marginRight: "14px", color: "#fff" }}>{props.language.repeatInterval}</p>
        <div style={{ marginRight: "28px", color: "#fff" }}>
          {renderRepeatHours()}
        </div>
       
        <div style={{ marginRight: "28px", color: "#fff" }}>
          {renderRepeatMinutes()}
        </div>
        
       
     
        <div style={{ color: "#fff" }}>{renderRepeatSeconds()}</div>
      </div>
    </div>
  );
}
