import React, { Component } from 'react';
// import Carousel from 'react-elastic-carousel';
import '../style.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css'; 
import DollarMonthSpeedoTotal from '../GaugeSet/dollar/DollarMonthSpeedoTotal';
import DollarNowSpeedoTotal from '../GaugeSet/dollar/DollarNowSpeedoTotal';
import DollarTodaySpeedoTotal from '../GaugeSet/dollar/DollarTodaySpeedoTotal';
import DollarWeekSpeedoTotal from '../GaugeSet/dollar/DollarWeekSpeedoTotal';



export default class DollarCardSliderSetTotal extends Component {
    render() 
      {
     
      return (
        <div>
      <AwesomeSlider
      organicArrows={false}
      fillParent={false}
      >
         <div>
          <DollarNowSpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
            <div>
          <DollarTodaySpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
            <div>
          <DollarWeekSpeedoTotal
           totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
          <div>
          <DollarMonthSpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>

</AwesomeSlider>
        </div>
      )
    }
  }

