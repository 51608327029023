import React,  { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import DollarNowStatsSpeedo from "../PowerStatsGaugeSet/dollar/DollarNowStatsSpeedo";
import DollarTodayStatsSpeedo from "../PowerStatsGaugeSet/dollar/DollarTodayStatsSpeedo";
import DollarWeekStatsSpeedo from "../PowerStatsGaugeSet/dollar/DollarWeekStatsSpeedo";
import DollarMonthStatsSpeedo from "../PowerStatsGaugeSet/dollar/DollarMonthStatsSpeedo";
import DollarChartSet from "../PowerStatCharts/DollarChartSet";


// style sheet imported locally // 
import styles from '../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

export const DollarPowerStatsSet = React.memo((props) => {
  const classes = useStyles();
  const [powerData, setPowerData] =useState();

//   useEffect(() => {
//    if (props.cbusEvent) {
//      if (props.cbusEvent.name === "cbus_event") {
//        if (props.cbusEvent.attributes.name === "cbusInstantaneousPowerHistory") {
//          if (props.cbusEvent.attributes.meter === props.widget.params._attributes.meterno) {
//            setPowerData(props.cbusEvent.attributes)
//          }
//        }
//      }
//    }
//    // if(initialData){
  
//    // }
//  }, [props.cbusEvent]);

  return (
    <div>
     <Grid container spacing={0} className={classes.powerStatsTablayout}>
       <Divider variant="fullWidth" className={classes.powerStatsTabDivider} />
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.now}
           <DollarNowStatsSpeedo
            powerData={props.powerData}
            data={props.data} 
            widget={props.widget}
            energy={props.energy}
            meterData={props.meterData} />
        </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.today}
           <DollarTodayStatsSpeedo 
            powerData={props.powerData}
            data={props.data} 
            widget={props.widget}
            energy={props.energy}
            meterData={props.meterData} />
           </Grid>
       <Grid items md={3} className={classes.PowerStatsTabHeaderText}>
            {props.language.thisWeek}
           <DollarWeekStatsSpeedo 
            powerData={props.powerData}
            data={props.data} 
            widget={props.widget}
            energy={props.energy}
            meterData={props.meterData}/>
           </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
       {props.language.thisMonth}
       <DollarMonthStatsSpeedo 
        powerData={props.powerData}
        data={props.data} 
        widget={props.widget}
        energy={props.energy}
        meterData={props.meterData}/>
       </Grid>

       <Grid items md={12}>
       <DollarChartSet
         powerData={powerData}
         powerDataHistory={props.powerDataHistory}
         data={props.data}
         widget={props.widget}
         energy={props.energy}
         meterData={props.meterData}
        />
       </Grid>

      </Grid>
      
    </div>
  );
});

export default DollarPowerStatsSet;