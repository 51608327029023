import React, { useState, useEffect } from "react";
import axios from 'axios';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { getAppConfigData, getAuthKey, cbusEventChanger, poweMeterEventChanger, getLanguages, setLanguage, socketEventDataChanger, getCurrentUserDetails, updateCurrentuser } from './redux/actions/action'
import { connect } from "react-redux";
import { connectToDevice, whoimi } from "./services/xmlService";
import Swal from 'sweetalert2'
import loading from "./assets/img/loading.gif";

// core components
import Admin from "layouts/Admin.js";
import EditSurveillanceWidget from "components/Widgets/editSurveillanceWidget";
import Login from './components/Login';
import { loginCheck } from "./services/xmlService";

import "assets/css/base.css";
const hist = createBrowserHistory();

const App = (props) => {
	const [loader, setLoader] = useState(true);
	const [authLogin, setAuthLogin] = useState(false);

    useEffect(() => {
		let auth = sessionStorage.getItem("auth");
		if (auth) {
			loginCheck(auth).then((res) => {
				if (res.status == 200 || res.status == 304) {
					axios.defaults.headers.common['Authorization'] = `Basic ${auth}`;
					setLoader(false);
					props.getAppConfigData();
				} else {
					setAuthLogin(false);
				}
			}).catch((error) => {
				setAuthLogin(false);
				
			});
		}
	}, []);

	useEffect(() => {
		let auth = sessionStorage.getItem("auth");
		if (auth && props.authLogin) {
			axios.defaults.headers.common['Authorization'] = `Basic ${auth}`;
			// setAuthLogin(true);
			setLoader(false);
			props.getAppConfigData();
		}
	}, [props.authLogin]);

	useEffect(() => {
		if (props.languageName) {
			props.getLanguages(props.languageName);
			if (props.profileData) {
				whoimi().then((res) => {
					
					props.getCurrentUserDetails(res.data);
					let userDetails = res.data.split("&");
					let username = userDetails[0].split("=")[1];
					if (Array.isArray(props.profileData.Profiles.Profile)) {
						let currentuser = props.profileData.Profiles.Profile.filter(x => x._attributes.User === username);
						if (currentuser.length > 0) {
							props.updateCurrentuser(currentuser[0]._attributes);
						}
					} else {
						props.updateCurrentuser(props.profileData.Profiles.Profile._attributes);
					}
				}).catch((error) => {
					
				});
			}
			setAuthLogin(true);
			setTimeout(() => {
				setLoader(true);
			}, 20000);
		}
	}, [props.languageName]);


	if (loader) {
		return (
			<Router history={hist}>
				<Switch>
					{authLogin ? (
						<>
							<Route path="/home" component={Admin} />
							<Route path="/WebCam" component={EditSurveillanceWidget} />
							<Redirect from="/" to="/home/favorite" />
						</>
					) : (
						<>
						<Route path="/" component={Login} />
						</>
					)}
				</Switch>
			</Router>
		)
	}
	return (
		<div>
			<img style={{ width: "100%" }} src={loading} />
		</div>
	)
}

const mapStateToProps = state => ({
	authKey: state.reducer.authKey,
	languages: state.reducer.languages,
	languageName: state.reducer.languageName,
	eventData: state.eventsReducer.socketEvents,
	appNos: state.reducer.appNos,
	language: state.reducer.language,
	profileData: state.reducer.profiles,
	userName: state.reducer.userName,
	authLogin: state.reducer.authLogin
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getAppConfigData, getAuthKey, cbusEventChanger, getLanguages, poweMeterEventChanger, setLanguage, socketEventDataChanger, getCurrentUserDetails, updateCurrentuser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);