import React, { Component } from 'react';
// import Carousel from 'react-elastic-carousel';
import '../style.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import KwhMonthSpeedoTotal from '../GaugeSet/kwh/KwhMonthSpeedoTotal';
import KwhNowSpeedoTotal from '../GaugeSet/kwh/KwhNowSpeedoTotal'
import KwhTodaySpeedoTotal from '../GaugeSet/kwh/KwhTodaySpeedoTotal'
import KwhWeekSpeedoTotal from '../GaugeSet/kwh/KwhWeekSpeedoTotal'



export default class DollarCardSliderSetTotal extends Component {

    render() 
      {
      return (
        <div>
      <AwesomeSlider
      organicArrows={false}
      fillParent={false}
    >
         <div>
          <KwhNowSpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
            <div>
          <KwhTodaySpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
            <div>
          <KwhWeekSpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
          <div>
          <KwhMonthSpeedoTotal
            totalPowerData={this.props.totalPowerData}
            totalPowerDataHistory={this.props.totalPowerDataHistory}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>

</AwesomeSlider>
        </div>
      )
    }
  }

