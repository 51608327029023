import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import './../../style.css';
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

const NowKwhRateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] =useState("");
  const [rateInfo, setRateInfo] =useState("");
  //const [generatorType, setGeneratorType]= useState("");

  //const generation = props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter[0]._attributes.Generator;

//   const generatorValue =(generation)=>{
//     if(generation==1) {
//  return "Rate of Generation"
//     } else if (generation==0){
//       return "Rate of Consumption"
//     } else if (generation==undefined){
//       return " Rate of Generation/ Rate of Consumption "
//     }
//   }

  // const generation = props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter[0]._attributes.Generator;
  //let consumption = props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter[1]._attributes.Generator;

  // let generatorType = ( generation === 1? "Rate of Generation" :(generation===0)? "Rate of Consumption": "Rate of Generation/ Rate of Consumption ")

  //estimated values
  let meterData = (meterValue/1000).toFixed(2).replace("-", " ");

  let meterResult = meterData >= 9999 ? 9999 : meterData;

  //consumption and generated values
   let meterDataInfo = (rateInfo/1000).toFixed(2);

   let meterResult1 = meterDataInfo >= 9999 ? 9999 : meterDataInfo;

   useEffect(() => {
    if (props.totalPowerDataHistory) {
        if (props.totalPowerDataHistory.name === "cbusPowerHistoryTotals") {
         setMeterValue(props.totalPowerDataHistory.predicted_total_power_hour); 
      }
    }
  }, [props.totalPowerDataHistory]);

  useEffect(() => {
    if (props.totalPowerDataHistory !== undefined ) {
       if(props.totalPowerDataHistory.name=== "cbusPowerHistoryTotals"){
        let hourlyEnergy = props.totalPowerDataHistory.hourly_energy_totals.split(",").reverse();
        //let data = "405.83,405.83,405.83,405.83,405.83,405.83,405.83,405.83,405.83,405.83,405.83,405.83,7247.78,7247.78,7247.78,7247.78,7247.78,7247.78,7247.78,7247.78,7247.78,-5670.94,-13786.83,-356.15,";
        //let hourlyEnergy = data.split(",").reverse();
        let hourlyValue = hourlyEnergy[2];
        setRateInfo(hourlyValue);
       }
    }
  }, [props.totalPowerDataHistory]);

  return (
  <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    Rate of Consumption/Generation
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this {props.language.hour}<span style={{wordSpacing:'3px'}}> {meterResult} {props.language.kWh}</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {meterResult1 > 0 ? "Consumed last hr" :  "Generated last hr" } 
    <span style={{wordSpacing:'3px'}}> {meterResult1.toString().replace("-", " ")} {props.language.kWh}</span>
    </Typography>
      </div>
  )
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(NowKwhRateConsumption); 


