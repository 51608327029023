import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/js/all-styles/views/profileStyle.js";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
  } from "@material-ui/core";


import "assets/css/profileStyle.css";
import { PinDropSharp } from "@material-ui/icons";






const useStyles = makeStyles(styles);


const Featuredrawer = ({_attributes, key, setDraweropen, userValue, handleUdateUser, handleChange, handleInitialCHange, handleAdminChange, toggleDrawer, state, language}) =>{

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (key, open) => {

      setOpen(true);

    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleRebootNow =()=>{
      setOpen(false);
      handleUdateUser(key, false);

    }
  

    let userName = "Admin";
    return (
        <>
    
      <div style={{ background: "#3b3b3b" }}>
        <div style={{ padding: "25px 10px 5px 15px" }}>
          <p style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>
          {language.featurePermissions}
          </p>
          <p style={{ color: "#707070", fontWeight: "600", margin: "0" }}>
            {userValue&&userValue.Name}
          </p>
        </div>
        <hr style={{border:"1px solid black", margin:"0", borderTopColor: "transparent"}}/>
        <div style={{ display: "flex", padding: "15px" , justifyContent:"space-between"}}>       
          <button className={classes.drawerbtn}
            onClick={toggleDrawer(false)}          
            
          >
            {language.cancel}
          </button>
          <button 
          className={classes.drawerbtn}
            // disabled={(userValue&&userValue.Admin==="1")}
            // onClick ={()=>handleUdateUser(key, false)}
           
            onClick={()=>handleClickOpen(key, false)}
  
          >
           {language.save}
          </button>
        </div>
      <hr style={{border:"1px solid black", margin:"0", borderTopColor: "transparent"}}/>
 
      </div>

      <div
        style={{ display: "flex", flexDirection: "column", padding: "15px" }}
      >
        <p style={{ color: "#b4b4b4" }}>
          {language.tickOffTheFeatures}
         
        </p>
        <div style={{color:"white"}}>
          <FormGroup>
      
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked1}
                  onChange={handleAdminChange}
                  name="checked1"
                  disabled={userValue&&userValue.Name===userName}
                  // disabled={userValue&&userValue.Admin==="0" || userValue&&userValue.Name===userName}
                />
              }
              label={language.administratorPrivileges}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked2}
                  onChange={handleChange}
                  name="checked2"
                  disabled={state.checked1}
                />
              }
              label={language.profileEnabled}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked3}
                  onChange={handleChange}
                  name="checked3"
                  disabled={state.checked1}
                />
              }
              label={language.toolsAccess}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked4}
                  onChange={handleChange}
                  name="checked4"
                  disabled={state.checked1}
                />
              }
              label={language.timeAccess}
             
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked5}
                  onChange={handleChange}
                  name="checked5"
                  disabled={state.checked1}
                />
              }
              label={language.editPassword}
              
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked6}
                  onChange={handleChange}
                  name="checked6"
                  disabled={state.checked1}
                />
              }
              label={language.editScene}
          
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked7}
                  onChange={handleChange}
                  name="checked7"
                  disabled={state.checked1}
                />
              }
              label={language.editSchedule}
             
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked8}
                  onChange={handleChange}
                  name="checked8"
                  disabled={state.checked1}
                />
              }
              
              label={language.editWidget}
            />
          </FormGroup>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {language.rebootContinueDescription}
          {/* {"You need to reboot for these changes to take effect"} */}
          </DialogTitle>
     
        <DialogActions>
          <Button onClick={handleClose} className={classes.drawerbtn} >
          {language.rebootLater}
      
          </Button>
          <Button onClick={handleRebootNow}   className={classes.drawerbtn}  autoFocus>
          {language.rebootNow}
          
          </Button>
        </DialogActions>
      </Dialog>
    </>
    )

}


export default Featuredrawer;