import { APP_CONFIG_DATA, LOCATION_ID, FUNCTIONGROUP_ID, HEADER_TITLE, APP_AUTH_KEY, FEATURE_PERMISSION, CBUS_EVENT, USER_URL, TAB_CHANGE, LANGUAGES_DATA, SET_LANGUAGES, POWER_METER_DATA, IMAGE_GALLARY, SECURITY_STATUS, CURRENT_USER, UPDATE_CURRENT_USER, UPDATE_WIDGET, AUTH_LOGIN, UNAME_LOGIN, PASSWORD_LOGIN } from "../constants/action-types";

const initialState = {
   headerTitle: "Home",
   userName: "",
   imageloction: "",
   authKey: null,
   cbusEvent: null,
   userUrl: "",
   isFavorite: true,
   languageName: null,
   language: null,
   powerMeterData: null,
   wiserIpAddress: null,
   languageNames: null,
   securityStatus: null,
   currentUserDetails: {},
   widget: null,
   authLogin: false,
   uname: null,
   password: null,
};


function reducer(state = initialState, action) {
   const { type, data, functionGroups, widgets, locations, appNos, profiles, userName, imageloction, authKey, userUrl, language, languageName, wiserIpAddress, languageNames, securityStatus, remoteUserDetails, widget, authLogin, uname, password } = action;
   switch (type) {
      case APP_CONFIG_DATA:
         return {
            ...state,
            data,
            functionGroups,
            locations,
            widgets,
            profiles,
            imageloction,
            userUrl,
            appNos,
            languageName,
            wiserIpAddress,
            securityStatus
         };
      case POWER_METER_DATA:
         return {
            ...state,
            powerMeterData: data
         }
      case LOCATION_ID:
         return {
            ...state,
            location_id: data
         };
      case FUNCTIONGROUP_ID:
         return {
            ...state,
            functionGroup_id: data
         }
      case HEADER_TITLE:
         return {
            ...state,
            headerTitle: data
         }
      case APP_AUTH_KEY:
         return {
            ...state,
            authKey: authKey
         }
      case CBUS_EVENT:
         return {
            ...state,
            cbusEvent: data
         }
      case USER_URL:
         return {
            ...state,
            userUrl: data
         }

      case CURRENT_USER:
         return {
            ...state,
            currentUserDetails: data,
            userName
         }
      case TAB_CHANGE:
         return {
            ...state,
            isFavorite: data
         }
      case LANGUAGES_DATA:
         return {
            ...state,
            language,
            languageNames
         };
      case FEATURE_PERMISSION:
         return {
            ...state,
            profiles: data
         }
      case SET_LANGUAGES:
         return {
            ...state,
            language: data
         }
      case IMAGE_GALLARY:
         return {
            ...state,
            gallary: data
         }
      case SECURITY_STATUS:
         return {
            ...state,
            securityStatus
         }
      case UPDATE_CURRENT_USER:
         return {
            ...state,
            remoteUserDetails
         }
      case UPDATE_WIDGET:
         return {
            ...state,
            widget
         }
      case AUTH_LOGIN:
         return {
            ...state,
            authLogin
         }
      case UNAME_LOGIN:
         return {
            ...state,
            uname
         }
      case PASSWORD_LOGIN:
         return {
            ...state,
            password
         }
      default:
         return state
   }
}

export default reducer;