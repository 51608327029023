/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
import { ICON_URL } from "../../config/environment";
// Assets Import //
import bulbWhite from "../../assets/img/bulbWhite.png";
import bulbGreen from "assets/img/bulbGreen.png";
import alarm from "../../assets/img/alarm.png";
import alarmWhite from "assets/img/alarmWhite.png";
//Components imported locally //
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
//React-Countdown-Timer//
import { useCountdownTimer } from "use-countdown-timer";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
// CSS Style sheets imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function //
export default function Timer(props) {
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.widget.params._attributes.app).toString(16)
  );
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const duration = props.widget.params.param[4]._attributes.value;

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h < 10 ? "0" + h + ":" : h + ":";
    var mDisplay = m < 10 ? "0" + m + ":" : m + ":";
    var sDisplay = s < 10 ? "0" + s : s;

    return hDisplay + mDisplay + sDisplay;
  }

  //const durationToHms = secondsToHms(duration);

  const { countdown, start, reset, isRunning, onExpire } = useCountdownTimer({
    timer: 1000 * duration,
   
  });

  const handleStart = () => {
    start();
    handleOn();
    setTimeout(() => {
      handleOff()
    }, duration*1000);
  };

  const handleReset = () => {
    reset();
    handleOff();
  };

  const handleOn = () => {
    let on = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params._attributes.app +
        '" command="cbusSetLevel" network="' +
        props.widget.params._attributes.network +
        '" numaddresses="1" group="' +
        props.widget.params._attributes.ga +
        '" level="' +
        props.widget.params._attributes.level +
        '" ramp="0"/>',
    };
    props.handleSocketEventChange(on);
  };
  const handleOff = () => {
    let off = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params.param[1]._attributes.value +
        '" command="cbusSetLevel" network="' +
        props.widget.params.param[0]._attributes.value +
        '" numaddresses="1" group="' +
        props.widget.params.param[2]._attributes.value +
        '" level="' +
        props.widget.params._attributes.returnlevel +
        '"ramp="0"/>',
    };
    props.handleSocketEventChange(off);
  };

const timerStart =()=>{
  start();
  setTimeout(() => {
    handleOff()
  }, duration*1000);
};


  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.ga).toString(16)
          ) { 
            // if (
            //   props.cbusEvent.attributes.level ===
            //   "0x" + Number(props.widget.params._attributes.level).toString(16)
            // ) {
            //  timerStart();
            // } else if (
            //   props.cbusEvent.attributes.level ===
            //   "0x" + Number(props.widget.params._attributes.returnlevel).toString(16)
            // ){
            //   reset();
            //   ;
            // }
          }
        }
      }
    }
    if (initialData) {
      if (
        initialData[props.widget.params._attributes.ga] ===
        "0x" + Number(props.widget.params._attributes.level).toString(16)
      ) {
        // start();
      } else {
        // reset();
      }
    }
  }, [props.cbusEvent]);

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "all" ||
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  return (
    <>
      {" "}
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={isRunning ? ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png"}
                    className={classes.headerIcon}
                  />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={
                      isRunning
                        ? `${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
                {isRunning ? (
                  <Button
                    variant="contained"
                    className={classes.controlPanelIconButtonActive}
                    style={{ paddingTop: "2%", paddingBottom: "2%" }}
                    onClick={() => handleReset()}
                  >
                    <Typography
                      style={{ color: "#86D100", textTransform: "none" }}
                      variant="h6"
                    >
                      {props.language.stop}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.controlPanelIconButton}
                    style={{ paddingTop: "2%", paddingBottom: "2%" }}
                    onClick={() => handleStart()}
                  >
                    <Typography
                      style={{ color: "#A9A9A9", textTransform: "none" }}
                      variant="h6"
                    >
                      {props.language.start}
                    </Typography>
                  </Button>
                )}
              </div>
            </BootstrapTooltip>

            <Button
              variant="contained"
              className={classes.bottomPanel1Button}
              onClick={isRunning ? () => handleReset() : () => handleStart()}
            >
              <img
                src={isRunning ? ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png"}
                className={classes.bottomPanelIconAlarm}
              />
              <Typography
                className={
                  isRunning ? classes.bottomTextActive : classes.bottomText
                }
                variant="h6"
              >
                {secondsToHms(countdown / 1000)}
              </Typography>
            </Button>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
