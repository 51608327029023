/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
// Components Imported Locally //
import EditWidget from "./editWidget";
import EditMp3 from "./editMp3";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// import Assets locally //
import analogGreen from "../../assets/img/analogGreen.png";
import analogWhite from "assets/img/analogWhite.png";
import mp3White from "../../assets/img/mp3White.png";
import mp3Green from "../../assets/img/mp3green.png";
import muteGreen from "../../assets/img/mute.png";
import arrow from "../../assets/img/arrow.png";
// material-ui //
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
  Slider,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import styles from "../../assets/widgets/widgetStyles/commonStyles";
import TrebleSlider from "./TrebleSlider";
import BassSlider from "./BassSlider";
require("jquery-nstslider/dist/jquery.nstSlider.js");
const useStyles = makeStyles(styles);

const VolumeSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 28,
    width: 28,
    marginTop: -8,
    marginLeft: -12,
    backgroundImage: "linear-gradient(#B0B0B0, #474747)",
  },
  privateValueLabel: {
    display: "none",
  },
  active: {},
  valueLabel: {
    display: "",
  },
  track: {
    height: 8,
    borderRadius: 4,
    color: "#92E300",
    width: "80%",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    color: "black",
    width: "80%",
  },
})(Slider);

function MusicPlayer(props) {
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.widget.params._attributes.app).toString(16)
  );
  const classes = useStyles();  
  const [draweropen, setDraweropen] = useState(false);
  const [draweropen1, setDraweropen1] = useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [powerToggle, setPowerToggle] = useState(false);
  const [value, setValue] = useState(0);
  const [trebleValue, setTrebleValue] = useState(0);
  const [bassValue, setBassValue] = useState(0);
  const [mute, setMute] = useState(false);
  const [sourceItem, setSourceItem] = useState("");
  const [flicker, setFlicker] = useState(false);
  const [sourceValue, setSourceValue] = useState();
  const [selectedSource, setSelectedSource] = useState();
  const [sourceList, setSourceList] = useState([]);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };
  const toggleDrawer1 = (open) => {
    setDraweropen1(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };
  const handlePowerToggle = (powerToggle) => {
    setPowerToggle(!powerToggle);
  };

  // 
  const selectItem = (source) => {
    setSourceItem(source.sourceName);
    if (initialData) {
      initialData[props.widget.params._attributes.sourcega] =
        source.sourceValue;
      let mraData = new Map(props.initialCbusEvent);
      props.socketEventDataChanger(mraData);
    }
  };

  const handleVolumeSliderChange = (event, newValue) => {
    setValue(newValue);
    const cmdValue = Math.floor(newValue * 2.55);
    const cmdValue1 = { level: cmdValue };
    
  };

  

  const handleVolumeSliderCommittedChange = (event, newValue) => {
     if (newValue > 0) {
      setMute(false);
    } else if (newValue != 100) {
      setValue(newValue);
    }
    const cmdValue = Math.floor(newValue * 2.55);
    const cmdValue1 = { level: cmdValue };
    
    let on = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params._attributes.app +
        '" command="cbusSetLevel" network="' +
        props.widget.params._attributes.network +
        '" numaddresses="1" group="' +
        props.widget.params._attributes.volumega +
        '" level="' +
        cmdValue1.level +
        '" ramp="0" />',
    };
    props.handleSocketEventChange(on);
  };

  const handleTrebleSliderCommittedChange = (newValue, ga) => {
    if (newValue == 50) {
      let cmdTrebleValue1 = { level: "255" };
      let treble = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel"  network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          ga +
          '" level="' +
          cmdTrebleValue1.level +
          '" ramp="0" />',
      };
      props.handleSocketEventChange(treble);
    } else {
      if (newValue < 0) {
        let val = newValue + 50;
        let cmdTrebleValue = Math.floor(val * 2.55);
        let cmdTrebleValue1 = { level: cmdTrebleValue };
        let treble = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params._attributes.app +
            '" command="cbusSetLevel"  network="' +
            props.widget.params._attributes.network +
            '" numaddresses="1" group="' +
            ga +
            '" level="' +
            cmdTrebleValue1.level +
            '" ramp="0" />',
        };
        props.handleSocketEventChange(treble);
      }
      if (newValue > 0) {
        let val = newValue + 50;
        let cmdTrebleValue = Math.floor(val * 2.55);
        let cmdTrebleValue1 = { level: cmdTrebleValue };
        let treble = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params._attributes.app +
            '" command="cbusSetLevel"  network="' +
            props.widget.params._attributes.network +
            '" numaddresses="1" group="' +
            ga +
            '" level="' +
            cmdTrebleValue1.level +
            '" ramp="0" />',
        };
        props.handleSocketEventChange(treble);
      }
      if (newValue == 0) {
        let cmdTrebleValue = 0;
        let cmdTrebleValue1 = { level: cmdTrebleValue };
        let treble = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params._attributes.app +
            '" command="cbusSetLevel"  network="' +
            props.widget.params._attributes.network +
            '" numaddresses="1" group="' +
            ga +
            '" level="' +
            cmdTrebleValue1.level +
            '" ramp="0" />',
        };
        props.handleSocketEventChange(treble);
      }
    }
  };

  const handleBassSliderCommittedChange = (newBassValue, ga) => {
    if (newBassValue == 50) {
      let cmdBassValue1 = { level: "255" };
      let bass = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel"  network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          ga +
          '" level="' +
          cmdBassValue1.level +
          '" ramp="0" />',
      };
      props.handleSocketEventChange(bass);
    } else {
      if (newBassValue < 0) {
        let val = newBassValue + 50;
        const cmdBassValue = Math.floor(val * 2.55);
        const cmdBassValue1 = { level: cmdBassValue };
        let bass = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params.param[1]._attributes.value +
            '" command="cbusSetLevel"  network="' +
            props.widget.params._attributes.network +
            '" numaddresses="1" group="' +
            ga +
            '" level="' +
            cmdBassValue1.level +
            '" ramp="0" />',
        };
        props.handleSocketEventChange(bass);
      }
      if (newBassValue > 0) {
        let val = newBassValue + 50;
        const cmdBassValue = Math.floor(val * 2.55);
        const cmdBassValue1 = { level: cmdBassValue };
        let bass = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params.param[1]._attributes.value +
            '" command="cbusSetLevel"  network="' +
            props.widget.params._attributes.network +
            '" numaddresses="1" group="' +
            ga +
            '" level="' +
            cmdBassValue1.level +
            '" ramp="0" />',
        };
        props.handleSocketEventChange(bass);
      }
      if (newBassValue == 0) {
        const cmdBassValue = 0;
        const cmdBassValue1 = { level: cmdBassValue };
        let bass = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params.param[1]._attributes.value +
            '" command="cbusSetLevel"  network="' +
            props.widget.params._attributes.network +
            '" numaddresses="1" group="' +
            ga +
            '" level="' +
            cmdBassValue1.level +
            '" ramp="0" />',
        };
        props.handleSocketEventChange(bass);
      }
    }
  };

  const handleMute = (value) => {
    setValue(0);
    setMute(true);
    let volumeMute = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params.param[1]._attributes.value +
        '" command="cbusSetLevel" network="' +
        props.widget.params._attributes.network +
        '" numaddresses="1" group="' +
        props.widget.params._attributes.volumega +
        '" level="0" ramp="0" />',
    };
    props.handleSocketEventChange(volumeMute);
  };

  const handleUnMute = (value) => {
    let volumeUnMute = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params.param[1]._attributes.value +
        '" command="cbusSetLevel" network="' +
        props.widget.params._attributes.network +
        '" numaddresses="1" group="' +
        props.widget.params._attributes.volumega +
        '" level="255" ramp="0" />',
    };
    props.handleSocketEventChange(volumeUnMute);
  };

 
  useEffect(() => {

    let sourceListFull = props.widget.params._attributes.sources.split("|");
    let sourceLists = [];
    let sourceName = null;
    let sourceValue1 = null;
  
    for (let i = 0; i < sourceListFull.length; i++) {
      let source = { sourceName: null, sourceValue: null };
      if (i % 2 !== 1) {
        sourceName = sourceListFull[i];
      }
      if (i % 2 === 1) {
        sourceValue1 = sourceListFull[i];
      }
      if (sourceName && sourceValue1) {
        source.sourceName = sourceName;
        source.sourceValue = sourceValue1;
        sourceLists.push(source);
        sourceName = null;
        sourceValue1 = null;
      }
    } 
    setSourceList(sourceLists);
    if(initialData) {
      if (initialData[props.widget.params._attributes.sourcega]) {
        let changedSourceValue = parseInt(
          initialData[props.widget.params._attributes.sourcega],
          16
        );
        setSelectedSource(changedSourceValue);
        for (let k = 0; k < sourceLists.length; k++) {
          if (sourceLists[k].sourceValue == changedSourceValue) {
            setSourceItem(sourceLists[k].sourceName);
          }
        }
      }
    }
  },[])
 

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.volumega).toString(16)
          ) {
            if (parseInt(props.cbusEvent.attributes.level, 16) == "0") {
              setMute(true);
              setValue(0);
            } else if (parseInt(props.cbusEvent.attributes.level, 16) == 255) {
              setMute(true);
              setValue(0);
            } else {
              let changedValue =
                parseInt(props.cbusEvent.attributes.level, 16) / 2.55;
              setValue(changedValue);
              if (changedValue > 0 ) setMute(false);
            }
            if (initialData) {
              initialData[props.widget.params._attributes.volumega] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.treblega).toString(16)
          ) {
            if (initialData) {
              initialData[props.widget.params._attributes.treblega] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.bassga).toString(16)
          ) {
            if (initialData) {
              initialData[props.widget.params._attributes.bassga] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.sourcega).toString(16)
          ) {
            let changedSourceValue =
              parseInt(props.cbusEvent.attributes.level, 16) / 2.55;
            setSourceValue(changedSourceValue);
            if (initialData) {
              initialData[props.widget.params._attributes.sourcega] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }
        }
      }
    }
    if (initialData) {
      if (initialData[props.widget.params._attributes.volumega]) {
        if (
          parseInt(initialData[props.widget.params._attributes.volumega], 16) ==
          "0"
        ) {
          setMute(true);
          setValue(0);
        } else if (
          parseInt(initialData[props.widget.params._attributes.volumega], 16) ==
          "255"
        ) {
          setMute(true);
          setValue(0);
        }
          else {
          let changedValue =
            parseInt(
              initialData[props.widget.params._attributes.volumega],
              16
            ) / 2.55;
           setValue(changedValue);
          if (changedValue > 0 ) setMute(false); 
        }
      }
      if (initialData[props.widget.params._attributes.treblega]) {
        let changedTrebleValue = Math.round(
          parseInt(initialData[props.widget.params._attributes.treblega], 16) /
            2.55
        );
        if (changedTrebleValue > 50) {
          setTrebleValue(changedTrebleValue - 50);
        }
        if (changedTrebleValue < 50) {
          setTrebleValue(-(50 - changedTrebleValue));
        }
        if (changedTrebleValue == 0) {
          setTrebleValue(-50);
        }
        if (changedTrebleValue == 50) {
          setTrebleValue(0);
        }
      }
      if (initialData[props.widget.params._attributes.bassga]) {
        let changedBassValue = Math.round(
          parseInt(initialData[props.widget.params._attributes.bassga], 16) /
            2.55
        );
        if (changedBassValue > 50) {
          setBassValue(changedBassValue - 50);
        }
        if (changedBassValue < 50) {
          setBassValue(-(50 - changedBassValue));
        }
        if (changedBassValue == 0) {
          setBassValue(-50);
        }
        if (changedBassValue == 50) {
          setBassValue(0);
        }
      }
      if (initialData[props.widget.params._attributes.sourcega]) {
        let changedSourceValue = parseInt(
          initialData[props.widget.params._attributes.sourcega],
          16
        );
        setSelectedSource(changedSourceValue);

        for (let k = 0; k < sourceList.length; k++) {
          if (sourceList[k].sourceValue == changedSourceValue) {
            setSourceItem(sourceList[k].sourceName);
          }
        }
      }
    }
  }, [props.cbusEvent]);

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.locations[Number(props.widget._attributes.location)]._attributes.Name;
  const functionGroup =
    props.functionGroups[Number(props.widget._attributes.functiongroup)]
      ._attributes.Name;

  return (
    <>
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                    style={{}}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="headerIcon"
                  className={classes.headerIconButton}
                  disabled="true"
                >
                  <img src={mp3Green} className={classes.headerIcon} />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={`${classes.headerText1Active} ${classes.textTruncation} `}
                  >
                    {sourceItem}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
                <IconButton
                  aria-label="headerRightIcon"
                  className={classes.headerRightIconButton}
                  onClick={() => {
                    toggleDrawer1(true);
                  }}
                >
                  <img
                    src={arrow}
                    className={classes.headerRightIcon}
                    style={{ width: "80%" }}
                  />
                </IconButton>
              </div>
            </BootstrapTooltip>
            <Button
              variant="contained"
              className={
                powerToggle ? classes.bottomPanelActive : classes.bottomPanel
              }
              style={{ borderRadius: "0%", padding: "2%" }}
              onClick={() => handlePowerToggle(powerToggle)}
            >
              <img
                src={powerToggle ? analogGreen : analogWhite}
                className={classes.musicPanelIcon}
              />
            </Button>
            <div
              className={
                powerToggle
                  ? classes.musicTreblePanel
                  : classes.musicTreblePanelActive
              }
            >
              <Typography
                className={classes.headerText1}
                style={{ paddingLeft: "2%" }}
              >
                {" "}
                Treble{" "}
              </Typography>
              {trebleValue > 0 && (
                <TrebleSlider
                  treblega={props.widget.params._attributes.treblega}
                  widget={props.widget}
                  handleTrebleSliderCommittedChange={
                    handleTrebleSliderCommittedChange
                  }
                  value={trebleValue}
                />
              )}
              {trebleValue < 0 && (
                <TrebleSlider
                  treblega={props.widget.params._attributes.treblega}
                  widget={props.widget}
                  handleTrebleSliderCommittedChange={
                    handleTrebleSliderCommittedChange
                  }
                  value={trebleValue}
                />
              )}
              {trebleValue == 0 && (
                <TrebleSlider
                  treblega={props.widget.params._attributes.treblega}
                  widget={props.widget}
                  handleTrebleSliderCommittedChange={
                    handleTrebleSliderCommittedChange
                  }
                  value={trebleValue}
                />
              )}
            </div>
            <div
              className={
                powerToggle
                  ? classes.musicTreblePanel
                  : classes.musicTreblePanelActive
              }
            >
              <Typography
                className={classes.headerText1}
                style={{ paddingLeft: "2%" }}
              >
                {" "}
                Bass{" "}
              </Typography>
              {bassValue > 0 && (
                <BassSlider
                  bassga={props.widget.params._attributes.bassga}
                  handleBassSliderCommittedChange={
                    handleBassSliderCommittedChange
                  }
                  value={bassValue}
                />
              )}
              {bassValue < 0 && (
                <BassSlider
                  bassga={props.widget.params._attributes.bassga}
                  handleBassSliderCommittedChange={
                    handleBassSliderCommittedChange
                  }
                  value={bassValue}
                />
              )}
              {bassValue == 0 && (
                <BassSlider
                  bassga={props.widget.params._attributes.bassga}
                  handleBassSliderCommittedChange={
                    handleBassSliderCommittedChange
                  }
                  value={bassValue}
                />
              )}
            </div>
            <div className={classes.mp3BottomPanel}></div>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <IconButton
                aria-label="previous"
                className={
                  value > 0
                    ? classes.controlPanelIconButton
                    : classes.controlPanelIconButtonActive
                }
              >
                <img
                  src={muteGreen}
                  onClick={
                    mute == true ? () => handleUnMute() : () => handleMute()
                  }
                  className={classes.controlPanelIcon}
                  style={{ width: "50%", padding: "15%" }}
                />
              </IconButton>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  flexGrow: "1",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  alignItems: "center",
                }}
              >
                <VolumeDown style={{ padding: "5%" }} />

                <VolumeSlider
                  style={{ padding: "10%", width: "80%" }}
                  value={value}
                  onChange={handleVolumeSliderChange}
                  onChangeCommitted={handleVolumeSliderCommittedChange}
                  aria-labelledby="continuous-slider"
                />

                <VolumeUp style={{ padding: "5%" }} />
              </div>
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen1}
              onClose={() => {
                toggleDrawer1(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <EditMp3
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                initialCbusEvent={props.initialCbusEvent}
                toggleDrawer={toggleDrawer}
                toggleDrawer1={toggleDrawer1}
                selectItem={selectItem}
                selectedSource={selectedSource}
                handleSocketEventChange={props.handleSocketEventChange}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}

export default MusicPlayer;
