import React, { Component } from 'react';
// import Carousel from 'react-elastic-carousel';
import '../style.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import KwhMonthSpeedo from '../GaugeSet/kwh/KwhMonthSpeedo';
import KwhNowSpeedo from '../GaugeSet/kwh/KwhNowSpeedo'
import KwhTodaySpeedo from '../GaugeSet/kwh/KwhTodaySpeedo'
import KwhWeekSpeedo from '../GaugeSet/kwh/KwhWeekSpeedo'



export default class KWHCardSliderSet extends Component {

    render () {
     
      return (
        <div>
        <AwesomeSlider
        organicArrows={false}
        fillParent={false}
      >
          <div>
          <KwhNowSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget} 
            energy={this.props.energy}
            language={this.props.language}/>
            </div>
            <div>
          <KwhTodaySpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language} />
            </div>
            <div>
          <KwhWeekSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
            <div>
          <KwhMonthSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
            </div>
       </AwesomeSlider>
  </div>
      )
    }
  }