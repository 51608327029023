export const APP_CONFIG_DATA = "APP_CONFIG_DATA";
export const FAVOURITE_DATA = "FAVOURITE_DATA";
export const FUNCTIONGROUP_ID = "FUNCTIONGROUP_ID";
export const LOCATION_ID = "LOCATION_ID";
export const HEADER_TITLE ="HEADER_TITLE";
export const FEATURE_PERMISSION ="FEATURE_PERMISSION";
export const APP_AUTH_KEY = "APP_AUTH_KEY";
export const CBUS_EVENT = "CBUS_EVENT";
export const USER_URL = "USER_URL";
export const TAB_CHANGE = "TAB_CHANGE";
export const LANGUAGES_DATA = "LANGUAGES_DATA";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const POWER_METER_DATA = "POWER_METER_DATA";
export const SOCKET_EVENTS_DATA = "SOCKET_EVENTS_DATA";
export const IMAGE_GALLARY = "IMAGE_GALLARY";
export const SECURITY_STATUS = "SECURITY_STATUS";
export const CURRENT_USER = "CURRENT_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const UPDATE_WIDGET = "UPDATE_WIDGET";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const PASSWORD_LOGIN = "PASSWORD_LOGIN";
export const UNAME_LOGIN = "UNAME_LOGIN";