import React from "react";
import { Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/js/all-styles/views/schedulesStyle.js";

const useStyles = makeStyles(styles);

export default function Sunsettime(props) {
  const classes = useStyles();


  let [state, setState] = React.useState({ Time: props.data.userValue.Time});


  let [hours, setHours] = React.useState(Math.floor(Math.abs(state.Time/ 3600)));

  let [minutes, setMinutes] = React.useState(Math.floor(Math.abs(((state.Time)%3600)/60)));

    const [timevalue, settimevalue] = React.useState(state.Time>=0?"timeplus":"timeminus");

    const hourscalculation = (event)=>{
      setHours(event.target.value);
      let secondsWithoutSign = (((event.target.value)*3600)+(minutes*60));
      let secondsWithSign = (timevalue==="timeplus"?secondsWithoutSign:(-1*secondsWithoutSign));
      props.setTotalSeconds(secondsWithSign);
      props.setTimeType("2");

    }
    
    const minutescalculation = (event)=>{

      setMinutes(event.target.value);
      let secondsWithoutSign = ((hours*3600)+((event.target.value)*60));
      let secondsWithSign = (timevalue==="timeplus"?secondsWithoutSign:(-1*secondsWithoutSign));
      props.setTotalSeconds(secondsWithSign);
      props.setTimeType("2");
    }


    let renderHours = () => {
      return (
        <>
          <select className="selectineditschedulesoptions"
            onChange={hourscalculation}
            style={{ color: "#fff", backgroundColor: "#535353" }}
            value = {hours}
          >
            <option value ="0">00</option>
                <option value ="1">01</option>      
                <option value ="2">02</option>
                <option value ="3">03</option>
                <option value ="4">04</option>
                <option value ="5">05</option>
                <option value ="6">06</option>
                <option value ="7">07</option>
                <option value ="8">08</option>
                <option value ="9">09</option>
                <option value ="10">10</option>
                <option value ="11">11</option>
                <option value ="12">12</option>
                <option value ="13">13</option>
                <option value ="14">14</option>
                <option value ="15">15</option>
                <option value ="16">16</option>
                <option value ="17">17</option>
                <option value ="18">18</option>
                <option value ="19">19</option>
                <option value ="20">20</option>
                <option value ="21">21</option>
                <option value ="22">22</option>
                <option value ="23">23</option>
          </select>
        </>
      );
    };
  
    let renderMinutes = () => {
      return (
        <>
          <select className="selectineditschedulesoptions"
            onChange={minutescalculation}
            style={{ color: "#fff", backgroundColor: "#535353" }}
            value = {minutes}
          >
            <option value ="0">00</option>
                <option value ="1">01</option>      
                <option value ="2">02</option>
                <option value ="3">03</option>
                <option value ="4">04</option>
                <option value ="5">05</option>
                <option value ="6">06</option>
                <option value ="7">07</option>
                <option value ="8">08</option>
                <option value ="9">09</option>
                <option value ="10">10</option>
                <option value ="11">11</option>
                <option value ="12">12</option>
                <option value ="13">13</option>
                <option value ="14">14</option>
                <option value ="15">15</option>
                <option value ="16">16</option>
                <option value ="17">17</option>
                <option value ="18">18</option>
                <option value ="19">19</option>
                <option value ="20">20</option>
                <option value ="21">21</option>
                <option value ="22">22</option>
                <option value ="23">23</option>
                <option value ="24">24</option>
                <option value ="25">25</option>
                <option value ="26">26</option>
                <option value ="27">27</option>
                <option value ="28">28</option>
                <option value ="29">29</option>
                <option value ="30">30</option>
                <option value ="31">31</option>      
                <option value ="32">32</option>
                <option value ="33">33</option>
                <option value ="34">34</option>
                <option value ="35">35</option>
                <option value ="36">36</option>
                <option value ="37">37</option>
                <option value ="38">38</option>
                <option value ="39">39</option>
                <option value ="40">40</option>
                <option value ="41">41</option>
                <option value ="42">42</option>
                <option value ="43">43</option>
                <option value ="44">44</option>
                <option value ="45">45</option>
                <option value ="46">46</option>
                <option value ="47">47</option>
                <option value ="48">48</option>
                <option value ="49">49</option>
                <option value ="50">50</option>
                <option value ="51">51</option>
                <option value ="52">52</option>
                <option value ="53">53</option>
                <option value ="54">54</option>
                <option value ="55">55</option>
                <option value ="56">56</option>
                <option value ="57">57</option>
                <option value ="58">58</option>
                <option value ="59">59</option>
               
          </select>
        </>
      );
    };
      const handleChangetimevalue = (event) => {
        settimevalue(event.target.value);
       
        let secondsWithoutSign = ((hours*3600)+((minutes)*60));
        let secondsWithSign = ((event.target.value)==="timeplus"?secondsWithoutSign:(-1*secondsWithoutSign));
        props.setTotalSeconds(secondsWithSign);
        props.setTimeType("2");
      };
  



    return (

      <div style={{ display: "flex", alignItems:"center" }}>
      <div>
        <div style={{ alignItems: "center" }}>
          <Radio
            checked={timevalue === "timeplus"}
            onChange={handleChangetimevalue}
            value="timeplus"
            name="timevalue"
            className={timevalue === "timeplus"?classes.colorSecondary:""}
          />
          <span style={{color:"#fff", fontWeight:"600"}}>+</span>
        </div>
        <div style={{  alignItems: "center" }}>
          <Radio
            checked={timevalue === "timeminus"}
            onChange={handleChangetimevalue}
            value="timeminus"
            name="timevalue"
            className={timevalue === "timeminus"?classes.colorSecondary:""}
          
          />

          <span style={{color:"#fff", fontWeight:"600"}}>-</span>
        </div>
      </div>
      <div style={{ marginRight: "20px", color: "#fff", marginLeft:"10px" }}>{renderHours()}</div>
      <p style={{ marginRight: "20px", color: "#fff" }}>:</p>
      <div style={{ color: "#fff" }}>{renderMinutes()}</div>
    </div>
       
      )

    


}