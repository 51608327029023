import React from "react";
import { useState, useEffect } from "react";
import {Divider, Typography} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { setHeaderTitle } from '../../redux/actions/action'
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import styles from '../../assets/widgets/widgetStyles/commonStyles'
import { updateSocketEvent, saveProject } from "../../services/xmlService";
const useStyles = makeStyles(styles);

const EditWidget = (props) => {
    const classes = useStyles();
    let [name, setName] = useState(props.data.widget.params._attributes.label!=undefined ? props.data.widget.params._attributes.label:"");
    let [functionGroup, setFunctionGroup] = useState(props.data.widget._attributes.functiongroup==='none'?"":props.data.functionGroups[Number(props.data.widget._attributes.functiongroup)]._attributes.Name);
    let [location, setLocation] = useState((props.data.widget._attributes.location === "all" || props.data.widget._attributes.location === "none") ? "": props.data.locations[Number(props.data.widget._attributes.location)]._attributes.Name);  
    const [isNameUpdate,setIsNameUpdate] = useState(false);
    const [isFunctionUpdate, setIsFunctionUpdate] = useState(false);
    const [isLocationUpdate, setIsLocationUpdate] = useState(false);
    //handle functions//
    const handleFormdata = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value);
            setIsNameUpdate(true);
           }
        if (e.target.name === "functionName") {
            setFunctionGroup(e.target.value);
            setIsFunctionUpdate(true);
        }
        if (e.target.name === "location") {
            setLocation(e.target.value);
            setIsLocationUpdate(true);
        }
    }

    // const saveEdit = (cmd) => {
    //   updateSocketEvent(cmd)
    //     .then((res) => {
    //       saveProject()
    //         .then((result) => {})
    //         .catch((error) => {});
    //     })
    //     .catch((error) => {});
    // };

    useEffect(() => {
        setFunctionGroup(props.data.widget._attributes.functiongroup==='none'?"":props.data.functionGroups[Number(props.data.widget._attributes.functiongroup)]._attributes.Name);
        setLocation((props.data.widget._attributes.location === "all" || props.data.widget._attributes.location === "none") ? "": props.data.locations[Number(props.data.widget._attributes.location)]._attributes.Name);
    }, [props.data.functionGroups,props.data.locations]);
    const handleSave = (name, functionGroup, location,widget) =>{
        if(isNameUpdate){
        let wid = {
            "cmd": '<label-edit type="2" id="' + props.data.widget._attributes.id + '" label="' + name + '" />'
          }
          
          props.data.handleSocketEventChange(wid);
          //saveEdit(wid)
          setIsNameUpdate(false);
        } 
        if(isFunctionUpdate){
            let func = {
                "cmd": '<label-edit type="1" id="' + props.data.widget._attributes.functiongroup + '" label="' + functionGroup + '" />'
              }
            
              props.data.handleSocketEventChange(func);
            //saveEdit(func)
            setIsFunctionUpdate(false);
            } 
        if(isLocationUpdate){
            let loc = {
                "cmd": '<label-edit type="0" id="' + props.data.widget._attributes.location + '" label="' + location + '" />'
              }
              props.data.handleSocketEventChange(loc);
            //saveEdit(loc)
            setIsLocationUpdate(false);
            } 
        
        props.saveEditedWidget(name, functionGroup, location, widget);
        props.toggleDrawer(false);
        props.setHeaderTitle(functionGroup);
    }
    return (
        <div>
            <div className={classes.editWidgetRoot} >
                <CloseIcon onClick={() => { props.toggleDrawer(false) }} style={{ display: 'flex', alignSelf: 'flex-end', width: '10%', color: 'white' }} />
                <div className={classes.editWidgetHeader} style={{ paddingBottom: '3%' }} >
                    <Typography variant="h4" style={{ paddingBottom: '2%' }} className={classes.headerText1} >
                        {props.data.language.editWidget}
                    </Typography>
                    <Divider style={{ height: '2px solid #000000' }} />
                    <Typography variant="h6" className={classes.headerText1} >
                        {props.data.widget._attributes.name}
                    </Typography>
                </div>
                <hr style={{ border: "1px solid black", margin: "0", borderTopColor: "transparent" }} />
                <div className={classes.editWidgetButtonDiv} >
                    <button
                        onClick={() => { props.toggleDrawer(false) }}
                        className={classes.drawerbtn}
                    >
                        <Typography variant="h5" style={{ fontWeight: '500', fontSize: '1.1rem' }} >
                            {props.data.language.cancel}
                        </Typography>

                    </button>
                    <button
                        onClick={() => handleSave(name, functionGroup, location, props.data.widget)}
                        className={classes.drawerbtn}
                    >
                        <Typography variant="h5" style={{ fontWeight: '500', fontSize: '1.1rem' }} >
                            {props.data.language.save}
                        </Typography>
                    </button>
                </div>
                <Divider />
            </div>
            <Divider />
            <div className={classes.editWidgetPanel}        >
                <Typography variant="h6" className={classes.editWidgetLabel} >
                    {props.data.language.widgetName}
                </Typography>
                <input
                    type="text"
                    className={classes.editWidgetInput}
                    name="name"
                    onChange={(event) => handleFormdata(event)}
                    value={name}
                />
                <Typography variant="h6" className={classes.editWidgetLabel} >
                    {props.data.language.functionName}
                </Typography>

                <input
                    type="text"
                    className={classes.editWidgetInput}
                    name="functionName"
                    onChange={(event) => handleFormdata(event)}
                    value={functionGroup}
                />
                <Typography variant="h6" className={classes.editWidgetLabel} >
                    {props.data.language.locationName}
                </Typography>
                <input
                    disabled={props.data.widget._attributes.location === "none" || props.data.widget._attributes.location === "" || props.data.widget._attributes.location === undefined}
                    type="text"
                    className={classes.editWidgetInput}
                    name="location"
                    onChange={(event) => handleFormdata(event)}
                    value={location}
                />
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setHeaderTitle
},dispatch);
  
export default connect(null,mapDispatchToProps)(EditWidget);