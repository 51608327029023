/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { ICON_URL } from "../../config/environment";

// Assets Import //
import bellWhite from '../../assets/img/bellWhite.png'
//import bellGreen from '../../assets/img/bellGreen.png'

//Components imported locally //
import EditWidget from './editWidget';
import BootstrapTooltip from '../../assets/widgets/tooltip/tooltip'

// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {Button, IconButton, Typography, Drawer, Grid} from '@material-ui/core';
import classNames from "classnames"
import StarsIcon from '@material-ui/icons/Stars'
import EditIcon from '@material-ui/icons/Edit';

import styles from '../../assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);

//function // 
export default function BellPress (props) {
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] =useState(props.favData);
  const [onPressclasses, setOnPressClasses] = useState(classes.controlPanelIconButton);
  const [onPress, setOnPress] = useState(false)
  
  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) =>{
    setFaData(!fav);
  } 

  const BellPressOn =()=>{
    setOnPressClasses(classes.controlPanelIconButtonActive);
    setOnPress(true);
    let on = {
      "cmd": '<cbus_cmd app="' + props.widget.params.param[1]._attributes.value + '" command="cbusSetLevel" network="' + props.widget.params.param[0]._attributes.value + '" numaddresses="1" group="' + props.widget.params.param[2]._attributes.value + '" level="255" ramp="0" />'
    }
    
      props.handleSocketEventChange(on)
  }

  const BellPressOff =()=>{
    let off = {
      "cmd": '<cbus_cmd app="' + props.widget.params.param[1]._attributes.value + '" command="cbusSetLevel" network="' + props.widget.params.param[0]._attributes.value + '" numaddresses="1" group="' + props.widget.params.param[2]._attributes.value + '" level="0" ramp="0" />'
    }
    
   setTimeout(() => {
      props.handleSocketEventChange(off)
      setOnPressClasses(classes.controlPanelIconButton);
      setOnPress(false);
    }, 500);     
  }

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
        if (props.cbusEvent.attributes.app ==="0x" + Number(props.widget.params._attributes.app).toString(16)) {
          if (props.cbusEvent.attributes.group === "0x"+Number(props.widget.params._attributes.ga).toString(16)) {
              if (props.cbusEvent.attributes.level == "0x0") {
                setOnPressClasses(classes.controlPanelIconButton);
                setOnPress(false);
              } else {
                setOnPressClasses(classes.controlPanelIconButtonActive);
                setOnPress(true);
              }
            }
          }
        }
      }
    }
  }, [props.cbusEvent])

// Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const location = props.widget._attributes.location=== "none" ? "" : props.locations[Number(props.widget._attributes.location)]._attributes.Name;
const functionGroup = props.widget._attributes.functiongroup==="none" ? "" : props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name;
  
return (
  <> { props.data !== undefined &&
  <Grid item lg={4} md={6} sm={6} xs={12}>
    <div className={classes.root} >
    <BootstrapTooltip
          title={
            <React.Fragment>
              <IconButton
                className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
                aria-label="delete"
                onClick={() => {props.handleFavorite(id, props.widget._attributes.type);updateFav(favData);}}
                
              >
              <StarsIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={()=>{ toggleDrawer(true)}}
              >
                < EditIcon />
              </IconButton>
            </React.Fragment>
          }
        >
    <div className={classes.header}  >
    <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton}>
          <img src={ICON_URL+"white_"+props.widget.params._attributes.icon+".png"} className={classes.headerIcon} />
          </IconButton>
          <div className={classes.header1}> 
          <Typography style={onPress ? {color:'rgb(131, 203, 2)'}: {} } variant='h6'  className= {classes.headerText1} >
          {name}
          </Typography>
          <Typography variant='h6' className={classes.headerText2} >
          { props.widgetCategory==="functionGroup" ?  location : functionGroup }
          </Typography>          
          </div>
          <Button
        variant="contained"
        className={onPressclasses}
        // onClick={handlePress}
        onMouseDown={BellPressOn}
        onMouseUp={BellPressOff}
        style={{paddingBottom:'2%', paddingTop:'2%'}}
      >
    <Typography className={classes.headerText1} variant='h6' >
      {props.language.press}
          </Typography>       
      </Button> 
          </div>
          </BootstrapTooltip>
          <Drawer
          variant="temporary"
          anchor={"right"}
          open={draweropen}
          onClose={()=>{toggleDrawer(false)}}
          modal
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
        <EditWidget saveEditedWidget={props.saveEditedWidget} data={props} toggleDrawer={toggleDrawer} />
        </Drawer>
              </div>
              </Grid>
  }
    </>
  );
}
