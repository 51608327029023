/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Divider, Typography, Button } from "@material-ui/core";
import cctvImage from "../../assets/img/homeCctv.png";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SkipPreviousSharpIcon from '@material-ui/icons/SkipPreviousSharp';
import SkipNextSharpIcon from '@material-ui/icons/SkipNextSharp';
import styles from "../../assets/widgets/widgetStyles/commonStyles";
import { deleteImage } from "../../services/xmlService";

const useStyles = makeStyles(styles);

const EditSurveillancePhoto = (props) => {
  const classes = useStyles();
  const adjustTimeFormate = (time) => {
    if(time==0){
      return '12';
    }else{
      if(time>9){
        return time;
      }
      if(time<=9){
        return '0' + time;
      }
    }
  }

  const datetimeextracter = (datetime) => {
    if (datetime) {
      let year = datetime.substring(0, 4);
      let month = datetime.substring(4, 6);
      let date = datetime.substring(6, 8);
      let hours = datetime.substring(8, 10);
      let minutes = datetime.substring(10, 12);
      let seconds = datetime.substring(12, 14);
      // let d = adjustForTimezone(new Date(year +'-'+month+'-'+date+' '+hours+':'+minutes+':'+seconds));
      let d = new Date(year +'-'+month+'-'+date+' '+hours+':'+minutes+':'+seconds);
      let baistime = parseInt(Math.abs(props.data.data.data.Project.Info.Bias._text), 10); 
      let btime   = Math.floor(baistime / 3600);
      let bhours = Math.floor((baistime - (btime * 3600)) / 60);
      let bminutes = baistime - (btime * 3600) - (bhours * 60);
      if(Number(props.data.data.data.Project.Info.Bias._text)>0){
        d.setHours(d.getHours() - bhours);
        d.setMinutes(d.getMinutes() - bminutes);
      }else{
        d.setHours(d.getHours() + bhours);
        d.setMinutes(d.getMinutes() + bminutes);
      }
      //d.setHours(d.getHours() + 1);
      let timestamp = "";
      if (d.getHours() > 12) {
        timestamp = adjustTimeFormate(d.getHours()-12) + ":" + adjustTimeFormate(d.getMinutes()) + ":" + adjustTimeFormate(d.getSeconds()) + " PM";
      } else {
        timestamp = adjustTimeFormate(d.getHours()) + ":" + adjustTimeFormate(d.getMinutes()) + ":" + adjustTimeFormate(d.getSeconds()) + " AM";
      }
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let datedata = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
      let dayName = days[d.getDay()];
      let finalresult = "<div>" + dayName + "</div>" + "<div>" + datedata + "</div>" + "<div>" + timestamp + "</div>";
      return finalresult;
    } else {
      return "";
    }
  }

  const toDataURL = (url) => {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }
  const download = async (url) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = props.curImage;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  const imageDelete = () => {
    let data = { name: props.curImage };
    deleteImage(data).then((res) => {
      
      props.imageGalleryRefresh();
    }).catch((error) => {
      
    });
  }

  const saveToHardDrive = () => {
    let imageUrl = window.location.origin + "/getsnap.pl?name=" + props.curImage;
    download(imageUrl);
  }

  return (
    <>
      {props.data !== undefined && (
        <div
          className={classes.editWidgetRoot}
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundImage: "linear-gradient(#3b3b3b, #202020)",
            height: "100vh",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className={classes.controlPanelIconButton}
                style={{ width: "10%", margin: "2%" }}
              >
                <SkipPreviousSharpIcon style={{ color: "#bfbfbf" }} />
              </Button>
              <Button
                className={classes.controlPanelIconButton}
                style={{ width: "10%", margin: "2%", marginLeft: "0" }}
              >
                <SkipNextSharpIcon style={{ color: "#bfbfbf" }} />
              </Button>
            </div>
            <div
              style={{
                width: "80%",
                height: "100vh",
                justifyContent: "center !important",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <img
                src={window.location.origin + "/getsnap.pl?name=" + props.curImage}
                alt="CCTV Feed is not available"
                style={{ height: "", width: "90%", alignSelf: "center" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              height: "100vh",
              backgroundColor: "#3b3b3b",
            }}
          >
            <CloseIcon
              onClick={() => {
                props.toggleDrawer(false);
              }}
              style={{
                display: "flex",
                alignSelf: "flex-end",
                width: "15%",
                padding: "5%",
                color: "white",
              }}
            />
            <div
              className={classes.editWidgetHeader}
              style={{ paddingTop: "0%" }}
            >
              <Button
                variant="contained"
                style={{
                  width: "90%",
                  alignSelf: "center",
                  margin: "5%",
                  borderRadius: "0",
                  padding: "2%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10%",
                }}
                className={classes.controlPanelIconButton}
                onClick={() => {
                  props.toggleDrawer(false);
                }}
              >
                <Typography className={classes.headerText1}> Close </Typography>
              </Button>
              <Divider
                className={classes.editWidgetHeaderDivider}
                style={{
                  color: "black",
                  height: "2px",
                  width: "100%",
                  margin: "0",
                  padding: "0",
                }}
              />

              <Typography
                variant="h4"
                style={{
                  paddingBottom: "0%",
                  paddingLeft: "10%",
                  marginTop: "10%",
                }}
                className={classes.headerText1}
              >
                Image
              </Typography>
              <Typography
                variant="h6"
                style={{ paddingLeft: "10%" }}
                className={classes.headerText2}
              >
                <div dangerouslySetInnerHTML={{ __html: datetimeextracter(props.dayDate) }} />
              </Typography>
              <Divider
                className={classes.editWidgetHeaderDivider}
                style={{
                  color: "#343434",
                  marginTop: "10",
                  padding: "0",
                  marginBottom: "10%",
                }}
              />
              <Button
                variant="contained"
                style={{
                  width: "90%",
                  alignSelf: "center",
                  margin: "5%",
                  borderRadius: "0",
                  padding: "2%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20%",
                }}
                className={classes.controlPanelIconButton}
                onClick={() => {
                  saveToHardDrive();
                }}
              >
                <Typography className={classes.headerText1}>
                  Save to harddrive
                </Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  width: "90%",
                  alignSelf: "center",
                  margin: "5%",
                  borderRadius: "0",
                  padding: "2%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "60%",
                  backgroundColor: "red !important",
                }}
                className={classes.controlPanelIconButtonDelete}
                onClick={() => {
                  props.toggleDrawer(false);
                  imageDelete();
                }}
              >
                <Typography className={classes.headerTextDelete}>
                  Delete
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditSurveillancePhoto;
