import React from "react";
import { useState, useEffect } from "react";

// material-ui //
import { makeStyles} from "@material-ui/core/styles";
import { Typography, Grid} from '@material-ui/core';
import moment from 'moment';
// CSS Style sheets imported locally //
import styles from 'assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);

//function // 
export default function IODateLogic(props) {
  const [state, setState] = React.useState({hours: "00",min:"00", sec:"00" });
  let [hours, setHours] = React.useState(Math.floor(Math.abs(state.Time/ 3600)));
  let [minutes, setMinutes] = React.useState(Math.floor(Math.abs(((state.Time)%3600)/60)));
  const [timevalue, settimevalue] = React.useState(state.Time>0?"timeplus":"timeminus");
  const [iosSwitch, setIosSwitch] = React.useState({ checked: true });
  const [iPAddress, setIPAddress] = React.useState(props.sysDate==undefined?0: Math.floor(props.sysDate));
  const [day, setDay] = React.useState(props.sysDate.getDate());
  const [month, setMonth] = React.useState(props.sysDate.getMonth() + 1);
  const [year, setYear] = React.useState(props.sysDate.getFullYear().toString().substr(-2));
  const [val, setVal] = React.useState(props.sysDate);

  const handleIOSSwitch = (event) =>{
    setIosSwitch(event.target.checked)
  }
  const [draweropen, setDraweropen] = useState(false);

  useEffect(() =>{
    props.handleEditSysDate(day);
    props.handleEditSysMonth(month);
    props.handleEditSysYear(year);
  
  },[props.sysDate]);

  
  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  
    const classes = useStyles();

  const toggleDrawer = (open) => {
  setDraweropen(open);
  };

  const handleChangetimevalue = (event) => {
    settimevalue(event.target.value);
  };


  const handleChangeDay = (event) => {
    setDay(event.target.value)
    props.handleEditSysDate(event.target.value)
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
    props.handleEditSysMonth(event.target.value)

  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
    props.handleEditSysYear(event.target.value)

  };
  const ExcelDateToJSDate = (serial) => {
    var hours = Math.floor((serial % 1) * 24);
    var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
    return moment(new Date(Date.UTC(0, 0, serial, hours - 17, minutes))).format("DD/MM/YY");
  }
  let displayDate = () => {
    return(
      <div className={classes.rootLogic} >
<Typography variant="h6" className={classes.editWidgetLabel} >
          Value
 </Typography>
<div className={classes.header1} >
       
     </div>
    
     <div style={{ display: "flex", justifyContent:"space-between", color:"#fff", fontWeight:"600"}} >
        <select
          name="day"
          className="selectwithoptions"
          value={day}
          onChange={handleChangeDay}   
          style={{background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )"}}     
         
        >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
            <option>13</option>
            <option>14</option>
            <option>15</option>
            <option>16</option>
            <option>17</option>
            <option>18</option>
            <option>19</option>
            <option>20</option>
            <option>21</option>
            <option>22</option>
            <option>23</option>
            <option>24</option>
            <option>25</option>
            <option>26</option>
            <option>27</option>
            <option>28</option>
            <option>29</option>
            <option>30</option>
            <option>31</option>
          </select>

      {"/"}

      <select
          name="month"
          className="selectwithoptions"
          value={month}
          onChange={handleChangeMonth}   
          style={{background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )"}}
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
        </select>
      {"/"}
    
      <select
          name="year"
          className="selectwithoptions"
          value={year}
          onChange={handleChangeYear}   
          style={{background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )"}}      
        >
       
          <option>00</option>
          <option>01</option>
          <option>02</option>
          <option>03</option>
          <option>04</option>
          <option>05</option>
          <option>06</option>
          <option>07</option>
          <option>08</option>
          <option>09</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
          <option>13</option>
          <option>14</option>
          <option>15</option>
          <option>16</option>
          <option>17</option>
          <option>18</option>
          <option>19</option>
          <option>20</option>
          <option>21</option>
          <option>22</option>
          <option>23</option>
          <option>24</option>
          <option>25</option>
          <option>26</option>
          <option>27</option>
          <option>28</option>
          <option>29</option>
          <option>30</option>
          <option>31</option>
          <option>32</option>
          <option>33</option>
          <option>34</option>
          <option>35</option>
          <option>36</option>
          <option>37</option>
          <option>38</option>
          <option>39</option>
          <option>40</option>
          <option>41</option>
          <option>42</option>
          <option>43</option>
          <option>44</option>
          <option>45</option>
          <option>46</option>
          <option>47</option>
          <option>48</option>
          <option>49</option>
          <option>50</option>
          <option>51</option>
          <option>52</option>
          <option>53</option>
          <option>54</option>
          <option>55</option>
          <option>56</option>
          <option>57</option>
          <option>58</option>
          <option>59</option>
          <option>60</option>
          <option>61</option>
          <option>62</option>
          <option>63</option>
          <option>64</option>
          <option>65</option>
          <option>66</option>
          <option>67</option>
          <option>68</option>
          <option>69</option>
          <option>70</option>
          <option>71</option>
          <option>72</option>
          <option>73</option>
          <option>74</option>
          <option>75</option>
          <option>76</option>
          <option>77</option>
          <option>78</option>
          <option>79</option>
          <option>80</option>
          <option>81</option>
          <option>82</option>
          <option>83</option>
          <option>84</option>
          <option>85</option>
          <option>86</option>
          <option>87</option>
          <option>88</option>
          <option>89</option>
          <option>90</option>
          <option>91</option>
          <option>92</option>
          <option>93</option>
          <option>94</option>
          <option>95</option>
          <option>96</option>
          <option>97</option>
          <option>98</option>
          <option>99</option>
          <option>00</option>
        </select>
   </div>
   <div style={{ display: "flex", justifyContent:"space-between", color:"#fff", fontWeight:"600"}}>
       <span>{ExcelDateToJSDate(props.userVariable.IntegerMin)}</span>
       <span>{ExcelDateToJSDate(props.userVariable.IntegerMax)}</span>
     </div>
     
  </div>
      
    )
  }



    // Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const widgetType = props.widget._attributes.type


  return (

<Grid item lg={12} md={12} sm={12} xs={12} className="mainPannelSideedit">
  

<div>{displayDate()}</div>


</Grid>

);
}