import React, { useState, useEffect, useRef } from "react";
require('jquery-nstslider/dist/jquery.nstSlider.js');

const  BassSlider=(props)=> {
    const [bassValue, setBassValue] = useState(props.value);
    const [bassGa, setBassGa] = useState(props.bassga);

    window.$('.leftGrip' + bassGa).slider({
        slide: function (e, ui) {
        },
        stop: function (e, ui) {
          props.handleBassSliderCommittedChange(window.$('.bassSlider' + bassGa).nstSlider("get_current_min_value"), props.bassga);
        }
    })
    window.$('.bassSlider' +bassGa).click( function(e){
        props.handleBassSliderCommittedChange(window.$('.bassSlider' + bassGa).nstSlider("get_current_min_value"), props.bassga);
        e.stopImmediatePropagation();
    })
    useEffect(() => {
        window.$('.bassSlider'+bassGa).nstSlider({
            "left_grip_selector": ".leftGrip"+bassGa,
            "value_bar_selector": ".bar"+bassGa,
            "value_changed_callback": function(cause, leftValue, rightValue) {
                var $container = window.$(this).parent();
                $container.find('.leftLabel').text(leftValue);
                window.$(this).find('.bar').css('background', '#92E300');
            }
        });
      },[props.value])
      return (
          <>
              <div className={"nstSlider bassSlider"+bassGa} data-range_min="-50" data-range_max="50"
                  data-cur_min={bassValue} data-cur_max="0">
                  <div className={"bar bar"+bassGa} ></div>
                  <div className={"leftGrip leftGrip"+bassGa} ></div>
              </div>
          </>
      )

}
export default BassSlider;