
import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  infoColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb
} from "assets/js/base-styles.js";

import MenuSelectImg from "assets/img/Menu-Select-Img.png";

const sidebarStyle = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "0",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  

  logo: {
    position: "relative",
    padding: "15px 15px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)"
    }
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: whiteColor
    }
  },
  logoh6text:{
    color: "#477b01",
    textTransform:"none",
    marginBottom:"10px",
    fontWeight: "600"
  },
  
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
    marginLeft: "10px",
    marginRight: "15px"
  },

  img: {
    width:"100%",
    height:"100%",
    borderRadius: "50%",
    //border: "3px solid #538c0d"
  },
  imgcontainer:{
    width: "70px",
    height: "70px"
    
  },
  logoandtext:{
display:"flex",
flexDirection:"column",
justifyContent:"center",
alignItems:"center",
paddingTop:"20px",
borderBottom:"solid 1px rgba(0, 0, 0, 0.12)"

  },


  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8"
    }
  },
  list: {
    
    marginTop: "0px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
    
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    fontWeight:"600",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor
    },
    borderBottom:"solid 1px  #306507"
  },

  itemDisable: {
    //position: "relative",
    display: "none",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      textDecoration: "none"
    },
    color: "d3d3d3 !important",
    //borderBottom:"solid 1px  #306507",
    pointerEvents: "none !important",
    cursor: "default"
  },
  itemLink: {
    width: "100%",
    transition: "all 300ms linear",   
    position: "relative",
    display: "flex",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont
  },


  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "20px",
    color: "#000",
    fontWeight: "600"

  },
 
  whiteFont: {
    color: "#000",
    fontWeight: "600"
  },
  imgcon:{
    width:"20%"

  },
  imgwidth:{
width:"70%"
  },
  
  blue: {
  
  },
  
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh-75px)",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch",
   
  },
  sidebarWrapperWithPerfectScrollbar:{
    overflow:"hidden !important"

  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px",
    }
  }
});

export default sidebarStyle;
