
import React, { useState, useEffect, Component } from "react";
import PowerStats from '../../components/Widgets/PowerMeter/PowerStats/PowerStats'
import PowerStatsTotal from '../../components/Widgets/PowerMeter/PowerStatsTotal/PowerStatsTotal'
import { socketEventDataChanger } from "../../redux/actions/action";

// Redux setup //
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const Stats = (props) => {
  const [widgetCategory, setWidgetCategory] = useState("functionGroup");
  const [widgetList, setWidgetList] =  React.useState([]);

  React.useEffect(()=>{ 
      if(props.widgets){
      if(!Array.isArray(props.widgets)){ 
        let data = [];
        data.push(props.widgets);
        setWidgetList(data);
        //setScenesedit(props.widgets);
     }else{
      setWidgetList(props.widgets);
     }
    }
  },[props.widgets])

  return (
    <>
      {widgetList && Array.isArray(widgetList) &&
        widgetList.map((widget, key) => {
          return (
            <>
              {widget._attributes.functiongroup !== "none" && (
                <>
                  {(widget._attributes.type === "32") &&
                    <PowerStats
                      widgetCategory={widgetCategory}
                      widget={widget}
                      cbusEvent={props.cbusEvent}
                      functionGroups={props.functionGroups}
                      language={props.language}
                      data={props.data}
                      initialCbusEvent={props.initialCbusEvent}
                      socketEventDataChanger={props.socketEventDataChanger}
                    />
                  }
                  {(widget._attributes.type === "33") &&
                    <PowerStatsTotal
                      widgetCategory={widgetCategory}
                      widget={widget}
                      cbusEvent={props.cbusEvent}
                      functionGroups={props.functionGroups}
                      language={props.language}
                      data={props.data}
                      initialCbusEvent={props.initialCbusEvent}
                      socketEventDataChanger={props.socketEventDataChanger}
                    />
                  }
                </>
              )}
            </>
          )
        }
        )}


    </>
  )
}

const mapStateToProps = state => ({
  data: state.reducer.data,
  locations: state.reducer.locations,
  functionGroups: state.reducer.functionGroups,
  widgets: state.reducer.widgets,
  functionGroupId: state.reducer.functionGroup_id,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      socketEventDataChanger
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Stats);