/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
//Assets //
import relay from "assets/img/relay.png";
//Components imported locally //
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
// import style //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

export default function ShutterRelay3button(props) {
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [button1, setButton1] = useState(false);
  const [button2, setButton2] = useState(false);
  const [button3, setButton3] = useState(false);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [start, setStart] = useState(false);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const handleButton3 = (buttonNo) => {
    if (buttonNo == 1) {
      setButton1(true)
      let open = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.ga +
          '" level="255" ramp="0"/>',
      };
      props.handleSocketEventChange(open);
      
    } else if (buttonNo == 2) {
      setButton2(true)
      let close = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.ga +
          '" level="5" ramp="0"/>',
      };
      props.handleSocketEventChange(close);
      
    } else if (buttonNo == 3) {
      setButton3(true)
      let stop = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.ga +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(stop);
      
    }
  };

  const handleButton2 = (buttonNo) => {
    if (buttonNo == 1) {
      setButton1(true)
      let open = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.ga +
          '" level="252" ramp="0"/>',
      };
      props.handleSocketEventChange(open);
      
    } else if (buttonNo == 2) {
      setButton2(true)
      let close = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.ga +
          '" level="2" ramp="0"/>',
      };
      props.handleSocketEventChange(close);
      
    }
  };

  const handleButton1 = (buttonNo) => {
    if (buttonNo == 1) {
      setButton1(true)
      let open = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.ga +
          '" level="249" ramp="0"/>',
      };
      props.handleSocketEventChange(open);
      
      setStart(!start);
    } else if (buttonNo == 2) {
      let close = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.ga +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(close);
      
      setStart(!start);
    }
  };

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  const noOfButtons = props.widget.params._attributes.type;
  // 

  if (noOfButtons === "three") {
    return (
      <>
        {" "}
        {props.data !== undefined && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className={classes.root}>
              <BootstrapTooltip
                title={
                  <React.Fragment>
                    <IconButton
                      className={
                        favData
                          ? classes.toolTipFavIconSelected
                          : classes.toolTipFavIcon
                      }
                      aria-label="delete"
                      onClick={() => {
                        props.handleFavorite(id, props.widget._attributes.type);
                        updateFav(favData);
                      }}
                    >
                      <StarsIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        toggleDrawer(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </React.Fragment>
                }
              >
                <div className={classes.header}>
                  <IconButton
                    aria-label="previous"
                    disabled="true"
                    className={classes.headerIconButton}
                  >
                    <img src={relay} className={classes.headerIcon} />
                  </IconButton>
                  <div className={classes.header1}>
                    <Typography variant="h6" className={`${classes.headerText1} ${classes.textTruncation} `} style={{ textTransform: "none" }}>
                      {name}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: "#797979", textTransform: "none" }}
                      className={classes.headerText1}
                    >
                      {props.widgetCategory === "functionGroup"
                        ? location
                        : functionGroup}
                    </Typography>
                  </div>
                </div>
              </BootstrapTooltip>
              <div className={classes.bottomPanelScenes}>
                <Button
                  variant="contained"
                  className={button1?classes.bottomPanel3ButtonActive: classes.bottomPanel3Button}
                  onMouseDown={() => handleButton3(1)}
                  onMouseUp={()=>setButton1(false)}
                >
                  <Typography
                    className={classes.bottomPanel2ButtonText}
                    variant="h5"
                  >
                    {props.widget.params._attributes.button1label}
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  className={button2?classes.bottomPanel3ButtonActive: classes.bottomPanel3Button}
                  style={{ flexGrow: "1" }}
                  onMouseDown={() => handleButton3(2)}
                  onMouseUp={()=>setButton2(false)}
                >
                  <Typography
                    className={classes.bottomPanel2ButtonText}
                    variant="h5"
                  >
                    {props.widget.params._attributes.button2label}
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  className={button3?classes.bottomPanel3ButtonActive: classes.bottomPanel3Button}
                  style={{ borderRight: "none" }}
                  onMouseDown={() => handleButton3(3)}
                  onMouseUp={()=>setButton3(false)}
                >
                  <Typography
                    className={classes.bottomPanel2ButtonText}
                    variant="h5"
                  >
                    {props.widget.params._attributes.button3label}
                  </Typography>
                </Button>
              </div>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={draweropen}
                onClose={() => {
                  toggleDrawer(false);
                }}
                modal
                classes={{
                  paper: classNames(classes.drawerPaper),
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <EditWidget
                  saveEditedWidget={props.saveEditedWidget}
                  data={props}
                  toggleDrawer={toggleDrawer}
                />
              </Drawer>
            </div>
          </Grid>
        )}
      </>
    );
  } else if (noOfButtons === "two") {
    return (
      <>
        {" "}
        {props.data !== undefined && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className={classes.root}>
              <BootstrapTooltip
                title={
                  <React.Fragment>
                    <IconButton
                      className={
                        favData
                          ? classes.toolTipFavIconSelected
                          : classes.toolTipFavIcon
                      }
                      aria-label="delete"
                      onClick={() => {
                        props.handleFavorite(id, props.widget._attributes.type);
                        updateFav(favData);
                      }}
                    >
                      <StarsIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        toggleDrawer(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </React.Fragment>
                }
              >
                <div className={classes.header}>
                  <IconButton
                    aria-label="previous"
                    disabled="true"
                    className={classes.headerIconButton}
                  >
                    <img src={relay} className={classes.headerIcon} />
                  </IconButton>
                  <div className={classes.header1}>
                    <Typography variant="h6" className={`${classes.headerText1} ${classes.textTruncation} `}>
                      {name}
                    </Typography>
                    <Typography variant="h6" className={`${classes.headerText2} ${classes.textTruncation} `}>
                      {props.widgetCategory === "functionGroup"
                        ? location
                        : functionGroup}
                    </Typography>
                  </div>
                </div>
              </BootstrapTooltip>
              <div className={classes.bottomPanelScenes}>
                <Button
                  variant="contained"
                  className={button1?classes.bottomPanel2ButtonActive: classes.bottomPanel2Button}
                  style={{ borderRight: "none" }}
                  onMouseDown={() => handleButton2(1)}
                  onMouseUp={()=>setButton1(false)}
                >
                  <Typography
                    variant="h5"
                    className={classes.bottomPanel2ButtonText}
                  >
                    {props.widget.params._attributes.button1label}
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  className={button2?classes.bottomPanel2ButtonActive: classes.bottomPanel2Button}
                  style={{ borderRight: "none" }}
                  onMouseDown={() => handleButton2(2)}
                  onMouseUp={()=>setButton2(false)}
                >
                  <Typography
                    className={classes.bottomPanel2ButtonText}
                    variant="h5"
                  >
                    {props.widget.params._attributes.button2label}
                  </Typography>
                </Button>
              </div>{" "}
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={draweropen}
                onClose={() => {
                  toggleDrawer(false);
                }}
                modal
                classes={{
                  paper: classNames(classes.drawerPaper),
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <EditWidget
                  saveEditedWidget={props.saveEditedWidget}
                  data={props}
                  toggleDrawer={toggleDrawer}
                />
              </Drawer>
            </div>
          </Grid>
        )}
      </>
    );
  } else if (noOfButtons === "one") {
    return (
      <>
        {" "}
        {props.data !== undefined && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className={classes.root}>
              <BootstrapTooltip
                title={
                  <React.Fragment>
                    <IconButton
                      className={
                        favData
                          ? classes.toolTipFavIconSelected
                          : classes.toolTipFavIcon
                      }
                      aria-label="delete"
                      onClick={() => {
                        props.handleFavorite(id, props.widget._attributes.type);
                        updateFav(favData);
                      }}
                    >
                      <StarsIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        toggleDrawer(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </React.Fragment>
                }
              >
                <div className={classes.header}>
                  <IconButton
                    aria-label="previous"
                    disabled="true"
                    className={classes.headerIconButton}
                  >
                    <img src={relay} className={classes.headerIcon} />
                  </IconButton>
                  <div className={classes.header1}>
                    <Typography
                      variant="h6"
                      className={
                        click ?  `${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `
                      }
                    >
                      {name}
                    </Typography>
                    <Typography variant="h6" className={ `${classes.headerText2} ${classes.textTruncation} `}>
                      {props.widgetCategory === "functionGroup"
                        ? location
                        : functionGroup}
                    </Typography>
                  </div>
                </div>
              </BootstrapTooltip>
              <Button
                variant="contained"
                  className={button1?classes.bottomPanel1ButtonActive: classes.bottomPanel1Button}
                  style={{ borderRight: "none" }}
                  onMouseDown={() => handleButton1(1)}
                  onMouseUp={()=>setButton1(false)}
              >
                <Typography
                  className={classes.bottomPanel2ButtonText}
                  variant="h5"
                >
                  { props.widget.params._attributes.button1label}
                </Typography>
              </Button>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={draweropen}
                onClose={() => {
                  toggleDrawer(false);
                }}
                modal
                classes={{
                  paper: classNames(classes.drawerPaper),
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <EditWidget
                  saveEditedWidget={props.saveEditedWidget}
                  data={props}
                  toggleDrawer={toggleDrawer}
                />
              </Drawer>
            </div>
          </Grid>
        )}
      </>
    );
  }
}
