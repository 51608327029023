import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import styles from "assets/js/all-styles/views/schedulesStyle.js";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

export default function SelectActiveMonth(props) {
  
  let listOfNumber = [];
  const classes = useStyles();
  const [monthData, setMonthData] = useState([
    {
      name: "Jan",
      Value: 1,
      isSelected: false,
      key: 0,
    },
    {
      name: "Feb",
      Value: 2,
      isSelected: false,
      key: 1,
    },
    {
      name: "Mar",
      Value: 4,
      isSelected: false,
      key: 2,
    },
    {
      name: "Apr",
      Value: 8,
      isSelected: false,
      key: 3,
    },
    {
      name: "May",
      Value: 16,
      isSelected: false,
      key: 4,
    },
    {
      name: "Jun",
      Value: 32,
      isSelected: false,
      key: 5,
    },
    {
      name: "Jul",
      Value: 64,
      isSelected: false,
      key: 6,
    },
    {
      name: "Aug",
      Value: 128,
      isSelected: false,
      key: 7,
    },
    {
      name: "Sep",
      Value: 256,
      isSelected: false,
      key: 8,
    },
    {
      name: "Oct",
      Value: 512,
      isSelected: false,
      key: 9,
    },
    {
      name: "Nov",
      Value: 1024,
      isSelected: false,
      key: 10,
    },
    {
      name: "Dec",
      Value: 2048,
      isSelected: false,
      key: 11,
    }
  ]);

  const [allMonth, setAllMonth] = useState(false);

  useEffect(() => {
    let day = new Array(12).fill(false);
    day = parseIntArray(props.data, 12);
    
    let data = [...monthData];
    data.map((item, key) => item.isSelected = day[key]);


    

  }, []);

  useEffect(() => {
    let day_d = 0;
    let data = [...monthData];
    let dataCount = data.filter((val) => val.isSelected);
    const result =  extractValue(data, "isSelected");
    day_d = parseArrayInt(result);

    if (dataCount.length === 12) {
          setAllMonth(true);
        } else {
          setAllMonth(false);
        }
  
   
    
    props.setActiveMonth(day_d.toString(16));
  }, [monthData, allMonth]);

  

  function extractValue(arr, prop) {

    let extractedValue = [];

    for (let i=0; i < arr.length ; ++i) {

        // extract value from property
        extractedValue.push(arr[i][prop]);
    }
    return extractedValue;
}
  function parseIntArray(int_value, count) {
    var ret = new Array(count);
  
    var binary = intToBinary(int_value);
  
    while (binary.length < count)
      binary = "0" + binary;
    if (binary.length >= count)
      for (var i = 0; i < count; i++)
        ret[i] = (binary.charAt(count - 1 - i) == '1');
  
    return ret;
  }

  function parseArrayInt(array) {
    let ret = -1;
    let count = array.length - 1;
    let binary = "";
    for (let i = count; i >= 0; i--)
      binary += array[i] ? 1 : 0;
  
    if (binary.length > 0)
      ret = binaryToInt(binary);
    return ret;
  }
  
  function intToBinary(dec) {
      return (dec >>> 0).toString(2);
  }
  
  
  function binaryToInt(dec) {
      return parseInt((dec).toString(2), 2);
  }
 

  const subsetSum = (numbers, target, partial = [], sum = 0) => {
    if (sum < target) {
      numbers.forEach((num, i) =>
        subsetSum(
          numbers.slice(i + 1),
          target,
          partial.concat([num]),
          sum + num
        )
      );
    } else if (sum === target) {
      listOfNumber = [...partial];
    }
    return listOfNumber;
  };

  const selectDate = (key) => {
    let data = [...monthData];
    data[key].isSelected = !data[key].isSelected;
    setMonthData(data);
  };

  const handleFullChange = (event) => {
    let data = [...monthData];
    data.forEach((val, index) => {
      val.isSelected = allMonth ? false : true;
    });
    setAllMonth(!allMonth);
    setMonthData(data);
  };

  return (
    <div>
      <h1 style={{ color: "white", fontWeight: "600", fontSize: "20px" }}>
      {props.language.month}
      </h1>
      <Divider style={{ margin: "0" }} />
      <p style={{ color: "white", fontWeight: "400", fontSize: "16px" }}>
      {props.language.selectActiveMonths}
      </p>
      <ButtonGroup
        // orientation="vertical"
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {monthData.slice(0, 4).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.iconButtonActiveSchedule
                  : classes.controlPanelIconButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ButtonGroup
        // orientation="vertical"
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {monthData.slice(4, 8).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.iconButtonActiveSchedule
                  : classes.controlPanelIconButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ButtonGroup
        // orientation="vertical"
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {monthData.slice(8, 12).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.iconButtonActiveSchedule
                  : classes.controlPanelIconButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={allMonth}
            onChange={handleFullChange}
            name="allMonth"
          />
        }
        label={props.language.selectAllMonths}
      />
    </div>
  );
}
