import React, { Component } from 'react';
// import Carousel from 'react-elastic-carousel';
import '../style.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css'; 
import DollarMonthSpeedo from '../GaugeSet/dollar/DollarMonthSpeedo';
import DollarNowSpeedo from '../GaugeSet/dollar/DollarNowSpeedo';
import DollarTodaySpeedo from '../GaugeSet/dollar/DollarTodaySpeedo';
import DollarWeekSpeedo from '../GaugeSet/dollar/DollarWeekSpeedo';



export default class DollarCardSliderSet extends Component {
    
    render () {
      return (
        <div>
        <AwesomeSlider
        organicArrows={false}
        fillParent={false}
        >
           <div>
          <DollarNowSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy} 
            language={this.props.language} />
            </div>
            <div>
          <DollarTodaySpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy} 
            language={this.props.language} />
             </div>
             <div>
          <DollarWeekSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
              </div>
              <div>
          <DollarMonthSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language}  />
             </div>

         </AwesomeSlider>
        </div>
      )
    }
  }
