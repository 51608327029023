import React from 'react';
import { useState, useEffect } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
  import { curveCardinal } from 'd3-shape';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

const styles = {
  layout: {
    color: "#87d200",
    marginTop: "2vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
};

const useStyles = makeStyles(styles);

const CO2TotalHourChart = (props) =>{
  const classes = useStyles();
  //CarbonFootPrint Value
  const carbonFootValue = props.energy.Project.Energy._attributes.CarbonFootprint;
  const minMeterValue = (Number(props.widget.params._attributes.max_hourly_gen_w)/1000)*carbonFootValue;
  const maxMeterValue = (Number(props.widget.params._attributes.max_hourly_con_w)/1000)*carbonFootValue;
  const [data, setData] = React.useState(0);
  // useEffect(() => {
  //   if (props.powerStatTotal) {
  //     // let data1='<cbus_event app="0x102" name="cbusPowerHistoryTotals" hourly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,45725572.00,718544896.00,2078.10,2074.90,2055.00,2115.00,2137.62,2159.00,17457.07,22095.74,25585.58,21744.13,18065.10,17181.04,19979.07,21302.35,22341.30,7745.02," daily_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," weekly_energy_totals="0.00,0.00,0.00,764453312.00," monthly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," hourly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,19204.79,301788.59,0.88,0.88,0.87,0.90,0.91,0.92,7.49,9.60,11.09,9.43,8.25,8.10,9.28,9.76,9.86,3.36," daily_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," weekly_cost_totals="0.00,0.00,0.00,321060.94," monthly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," current_total_power="28878.11" current_total_cost="12.47" current_total_power_hour="7745.02" current_total_power_day="764453312.00" current_total_power_week="764453312.00" current_total_power_month="764453312.00" current_total_cost_hour="3.36" current_total_cost_day="321060.94" current_total_cost_week="321060.94" current_total_cost_month="321060.94" predicted_total_power_hour="27654.87" predicted_total_power_day="764675328.00" predicted_total_power_week="1454278656.00" predicted_total_power_month="0.00" predicted_total_cost_hour="11.96" predicted_total_cost_day="321156.84" predicted_total_cost_week="610779.12" predicted_total_cost_month="0.00" />';
  //     // let eventData = new XMLParser().parseFromString(data1);
  //     // let hourlyEnergy=eventData.attributes.hourly_energy_totals.split(",");
  //     if (props.powerStatTotal?.attributes?.hourly_energy_totals) {
  //       let hourlyEnergy=props.powerStatTotal.attributes.hourly_energy_totals.split(",");
  //       hourlyEnergy.pop();
  //       let hoursData=[];
  //       let hourz=[];
  //       for (let i = 0; i < 24; i++) {
  //          let hour = moment(new Date())
  //           .add(60 * i, 'minutes')
  //           .format('HH');
  //         hourz.push(hour);
  //       }
  //       hourz.reverse();
  //        for (let j = 0; j < hourlyEnergy.length; j++) {
  //         let hrsData = {
  //           name: null, KgCO2: null
  //         }
  //          hrsData.name = hourz[j]+":00";
  //          let KgCO2 = Math.round(hourlyEnergy[j])/1000;
  //          if(KgCO2>9999){
  //           hrsData.KgCO2 = maxMeterValue;
  //          }
  //         if(KgCO2>0 && KgCO2<9999){
  //           hrsData.KgCO2 = KgCO2*carbonFootValue;
  //         }
  //          if(KgCO2==0){
  //           hrsData.KgCO2 = 0;
  //          }
  //          hoursData.push(hrsData);
  //        }
  //       setData(hoursData);
  //   }
  // }
  // }, [props.powerStatTotal]);

  useEffect(() => {
    if (props.totalPowerDataCharts) {
      // let data1='<cbus_event app="0x102" name="cbusPowerHistoryTotals" hourly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,45725572.00,718544896.00,2078.10,2074.90,2055.00,2115.00,2137.62,2159.00,17457.07,22095.74,25585.58,21744.13,18065.10,17181.04,19979.07,21302.35,22341.30,7745.02," daily_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," weekly_energy_totals="0.00,0.00,0.00,764453312.00," monthly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," hourly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,19204.79,301788.59,0.88,0.88,0.87,0.90,0.91,0.92,7.49,9.60,11.09,9.43,8.25,8.10,9.28,9.76,9.86,3.36," daily_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," weekly_cost_totals="0.00,0.00,0.00,321060.94," monthly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," current_total_power="28878.11" current_total_cost="12.47" current_total_power_hour="7745.02" current_total_power_day="764453312.00" current_total_power_week="764453312.00" current_total_power_month="764453312.00" current_total_cost_hour="3.36" current_total_cost_day="321060.94" current_total_cost_week="321060.94" current_total_cost_month="321060.94" predicted_total_power_hour="27654.87" predicted_total_power_day="764675328.00" predicted_total_power_week="1454278656.00" predicted_total_power_month="0.00" predicted_total_cost_hour="11.96" predicted_total_cost_day="321156.84" predicted_total_cost_week="610779.12" predicted_total_cost_month="0.00" />';
      // let eventData = new XMLParser().parseFromString(data1);
      // let hourlyEnergy=eventData.attributes.hourly_energy_totals.split(",");
      if (props.totalPowerDataCharts.hourly_energy_totals) {
        let hourlyEnergyReversed=props.totalPowerDataCharts.hourly_energy_totals.split(",").reverse();
        //hourlyEnergy.pop();
        let hourlyEnergy = hourlyEnergyReversed.slice(1,25);
        let hoursData=[];
        let hourz=[];
        for (let i = 0; i < 24; i++) {
           let hour = moment(new Date()).add(1, 'hours')
            .add(60 * i, 'minutes')
            .format('HH');
          hourz.push(hour);
        }
        hourz.reverse();
         for (let j = 0; j < hourlyEnergy.length; j++) {
          let hrsData = {
            name: null, KgCO2: null
          }
           hrsData.name = hourz[j]+":00";
           let KgCO2 = Math.round(hourlyEnergy[j])/1000;
           if(KgCO2>maxMeterValue){
            hrsData.KgCO2 = maxMeterValue;
           }
          if(KgCO2<-minMeterValue){
            hrsData.KgCO2 = -minMeterValue;
          }
          if(KgCO2<maxMeterValue && KgCO2>-minMeterValue){
            hrsData.KgCO2 = KgCO2;
          }
           if(KgCO2==0){
            hrsData.KgCO2 = 0;
           }
           hoursData.push(hrsData);
         }
        setData(hoursData.reverse());
    }
  }
  }, [props.totalPowerDataCharts]);

    const cardinal = curveCardinal.tension(0.2);
return (
    <div style={{ width: '120%', height: 150, fontSize: '12px' }}>
       <ResponsiveContainer>
     <AreaChart
        width={600}
        height={100}
        data={data}
        margin={{
          top: 0, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis 
        ticks={[-minMeterValue,0, maxMeterValue]}
        domain={[-minMeterValue, maxMeterValue]} 
        />
        <Tooltip />
        <Area type="monotone" dataKey=" " stroke="#000" fill="#87d200" fillOpacity={0.3} />
        <Area type={cardinal} dataKey="KgCO2" stroke="#000" fill="#87d200" fillOpacity={0.3} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
)
}
export default CO2TotalHourChart;



