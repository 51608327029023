import React from 'react';
import { useState, useEffect } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
  import { curveCardinal } from 'd3-shape';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import XMLParser from "react-xml-parser";

const styles = {
  layout: {
    color: "#87d200",
    marginTop: "2vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
};

const useStyles = makeStyles(styles);

const KwhTotalWeekChart = (props) =>{
  const minMeterValue = Number(props.widget.params._attributes.max_weekly_gen_w)/1000;
  const maxMeterValue = Number(props.widget.params._attributes.max_weekly_con_w)/1000;
  const weeks = () => {
    let dates=[];
    for (let i = 0; i < 4; i++) {
      let date = moment().isoWeekday(7);
      dates.push({ name: date.subtract(i, 'week').format('DD MMM') });
    }
    return dates;
 }  
const [data, setData] = React.useState(0);
// useEffect(() => {
//   if (props.powerStatTotal) {
//     if (props.powerStatTotal?.attributes?.weekly_energy_totals) {
//   let weekEnergy=props.powerStatTotal.attributes.weekly_energy_totals.split(",");
//   weekEnergy.pop();
//   let previousWeeks= weeks();
//   previousWeeks.reverse();
//   let weeksData=[];
//   for (let j = 0; j < previousWeeks.length; j++) {
//        let dData = {
//          name: null, kwh: null
//        }
//         dData.name = previousWeeks[j].name;
//         let kwh = Math.round(weekEnergy[j])/1000;
//            if(kwh>9999){
//             dData.kwh = maxMeterValue;
//            }
//           if(kwh>0 && kwh<9999){
//             dData.kwh = kwh;
//           }
//            if(kwh==0){
//             dData.kwh = 0;
//            }
//         weeksData.push(dData);
//      }
//      setData(weeksData);
//     }
//   }
// }, []);

useEffect(() => {
  if (props.totalPowerDataCharts) {
    if (props.totalPowerDataCharts.weekly_energy_totals) {
  //let data = "-14110.00,-27490.00,173909.98,-41586.34,"; 
  let weekEnergy=props.totalPowerDataCharts.weekly_energy_totals.split(",");
  //let weekEnergy=data.split(",");
  weekEnergy.pop();
  let previousWeeks= weeks();
  previousWeeks.reverse();
  let weeksData=[];
  for (let j = 0; j < previousWeeks.length; j++) {
       let dData = {
         name: null, kwh: null
       }
        dData.name = previousWeeks[j].name;
        let kwh = Math.round(weekEnergy[j])/1000;
        if(kwh>maxMeterValue){
          dData.kwh = maxMeterValue;
         }
        if(kwh<-minMeterValue){
          dData.kwh = -minMeterValue;
        }
        if(kwh<maxMeterValue && kwh>-minMeterValue){
          dData.kwh = kwh;
        }
           if(kwh==0){
            dData.kwh = 0;
           }
        weeksData.push(dData);
     }
     setData(weeksData);
    }
  }
}, []);

const classes = useStyles();
const cardinal = curveCardinal.tension(0.2);
return (
    <div style={{ width: '120%', height: 150, fontSize: '12px' }}>
       <ResponsiveContainer>
     <AreaChart
        width={600}
        height={100}
        data={data}
        margin={{
          top: 0, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis 
        ticks={[-minMeterValue,0, maxMeterValue]}
        domain={[-minMeterValue, maxMeterValue]} />
        <Tooltip />
        <Area type="monotone" dataKey=" " stroke="#000" fill="#87d200" fillOpacity={0.3} />
        <Area type={cardinal} dataKey="kwh" stroke="#000" fill="#87d200" fillOpacity={0.3} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
)
}
export default React.memo(KwhTotalWeekChart);



