/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import wiserLogo from "./../assets/img/wLogo.png";
import loginBg from "./../assets/img/loginBg.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import sha256 from 'crypto-js/sha256';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import "assets/css/profileStyle.css";
import { updateLoginStatus, updateLoginUname, updateLoginPassword } from '../redux/actions/action'
import styles from "./../assets/widgets/widgetStyles/commonStyles";
import { login, passwordReset, versionInfo, rebootDevice } from "../services/xmlService";
import PasswordValidation from "./../components/Widgets/passwordValidation";
import { includes } from "lodash";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles);

const Login = (props) =>{
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = React.useState();
  const [password, setPassword] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState();
  //for password validation
  const [username, setUsername] = useState('');
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
    specialNumberCharCheck: false,
    noSpaceCheck: false,
    usernameCheck: false
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [version, setVersion] = useState();
  const [validationCheck, setValidationCheck] = useState();
  const [savedisabled, setSavediabled] = useState(false);
  const [open, setOpen] = React.useState(false);

  //Working code 
  useEffect(()=>{
    if(newPasswordConfirmation!=="" && newPassword!=="" && (newPassword===newPasswordConfirmation) && (checks.capsLetterCheck) && (checks.pwdLengthCheck) && (checks.specialNumberCharCheck) && (checks.noSpaceCheck) && (!checks.usernameCheck)) {
      setSavediabled(true);
      
  
    } else {
      setSavediabled(false);
      
    
    }  
  },[newPassword, newPasswordConfirmation])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event) => {
    setOpen(false);
    setPassword(event.target.value = "");
    setErrorMessage("");
    setValidationCheck(false);
    
   };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleNewPasswordChange = (event) => {
    
    setNewPassword(event.target.value);
  };
  const handlePasswordConfirmationChange = (event) => {
    setNewPasswordConfirmation(event.target.value);
  };

  // const handleOnChange = (e) => {
  //   setPassword(e.target.value);
  // }
  
  const handleOnChangeUser = (e) => {
    setUsername(e.target.value);
  }
  
  const handleOnFocus = () => {
    setPasswordValidation(true)
  }
  
  const handleOnBlur = () => {
    setPasswordValidation(false)
  }

  const handleOnKeyUp = (e) => {
    const { value } = e.target;
    const capsLetterCheck = /(?=.*[a-z])(?=.*[A-Z])/.test(value);
    const pwdLengthCheck = (value.length >= 8) && (value.length <=  64);
    const specialNumberCharCheck = /[~!@#$%\^&*"'`)}|\[\]\/\\?><;,:{(+=._-]|[0-9]/.test(value);
    const noSpaceCheck = /^[^\s]+(\s+[^\s]+)*$/.test(value);
    const usernameCheck = value.toLowerCase().includes(name.toLowerCase());
    setChecks({
      capsLetterCheck,
      pwdLengthCheck,
      specialNumberCharCheck,
      noSpaceCheck,
      usernameCheck
    })
    }

  const handleClick = () => {
    login(name, sha256(password).toString()).then((res) => {
        if(res.status==200 || res.status==304){
          let validate = /^(?=.*[~!@#$%\^&*"'`)}|\[\]\/\\?><;,:{(+=._-])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9_ ~!@#$%\^&*"'`)}|\[\]\/\\?><;,:{(+=._-]{8,64}$/.test(password) && /^[^\s].+[^\s]$/.test(password) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9_ ~!@#$%\^&*"'`)}|\[\]\/\\?><;,:{(+=._-]{8,64}$/.test(password) && /^[^\s].+[^\s]$/.test(password);
          if (validate) {
            setValidationCheck(false);
            sessionStorage.setItem("auth", window.btoa(name + ':' + sha256(password).toString()));
            props.updateLoginUname(name);
            props.updateLoginPassword(sha256(password).toString());
            props.updateLoginStatus(true);
          } else{
            setValidationCheck(true);
            setErrorMessage("Not as per password guidelines");
          }
    }

    }).catch((error) => {
      if (error.response.status == 401) {
        setErrorMessage("Incorrect username or password, please try again");
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.response.status == 402) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setErrorMessage("Please use HTTPS for remote connection.");
        console.log(error.request);
      }
      else if (error.response.status == 403) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setErrorMessage("Please check if the Projector control is enabled under the Remote Access options");
        console.log(error.request);
      } else {
        setErrorMessage("Something went wrong! Please try again.");
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    })
  };

  const handleReset = () => {
    if(newPassword !== newPasswordConfirmation){
      setConfirmPasswordError("The password doesn't match");
  } else{
    let data = {
      name : name,
      currentPassword: password,
      newPassword: sha256(newPassword).toString()
    }
    passwordReset(data, name, sha256(password).toString()).then((res) => {
      if (res.status == 200 || res.status == 304) {
        rebootDevice().then((res) => {
          setOpen(true);
        }).catch((error) => {
          
        });
        
      }else{
        
        setConfirmPasswordError("Something went wrong! Please try again.");
      }
    }).catch((error) => {
      
    });
  }
  }

  useEffect(() => {
    versionInfo()
      .then((res) => {
        
        setVersion(res.data);
      })
      .catch((error) => {
        
      });
  }, []);

    return (
      <Grid item lg={12} md={12} sm={12} xs={12}>
         <div className={classes.loginPage} style={{ backgroundImage:`url(${loginBg})`, backgroundSize: "100%" }}> 
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            textAlign="center"
          >
            <img src={wiserLogo} alt="logo" className={classes.loginLogo} />
            <input
              className={classes.loginField}
              type="text"
              label="Username"
              placeholder="Username"
              value={name}
              onChange={handleNameChange}
              autoComplete="off"
              inputProps={{
                style: { color: "black", borderColor: "black !important" },
              }}
            />
            <input
              className={classes.loginField}
              type="password"
              label="Password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="off"
            />
            {errorMessage && (
              <p className={classes.error}> {errorMessage} </p>
            )}
         {validationCheck &&  <input
              className={classes.loginField}
              type="password"
              label="New password"
              placeholder="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              onFocus={handleOnFocus}  
              onBlur={handleOnBlur}   
              onKeyUp={handleOnKeyUp}
              autoComplete="off"
            />}
        {validationCheck && passwordValidation ? <PasswordValidation 
        capsLetterFlag={checks.capsLetterCheck? "valid": "invalid" }
        pwdLengthFlag={checks.pwdLengthCheck? "valid": "invalid" }
        specialNumberCharFlag={checks.specialNumberCharCheck? "valid": "invalid" }
        noSpaceCheckFlag={checks.noSpaceCheck? "valid": "invalid"}  
        usernameFlag={checks.usernameCheck? "invalid" : "valid"}     
        /> 
        : null}
           { validationCheck &&  <input
              className={classes.loginField}
              type="password"
              label="New password confirmation"
              placeholder="New Password Confirmation"
              value={newPasswordConfirmation}
              onChange={handlePasswordConfirmationChange}
              autoComplete="off"
            />}
            {validationCheck && confirmPasswordError && (
              <p className={classes.perror}> {confirmPasswordError} </p>
            )} 
            {!validationCheck && <Button
              size="medium"
              className={classes.loginButton}
              variant="contained"
              onClick={handleClick}
            >
              Login
            </Button>}
             {validationCheck && <Button
              size="medium"
              className={classes.loginButton}
              disabled = {!savedisabled}
              variant="contained"
              onClick={handleReset}
              //onClick={() => handleClickOpen(false)}
            >
              Reset Password
            </Button>}
          </Box>

          <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Password Reset Successfully. Your device is rebooting. Please login after 3 minutes.
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleClose}
              className={classes.drawerbtn}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        </div>
        <div> <p className={classes.versionInfo}> Version Info {version} </p></div>
      </Grid>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
	updateLoginStatus, updateLoginUname, updateLoginPassword
}, dispatch);

export default connect(null, mapDispatchToProps)(Login);