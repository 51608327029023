import ReactSpeedometer from "react-d3-speedometer";
import { useState, useEffect } from "react";
import { connect } from "react-redux";

function DollarWeekStatsTotalSpeedo(props) {
  const [meterValue, setMeterValue] =useState("");

  let exceedValue = 9999;

  //CurrencyUnit Value
  const currencyUnit = props.widget.params._attributes.currency_units;

  // //Tariff_offPeak Value
  // const tariff1 = props.energy.Project.Energy.Tariffs.Tariff[0]._attributes.Price;

  // //Tariff_general Value
  // const tariff2 = props.energy.Project.Energy.Tariffs.Tariff[1]._attributes.Price;

  let tariff, tariff1;
  
  let tariffData = props.energy.Project.Energy.Tariffs.Tariff;
  if(!Array.isArray(tariffData)){
   tariff = tariff1 = props.energy.Project.Energy.Tariffs.Tariff._attributes.Price
  }
  else{
      tariff = props.energy.Project.Energy.Tariffs.Tariff[0]._attributes.Price;
      tariff1 = props.energy.Project.Energy.Tariffs.Tariff[1]._attributes.Price;
  }

  let minMeterValue = (Number(props.widget.params._attributes.max_weekly_gen_w)/1000)*tariff1;

  let maxMeterValue = (Number(props.widget.params._attributes.max_weekly_con_w)/1000)*tariff;

  let meterResult = meterValue >= 9999 ? exceedValue : meterValue;

  let meterText = meterValue >= 9999 ? exceedValue : meterValue.replace("-", " ");


  useEffect(() => {
    if (props.powerStatTotal) {
        if (props.powerStatTotal.name === "cbusPowerTotals") {
         setMeterValue(props.powerStatTotal.current_total_cost_week);
      }
    }
  }, [props.powerStatTotal]);

    return (
        <div style={{ marginTop: '10px'}}>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxSegmentLabels={1}
                minValue={-minMeterValue}
                maxValue={maxMeterValue}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                customSegmentStops={[-minMeterValue, 0, maxMeterValue]}
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterText} ${currencyUnit}`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={170}
                height={110}
               dimensionUnit="px"
                
/>
        </div>
    );
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(DollarWeekStatsTotalSpeedo); 
