import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import KWHPowerStatsSet from './PowerStatsSet/KWHPowerStatsSet';
import DollarPowerStatsSet from './PowerStatsSet/DollarPowerStatsSet';
import CO2PowerStatsSet from './PowerStatsSet/CO2PowerStatsSet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { updateSocketEvent } from "../../../../services/xmlService";

// style sheet imported locally // 
import styles from '../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

//function
const PowerStats = (props) => {
  const initialData = props.initialCbusEvent.get(
    "meter"+props.widget.params._attributes.meterno
  );

  const initialDataCharts =props.initialCbusEvent.get(
    "meter"+props.widget.params._attributes.meterno);

  const classes = useStyles();
  const [meterData, setMeterData] = useState();
  const [powerData, setPowerData] =useState("");
  const [powerDataHistory, setPowerDataHistory] = useState("");
  const defaultTab = props.widget.params._attributes.default_units;
  const [value, setValue] = React.useState(defaultTab === "0" ? 0 : defaultTab === "1" ? 2 : 1);


  const handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
    };

  useEffect(() => {
    let powerCmd = {
      cmd: "<cbus_cmd app=\"0x102\" command=\"cbusGetComboPowerHistory\" meter=\"" + props.widget.params._attributes.meterno + "\" />"
    }
    setTimeout(() => {
      handleSocketEventChange(powerCmd);
    }, Number(props.widget.params._attributes.meterno) * 1000)   
  }, []);

  useEffect(() => {
    if(initialData){
      setPowerData(initialData);
    } else{
      let powerCmd = {
        cmd: "<cbus_cmd app=\"0x102\" command=\"cbusGetComboPowerHistory\" meter=\"" + props.widget.params._attributes.meterno + "\" />"
      }
      setTimeout(() => {
      handleSocketEventChange(powerCmd);
      }, Number(props.widget.params._attributes.meterno) * 1000)
    }
  }, [initialData]);
  
  useEffect(() => {
    if(initialDataCharts){
      setPowerDataHistory(initialDataCharts);
    }
  }, [initialDataCharts]);
  
  const TabPanel = (prop) => {
    const { children, value, index, ...other } = prop;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        style={{ width: "50%" }}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}

      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // useEffect(() => {
  //   if (props.cbusEvent) {
  //     if (props.cbusEvent.name === "cbus_event") {
  //       if (props.cbusEvent.attributes.name === "cbusInstantaneousPowerHistory") {
         
  //         if (props.cbusEvent.attributes.meter === props.widget.params._attributes.meterno) {
  //           setPowerData(props.cbusEvent.attributes);
  //           if(initialData){
  //             props.initialCbusEvent.set("meter"+props.widget.params._attributes.meterno, props.cbusEvent.attributes);
  //             let powerData=new Map(props.initialCbusEvent);
  //             props.socketEventDataChanger(powerData);
  //           }
  //         }
  //       }
  //     }
  //   }
  //   if(initialData){
  //     setPowerData(initialData);
  //   }
  // }, [props.cbusEvent]);



  const name = props.widget.params._attributes.label != undefined ? props.widget.params._attributes.label : "";

  return (
    <>
      { props.widget !== undefined &&
        <Grid container items lg={11} spacing={1} className={classes.powerStatsLayout}>
          <Grid items md={12} className={classes.powerStatsTabs}>
            <Grid items md={8}>
              <Typography align="left" className={classes.powerStatsLayoutHeaderText}>{name} </Typography>
            </Grid>
            <Grid items md={4}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                inkBarStyle={{ background: 'green' }}
                TabIndicatorProps={{ style: { background: '#87d200' } }}
              >
                <Tab
                  variant="contained"
                  onActive
                  label={props.language.kWh}
                  className={classes.PowerTab}
                  {...a11yProps(0)}
                  style={{ fontSize: '16px', minWidth: '10px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
                />

                <Tab
                  label={props.widget.params._attributes.currency_units}
                  className={classes.PowerTab}
                  {...a11yProps(1)}
                  style={{ fontSize: '16px', minWidth: '10px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
                />
                <Tab
                  label={props.language.co2}
                  className={classes.PowerTab}
                  {...a11yProps(2)}
                  style={{ fontSize: '16px', minWidth: '10px', textTransform: 'none', fontWeight: '700', color: '#959595' }}
                />
              </Tabs>
            </Grid>
          </Grid>

          <Grid items style={{ marginTop: '10px'}} md={12}>
          {value == 0 &&
            <KWHPowerStatsSet
              data={props.widget}
              widget={props.widget}
              powerData={powerData}
              powerDataHistory={powerDataHistory}
              energy={props.data}
              language={props.language}
              //meterData={meterData}
            />
          }
          {value == 1 &&
            <DollarPowerStatsSet
              data={props.widget}
              widget={props.widget}
              powerData={powerData}
              powerDataHistory={powerDataHistory}
              energy={props.data}
              language={props.language}
              //meterData={meterData}
            />
          }
          {value == 2 &&
            <CO2PowerStatsSet
              data={props.widget}
              widget={props.widget}
              powerData={powerData}
              powerDataHistory={powerDataHistory}
              energy={props.data}
              language={props.language}
              //meterData={meterData}
            />
          }

          </Grid>
        </Grid>
      }

    </>
  );
}

export default PowerStats;
