import React, { Component } from "react";

// Redux Store //
import { bindActionCreators } from "redux";
import {
  getAppConfigData,
  socketEventDataChanger,
} from "../../redux/actions/action";
import { connect } from "react-redux";
import { updateXml, updateSocketEvent } from "../../services/xmlService";

// Style //

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/js/all-styles/views/favoriteStyle.js";

// Component imported Locally //
// Climate //
import Hvac from "components/Widgets/hvac";
// LIghtings //
import BellPress from "components/Widgets/bellPress";
import LightingRelay2State from "components/Widgets/lightingRelay2state.js";
import LightingPreset from "components/Widgets/lightingPreset";
import GeneralLightingDimmer from "components/Widgets/Lighting/generalLightingDimmer";
import GeneralLightingRelay from "components/Widgets/Lighting/generalLightingRelay.js";
import LightingStatus from "components/Widgets/lightingStatus";
import Timer from "components/Widgets/timer";
// Music //
import Analog from "components/Widgets/analog";
import MusicPlayer from "components/Widgets/musicPlayer";
//Scenes //
import ScenesTrigger from "components/Widgets/scenesTrigger";
import Scenes from "components/Widgets/scenes";
//Surveillance //
import SurveillanceWidget from "components/Widgets/SurveillanceWidget.js";
//Logic //
import UserSystemTime from "components/Widgets/Logic/userSystemTime";
import UserSystemBoolean from "components/Widgets/Logic/userSystemBoolean";
import UserSystemDate from "components/Widgets/Logic/userSystemDate";
import UserSystemInteger from "components/Widgets/Logic/userSystemInteger";
import UserSystemRealNumber from "components/Widgets/Logic/userSystemRealNumber";
import UserSystemString from "components/Widgets/Logic/userSystemString";
// Curtain //
import Curtain3Button from "components/Widgets/Curtain/curtain3Button";
import Curtain2Button from "components/Widgets/Curtain/curtain2Button";
import Curtain1Button from "components/Widgets/Curtain/curtain1Button";
//Shutter-Relay //
import ShutterRelay3Button from "components/Widgets/shutterRelay3Button";
// Security //
import SecurityWidget from "components/Widgets/securityWidget";
import MinderProWidget from "components/Widgets/minderProWidget";
//C-Bus Others //
import FanController from "components/Widgets/fanController";
import PowerMetering from "components/Widgets/PowerMeter/PowerMeteringWidget/PowerMetering";
import PowerMeteringTotal from "components/Widgets/PowerMeter/PowerMeteringWidgetTotal/PowerMeteringTotal";

const classes = makeStyles(styles);

class Function extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetCategory: "functionGroup",
      favorites: [],
      widgetsList: []
        };
  }

  componentDidMount() {
    let fvs = JSON.parse(localStorage.getItem("favorites"));
    if (fvs) {
      this.setState({
        favorites: fvs,
      });
    }
    let widgetData = this.props.widgets;
    if(!Array.isArray(widgetData)){
      let data = [];
      data.push(this.props.widgets);
      this.setState({
        widgetsList: data,
      });

    } else{
      this.setState({
        widgetsList: widgetData,
      });    
    }

  }


  handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
  };

  saveEditedWidget = (name, functiongroup, location, widget) => {
    for (
      let i = 0;
      i < this.props.data.Project.FunctionGroups.FunctionGroup.length;
      i++
    ) {
      if (
        this.props.data.Project.FunctionGroups.FunctionGroup[i]._attributes
          .id === widget._attributes.functiongroup
      ) {
        this.props.data.Project.FunctionGroups.FunctionGroup[
          i
        ]._attributes.Name = functiongroup;
      }
    }
    for (
      let j = 0;
      j < this.props.data.Project.Locations.Location.length;
      j++
    ) {
      if (
        this.props.data.Project.Locations.Location[j]._attributes.id ===
        widget._attributes.location
      ) {
        this.props.data.Project.Locations.Location[
          j
        ]._attributes.Name = location;
      }
    }
    for (let m = 0; m < this.props.data.Project.Widgets.widget.length; m++) {
      if (
        this.props.data.Project.Widgets.widget[m]._attributes.id ===
        widget._attributes.id
      ) {
        this.props.data.Project.Widgets.widget[
          m
        ].params._attributes.label = name;
      }
    }
    // we are not writing it back to xml so commented this block to fix the segmentation issue
    // updateXml(this.props.data)
    //   .then((result) => {
    //     if (result.status === 200) {
    //       this.props.getAppConfigData();
    //     }
    //   })
    //   .catch((error) => {
        
    //   });
  };

  handleFavorite = (id, type) => {
    if (localStorage.getItem("favorites")) {
      let fvs = JSON.parse(localStorage.getItem("favorites"));
      if (fvs.includes(id)) {
        const index = fvs.indexOf(id);
        if (index > -1) {
          fvs.splice(index, 1);
          localStorage.setItem("favorites", JSON.stringify(fvs));
          this.setState({
            favorites: fvs,
          });
        }
      } else {
        fvs.push(id);
        localStorage.setItem("favorites", JSON.stringify(fvs));
        this.setState({
          favorites: fvs,
        });
      }
    } else {
      let f = [];
      f.push(id);
      localStorage.setItem("favorites", JSON.stringify(f));
    }
  };

  render() {
    return (
      <>
        {this.state.widgetsList && Array.isArray(this.state.widgetsList) && 
          this.state.widgetsList.map((widget, key) => {
            return (
              <>
                {widget._attributes.type === "25" && 
                  this.state.favorites.includes(widget._attributes.id) && (
                    <Hvac
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "8" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <BellPress
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "15" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <LightingRelay2State
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "14" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <SurveillanceWidget
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "26" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <LightingPreset
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "1" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <GeneralLightingDimmer
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "0" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <GeneralLightingRelay
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "27" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <LightingStatus
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "11" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <ScenesTrigger
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "17" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <Analog
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "12" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <MusicPlayer
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}

                {widget._attributes.type === "9" &&
                widget.params._attributes.noofbuttons === "3" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <Curtain3Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "9" &&
                  widget.params._attributes.noofbuttons === "2" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <Curtain2Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "9" &&
                  widget.params._attributes.noofbuttons === "1" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <Curtain1Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "10" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <Scenes
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {/* {widget._attributes.type === "16" &&
                  this.state.favorites.includes(widget._attributes.id) &&
                  
                   (
                    <ShutterRelay1Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      locations={this.props.locations}
                      widget={widget} 
language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "16" &&
                  this.state.favorites.includes(widget._attributes.id) && 
                  widget.params._attributes.type==="two" &&
                  (
                    <ShutterRelay2Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget} 
language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )} */}
                {widget._attributes.type === "16" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <ShutterRelay3Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "13" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <SecurityWidget
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "24" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <MinderProWidget
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {/* {widget._attributes.type === "9" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <ZigBeeDimmerWidget
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget} 
language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )} */}
                {widget._attributes.type === "30" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <Timer
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Time" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <UserSystemTime
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Boolean" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <UserSystemBoolean
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Date" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <UserSystemDate
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Integer" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <UserSystemInteger
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Real Number" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <UserSystemRealNumber
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO String" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <UserSystemString
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "31" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <FanController
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "32" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <PowerMetering
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "33" &&
                  this.state.favorites.includes(widget._attributes.id) && (
                    <PowerMeteringTotal
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
              </>
            );
          })}
        {this.state.favorites.length === 0 && (
          <div
            className={classes.favcontainer}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(100vh - 78px)",
              width: "100%",
              color: "#fff",
            }}
          >
            <h2 className={classes.favh2} style={{ margin: "0" }}>
              {this.props.language.noFavorites}
            </h2>
            <h4
              className={classes.favh4}
              style={{ margin: "0", color: "#83cb02" }}
            >
              {this.props.language.selectFavorites}
            </h4>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.reducer.data,
  locations: state.reducer.locations,
  functionGroups: state.reducer.functionGroups,
  widgets: state.reducer.widgets,
  functionGroupId: state.reducer.functionGroup_id,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      socketEventDataChanger,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Function);