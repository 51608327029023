import React from "react";
import { useState, useEffect } from "react";

//Components imported locally //
import EditWidget from '../editWidget';
import BootstrapTooltip from '../../../assets/widgets/tooltip/tooltip'
import IOIntegerLogic from "../IOIntegerLogic";

// material-ui //
import { makeStyles} from "@material-ui/core/styles";
import { Button, IconButton, Typography, Drawer, Grid} from '@material-ui/core';
import classNames from "classnames"
import StarsIcon from '@material-ui/icons/Stars'
import EditIcon from '@material-ui/icons/Edit';


import profileGreen from "../../../assets/img/profileGreen.png";

import styles from '../../../assets/widgets/widgetStyles/commonStyles';
import { updateXml, rebootDevice } from "../../../services/xmlService";
const useStyles = makeStyles(styles);


const UserSystemInteger = (props)=> {
  const initialData=props.initialCbusEvent.get("usio_var"+props.widget.params._attributes.usio_var);
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [favData, setFaData] =useState(props.favData);
  const [sysInt, setSysInt] =useState(props.sysInt==undefined?0: Math.floor(props.sysInt));
  const [editedsysInt, setEditedsysInt] =useState(props.sysInt==undefined?0: Math.floor(props.sysInt));
  const [userVariable, setUserVariable] =useState();
  
  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const toggleDrawer1 = (open) => (event) => {
    setDraweropen1(open); 
  };

  const updateFav = (fav) =>{
    setFaData(!fav);
  } 

  const test = () => {
    let initialEventTriger = {
      cmd:
      '<cbus_cmd app="257" command="cbusSetUserSystemIOInt" sio_index="' +
      props.widget.params._attributes.usio_var +
      '" nooofparams="0"/>',
    };
    props.handleSocketEventChange(initialEventTriger);
  }

  const handleChangeIPAdreess = (event) => {
    //  const re = /^[0-9\b]+$/;
    const re = /^([0-9][0-9]{0,2}|100)$/;
    if (event.target.value === '' || event.target.value <= 100 && re.test(event.target.value)) {
      setSysInt(event.target.value);

    }
  };


  const handleSave = ()=>{
    setDraweropen1(false); 
    setSysInt(editedsysInt);
    let newsysint = parseInt(editedsysInt);
    let savesio4 = 
      {cmd: '<cbus_cmd app="0x101" command="cbusSetUserSystemIOInt" value="'+newsysint+'" sio_index="'+props.widget.params._attributes.usio_var+'" status="0" />'  };
      
       props.handleSocketEventChange(savesio4);
       let uservar = props.data.Project.UserVariables.UserVariable;
       for (let i = 0; i < uservar.length; i++) {
         if (uservar[i]._attributes.id == props.widget.params._attributes.usio_var) {
           props.data.Project.UserVariables.UserVariable[i]._attributes.IntegerValue = editedsysInt;
           
         }
       }
       updateXml(props.data)
         .then((result) => {
           if (result.status === 200) {
             
           }
         })
         .catch((error) => {
           
         });
  }

  const handleChange = (changedVal) => {
    setEditedsysInt(changedVal);
  }

  const editIOLogic = (sysInt,setSysInt) => (
    <>
    <div style={{ background: "#3b3b3b" }}>
        <div style={{ padding: "25px 10px 5px 15px" }}>
          <p style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>
          Edit Widget
          </p>
          <p style={{ color: "#707070", fontWeight: "600", margin: "0" }}>
          {name}
          </p>
        </div>
        <hr style={{border:"1px solid black", margin:"0", borderTopColor: "transparent"}}/>
        <div style={{ display: "flex", padding: "15px" , justifyContent:"space-between"}}>       
          <button className={classes.drawerbtn}
            onClick={toggleDrawer1(false)}          
    
          >
            Cancel
          </button>
          <button 
          className={classes.drawerbtn}
          onClick={handleSave}
          >
            Save
          </button>
        </div>
        <hr style={{border:"1px solid black", margin:"0", borderTopColor: "transparent"}}/>      
      </div>
      <IOIntegerLogic widget={props.widget} userVariable={userVariable} sysInt={sysInt} setSysInt={setSysInt} saveChanges={handleChange}   />

     </>
  );

  useEffect(() => {
    let data = props.data.Project.UserVariables.UserVariable;
    if(data){
      for(let i=0;i<data.length;i++){
        if(data[i]._attributes.id == props.widget.params._attributes.usio_var){
          setUserVariable(data[i]._attributes);
          let intitialdatavalue = {
            value: data[i]._attributes.IntegerValue
          }
          props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, intitialdatavalue);
          let ioData=new Map(props.initialCbusEvent);
          props.socketEventDataChanger(ioData);
          setSysInt(data[i]._attributes.IntegerValue);
        }
      }
    }
  },[]);

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_resp") {
        if (props.cbusEvent.attributes.command === "cbusSetUserSystemIOInt") {
          if (props.cbusEvent.attributes.sio_index === props.widget.params._attributes.usio_var) {
            setSysInt(props.cbusEvent.attributes.value);
            if(initialData){
              initialData.value=props.cbusEvent.attributes.value;
              props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, initialData);
              let ioData=new Map(props.initialCbusEvent);
              props.socketEventDataChanger(ioData);
            }
          } 
        }
      }
    }
    // if (props.cbusEvent) {
    //   if (props.cbusEvent.name === "cbus_resp") {
    //     if (props.cbusEvent.attributes.command === "cbusSetUserSystemIOInt") {
    //       test();
    //     }
    //   }
    // }
    if(initialData){
      setSysInt(initialData.value);
    }
  }, [props.cbusEvent]);


// Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const location = props.widget._attributes.location=== "none" ? "" : props.locations[Number(props.widget._attributes.location)]._attributes.Name;
const functionGroup = props.widget._attributes.functiongroup==="none" ? "" : props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name;
 
return (
  <> { props.data !== undefined &&
  <Grid item lg={4} md={6} sm={6} xs={12}>
    <div className={classes.root} >
    <BootstrapTooltip
          title={
            <React.Fragment>
              <IconButton
               className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
                aria-label="delete"
                onClick={() => {props.handleFavorite(id, props.widget._attributes.type);updateFav(favData);}}
                
              >
              <StarsIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={()=>{ toggleDrawer(true)}}
              >
                < EditIcon />
              </IconButton>
            </React.Fragment>
          }
        >
    <div className={classes.header} >
    <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton} >
          <img src={profileGreen} alt={"bulb"} className={classes.headerIconLogic}   />
          </IconButton>
          <div className={classes.header1} > 
          <Typography variant='h6' className={ `${classes.headerTextLogicIO} ${classes.textTruncation} `} >
         {name}
          </Typography>
               
          </div>
         </div>
         </BootstrapTooltip>  
         <Button
        variant="contained"
       // disabled='true'
        className={classes.logicIoBottomPanel} 
        onClick={toggleDrawer1(true)}  
        style={{ cursor: "pointer"}}
         >
       <Typography className={classes.bottomPanel2ButtonText} variant='h5' >
        {Number(sysInt) == Math.floor(Number(sysInt)) ? Number(sysInt) : Number(sysInt).toFixed(2)}
          </Typography>
       </Button> 
       <Drawer
          variant="temporary"
          anchor={"right"}
          open={draweropen}
          onClose={()=>{toggleDrawer(false)}}
          modal
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
        <EditWidget saveEditedWidget={props.saveEditedWidget} data={props} toggleDrawer={toggleDrawer} />
        </Drawer>
              </div>
              </Grid>
  }
   <Drawer
          variant="temporary"
          anchor={"right"}
          open={draweropen1}
          onClose={()=>{toggleDrawer1(false)}}
          modal
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
       {editIOLogic(sysInt, setSysInt )}
        </Drawer>
    </> 
  );
}

export default UserSystemInteger;