import React, { useState, useEffect } from "react";
//Assets //
import relay from "assets/img/relay.png";
//Components imported locally //
import EditWidget from "../editWidget";

// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";

// CSS Style sheets imported locally //
import styles from "../../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

// Tooltip Styles //

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "grey",
    placement: "right",
    maxHeight: "150px",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return (
    <Tooltip
      interactive
      enterDelay={300}
      leaveDelay={1000}
      placement="top-start"
      arrow
      classes={classes}
      {...props}
    />
  );
}

export default function Curtain3Button(props) {
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.widget.params._attributes.app).toString(16)
  );
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [open3, setOpen3] = useState(false);
  const [close3, setClose3] = useState(false);
  const [stop3, setStop3] = useState(false);
  const [count, setCount] = useState(0);
  const [label, setLabel] = useState(
    props.widget.params._attributes.button0label
  );
  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const handleButton3 = (buttonNo) => {
    if (buttonNo === 1) {
      setOpen3(true);
      setClose3(false);
      setStop3(false);
      handleClick3(true, false, false);
    } else if (buttonNo == 2) {
      setOpen3(false);
      setClose3(false);
      setStop3(true);
      handleClick3(false, false, true);
    } else if (buttonNo == 3) {
      setOpen3(false);
      setClose3(true);
      setStop3(false);
      handleClick3(false, true, false);
    }
  };

  const handleClick3 = (open3, close3, stop3) => {
    if (open3 === true) {
      
      
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="255" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="0" ramp="0"/>',
      };

      props.handleSocketEventChange(buttonOpen2);
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen1);
      }, 100);
      
      
    } else if (close3 === true) {
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="255" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="0" ramp="0"/>',
      };

      props.handleSocketEventChange(buttonOpen2);
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen1);
      }, 100);
      
      
    } else if (stop3 === true) {
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="0" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(buttonOpen1);
      props.handleSocketEventChange(buttonOpen2);
      
      
    }
  };

  const noOfButtons = props.widget.params._attributes.noofbuttons;

  // Data assigned to consts from Redux Store //

  const id = props.widget._attributes.id;

  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.locations[Number(props.widget._attributes.location)]._attributes.Name;
  const functionGroup =
    props.functionGroups[Number(props.widget._attributes.functiongroup)]
      ._attributes.Name;




  return (
    <>
      {" "}
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img src={relay} alt="relay" className={classes.headerIcon} />
                </IconButton>
                <div className={classes.header1}>
                  <Typography variant="h6" className={ `${classes.headerText1} ${classes.textTruncation} `}>
                    {name}
                  </Typography>
                  <Typography variant="h6" className={ `${classes.headerText2} ${classes.textTruncation} `}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>
            <div className={classes.bottomPanelScenes}>
              <Button
                variant="contained"
                className={open3?classes.bottomPanel3ButtonActive:classes.bottomPanel3Button}
                onMouseDown={() => handleButton3(1)}
                onMouseUp={()=>setOpen3(false)}
              >
                <Typography
                  className={classes.bottomPanel2ButtonText}
                  variant="h5"
                >
                  {props.widget.params._attributes.button0label}
                </Typography>
              </Button>
              <Button
                variant="contained"
                className={stop3?classes.bottomPanel3ButtonActive:classes.bottomPanel3Button}
                style={{ flexGrow: "1" }}
                onMouseDown={() => handleButton3(2)}
                onMouseUp = {()=>setStop3(false)}
              >
                <Typography
                  className={classes.bottomPanel2ButtonText}
                  variant="h5"
                >
                  {props.widget.params._attributes.button1label}
                </Typography>
              </Button>
              <Button
                variant="contained"
                className={close3?classes.bottomPanel3ButtonActive:classes.bottomPanel3Button}
                style={{ borderRight: "none" }}
                onMouseDown={() => handleButton3(3)}
                onMouseUp={()=>setClose3(false)}
              >
                <Typography
                  className={classes.bottomPanel2ButtonText}
                  variant="h5"
                >
                  {props.widget.params._attributes.button2label}
                </Typography>
              </Button>
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
