import ReactSpeedometer from "react-d3-speedometer"
import NowKwhRateConsumption from "../../PowerConsumption/kwh/NowKwhRateConsumption"
import { useState, useEffect } from "react";
import { connect } from "react-redux";

function KwhNowSpeedoTotal(props) {
const [meterValue, setMeterValue] =useState("");

let minMeterValue = Number(props.widget.params._attributes.max_hourly_gen_w)/1000;

let maxMeterValue = Number(props.widget.params._attributes.max_hourly_con_w)/1000;

//let meterData = (meterValue/1000).toFixed(2).replace("-", " ");

let meterData = (meterValue/1000).toFixed(2);

let meterResult = meterData >= 9999 ? 9999 : meterData;

let meterText = (meterValue/1000).toFixed(2).replace("-", " ");

let removeNeg = Math.abs(-minMeterValue)

useEffect(() => {
    
  if (props.totalPowerData) {
      if (props.totalPowerData.name === "cbusPowerTotals") {
       setMeterValue(props.totalPowerData.current_total_power); 
    }
  }
}, [props.totalPowerData]);

    return (

      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
      <h6 style={{margin: '0px' }}>{props.language.now}</h6>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxSegmentLabels={1}
                // valueFormat="-15"
                minValue={-minMeterValue}
                maxValue={maxMeterValue}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                customSegmentStops={[-minMeterValue, 0, maxMeterValue]}
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterText} kW`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={180}
                height={120}
               dimensionUnit="px"
        />
        <NowKwhRateConsumption
          powerData={props.powerData}
          totalPowerDataHistory={props.totalPowerDataHistory}
          data={props.data}
          widget={props.widget}
          energy={props.energy}
          language={props.language}
        />
        </div>
    );
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(KwhNowSpeedoTotal);