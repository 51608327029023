import React, { useRef } from "react";

import Grid from "@material-ui/core/Grid";
import reactlogo from "assets/img/reactlogo.jpg";
import reactlogo1 from "assets/img/reactlogo1.png";
import classNames from "classnames";
import ImageUploadComponent from "views/Settings/Profiles1";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/js/all-styles/views/profileStyle.js";

import "assets/css/profileStyle.css";

import { connect } from "react-redux";

import { getAppConfigData, profileUserChanger } from "redux/actions/action";
import { bindActionCreators } from "redux";
import { updateXml, uploadFile, rebootDevice } from "../../services/xmlService";


import {
  Drawer,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";


import ImageEditdrawer from "./ImageEditdrawer";
import Userdetails from "views/Settings/Profiles/Userdetails";
import Featuredrawer from "views/Settings/Profiles/Featuredrawer";
import ProfileImage from "./ProfileImage";

import "assets/css/profileStyle.css";
const useStyles = makeStyles(styles);

const Profiles = (props) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  let [file, setFile] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(reactlogo);
  const [imageUrlNew, setimageUrlNew] = React.useState(imageUrl);
  const [userKey, setUserKey] = React.useState();

  const [newProfileData, setNewProfileData] = React.useState();
  const [updatedImage, setUpdatedImage] = React.useState(null);
  

  const handleImageChange = (e, profileData) => {
    
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    setNewProfileData(profileData);
  
    reader.onload = () => {
      setFile(newFile);
      setImageUrl(reader.result);
      setimageUrlNew(reader.result);
    };
    setDraweropenimage(true);
    if (newFile) {
      reader.readAsDataURL(newFile);

    }
  };

  const [draweropen, setDraweropen] = React.useState(false);
  const [userValue, setUserValue] = React.useState({ id: "0" });
  const [open, setOpen] = React.useState(true);
  const userProfiledata = props.data.Project.ProfileData;
  const [profilesData, setProfilesData] =  React.useState([]);
  React.useEffect(()=>{
    if(!Array.isArray(userProfiledata.Profiles.Profile)){
 
      setProfilesData(profilesData=>[userProfiledata.Profiles.Profile]);
   }else{

     setProfilesData(userProfiledata.Profiles.Profile);
   }

  },[userProfiledata])

  const [state, setState] = React.useState({
    checked1: false,
    checked2: true,
    checked3: true,
    checked4: true,
    checked5: true,
    checked6: true,
    checked7: true,
    checked8: true,
  });
  const [name, setName] = React.useState({
    username: "",
    oldpassword: "",
    newpassword: "",
    repeatpassword: "",
  });

  const handleAdminChange = (event) => {
    setState({
      [event.target.name]: event.target.checked,
      checked2: true,
      checked3: true,
      checked4: true,
      checked5: true,
      checked6: true,
      checked7: true,
      checked8: true,
    });
  };

  const handleInitialCHange = (user) => {
    setState({
      checked1: user && user.Admin === "1",
      checked2: user && user.Enabled === "1",
      checked3: user && user.Tools === "1",
      checked4: user && user.Time === "1",
      checked5: user && user.EditPW === "1",
      checked6: user && user.Scenes === "1",
      checked7: user && user.Schedules === "1",
      checked8: user && user.Project === "1",
    });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const toggleDrawer = (open, user, key) => (event) => {
    setDraweropen(open);
    setUserValue(user);
    handleInitialCHange(user);
    setUserKey(key);
  };

  const [draweropenimage, setDraweropenimage] = React.useState(false);

  const toggleDrawerImage = (open) => (event) => {
    setDraweropenimage(open);
  };

  let userName = props.userName;
 

  const handleUdateUser = (key, open) => {
    setDraweropen(open);
    let profileValue = props.data.Project.ProfileData.Profiles.Profile;
    if(!Array.isArray(profileValue)){
    
      let profilekey = profileValue._attributes;

      profilekey.Admin = state.checked1 ? "1" : "0";
      profilekey.Enabled = state.checked2 ? "1" : "0";
      profilekey.Tools = state.checked3 ? "1" : "0";
      profilekey.Time = state.checked4 ? "1" : "0";
      profilekey.EditPW = state.checked5 ? "1" : "0";
      profilekey.Scenes = state.checked6 ? "1" : "0";
      profilekey.Schedules = state.checked7 ? "1" : "0";
      profilekey.Project = state.checked8 ? "1" : "0";
   }else{
     
     let profilekey = profileValue[userValue.id]._attributes;

    profilekey.Admin = state.checked1 ? "1" : "0";
    profilekey.Enabled = state.checked2 ? "1" : "0";
    profilekey.Tools = state.checked3 ? "1" : "0";
    profilekey.Time = state.checked4 ? "1" : "0";
    profilekey.EditPW = state.checked5 ? "1" : "0";
    profilekey.Scenes = state.checked6 ? "1" : "0";
    profilekey.Schedules = state.checked7 ? "1" : "0";
    profilekey.Project = state.checked8 ? "1" : "0";
   }
    

   
    updateXml(props.data)
      .then((result) => {
        if (result.status === 200) {
          props.getAppConfigData();
          rebootDevice().then((res) => {
            
          }).catch((error) => {
            
          });
        }
      })
      .catch((error) => {
        
      });
  };

  const hundleImageUrl = (a) => {
    alert(a);
  };

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}


  const handleimageUpdate = (urlvalue) => (event) => {
    let profileName ="profile_" +newProfileData.User +"_" +newProfileData.id +".jpg"
    let file = dataURLtoFile(urlvalue, profileName);
    setDraweropenimage(false);

     uploadFile(file, newProfileData)
        .then((result) => {
          
          if (result.status === 200) {
            let profileName =
              "profile_" +
              newProfileData.User +
              "_" +
              newProfileData.id +
              ".jpg&" +
              Date.now();
              setUpdatedImage(Date.now());
            if (newProfileData.id == 0) {
              props.profileUserChanger(profileName);
            }
          }
        })
        .catch((error) => {
          
        });
  };

  const handleCancelButton = (event) => {
    setDraweropenimage(false);
  };


  return (
    <>
      {userProfiledata &&
        profilesData.map(
          ({ _attributes }, key) => (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="marcheck">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "10%",
                  }}
                >
                  <div className={classes.imgcontainer}>
                    <>
                      <div
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                       <ProfileImage
                       userDetails={_attributes}
                       classes={classes}
                       handleImageChange ={handleImageChange}
                       updatedImage = {updatedImage} 
                       />
                        <h6
                          style={{
                            margin: "0",
                            textTransform: "none",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {props.language.edit}
                        </h6>
                      </div>
                    </>
                  </div>

                  <div style={{ width: "60%", paddingTop: "25px" }}>
                    <p
                      style={{ color: "#fff", fontWeight: "600", margin: "0" }}
                    >
                      {_attributes.Name}
                    </p>
                    <p
                      style={{
                        color: "#9b9b9b",
                        fontWeight: "600",
                        margin: "0",
                      }}
                    >
                      {_attributes.Admin === "1"
                        ? `${props.language.superuser}`
                        : `${props.language.restrictedUser}`}
                    </p>
                  </div>
                </div>

                <Userdetails
                  userName={userName}
                  setDraweropen={() => setDraweropen(open)}
                  _attributes={_attributes}
                  key={key}
                  userValue={userValue}
                  toggleDrawer={toggleDrawer}
                  userProps={props}
                  state={state}
                  handleAdminChange={handleAdminChange}
                  handleInitialCHange={handleInitialCHange}
                  handleUdateUser={handleUdateUser}
                  handleChange={handleChange}
                  draweropen={draweropen}
                  language={props.language}
                  
                />
               
               
               <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={draweropenimage}
                  onClose={toggleDrawer(false)}
                  modal
                  classes={{
                    paper: classNames(classes.drawerPapereditimage),
                  }}
                  ModalProps={{
                    keepMounted: false, // Better open performance on mobile.
                  }}
                >
                  {
                    <ImageEditdrawer
                      toggleDrawerImage={toggleDrawerImage}
                      img1={imageUrlNew}
                      updateimageUrl={handleimageUpdate}
                      profiledata={_attributes}
                      setDraweropenimage={setDraweropenimage}
                      language={props.language}
                      handleCancelButton={handleCancelButton}
                      handleImageChange ={handleImageChange}
                      setFile={setFile}
                      setimageUrlNew={setimageUrlNew}
                      setNewProfileData={setNewProfileData}
                    />
                  }
                </Drawer>
                </div>

            </Grid>
          )
        )}
        <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={draweropen}
                  onClose={toggleDrawer(false, userValue, userKey)}
                  modal
                  classes={{
                    paper: classNames(classes.drawerPaper),
                  }}
                  ModalProps={{
                    keepMounted: false, // Better open performance on mobile.
                  }}
                >
                  <Featuredrawer
                    _attributes={userValue}
                    key={userKey}
                    userValue={userValue}
                    handleUdateUser={handleUdateUser}
                    toggleDrawer={toggleDrawer}
                    handleChange={handleChange}
                    handleInitialCHange={handleInitialCHange}
                    handleAdminChange={handleAdminChange}
                    state={state}
                    setDraweropen={()=>setDraweropen()}
                    language={props.language}

                  />
                </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.reducer.data,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language,
  userName: state.reducer.userName

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      profileUserChanger,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profiles);
