import React, { useState, useEffect }  from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import KwhNowStatsSpeedo from "../PowerStatsGaugeSet/kwh/KwhNowStatsSpeedo";
import KwhTodayStatsSpeedo from "../PowerStatsGaugeSet/kwh/KwhTodayStatsSpeedo";
import KwhWeekStatsSpeedo from "../PowerStatsGaugeSet/kwh/KwhWeekStatsSpeedo";
import KwhMonthStatsSpeedo from "../PowerStatsGaugeSet/kwh/KwhMonthStatsSpeedo";
import KWHChartSet from "../PowerStatCharts/KWHChartSet";

// style sheet imported locally // 
import styles from '../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

export const KWHPowerStatsSet = React.memo((props) => {
//const initialData=props.initialCbusEvent.get("meter"+props.widget.params._attributes.meterno);


// const [powerData, setPowerData] =useState();

//    useEffect(() => {
//     if (props.cbusEvent) {
//       if (props.cbusEvent.name === "cbus_event") {
//         if (props.cbusEvent.attributes.name === "cbusInstantaneousPowerHistory") {
//           if (props.cbusEvent.attributes.meter === props.widget.params._attributes.meterno) {
//             setPowerData(props.cbusEvent.attributes)
//           }
//         }
//       }
//     }
//     // if(initialData){
   
//     // }
//   }, [props.cbusEvent]);


  const classes = useStyles();
  return (
    <div>
     <Grid container spacing={0} className={classes.powerStatsTablayout}>
       <Divider variant="fullWidth" className={classes.powerStatsTabDivider} />
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.now}
           <KwhNowStatsSpeedo 
           powerData={props.powerData} 
           data={props.data} 
           widget={props.widget}
           energy={props.data}
           //meterData={props.meterData}
            />
        </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.today}
           <KwhTodayStatsSpeedo 
           powerData={props.powerData} 
           data={props.data} 
           widget={props.widget}
           energy={props.data}
           meterData={props.meterData}
            />
           </Grid>
       <Grid items md={3} className={classes.PowerStatsTabHeaderText}>
           {props.language.thisWeek}
           <KwhWeekStatsSpeedo 
           powerData={props.powerData} 
           data={props.data} 
           widget={props.widget}
           energy={props.data}
           meterData={props.meterData}
           />
           </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
       {props.language.thisMonth}
       <KwhMonthStatsSpeedo 
        powerData={props.powerData} 
       data={props.data} 
       widget={props.widget}
       energy={props.data}
       meterData={props.meterData}
        />
       </Grid>

       <Grid items md={12}>
       <KWHChartSet 
         powerData={props.powerData}  
         powerDataHistory={props.powerDataHistory}
         data={props.data}
         widget={props.widget}
         energy={props.data}
         meterData={props.meterData}
          />
       </Grid>

      </Grid>
      
    </div>
  );
});

export default KWHPowerStatsSet;