/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState } from "react";
import { Typography, Drawer } from "@material-ui/core";
import cctvImage from "../../assets/img/homeCctv.png";
import defaultImg from "./../../assets/img/reactlogo.jpg";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
//import CameraAltIcon from "@material-ui/icons/CameraAlt";
import classNames from "classnames";
import EditSurveillancePhoto from "./editSurveillancePhoto";
import styles from "../../assets/widgets/widgetStyles/commonStyles";
import { Widgets } from "@material-ui/icons";
import { imageGallery } from "../../services/xmlService";
const useStyles = makeStyles(styles);

const SurveillanceGallery = (props) => {
  const classes = useStyles();
  const filterType = props.widgets.filter(x => x._attributes.type === "14").map((x) => ({ id: x._attributes.id, label: x.params._attributes.label }));
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [imageList, setImageList] = React.useState([]);
  const [fileteredImageList, setFileteredImageList] = React.useState([]);
  const [widgetLabel, setWidgetLabel] = React.useState();

  const [curImage, setCurImage] = React.useState();
  const [dayDate, setDayDate] = React.useState();
  const toggleDrawer1 = (open) => {
    setDraweropen1(open);
  };
  const setCurrentImage = (name) => {
    setCurImage(name);
  };

  const adjustTimeFormate = (time) => {
    if(time==0){
      return '12';
    }else{
      if(time>9){
        return time;
      }
      if(time<=9){
        return '0' + time;
      }
    }
  }

  const datetimeextracter = (datetime) => {
    if (datetime) {
      let year = datetime.substring(0, 4);
      let month = datetime.substring(4, 6);
      let date = datetime.substring(6, 8);
      let hours = datetime.substring(8, 10);
      let minutes = datetime.substring(10, 12);
      let seconds = datetime.substring(12, 14);
      // let d = adjustForTimezone(new Date(year +'-'+month+'-'+date+' '+hours+':'+minutes+':'+seconds));
      let d = new Date(year +'-'+month+'-'+date+' '+hours+':'+minutes+':'+seconds);
      let baistime = parseInt(Math.abs(props.data.data.Project.Info.Bias._text), 10); 
      let btime   = Math.floor(baistime / 3600);
      let bhours = Math.floor((baistime - (btime * 3600)) / 60);
      let bminutes = baistime - (btime * 3600) - (bhours * 60);
      if(Number(props.data.data.Project.Info.Bias._text)>0){
        d.setHours(d.getHours() - bhours);
        d.setMinutes(d.getMinutes() - bminutes);
      }else{
        d.setHours(d.getHours() + bhours);
        d.setMinutes(d.getMinutes() + bminutes);
      }
      //d.setHours(d.getHours() + 1);
      let timestamp = "";
      if (d.getHours() > 12) {
        timestamp = adjustTimeFormate(d.getHours()-12) + ":" + adjustTimeFormate(d.getMinutes()) + ":" + adjustTimeFormate(d.getSeconds()) + " PM";
      } else {
        timestamp = adjustTimeFormate(d.getHours()) + ":" + adjustTimeFormate(d.getMinutes()) + ":" + adjustTimeFormate(d.getSeconds()) + " AM";
      }
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let datedata = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
      let dayName = days[d.getDay()];
      let finalresult = "<div>" + dayName + "</div>" + "<div>" + datedata + "</div>" + "<div>" + timestamp + "</div>";
      return finalresult;
    } else {
      return "";
    }
  }
  const handleSelect = (e) => {
    setWidgetLabel(e.target.value);
    let selectedType = filterType.filter(x => x.label === e.target.value);
    setFileteredImageList(imageList.filter(x => x.widgetId === selectedType[0].id).reverse());
  }
  React.useEffect(() => {
    if (props.imageListData) {
      let data = [];
      let imageData = props.imageListData.split("DATA=")[1].split("\n").slice(0, -1);
      for (let i = 0; i < imageData.length; i++) {
        let fileData = {
          "name": "",
          "widgetId": "",
          "timestamp": ""
        }
        if (imageData[i].includes("cam")) {
          let camData = imageData[i].split("_");
          fileData.name = imageData[i];
          fileData.widgetId = camData[0].substring(3);
          fileData.timestamp = camData[1].substring(0, 14);
          data.push(fileData);
        }
      }
      setImageList(data);
      setFileteredImageList(data.filter(x => x.widgetId === props.data.widget._attributes.id).reverse());
    }
  }, [props.imageListData]);

  React.useEffect(() => {
    if(props.widget){
      setWidgetLabel(props.data.widget.params._attributes.label);
    }
  }, []);

  return (
    <>
      {props.data !== undefined && (
        <div
          className={classes.editWidgetRoot}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            backgroundImage: "linear-gradient(#3b3b3b, #202020)",
            width: "100%",
          }}
        >
          <CloseIcon
            onClick={() => {
              props.toggleDrawer(false);
            }}
            style={{
              display: "flex",
              alignSelf: "flex-end",
              width: "3%",
              color: "white",
              marginTop: "1%",
              justifyContent: "flex-end",
            }}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "70%" }}>
              <Typography
                variant="h4"
                style={{
                  color: "white",
                  padding: "2% 4% 2%",
                  paddingBottom: "0%",
                  justifyContent: "stretch",
                }}
                className={classes.headerText2}
              >
                Library
              </Typography>
            </div>
            <div style={{ width: "30%", marginTop: "1%" }}>
              <select value={widgetLabel} className="pagedropdown" onChange={handleSelect}>
                {filterType.map((x, y) =>
                  <option key={y} value={x.label}>{x.label}</option>
                )}
              </select>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {fileteredImageList && fileteredImageList.map(
              ({ name, wigetId, timestamp }, key) => (
                <div style={{ width: "15%", padding: "3%" }}>
                  <img
                    src={window.location.origin + "/getsnap.pl?name=" + name}
                    onClick={() => {
                      toggleDrawer1(true); setCurrentImage(name);
                      setDayDate(timestamp);
                    }}
                    style={{ height: "46%", width: "100%", alignSelf: "center", border: "4px solid #555" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      className={classes.headerTextGallery}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      {key + 1}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={classes.headerTextGallery}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: datetimeextracter(timestamp) }} />
                    </Typography>
                  </div>
                </div>
              )
            )}

          </div>

          <Drawer
            variant="temporary"
            anchor={"right"}
            open={draweropen1}
            onClose={() => {
              toggleDrawer1(false);
            }}
            modal
            classes={{
              paper: classNames(classes.drawerPaper2),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <EditSurveillancePhoto
              functionGroups={props.data.functionGroups}
              saveEditedWidget={props.saveEditedWidget}
              data={props}
              imageGalleryRefresh = {props.imageGalleryRefresh}
              curImage={curImage}
              dayDate={dayDate}
              toggleDrawer={toggleDrawer1}
            />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default SurveillanceGallery;
