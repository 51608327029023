import React, { useState, useEffect, useRef } from "react";

require('jquery-nstslider/dist/jquery.nstSlider.js');

const  TrebleSlider=(props)=> {
    const [trebleValue, setTrebleValue] = useState(props.value);
    const [trebleGa, setTrebleGa] = useState(props.treblega);
    window.$('.leftGrip' +trebleGa).slider({
        slide: function (e, ui) {
        },
        stop: function (e, ui) {
          props.handleTrebleSliderCommittedChange(window.$('.trebleSlider' +trebleGa).nstSlider("get_current_min_value"), props.treblega);
        }
    })
    window.$('.trebleSlider' +trebleGa).click( function(e){
        props.handleTrebleSliderCommittedChange(window.$('.trebleSlider' +trebleGa).nstSlider("get_current_min_value"), props.treblega);
        e.stopImmediatePropagation();
    })
    useEffect(() => {
        window.$('.trebleSlider'+trebleGa).nstSlider({
            "left_grip_selector": ".leftGrip"+trebleGa,
            "value_bar_selector": ".bar"+trebleGa,
            "value_changed_callback": function (cause, leftValue, rightValue) {
                var $container = window.$(this).parent();
                $container.find('.leftLabel').text(leftValue);
                window.$(this).find('.bar').css('background', '#92E300');
            }
        });
    }, [props.value])
      return (
          <>
              <div className={"nstSlider trebleSlider"+trebleGa} data-range_min="-50" data-range_max="50"
                  data-cur_min={trebleValue} data-cur_max="0">
                  <div className={"bar bar"+trebleGa}></div>
                  <div className={"leftGrip leftGrip"+trebleGa}></div>
              </div>
          </>
      )

}
export default TrebleSlider;