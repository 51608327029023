import React from "react";
import Stats from "./Stats/Stats"
import Favorite from "../views/Favorites/Favorite";
import { connect } from "react-redux";


const Home = (props) =>{
  return (
    <>
    { props.isFavorite && 
      <Favorite />
    }  
    { !props.isFavorite &&
      <Stats />
    }
    </>
  )
}

const mapStateToProps= state =>({ 
    isFavorite: state.reducer.isFavorite,
})
    
export default connect(mapStateToProps,null)(Home);