export const rebootJson = {
    "ipaddr": null,
    "subnet": null,
    "gwaddr": null,
    "dns": null,
    "dhcpen": null,
    "wifien": null,
    "wifiname": null,
    "wifisec": null,
    "wifipw": null,
    "smbclien": 0,
    "smbcliuser": null,
    "smbclipw": null,
    "smbclidomain": null,
    "smbclihost": null,
    "smbclifolder": null,
    "smbserven": 1,
    "smbservworkgroup": "workgroup",
    "smbservfolder": null,
    "dyndnsen": 0,
    "dyndnsserver": null,
    "dyndnshost": null,
    "dyndnsuser": null,
    "dyndnspw": null,
    "ntpen": 0,
    "ntpurl": null
}
