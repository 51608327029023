import React from "react";
import { Grid } from "@material-ui/core";
import styles from "assets/js/all-styles/views/schedulesStyle.js";
import calendar from "assets/img/calendar.png";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import SelectActiveDates from "views/Settings/Schedules/SelectActiveDates";
import SelectActiveMonth from "views/Settings/Schedules/SelectActiveMonth";
import SelectDaysOfWeek from "views/Settings/Schedules/SelectDaysOfWeek";
import RepeatDates from "views/Settings/Schedules/RepeatDates";
import Fixedtime from "views/Settings/Schedules/Fixedtime";
import Sunrisetime from "views/Settings/Schedules/Sunrisetime";
import Sunsettime from "views/Settings/Schedules/Sunsettime";
import Randomtime from "views/Settings/Schedules/Randomtime";
import Repeattime from "views/Settings/Schedules/Repeattime";
import { updateXml } from "../../../services/xmlService";

const useStyles = makeStyles(styles);

export default function Scheduledrawer(props) {
  const classes = useStyles();
  let [state, setState] = React.useState({
    selectedTimeType:
      props.userValue.TimeType === undefined || props.userValue.TimeType === "0"
        ? "fixed"
        : props.userValue.TimeType,
  });
  let [dates, setDates] = React.useState({
    selectedDateType:
      props.userValue.DayType === undefined || props.userValue.DayType === "0"
        ? "any"
        : props.userValue.DayType === "32"
        ? "repeat"
        : "lastweek",
  });

  let [year, setYear] = React.useState(
    props.userValue.Year === "-1" ? "-1" : props.userValue.Year
  );

let [totalSeconds, setTotalSeconds] = React.useState(props.userValue.Time);
let [totalStartTimeSeconds, setTotalStartTimeSeconds] = React.useState(props.userValue.StartTime);
let [totalEndTimeSeconds, setTotalEndTimeSeconds] = React.useState(props.userValue.EndTime);
let [totalRepeatTimeSeconds, setTotalRepeatTimeSeconds] = React.useState(props.userValue.Time);
  

  const [weekOfDays, setWeekOfDays] = React.useState(props.userValue.DayOfWeek===undefined?"0x7f":props.userValue.DayOfWeek);
  const [activeMonth, setActiveMonth] = React.useState(props.userValue.Month===undefined?"0xfff":props.userValue.Month);
  const [activeDates, setActiveDates] = React.useState(
                                                  ((props.userValue.RepeatDays === undefined && props.userValue.DayType === undefined) ? 
                                                  ((props.userValue.Day === undefined) ? "0x7fffffff" : props.userValue.Day):props.userValue.Day)
                                                  );

  const yearSelect = (event) => {
    setYear(event.target.value);
  };

  let [timeType, setTimeType] = React.useState(
    props.userValue.TimeType === undefined || props.userValue.TimeType === "0"
      ? "fixed"
      : props.userValue.TimeType
  );

  let [dateType, setDateType] = React.useState(
    props.userValue.DayType === undefined || props.userValue.DayType === "0"
      ? "any"
      : props.userValue.DayType === "32"
      ? "repeat"
      : "lastweek"
  );
  let [repeatOffset, setRepeatOffset] = React.useState(0);
  let [repeatDays, setRepeatDays] = React.useState(0);

  const handleTimeDropDown = (e) => {
    setState({ selectedTimeType: e.target.value });
    setTimeType(e.target.value);
  };

  const handleDateType = (e) => {
    setDates({ selectedDateType: e.target.value });
    setDateType(e.target.value);
  };

  const saveChanges = (valuebool, propsuserValue) => {
    let scheduleData = props.data.Project.Schedules.Schedule;
    let scheduleDataKey = scheduleData[propsuserValue.id]._attributes;
    scheduleDataKey.Year = year;
   

    if (timeType === "0" || timeType === "fixed") {
      scheduleDataKey.TimeType = "0";
      scheduleDataKey.Time = totalSeconds.toString();
    }

    if (timeType === "1") {
      scheduleDataKey.TimeType = "1";
      scheduleDataKey.Time = totalSeconds.toString();
    }
    if (timeType === "2") {
      scheduleDataKey.TimeType = "2";
      scheduleDataKey.Time = totalSeconds.toString();
    }
    if (timeType === "3") {
      scheduleDataKey.TimeType = "3";

      scheduleDataKey.StartTime = totalStartTimeSeconds.toString();
      scheduleDataKey.EndTime = totalEndTimeSeconds.toString();
      scheduleDataKey.Time = totalRepeatTimeSeconds.toString();
    }
    if (timeType === "4") {
      scheduleDataKey.TimeType = "4";
      scheduleDataKey.StartTime = totalStartTimeSeconds.toString();
      scheduleDataKey.EndTime = totalEndTimeSeconds.toString();
    }

    if (dateType === "0" || dateType === "any") {
      scheduleDataKey.DayType = "0";
    }

    if (dateType === "32" || dateType === "repeat") {
      scheduleDataKey.RepeatDays = repeatDays;
      scheduleDataKey.RepeatOffset = repeatOffset;
     
      scheduleDataKey.DayType = "32";
    }
    if (dateType === "16" || dateType === "lastweek") {
      scheduleDataKey.DayType = "16";
    }

    scheduleDataKey.DayOfWeek =weekOfDays==0?"0x0":"0x"+weekOfDays; 
    scheduleDataKey.Month = activeMonth==0?"0x0":"0x"+activeMonth;
    scheduleDataKey.Day = activeDates==0?"0x0":"0x"+activeDates;

    let hexaDay = scheduleDataKey.Day? scheduleDataKey.Day: "0";
    let hexaWeek = scheduleDataKey.DayOfWeek? scheduleDataKey.DayOfWeek: "0";
    let hexaMonth = scheduleDataKey.Month? scheduleDataKey.Month: "0";
    //let timeTypeCmd = scheduleDataKey.TimeType;
    //
 
    let timeTypeCmd = scheduleDataKey.TimeType?scheduleDataKey.TimeType:"0" ;
    let startTime = scheduleDataKey.StartTime?scheduleDataKey.StartTime:"0" ;
    let endTime = scheduleDataKey.EndTime?scheduleDataKey.EndTime:"0";
    let durationCmd = scheduleDataKey.Duration?scheduleDataKey.Duration: "0";
    let returnLevelCmd = scheduleDataKey.ReturnLevel? scheduleDataKey.ReturnLevel: "0"
    let repeatDaysVal = scheduleDataKey.RepeatDays? scheduleDataKey.RepeatDays: "0"
    let repeatOffsetVal = scheduleDataKey.RepeatOffset? scheduleDataKey.RepeatOffset: "0"
    let enableLevelVal = scheduleDataKey.EnableLevel? scheduleDataKey.EnableLevel : "0"
    let specialFunction = scheduleDataKey.SpecialFunction? scheduleDataKey.SpecialFunction : "0"

    let editScheduleCmd = {
  
      //"cmd": '<schedule-edit id="' +scheduleDataKey.id+ '" name="' +scheduleDataKey.Name+ '" schedule_type="' +timeType+ '" schedule_day_type="' +timeType+ '" enable_app_no="' +scheduleDataKey.EnableAppNo+ '" enable_group_add="' +scheduleDataKey.EnableGroupAdd+ '" enable_level="' +enableLevelVal+ '" time="' +scheduleDataKey.Time+ '" start_time="' +startTimeCmd + '" end_time="' +endTimeCmd+ '" day_of_week="' +hexaWeek+ '" day="' +hexaDay+ '" month="' +hexaMonth+ '" year="' +scheduleDataKey.Year+ '" also_on_mask="0" repeat_days="'+repeatDaysVal+'" repeat_days_offset="'+repeatOffsetVal+'" cbus_type="' +scheduleDataKey.CBusType+ '" scene="-2" network="' +scheduleDataKey.Network+ '" app_no="' +scheduleDataKey.AppNo+ '" group_add="' +scheduleDataKey.GroupAdd+ '" level="' +scheduleDataKey.Level+ '" duration="' +durationCmd+ '" return_level="'+returnLevelCmd+'" can_edit="1" special_function="0" />'
   "cmd": '<schedule-edit id="' +scheduleDataKey.id+ '" Name="' +scheduleDataKey.Name+ '" TimeType="' +timeTypeCmd+ '" DayType="0" EnableAppNo="' +scheduleDataKey.EnableAppNo+ '" EnableGroupAdd="' +scheduleDataKey.EnableGroupAdd+ '" EnableLevel="' +enableLevelVal+ '" Time="' +scheduleDataKey.Time+ '" StartTime="' +scheduleDataKey.StartTime + '" EndTime="' +scheduleDataKey.EndTime+ '" DayOfWeek="' +hexaWeek+ '" Day="' +hexaDay+ '" Month="' +hexaMonth+ '" Year="' +scheduleDataKey.Year+ '" AlsoOnMask="0" RepeatDays="'+repeatDaysVal+'" RepeatOffset="'+repeatOffsetVal+'" CBusType="' +scheduleDataKey.CBusType+ '" Scene="-2" Network="' +scheduleDataKey.Network+ '" AppNo="' +scheduleDataKey.AppNo+ '" GroupAdd="' +scheduleDataKey.GroupAdd+ '" Level="' +scheduleDataKey.Level+ '" RampRate="0" Duration="' +durationCmd+ '" ReturnLevel="'+returnLevelCmd+'" SpecialFunction="' +specialFunction+ '" CanEdit="1" Profiles="0x65535"/>'
    }
    
    props.handleSocketEventChange(editScheduleCmd);


    props.toggleDrawer(valuebool, propsuserValue);
    
    // updateXml(props.data)
    //   .then((result) => {
    //     if (result.status === 200) {
    //       props.getAppConfigData();
    //     }
    //   })
    //   .catch((error) => {
        
    //   });
  };

  let renderTimeSelector = () => {
    return (
      <>
        <select
          className="selectineditschedulesdraweroptions"
          onChange={handleTimeDropDown}
          value={state.selectedTimeType}
        >
          <option value="fixed">{props.language.fixed}</option>
          <option value="1">{props.language.sunrise}</option>
          <option value="2">{props.language.sunset}</option>
          <option value="3">{props.language.repeat}</option>
          <option value="4">{props.language.random}</option>
        </select>
      </>
    );
  };

  let renderSelectedTime = (selectedTimeType) => {
    if (selectedTimeType === "fixed") {
      return (
        <Fixedtime
          language={props.language}
          totalSeconds={totalSeconds}
          setTotalSeconds={setTotalSeconds}
          setTimeType={setTimeType}
        />
      );
    }
    if (selectedTimeType === "1") {
      return (
        <Sunrisetime
          language={props.language}
          data={props}
          setTotalSeconds={setTotalSeconds}
          setTimeType={setTimeType}
        />
      );
    }

    if (selectedTimeType === "2") {
      return (
        <Sunsettime
          language={props.language}
          data={props}
          setTotalSeconds={setTotalSeconds}
          setTimeType={setTimeType}
        />
      );
    }
    if (selectedTimeType === "3") {
      return (
        <Repeattime
          language={props.language}
          data={props}
          setTimeType={setTimeType}
          setTotalStartTimeSeconds={setTotalStartTimeSeconds}
          setTotalEndTimeSeconds={setTotalEndTimeSeconds}
          setTotalRepeatTimeSeconds={setTotalRepeatTimeSeconds}
        />
      );
    }
    if (selectedTimeType === "4") {
      return (
        <Randomtime
          language={props.language}
          data={props}
          setTimeType={setTimeType}
          setTotalStartTimeSeconds={setTotalStartTimeSeconds}
          setTotalEndTimeSeconds={setTotalEndTimeSeconds}
        />
      );
    }
  };

  let renderDateSelector = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        <select
          className="selectineditschedulesdraweroptions"
          onChange={handleDateType}
          value={dates.selectedDateType}
        >
          <option value="any">{props.language.any}</option>
          <option value="repeat">{props.language.repeat}</option>
          <option value="lastweek">{props.language.lastWeek}</option>
        </select>
      </div>
    );
  };

  let renderSelectedDate = (selectedDateType) => {
    if (selectedDateType === "any") {
      return (
        <SelectActiveDates
          language={props.language}
          data={parseInt(activeDates, 16)}
          setActiveDates={setActiveDates}
        />
      );
    }
    if (selectedDateType === "repeat") {
      return (
        <RepeatDates
          language={props.language}
          data={props}
          setDateType={setDateType}
          setRepeatOffset={setRepeatOffset}
          setRepeatDays={setRepeatDays}
        />
      );
    }
    // if (selectedDateType === "lastweek") {
    //   setDateType("16");

    // }
  };

  let renderYearSelector = () => {
    let currentYear = new Date().getFullYear();
    let year1 = currentYear + 1;
    let year2 = currentYear + 2;
    let year3 = currentYear + 3;
    let year4 = currentYear + 4;
    let year5 = currentYear + 5;
    let year6 = currentYear + 6;
    let year7 = currentYear + 7;
    let year8 = currentYear + 8;
    let year9 = currentYear + 9;

    return (
      <div style={{ marginTop: "10px" }}>
        <select
          className="selectineditschedulesdraweroptions"
          onChange={yearSelect}
          value={year}
        >
          <option value="-1">{props.language.everyYear}</option>
          <option value={currentYear}>{currentYear}</option>
          <option value={year1}>{year1}</option>
          <option value={year2}>{year2}</option>
          <option value={year3}>{year3}</option>
          <option value={year4}>{year4}</option>
          <option value={year5}>{year5}</option>
          <option value={year6}>{year6}</option>
          <option value={year7}>{year7}</option>
          <option value={year8}>{year8}</option>
          <option value={year9}>{year9}</option>
        </select>
      </div>
    );
  };

  return (
    <div
      style={{
        overflowX: "hidden"
        
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            padding: "5px 10px 5px 15px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img className={classes.imgwidth} src={calendar} alt={"Logo"} />
            </div>

            <div>
              <p style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>
                {props.language.editSchedules}
              </p>
              <p style={{ color: "#707070", fontWeight: "600", margin: "0" }}>
                {props.userValue.Name}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              padding: "15px",
            }}
          >
            <button
              className={classes.scheduleCancelbtn}
              onClick={() => props.toggleDrawer(false, props.userValue)}
            >
              {props.language.cancel}
            </button>
            <button
              className={classes.scheduleSavebtn}
              // onClick={props.toggleDrawer(false, props.userValue)}
              onClick={() => saveChanges(false, props.userValue)}
            >
              {props.language.saveChanges}
            </button>
          </div>
        </div>
        <Divider style={{ margin: "0" }} />

        <Divider />
      </div>

      {/* Time Section */}
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ minHeight: "170px", background: "#232323" }}
      >
        <>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ padding: "0 0 0 4%" }}
          >
            <h1 style={{ color: "white", fontWeight: "600", fontSize: "22px", lineHeight: "0.5em" }}>
              {props.language.time}
            </h1>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{ padding: "0 2% 0 4%" }}
          >
            
            {renderTimeSelector()}
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12} style={{padding: "0px 2% 0 4%"}}>
            {renderSelectedTime(state.selectedTimeType)}
          </Grid>
        </>
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12} style={{ padding: "0 0 2px 0" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ padding: "0 0 0 4%" }}
        >
          <h1 style={{ color: "white", fontWeight: "600", fontSize: "22px", lineHeight: "0.15em" }}>
            {props.language.calendar}
          </h1>
        </Grid>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ margin: "0 2%" }}
        >
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{ padding: "0 2% 0 2%" }}
          >
            <SelectDaysOfWeek
              language={props.language}
              data={parseInt(weekOfDays, 16)}
              setWeekOfDays={setWeekOfDays}
            />
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{ padding: "0 2% 0 2%" }}
          >
            <SelectActiveMonth
              language={props.language}
              data={parseInt(activeMonth, 16)}
              setActiveMonth={setActiveMonth}
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{ padding: "0 2% 0 2%" }}
          >
            <h1 style={{ color: "white", fontWeight: "600", fontSize: "20px" }}>
              {props.language.dates}
            </h1>
            <Divider style={{ margin: "0" }} />

            <Divider />
            <div style={{ marginTop: "10px" }}>
              {renderDateSelector()}
              {renderSelectedDate(dates.selectedDateType)}
            </div>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{ padding: "0 2% 0 2%" }}
          >
            <h1 style={{ color: "white", fontWeight: "600", fontSize: "20px" }}>
              {props.language.year}
            </h1>
            <Divider style={{ margin: "0" }} />

            <Divider />
            {renderYearSelector()}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}