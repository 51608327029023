import React, { useState, useEffect } from "react";
import scenes from 'assets/img/scenes.png';
import "assets/css/profileStyle.css";
import styles from "assets/js/all-styles/views/editscenestyle.js";

// Material-UI // 
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames"
import { Button, IconButton, Typography, Drawer, Grid } from '@material-ui/core';


import { connect } from "react-redux";

import { getAppConfigData, socketEventDataChanger } from "redux/actions/action";
import { bindActionCreators } from "redux";
import { updateSocketEvent } from "../../services/xmlService";
import Editscenedrawer from "views/Settings/Editscenedrawer";

// import styles from '../../assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);

function EditScenes(props) {
  const [draweropen, setDraweropen] = React.useState(false);
  const classes = useStyles();
  const [livechanges, setLivechanges] = React.useState(true);
  const [powerToggle, setPowerToggle] = useState(false);
  const [scenesedit, setScenesedit] = React.useState();
  const [sceneKey, setSceneKey] = React.useState();
  const [cancel, setCancel] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [editScenesdata, setEditScenesdata] =  React.useState([]);
  const [scenesData, setScenesData] =  React.useState([]);

  const handlePowerToggle = (toggle) => {
    setPowerToggle(!toggle);
  }

  const handleLiveChange = (event) => {
    setLivechanges(event.target.checked);
    handlePowerToggle(powerToggle);
  };

  const handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
  };

  const toggleDrawer = (open, val, key) => (event) => {
    setDraweropen(open);
    setScenesedit(val);
    setSceneKey(key);
    if (open) {
      setCancel(!cancel);
    }
  };

  React.useEffect(()=>{ 
    if(props.data){
      if(props.data.Project.Scenes){
      if(!Array.isArray(props.data.Project.Scenes.Scene)){ 
        let scene = [];
        scene.push(props.data.Project.Scenes.Scene);
        setEditScenesdata(scene);
        setScenesedit(props.data.Project.Scenes.Scene);
     }else{
      setEditScenesdata(props.data.Project.Scenes.Scene);
     }
    }
  }
  },[props.data])

  return (<>
    {props.data !== undefined &&  props.data.Project.Scenes.Scene !== undefined &&
      editScenesdata.map(
        (val, key) => (
          <>
            {val._attributes.CanEdit !== "0" &&
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <div className={classes.root}>
                  <div className={classes.header}>
                    <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton} >
                      <img src={scenes} alt={scenes} className={classes.headerIcon} />
                    </IconButton>
                    <div className={classes.header1}  >
                      <Typography variant='h6' className={classes.headerText1} >
                        {val._attributes.Name}

                      </Typography>
                      <Typography variant='h6' className={classes.headerText2} >

                        {props.language.scene}
                      </Typography>
                    </div>
                    <Button
                      variant="contained"
                      onClick={toggleDrawer(true, val, key)}
                      className={classes.headerButton}
                    >
                      <Typography style={{ color: '#A9A9A9', textTransform: 'none' }} variant='h6' >
                        {props.language.edit}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </Grid>
            }
          </>
        ))
    }
    <Drawer
      variant="temporary"
      anchor={"right"}
      open={draweropen}
      onClose={() => { toggleDrawer(false, scenesedit, sceneKey) }}
      // handleInitialCHange={handleInitialCHange}
      modal
      classes={{
        paper: classNames(classes.drawerPaper),
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {props.data.Project.Scenes.Scene && 
      <Editscenedrawer data={props.data} language={props.language} getAppConfigData={getAppConfigData} handleSocketEventChange={handleSocketEventChange} scenesedit={scenesedit} sceneKey={sceneKey} toggleDrawer={toggleDrawer} handleLiveChange={handleLiveChange} livechanges={livechanges} setLivechanges={setLivechanges} cbusEvent={props.cbusEvent} initialCbusEvent={props.initialCbusEvent} socketEventDataChanger={props.socketEventDataChanger} setCancel={setCancel} setScenesedit={setScenesedit} 
      isCancel={cancel} /> }
    </Drawer>
  </>
  );
}


const mapStateToProps = (state) => ({
  data: state.reducer.data,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData, socketEventDataChanger

    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditScenes);