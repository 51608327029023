import React, { useState, useEffect } from "react";
const ProfileImage = (props) => {
    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {
        setImageUrl(window.location.origin + "/getsnap.pl?name=profile_" + props.userDetails.User + "_" + props.userDetails.id + ".jpg");
    }, []);

    useEffect(() => {
        if (props.updatedImage) {
            setImageUrl(window.location.origin + "/getsnap.pl?name=profile_" + props.userDetails.User + "_" + props.userDetails.id + ".jpg&" + new Date());
        }
    }, [props.updatedImage]);

    return (
        <>
            <div
                style={{
                    width: "80px",
                    height: "80px",
                    border: "3px solid #538c0d",
                    color: "fff",
                    margin: "5px auto",
                    overflow: "hidden",
                    borderRadius: "50%",
                }}
            >
                <img
                    src={imageUrl}
                    onError={(e) => {
                        e.target.src =
                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUkAAADcCAYAAAD9VlPsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAALRSURBVHhe7dQxAYBAEMCwAznn3x8sjE8VJEsd9NrdZwA4ur8CcGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAX7NvBS6AzUpvTQCAAAAAElFTkSuQmCC";
                    }}

                    alt="logo"
                    className={props.classes.img}
                />
                <input
                    type="file"
                    style={{
                        opacity: "0",
                        cursor: "pointer",
                        display: "block",
                        height: "100%",
                        left: "0",
                        position: "absolute",
                        top: "0",
                        width: "100%",
                    }}
                    onChange={(e) => props.handleImageChange(e, props.userDetails)}
                />
            </div>
        </>
    )
}

export default ProfileImage;