import React, { useState, useEffect } from "react";
import { ICON_URL } from "../../config/environment";
// Assets Import // 
import bulbWhite from "../../assets/img/bulbWhite.png";
import bulbGreen from "../../assets/img/bulbGreen.png";
// Components Imported Locally //
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
import { rampRateToSeconds } from "./../../config/rampRateToSeconds";

import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function // 
export default function LightingStatus (props) { 
  const initialData=props.initialCbusEvent.get("0x"+Number(props.widget.params._attributes.app).toString(16));
  const classes = useStyles();

 const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] =useState(props.favData);
  const [lightingStatus, setLightingStatus] = useState(0);
  const [count, setCount]=useState(0);
  const [onTimeInterval,setOnTimeInterval] = useState(null);
  const [offTimeInterval,setOffTimeInterval] = useState(null);
  const [levelTimeInterval,setLevelTimeInterval] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [lastValue, setLastValue] = useState();
  
  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (props.cbusEvent.attributes.app === "0x" + Number(props.widget.params._attributes.app).toString(16)) {
            if (props.cbusEvent.attributes.group === "0x" + Number(props.widget.params._attributes.ga).toString(16)) {
              let rampRatetoMillisoconds = rampRateToSeconds[Number(parseInt(props.cbusEvent.attributes.ramp, 16))] / 100;
              clearInterval(onTimeInterval);
              clearInterval(levelTimeInterval);
              clearInterval(offTimeInterval);
              if (props.cbusEvent.attributes.level == "0x0") {
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count1 = lastValue;
                  if(lastValue>changedValue){
                  let interval = setInterval(() => {
                    count1--;
                    setLightingStatus(count1);
                    setCount(count1);
                    setLastValue(count1);
                    if (count1 == changedValue) {
                      clearInterval(interval);
                      setLastValue(changedValue);
                    }
                  }, rampRatetoMillisoconds);
                  setOffTimeInterval(interval);
                } 
              }
                else {
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0 ) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count2 = lastValue;
                  if(lastValue<changedValue){
                    let interval = setInterval(() => {
                      count2++;
                      setLightingStatus(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }else{
                    let interval = setInterval(() => {
                      count2--;
                      setLightingStatus(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }
                }
                else {
                  setLightingStatus(0);
                }
                if (initialData) {
                  initialData[props.widget.params._attributes.ga] = "0x0";
                  let lightingData = new Map(props.initialCbusEvent);
                  props.socketEventDataChanger(lightingData);
                }
               }
              } else if (props.cbusEvent.attributes.level == "0xff") {
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0) {
                  clearInterval(offTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count1 = lastValue;
                  if(lastValue<changedValue){
                  let interval = setInterval(() => {
                    count1++;
                    setLightingStatus(count1);
                    setCount(count1);
                    setLastValue(count1);
                    if (count1 == changedValue) {
                      clearInterval(interval);
                      setLastValue(changedValue);
                    }
                  }, rampRatetoMillisoconds);
                  setOnTimeInterval(interval);

                }
                }  else {
                // setIsClicked(true);
                // setCheckedC(true)
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0 ) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count2 = lastValue;
                  if(lastValue<changedValue){
                    let interval = setInterval(() => {
                      count2++;
                      setLightingStatus(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }else{
                    let interval = setInterval(() => {
                      count2--;
                      setLightingStatus(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }
                  
                }
                
                 else {
                  setLightingStatus(100);
                }

                if (initialData) {
                  initialData[props.widget.params._attributes.ga] = "0xff";
                  let lightingData = new Map(props.initialCbusEvent);
                  props.socketEventDataChanger(lightingData);
                }
              }
             } else {
                // setIsClicked(true);
                // setCheckedC(true)
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count2 = lastValue;
                  if(lastValue<changedValue){
                    let interval = setInterval(() => {
                      count2++;
                      setLightingStatus(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }else{
                    let interval = setInterval(() => {
                      count2--;
                      setLightingStatus(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }
                  
                } else {
                  setLightingStatus(changedValue);
                  setLastValue(changedValue);
                }
                if (initialData) {
                  initialData[props.widget.params._attributes.ga] = props.cbusEvent.attributes.level;
                  let lightingData = new Map(props.initialCbusEvent);
                  props.socketEventDataChanger(lightingData);
                }
              }
            }
          }
        }
      }
    }
    if (initialData) {
      if (initialData[props.widget.params._attributes.ga] === "0x0") {
        //setLightingStatus(0);
        setCount(0);
        setLastValue(0);
      } else if (initialData[props.widget.params._attributes.ga] == "0xff") {
        //setLightingStatus(100);
        setCount(100);
        setLastValue(100);
      } else {
        let changedValue = Math.floor(parseInt(initialData[props.widget.params._attributes.ga], 16) / 2.55);
        setLightingStatus(changedValue);
        setCount(changedValue);
        setLastValue(changedValue);
      }
    }
  }, [props.cbusEvent]);
// Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const location = (props.widget._attributes.location === "all" || props.widget._attributes.location ==="none") ? "" : props.locations[Number(props.widget._attributes.location)]._attributes.Name;
const functionGroup = props.widget._attributes.functiongroup==="none" ? "" : props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name;

  return (
  <>
      { props.data !== undefined &&
        <Grid item lg={4} md={6} sm={6} xs={12}  >
          <div className={classes.root} >
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
                    aria-label="delete"
                    onClick={() => { props.handleFavorite(id, props.widget._attributes.type); updateFav(favData); }}

                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => { toggleDrawer(true) }}
                  >
                    < EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
            <div className={classes.header} >
              <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton} >
                <img src={lightingStatus>0?ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png"} alt={"bulb"} className={classes.headerIcon} />
              </IconButton>
              <div className={classes.header1}>
                <Typography variant='h6' className={lightingStatus>0?`${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `}>
                  {name}
                </Typography>
                <Typography variant='h6' className={lightingStatus>0?classes.headerText1Active:classes.headerText2}  >
                  {props.widgetCategory === "functionGroup" ? location : functionGroup}
                </Typography>
              </div> 
              <div style={{ width: '20%' }} >
                <Typography variant='h6' className={lightingStatus>0?classes.topButtonTextActive:classes.topButtonText}  >
                  { Math.round(lightingStatus)} %
                 </Typography>
              </div>
            </div>
           </BootstrapTooltip>
           <Drawer
                variant="temporary"
                anchor={"right"}
                open={draweropen}
                onClose={() => {
                  toggleDrawer(false);
                }}
                modal
                classes={{
                  paper: classNames(classes.drawerPaper),
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <EditWidget
                  saveEditedWidget={props.saveEditedWidget}
                  data={props}
                  toggleDrawer={toggleDrawer}
                />
              </Drawer>
            </div>
        </Grid>
      }
    </>
  );
}