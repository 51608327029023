import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles} from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

const NowDollarRateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] =useState("");
  const [rateInfo, setRateInfo] =useState("");

  //CurrencyUnit Value
  const currencyUnit = props.widget.params._attributes.currency_units;

  //estimated values
  let meterData = meterValue;

   //consumption and genetated values
   let meterData1 = rateInfo;

   useEffect(() => {
    if (props.totalPowerDataHistory) {
        if (props.totalPowerDataHistory.name === "cbusPowerHistoryTotals") {
         setMeterValue(props.totalPowerDataHistory.predicted_total_cost_hour); 
      }
    }
  }, [props.totalPowerDataHistory]);

  useEffect(() => {
    if (props.totalPowerDataHistory !== undefined ) {
       if(props.totalPowerDataHistory.name=== "cbusPowerHistoryTotals"){
        let hourlyCost = props.totalPowerDataHistory.hourly_cost_totals.split(",").reverse();
        let hourlyValue = hourlyCost[2];
        setRateInfo(hourlyValue);
       }
    }
  }, [props.totalPowerDataHistory]);

  return (
  <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    Rate of Consumption/Generation
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this {props.language.hour}<span style={{wordSpacing:'3px'}}> {meterData} {currencyUnit}</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {meterData1 > 0 ? "Consumed last hr" :  "Generated last hr" } 
    <span style={{wordSpacing:'3px'}}>  {meterData1.toString().replace("-", " ")} {currencyUnit}</span>
    </Typography>
      </div>
  )
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(NowDollarRateConsumption);
