
import homeIcon from "assets/img/home-b24.png";
import functionIcon from "assets/img/func-b24.png";
import locationIcon from "assets/img/loc-b24.png";
import settingsIcon from "assets/img/settings-b24.png";


// core components/views for Admin layout
import Location from "views/Locations/Location.js";
import Function from "views/Functions/Function.js";
import Setting from "views/Settings/Setting.js";
import Profiles from "views/Settings/Profiles.js";

import EditScenes from "views/Settings/EditScenes";
import Setup from "views/Settings/Setup";
import Schedules from "views/Settings/Schedules";
import SystemUtilities from "views/Settings/SystemUtilities";
import Stats from "views/Stats/Stats.js";
import Home from "./views/Home";




const dashboardRoutes = [
  {
    path: "/favorite",
    name: "Home",
    icon: homeIcon,
    component: Home,
    layout: "/home"
  },
  {
    path: "/functions",
    name: "Functions",
    icon: functionIcon,
    component: Function,
    layout: "/home"
  },
  {
    path: "/locations",
    name: "Locations",
    icon: locationIcon,
    component: Location,
    layout: "/home"
  },
  {
    path: "/setting",
    name: "Settings",
    icon: settingsIcon,
    component: Setting,
    layout: "/home",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: settingsIcon,
    component: Profiles,
    layout: "/home",
  },
  {
    path: "/editscenes",
    name: "Edit Scenes",
    icon: settingsIcon,
    component: EditScenes,
    layout: "/home",
  },
  {
    path: "/setup",
    name: "Setup",
    icon: settingsIcon,
    component: Setup,
    layout: "/home",
  },
  {
    path: "/schedules",
    name: "Schedules",
    icon: settingsIcon,
    component: Schedules,
    layout: "/home",
  },
  {
    path: "/systemutilities",
    name: "System Utilities",
    icon: settingsIcon,
    component: SystemUtilities,
    layout: "/home",
  },
  {
    path: "/stats",
    name: "Stats",
    icon: settingsIcon,
    component: Stats,
    layout: "/home",
  }

];

export default dashboardRoutes;
