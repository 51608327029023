import React from "react";
import { useState, useEffect } from "react";
import FormControl from '@material-ui/core/FormControl';

// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {Typography, Grid} from '@material-ui/core';
import { FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

// CSS Style sheets imported locally //
import styles from 'assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);

//function // 
export default function IOTimeLogic(props) {
  const [iosSwitch, setIosSwitch] = React.useState({ checked: true });
  const [iPAddress, setIPAddress] = React.useState();
  const [IOTime, setIOTime] = React.useState(props.min);
  const [hours, setHours] = React.useState(IOTime.slice(0,2) >= 10 ? IOTime.slice(0,2): ("0"+IOTime.slice(0,1)) );
  const [mins, setMins] = React.useState(IOTime.slice(0,2) >= 10 ? IOTime.slice(3,5):  IOTime.slice(2,4) );
  const [sec, setSec] = React.useState(IOTime.slice(0,2) >= 10 ? IOTime.slice(6,8) :  IOTime.slice(5,7) );
  const [timeval, setTimeval] = React.useState(IOTime.slice(0,2) >= 10 ? (IOTime.slice(8,10) === "AM" ? "am" : "pm") : (IOTime.slice(7,9) === "AM" ? "am" : "pm") );
  const [val, setVal] = React.useState(props.sysDate);
  const [minHours, setMinHours] = React.useState();
  const [minMinutes, setMinMinutes] = React.useState();
  const [minSeconds, setMinSeconds] = React.useState();
  const [maxHours, setMaxHours] = React.useState();
  const [maxMinutes, setMaxMinutes] = React.useState();
  const [maxSeconds, setMaxSeconds] = React.useState();
  const [ampmstatus, setAmpmstatus] = React.useState();
  

  const handleIOSSwitch = (event) =>{
    setIosSwitch(event.target.checked)
  }
  const [draweropen, setDraweropen] = useState(false);
  const classes = useStyles();

  const toggleDrawer = (open) => {
  setDraweropen(open);
  };
  useEffect(() => {
  let min = parseInt(props.userVariable.IntegerMin, 10); 
  let minhours   = Math.floor(min / 3600);
  let minminutes = Math.floor((min - (minhours * 3600)) / 60);
  let minseconds = min - (minhours * 3600) - (minminutes * 60);
  setMinHours(minhours);
  setMinMinutes(minminutes);
  setMinSeconds(minseconds);

  let max = parseInt(props.userVariable.IntegerMax, 10); 
  let maxhours   = Math.floor(max / 3600);
  let maxminutes = Math.floor((max - (maxhours * 3600)) / 60);
  let maxseconds = max - (maxhours * 3600) - (maxminutes * 60);
  setMaxHours(maxhours);
  setMaxMinutes(maxminutes);
  setMaxSeconds(maxseconds);
  if(minhours<12 && maxhours <12){
    setTimeval("am");
    setAmpmstatus("am");
  }else if(minhours>12 && maxhours >12){
    setTimeval("pm");
    setAmpmstatus("pm");
  }else{
    setAmpmstatus("both");
  }
  }, []);
  

  const handleChangetimevalue = (event) => {
    setTimeval(event.target.value);
    if(ampmstatus==="both"){
      props.dataValidition(false);
    }else{
      if(ampmstatus===event.target.value){
        props.dataValidition(false);
      }else{
        props.dataValidition(true);
      }
    }
    let secondsWithoutSign = ((hours*3600)+((mins)*60)+(sec*1));
    let secondsWithSign = ((event.target.value)==="am"? secondsWithoutSign:(+secondsWithoutSign >= 46800 ?secondsWithoutSign: secondsWithoutSign +(mins)*60)+(sec*1)  );
    props.saveChanges(event.target.value,'ampm');
  };


  const handleChangeHour = (event) => {
    
    if(ampmstatus==="am"){
      if(event.target.value>=minHours && event.target.value<=maxHours){
        props.dataValidition(false);
      }else{
        props.dataValidition(true);
      }
    }
    if(ampmstatus==="pm"){
      if(event.target.value+12>=minHours && event.target.value+12<=maxHours){
        props.dataValidition(false);
      }else{
        props.dataValidition(true);
      }
    }
    if(ampmstatus==="both"){
         if(timeval=="am"){
          if(event.target.value>=minHours && event.target.value<=maxHours){
            props.dataValidition(false);
          }else{
            props.dataValidition(true);
          }
         }
         if(timeval=="pm"){
          if(event.target.value+12>=minHours && event.target.value+12<=maxHours){
            props.dataValidition(false);
          }else{
            props.dataValidition(true);
          }
        }
    }
    setHours(event.target.value);
    let secondsWithoutSign = (((event.target.value)*3600)+(mins*60)+(sec*1));
    let secondsWithSign = (timeval==="am"? secondsWithoutSign:(+secondsWithoutSign >= 46800 ?secondsWithoutSign: secondsWithoutSign +(mins)*60)+(sec*1)  );
    props.saveChanges(event.target.value, 'hours');
  };

  const handleChangeMin = (event) => {
    if(event.target.value>=minMinutes && event.target.value<=maxMinutes){
      props.dataValidition(false);
    }else{
      props.dataValidition(true);
    }
    setMins(event.target.value);
    let secondsWithoutSign = ((hours*3600)+((event.target.value)*60)+(sec*1));
    let secondsWithSign = (timeval==="am"? secondsWithoutSign:(+secondsWithoutSign >= 46800 ?secondsWithoutSign: secondsWithoutSign +(mins)*60)+(sec*1)  );
    props.saveChanges(event.target.value, 'minutes');
  };

  const handleChangeSec = (event) => {
    setSec(event.target.value);
    if(event.target.value>=minSeconds && event.target.value<=maxSeconds){
      props.dataValidition(false);
    }else{
      props.dataValidition(true);
    }
    let secondsWithoutSign = ((hours*3600)+(mins*60)+((event.target.value)*1));
    let secondsWithSign = (timeval==="am"? secondsWithoutSign:(+secondsWithoutSign >= 46800 ?secondsWithoutSign: secondsWithoutSign +(mins)*60)+(sec*1)  );
    props.saveChanges(event.target.value, 'seconds');
  };

  const timer = (int, timeFormat24) =>{
    var sec_num = parseInt(int, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    let time= hours+':'+minutes+':'+seconds;
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    if(!parseInt(timeFormat24)){
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    }
  return time.join (''); // return adjusted time or original string
}

// const timer24 = (int) =>{
//   var sec_num = parseInt(int, 10); // don't forget the second param
//   var hours   = Math.floor(sec_num / 3600);
//   var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
//   var seconds = sec_num - (hours * 3600) - (minutes * 60);
//   if (hours   < 10) {hours   = "0"+hours;}
//   if (minutes < 10) {minutes = "0"+minutes;}
//   if (seconds < 10) {seconds = "0"+seconds;}
//   let time= hours+':'+minutes+':'+seconds;
//   time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
//   if (time.length > 1) { // If time format correct
//   time = time.slice (1);  // Remove full string match value
//   // time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
//   // time[0] = +time[0] % 12 || 12; // Adjust hours
//   }
// return time.join (''); // return adjusted time or original string
// }

  let displayTime = () => {
    return(
      <div className={classes.rootLogic} >
      <Typography variant="h6" className={classes.editWidgetLabel} >
                Value
       </Typography>
      <div className={classes.header1} >
             
           </div>
          
           <div style={{ display: "flex", justifyContent:"space-between", color:"#fff", fontWeight:"600"}} >
          
             {props.data.Project.Info.TimeFormat._attributes.Hour === "0" ?<> 
              <select
                name="hours"
                className="selectwithoptions"
                value={hours}
                onChange={handleChangeHour}   
                style={{background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )"}}     
              >
                    <option>00</option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                  </select>
                  </>:
                  <select
                  name="hours"
                  className="selectwithoptions"
                  value={hours}
                  onChange={handleChangeHour}   
                  style={{background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )"}}     
                >
                      <option>00</option>
                      <option>01</option>
                      <option>02</option>
                      <option>03</option>
                      <option>04</option>
                      <option>05</option>
                      <option>06</option>
                      <option>07</option>
                      <option>08</option>
                      <option>09</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                    </select>


             }
      
            {":"}
            <select
                name="min"
                className="selectwithoptions"
                value={mins}
                onChange={handleChangeMin}   
                style={{background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )"}}     
               
              >
             
                <option>00</option>
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
                <option>32</option>
                <option>33</option>
                <option>34</option>
                <option>35</option>
                <option>36</option>
                <option>37</option>
                <option>38</option>
                <option>39</option>
                <option>40</option>
                <option>41</option>
                <option>42</option>
                <option>43</option>
                <option>44</option>
                <option>45</option>
                <option>46</option>
                <option>47</option>
                <option>48</option>
                <option>49</option>
                <option>50</option>
                <option>51</option>
                <option>52</option>
                <option>53</option>
                <option>54</option>
                <option>55</option>
                <option>56</option>
                <option>57</option>
                <option>58</option>
                <option>59</option>
          
                
              </select>
            {":"}
            <select
                name="sec"
                className="selectwithoptions"
                value={sec}
                onChange={handleChangeSec}   
                style={{background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )"}}     
               
              >
             
                <option>00</option>
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
                <option>32</option>
                <option>33</option>
                <option>34</option>
                <option>35</option>
                <option>36</option>
                <option>37</option>
                <option>38</option>
                <option>39</option>
                <option>40</option>
                <option>41</option>
                <option>42</option>
                <option>43</option>
                <option>44</option>
                <option>45</option>
                <option>46</option>
                <option>47</option>
                <option>48</option>
                <option>49</option>
                <option>50</option>
                <option>51</option>
                <option>52</option>
                <option>53</option>
                <option>54</option>
                <option>55</option>
                <option>56</option>
                <option>57</option>
                <option>58</option>
                <option>59</option>
              
              </select>
              {props.data.Project.Info.TimeFormat._attributes.Hour === "0" && 
              <div style={{display: "flex", alignItems:"center"}}>
                  <div>
                  <FormControl
                component="fieldset"
              //   style={{ display: "flex", flexDirection: "column" }}
              >
                <RadioGroup
                  aria-label="timeval"
                  name="timeval"
                  value={timeval}
                  onChange={handleChangetimevalue}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "-30px"
                  }}
                >
                 
                  <FormControlLabel
                    checked={timeval === "am"}
                    value="am"
                    style={{ fontWeight: "600" }}
                    control={<Radio />}
                    label="am"
                  />
                  <FormControlLabel
                    checked={timeval === "pm"}
                    value="pm"
                    style={{ fontWeight: "600" }}
                    control={<Radio />}
                    label="pm"
                  />
                </RadioGroup>
              </FormControl>
                  </div>    
              </div>}
         </div>
         {props.data.Project.Info.TimeFormat._attributes.Hour === "0" ?
           <div style={{ display: "flex", justifyContent:"space-between", color:"#fff", fontWeight:"600"}}>
             <span>{timer(props.userVariable.IntegerMin, props.data.Project.Info.TimeFormat._attributes.Hour)}</span>
             <span>{timer(props.userVariable.IntegerMax, props.data.Project.Info.TimeFormat._attributes.Hour)}</span>
           </div> :  
           <div style={{ display: "flex", justifyContent:"space-between", color:"#fff", fontWeight:"600"}}>
           <span>{timer(props.userVariable.IntegerMin, props.data.Project.Info.TimeFormat._attributes.Hour)}</span>
           <span>{timer(props.userVariable.IntegerMax, props.data.Project.Info.TimeFormat._attributes.Hour)}</span>
         </div>         
         }
           
        </div>       
      
    )
  }


    // Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const widgetType = props.widget._attributes.type

// 

  return (

<Grid item lg={12} md={12} sm={12} xs={12} className="mainPannelSideedit">
  

<div>{displayTime()}</div>


</Grid>

);
}