import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {
  getAppConfigData,
  socketEventDataChanger,
} from "../../redux/actions/action";
import { connect } from "react-redux";
import { updateXml, updateSocketEvent } from "../../services/xmlService";

// Component imported Locally //
// Climate //
import Hvac from "components/Widgets/hvac";
// LIghtings //
import BellPress from "components/Widgets/bellPress";
import LightingRelay2State from "components/Widgets/lightingRelay2state.js";
import LightingPreset from "components/Widgets/lightingPreset";
import GeneralLightingDimmer from "components/Widgets/Lighting/generalLightingDimmer";
import GeneralLightingRelay from "components/Widgets/Lighting/generalLightingRelay.js";
import LightingStatus from "components/Widgets/lightingStatus";
import Timer from "components/Widgets/timer";
// Music //
import Analog from "components/Widgets/analog";
import MusicPlayer from "components/Widgets/musicPlayer";
//Scenes //
import ScenesTrigger from "components/Widgets/scenesTrigger";
import Scenes from "components/Widgets/scenes";
//Surveillance //
import SurveillanceWidget from "components/Widgets/SurveillanceWidget.js";
// Curtain //
import Curtain3Button from "components/Widgets/Curtain/curtain3Button";
import Curtain2Button from "components/Widgets/Curtain/curtain2Button";
import Curtain1Button from "components/Widgets/Curtain/curtain1Button";
//Shutter-Relay //
import ShutterRelay3Button from "components/Widgets/shutterRelay3Button";
// Security //
import SecurityWidget from "components/Widgets/securityWidget";
import MinderProWidget from "components/Widgets/minderProWidget";
//Logic //
import UserSystemTime from "components/Widgets/Logic/userSystemTime";
import UserSystemBoolean from "components/Widgets/Logic/userSystemBoolean";
import UserSystemDate from "components/Widgets/Logic/userSystemDate";
import UserSystemInteger from "components/Widgets/Logic/userSystemInteger";
import UserSystemRealNumber from "components/Widgets/Logic/userSystemRealNumber";
import UserSystemString from "components/Widgets/Logic/userSystemString";
//C-Bus Others //
import FanController from "components/Widgets/fanController";
import PowerMetering from "components/Widgets/PowerMeter/PowerMeteringWidget/PowerMetering";
import PowerMeteringTotal from "components/Widgets/PowerMeter/PowerMeteringWidgetTotal/PowerMeteringTotal";
import EnablePreset from "components/Widgets/enablePreset";
import LightingRelay2StateEnable from "components/Widgets/lightingRelay2stateEnable";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetCategory: "location",
      favorites: [],
      widgetsList: []
    };
  }

  componentDidMount() {
    let fvs = JSON.parse(localStorage.getItem("favorites"));
    if (fvs) {
      this.setState({
        favorites: fvs,
      });
    }
    let widgetData = this.props.widgets;
    if(!Array.isArray(widgetData)){
      let data = [];
      data.push(this.props.widgets);
      this.setState({
        widgetsList: data,
      });

    } else{
      this.setState({
        widgetsList: widgetData,
      });    
    }
  }

  handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
  };

  profileaccess = (hex,id) => {
    if (hex !== undefined) {
      let profileAccessList = this.hex2bin(hex).split("").reverse();
      if (this.props.remoteUserDetails) {
        if (profileAccessList[Number(this.props.remoteUserDetails.id)] === '1') {
          //
          return true
        } else {
          return false;
        }

      } else {
        return false
      }
    } else {
     return true;
    }
  };

  hex2bin = (hex) => {
      return (parseInt(hex, 16).toString(2)).padStart(16, '0').toString();
  }

  saveEditedWidget = (name, functiongroup, location, widget) => {
    for (
      let i = 0;
      i < this.props.data.Project.FunctionGroups.FunctionGroup.length;
      i++
    ) {
      if (
        this.props.data.Project.FunctionGroups.FunctionGroup[i]._attributes
          .id === widget._attributes.functiongroup
      ) {
        this.props.data.Project.FunctionGroups.FunctionGroup[
          i
        ]._attributes.Name = functiongroup;
      }
    }
    for (
      let j = 0;
      j < this.props.data.Project.Locations.Location.length;
      j++
    ) {
      if (
        this.props.data.Project.Locations.Location[j]._attributes.id ===
        widget._attributes.location
      ) {
        this.props.data.Project.Locations.Location[
          j
        ]._attributes.Name = location;
      }
    }
    for (let m = 0; m < this.props.data.Project.Widgets.widget.length; m++) {
      if (
        this.props.data.Project.Widgets.widget[m]._attributes.id ===
        widget._attributes.id
      ) {
        this.props.data.Project.Widgets.widget[
          m
        ].params._attributes.label = name;
      }
    }
    // we are not writing it back to xml so commented this block to fix the segmentation issue
    // updateXml(this.props.data)
    //   .then((result) => {
    //     if (result.status === 200) {
    //       this.props.getAppConfigData();
    //     }
    //   })
    //   .catch((error) => {
        
    //   });
  };
  handleFavorite = (id, type) => {
    if (localStorage.getItem("favorites")) {
      let fvs = JSON.parse(localStorage.getItem("favorites"));
      if (fvs.includes(id)) {
        const index = fvs.indexOf(id);
        if (index > -1) {
          fvs.splice(index, 1);
          localStorage.setItem("favorites", JSON.stringify(fvs));
        }
      } else {
        fvs.push(id);
        localStorage.setItem("favorites", JSON.stringify(fvs));
      }
    } else {
      let f = [];
      f.push(id);
      localStorage.setItem("favorites", JSON.stringify(f));
    }
  };

  render() {
    return (
      <>
        {this.state.widgetsList && Array.isArray(this.state.widgetsList) && 
           this.state.widgetsList.map((widget, key) => {
            return (
              <>
                {widget._attributes.location != "none" &&
                  widget._attributes.location != "all" && (
                    <>
                      {widget._attributes.type === "25" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <Hvac
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "8" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <BellPress
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "15" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <LightingRelay2State
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "14" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <SurveillanceWidget
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "26" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <LightingPreset
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                        {widget._attributes.type === "28" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                       this.props.locationId ===
                       widget._attributes.location && (
                        <EnablePreset
                          widgetCategory={this.state.widgetCategory}
                          favData={this.state.favorites.includes(
                            widget._attributes.id
                          )}
                          handleFavorite={this.handleFavorite}
                          saveEditedWidget={this.saveEditedWidget}
                          locations={this.props.locations}
                          handleSocketEventChange={this.handleSocketEventChange}
                          cbusEvent={this.props.cbusEvent}
                          initialCbusEvent={this.props.initialCbusEvent}
                          socketEventDataChanger={this.props.socketEventDataChanger}
                          widget={widget}
                          language={this.props.language}
                          functionGroups={this.props.functionGroups}
                          data={this.props.data}
                        />
                      )}
                       {widget._attributes.type === "29" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                       this.props.locationId ===
                       widget._attributes.location && (
                        <LightingRelay2StateEnable
                          widgetCategory={this.state.widgetCategory}
                          favData={this.state.favorites.includes(
                            widget._attributes.id
                          )}
                          handleFavorite={this.handleFavorite}
                          saveEditedWidget={this.saveEditedWidget}
                          handleSocketEventChange={this.handleSocketEventChange}
                          cbusEvent={this.props.cbusEvent}
                          initialCbusEvent={this.props.initialCbusEvent}
                          socketEventDataChanger={this.props.socketEventDataChanger}
                          locations={this.props.locations}
                          widget={widget}
                          language={this.props.language}
                          functionGroups={this.props.functionGroups}
                          data={this.props.data}
                        />
                      )}
                      {widget._attributes.type === "9" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        widget.params._attributes.noofbuttons === "1" &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <Curtain1Button
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "9" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                      widget.params._attributes.noofbuttons === "3" &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <Curtain3Button
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "9" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        widget.params._attributes.noofbuttons === "2" &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <Curtain2Button
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "1" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <GeneralLightingDimmer
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "0" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <GeneralLightingRelay
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "27" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <LightingStatus
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "17" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <Analog
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "11" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <ScenesTrigger
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "10" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <Scenes
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}

                      {widget._attributes.type === "12" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <MusicPlayer
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={this.handleSocketEventChange}
                            locations={this.props.locations}
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "12" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.functionGroupId === "none" && (
                          <MusicPlayer
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={this.handleSocketEventChange}
                            locations={this.props.locations}
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}

                      {/* {widget._attributes.type === "16" &&
                        this.props.locationId ===
                          widget._attributes.location && 
                          widget.params._attributes.type==="one" &&
                          (
                          <ShutterRelay1Button
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                            locations={this.props.locations}
                            widget={widget} 
language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "16" &&
                        this.props.locationId ===
                          widget._attributes.location && 
                          widget.params._attributes.type==="two" &&
                          (
                          <ShutterRelay2Button
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                            locations={this.props.locations}
                            widget={widget} 
language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )} */}
                      {widget._attributes.type === "16" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <ShutterRelay3Button
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "13" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <SecurityWidget
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "24" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <MinderProWidget
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "30" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <Timer
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                        {widget._attributes.name === "User System IO Time" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                 this.props.locationId ===
                  widget._attributes.location && (
                    <UserSystemTime
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Date" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                 this.props.locationId ===
                  widget._attributes.location && (
                    <UserSystemDate
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Integer" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                 this.props.locationId ===
                   widget._attributes.location && (
                    <UserSystemInteger
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Real Number" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                 this.props.locationId ===
                  widget._attributes.location && (
                    <UserSystemRealNumber
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Boolean" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                 this.props.locationId ===
                  widget._attributes.location && (
                    <UserSystemBoolean
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO String" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                 this.props.locationId ===
                  widget._attributes.location && (
                    <UserSystemString
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                      {/* { widget._attributes.type === "9" && this.props.locationId===widget._attributes.location &&
                <ZigBeeDimmerWidget widgetCategory={this.state.widgetCategory} favData={this.state.favorites.includes(widget._attributes.id)} handleFavorite={this.handleFavorite} saveEditedWidget={this.saveEditedWidget} locations={this.props.locations} widget={widget} 
language={this.props.language} functionGroups={this.props.functionGroups} data={this.props.data} />
              } */}
                      {widget._attributes.type === "31" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <FanController
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "32" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <PowerMetering
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                      {widget._attributes.type === "33" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                        this.props.locationId ===
                          widget._attributes.location && (
                          <PowerMeteringTotal
                            widgetCategory={this.state.widgetCategory}
                            favData={this.state.favorites.includes(
                              widget._attributes.id
                            )}
                            handleFavorite={this.handleFavorite}
                            handleSocketEventChange={
                              this.handleSocketEventChange
                            }
                            cbusEvent={this.props.cbusEvent}
                            initialCbusEvent={this.props.initialCbusEvent}
                            socketEventDataChanger={
                              this.props.socketEventDataChanger
                            }
                            saveEditedWidget={this.saveEditedWidget}
                            locations={this.props.locations}
                            widget={widget}
                            language={this.props.language}
                            functionGroups={this.props.functionGroups}
                            data={this.props.data}
                          />
                        )}
                    </>
                  )}
                {widget._attributes.type === "25" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <Hvac
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "8" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <BellPress
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "15" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <LightingRelay2State
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "14" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <SurveillanceWidget
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "26" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <LightingPreset
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                      {widget._attributes.type === "28" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                       widget._attributes.location === "all" && (
                        <EnablePreset
                          widgetCategory={this.state.widgetCategory}
                          favData={this.state.favorites.includes(
                            widget._attributes.id
                          )}
                          handleFavorite={this.handleFavorite}
                          saveEditedWidget={this.saveEditedWidget}
                          locations={this.props.locations}
                          handleSocketEventChange={this.handleSocketEventChange}
                          cbusEvent={this.props.cbusEvent}
                          initialCbusEvent={this.props.initialCbusEvent}
                          socketEventDataChanger={this.props.socketEventDataChanger}
                          widget={widget}
                          language={this.props.language}
                          functionGroups={this.props.functionGroups}
                          data={this.props.data}
                        />
                      )}
                       {widget._attributes.type === "29" &&
                       this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                       widget._attributes.location === "all" && (
                        <LightingRelay2StateEnable
                          widgetCategory={this.state.widgetCategory}
                          favData={this.state.favorites.includes(
                            widget._attributes.id
                          )}
                          handleFavorite={this.handleFavorite}
                          saveEditedWidget={this.saveEditedWidget}
                          handleSocketEventChange={this.handleSocketEventChange}
                          cbusEvent={this.props.cbusEvent}
                          initialCbusEvent={this.props.initialCbusEvent}
                          socketEventDataChanger={this.props.socketEventDataChanger}
                          locations={this.props.locations}
                          widget={widget}
                          language={this.props.language}
                          functionGroups={this.props.functionGroups}
                          data={this.props.data}
                        />
                      )}
                {widget._attributes.type === "9" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget.params._attributes.noofbuttons === "1" &&
                  widget._attributes.location === "all" && (
                    <Curtain1Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "9" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget.params._attributes.noofbuttons === "3" &&
                  widget._attributes.location === "all" && (
                    <Curtain3Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "9" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget.params._attributes.noofbuttons === "2" &&
                  widget._attributes.location === "all" && (
                    <Curtain2Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "1" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <GeneralLightingDimmer
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "0" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <GeneralLightingRelay
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "27" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <LightingStatus
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "17" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <Analog
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "11" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <ScenesTrigger
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "10" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <Scenes
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {/* { widget._attributes.type === "26" && this.props.locationId===widget._attributes.location &&
                <Preset widgetCategory={this.state.widgetCategory} favData={this.state.favorites.includes(widget._attributes.id)} handleFavorite={this.handleFavorite} saveEditedWidget={this.saveEditedWidget} locations={this.props.locations} widget={widget} 
language={this.props.language} functionGroups={this.props.functionGroups} data={this.props.data} />
              } */}
                {widget._attributes.type === "12" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  this.props.locationId === widget._attributes.location && (
                    <MusicPlayer
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      locations={this.props.locations}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}

                {/* {widget._attributes.type === "16" &&
                  widget._attributes.location === "all" && 
                  widget.params._attributes.type==="one" &&
                   (
                    <ShutterRelay1Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget} 
language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "16" &&
                  widget._attributes.location === "all" && 
                  widget.params._attributes.type==="two" &&
                  (
                    <ShutterRelay2Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget} 
language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )} */}
                {widget._attributes.type === "16" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <ShutterRelay3Button
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "13" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <SecurityWidget
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {/* { widget._attributes.type === "13" && this.props.locationId===widget._attributes.location &&
                <MinderProWidget widgetCategory={this.state.widgetCategory} favData={this.state.favorites.includes(widget._attributes.id)} handleFavorite={this.handleFavorite} saveEditedWidget={this.saveEditedWidget} locations={this.props.locations} widget={widget} 
language={this.props.language} functionGroups={this.props.functionGroups} data={this.props.data} />
              } */}
                {widget._attributes.type === "30" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <Timer
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                  {widget._attributes.name === "User System IO Time" &&
                   this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                    widget._attributes.location === "all" && (
                    <UserSystemTime
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Date" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                   widget._attributes.location === "all" && (
                    <UserSystemDate
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Integer" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                   widget._attributes.location === "all" && (
                    <UserSystemInteger
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Real Number" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                   widget._attributes.location === "all" && (
                    <UserSystemRealNumber
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO Boolean" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                   widget._attributes.location === "all" && (
                    <UserSystemBoolean
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.name === "User System IO String" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <UserSystemString
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {/* { widget._attributes.type === "9" && this.props.locationId===widget._attributes.location &&
                <ZigBeeDimmerWidget widgetCategory={this.state.widgetCategory} favData={this.state.favorites.includes(widget._attributes.id)} handleFavorite={this.handleFavorite} saveEditedWidget={this.saveEditedWidget} locations={this.props.locations} widget={widget} 
language={this.props.language} functionGroups={this.props.functionGroups} data={this.props.data} />
              } */}
                {widget._attributes.type === "31" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <FanController
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      handleSocketEventChange={this.handleSocketEventChange}
                      cbusEvent={this.props.cbusEvent}
                      initialCbusEvent={this.props.initialCbusEvent}
                      socketEventDataChanger={this.props.socketEventDataChanger}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
                {widget._attributes.type === "32" &&
                 this.profileaccess(widget._attributes.Profiles, widget._attributes.id) &&
                  widget._attributes.location === "all" && (
                    <PowerMetering
                      widgetCategory={this.state.widgetCategory}
                      favData={this.state.favorites.includes(
                        widget._attributes.id
                      )}
                      handleFavorite={this.handleFavorite}
                      saveEditedWidget={this.saveEditedWidget}
                      locations={this.props.locations}
                      widget={widget}
                      language={this.props.language}
                      functionGroups={this.props.functionGroups}
                      data={this.props.data}
                    />
                  )}
              </>
            );
          })}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.reducer.data,
  locations: state.reducer.locations,
  functionGroups: state.reducer.functionGroups,
  widgets: state.reducer.widgets,
  locationId: state.reducer.location_id,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language,
  remoteUserDetails: state.reducer.remoteUserDetails
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      socketEventDataChanger,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Location);
