import ReactSpeedometer from "react-d3-speedometer"
import MonthCo2RateConsumption from "../../PowerConsumption/co2/MonthCo2RateConsumption"
import { useState, useEffect } from "react";
import { connect } from "react-redux";

function Co2MonthSpeedoTotal(props) {
    const [meterValue, setMeterValue] =useState("");

    //CarbonFootPrint Value
    const carbonFootValue = props.energy.Project.Energy._attributes.CarbonFootprint;

    let minMeterValue = (Number(props.widget.params._attributes.max_monthly_gen_w)/1000)*carbonFootValue;

    let maxMeterValue = (Number(props.widget.params._attributes.max_monthly_con_w)/1000)*carbonFootValue;

    let meterData = ((meterValue/1000)*carbonFootValue).toFixed(2);

    let meterResult = meterData >= 9999 ? 9999 : meterData;

    let meterText = ((meterValue/1000)*carbonFootValue).toFixed(2).replace("-", " ");;

    useEffect(() => {
      if (props.totalPowerData) {
          if (props.totalPowerData.name === "cbusPowerTotals") {
           setMeterValue(props.totalPowerData.current_total_power_month); 
        }
      }
    }, [props.totalPowerData]);


    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
      <h6 style={{margin: '0px' }}>{props.language.month}</h6>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxSegmentLabels={1}
                minValue={-minMeterValue}
                maxValue={maxMeterValue}
                //value={meterResult}
                customSegmentStops={[-minMeterValue, 0, maxMeterValue]}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterText} kg CO2`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={180}
                height={120}
               dimensionUnit="px"

        />
        <MonthCo2RateConsumption
          powerData={props.powerData}
          totalPowerDataHistory={props.totalPowerDataHistory}
          data={props.data}
          widget={props.widget}
          energy={props.energy}
          language={props.language} />
        </div>
    );
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(Co2MonthSpeedoTotal); 