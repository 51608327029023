/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Larrow from "../../assets/img/LArrow.png";
import {Divider} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles} from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import styles from "../../assets/widgets/widgetStyles/commonStyles";

const useStyles = makeStyles(styles);

const redStyle = {width:"70px", color: "#ff0000"};
const greenStyle = { width:"70px", color: "#5DD300"};
const EditSecurityWidgetFeature = (props) => {
  const initialStatusesData=props.initialCbusEvent.get("cbusSecGetStatuses");
  const classes = useStyles();
  const [alarmStatus, setAlarmStatus] = useState("0x0");
  const [temperStatus, setTemperStatus] = useState("0x0");
  const [panicStatus, setPanicStatus] = useState("0x0");
  const [batteryLowStatus, setBatteryLowStatus] = useState("0x0");
  const [mainFailStatus, setMainFailStatus] = useState("0x0");
  const [lineFailStatus, setLineFailStatus] = useState("0x0");
  const [armFailStatus, setArmFailStatus] = useState("0x0");
  const [fireStatus, setFireStatus] = useState("0x0");
  const [gasStatus, setGasStatus] = useState("0x0");
  const [otherStatus, setOtherStatus] = useState("0x0");
  const [alarmCount, setAlarmCount] = useState(0);
  const [armStatus, setArmStatus] = useState("0x0");

  useEffect(() => {
    if (props.cbusEvent) {
        if (props.cbusEvent.name === "cbus_event") {
          if (props.cbusEvent.attributes.name === "status_message") {
            if (props.cbusEvent.attributes.message_type === "0x0") {
              setAlarmStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x1") {
              setTemperStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x2") {
              setPanicStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x3") {
              setBatteryLowStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x4") {
              setMainFailStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x5") {
              setLineFailStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x6") {
              setArmFailStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x7") {
              setFireStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x8") {
              setGasStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
            if (props.cbusEvent.attributes.message_type === "0x9") {
              setOtherStatus(props.cbusEvent.attributes.status);
              if(initialStatusesData){
                initialStatusesData[parseInt(props.cbusEvent.attributes.message_type, 16)] = props.cbusEvent.attributes.status;
                let statusData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(statusData);
              }
            }
          }
          if(props.cbusEvent.attributes.name === "system_armed") {
            if(props.cbusEvent.attributes.arm_code==='0x0'){
            setAlarmStatus("0x0");
            setTemperStatus("0x0");
            setPanicStatus("0x0");
            setBatteryLowStatus("0x0");
            setMainFailStatus("0x0");
            setLineFailStatus("0x0");
            setArmFailStatus("0x0");
            setFireStatus("0x0");
            setGasStatus("0x0");
            setOtherStatus("0x0");
            setArmStatus(props.cbusEvent.attributes.arm_code);
            if(initialStatusesData){
              initialStatusesData[0] = "0x0";
              initialStatusesData[1] = "0x0";
              initialStatusesData[2] = "0x0";
              initialStatusesData[3] = "0x0";
              initialStatusesData[4] = "0x0";
              initialStatusesData[5] = "0x0";
              initialStatusesData[6] = "0x0";
              initialStatusesData[7] = "0x0";
              initialStatusesData[8] = "0x0";
              initialStatusesData[9] = "0x0";
              let statusData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(statusData);
              setAlarmCount(0);
            }
          }
        }
      }
    }
    // if(armStatus=="0x0"){
    //   setAlarmStatus("0x0");
    //   setTemperStatus("0x0");
    //   setPanicStatus("0x0");
    //   setBatteryLowStatus("0x0");
    //   setMainFailStatus("0x0");
    //   setLineFailStatus("0x0");
    //   setArmFailStatus("0x0");
    //   setFireStatus("0x0");
    //   setGasStatus("0x0");
    //   setOtherStatus("0x0");
    //   setAlarmCount(0);
    // }
    if(initialStatusesData){
      for(let i=0;i<initialStatusesData.length;i++){
         if(i==0){
          setAlarmStatus(initialStatusesData[i]);
         }
         if(i==1){
          setTemperStatus(initialStatusesData[i]);
        }
        if(i==2){
          setPanicStatus(initialStatusesData[i]);
        }
        if(i==3){
          setBatteryLowStatus(initialStatusesData[i]);
        }
        if(i==4){
          setMainFailStatus(initialStatusesData[i]);
        }
        if(i==5){
          setLineFailStatus(initialStatusesData[i]);
        }
        if(i==6){
          setArmFailStatus(initialStatusesData[i]);
        }
        if(i==7){
          setFireStatus(initialStatusesData[i]);
        }
        if(i==8){
          setGasStatus(initialStatusesData[i]);
        }
        if(i==9){
          setOtherStatus(initialStatusesData[i]);
        }
      }
      let countdata=initialStatusesData.reduce((b,c)=>((b[b.findIndex(d=>d.el===c)]||b[b.push({el:c,count:0})-1]).count++,b),[]);
      if(statusExists(countdata,"0x1")){
        for(let j=0;j<countdata.length;j++){
          if(countdata[j].el=="0x1"){
            setAlarmCount(countdata[j].count);
          }
        }
      }else{
        setAlarmCount(0);
      }
    }
  }, [props.cbusEvent]);

  const statusExists = (arr,element) => {
    return arr.some(function(e) {
      return e.el === element;
    }); 
  }

  return (
    <div
       style={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: "linear-gradient(#3b3b3b, #202020)",
      }}
    >
      <CloseIcon
        onClick={() => {
          props.toggleDrawer(false);
        }}
        style={{
          display: "flex",
          alignSelf: "flex-end",
          width: "10%",
          color:'white',
           }}
      />
     <div className={classes.editWidgetHeader} style={{ marginTop: "0%" }}>
        <Typography
          variant="h6"
          style={{paddingLeft:'5%', marginBottom:'2%'  }}
          className={classes.headerText1}
        >
          Location
          {/* {props.data.data.language.locationName} */}
        </Typography>
      </div>
      <div
        style={{
          width: "100%",
           backgroundColor: "#393939",
          borderRadius: "0 !important",
          height: "40%",
          marginTop: "0%",
          marginBottom: "0%",
          paddingTop: "0%",
          paddingBottom: "0%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }} onClick={() => { props.toggleDrawer(false)}} >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-start",
              flexGrow: "1",
              width: "100%",
              height: "30%",
              paddingLeft:'4%' 

            }}
            className={ classes.header}
          >
            <IconButton > 
            <img src={Larrow} className={classes.headerRightIcon} style={{borderLeft:'2px solid #2b2b2b'}} />
          </IconButton>
            <Typography
              variant="h5"
              style={{color:'#c5c4c4'}}
              className={classes.headerText1}
              
            >
              {props.data.data.language.alarm} ({alarmCount})
            </Typography>
            
          </div>
         
        </div>
      </div>
      
      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5', paddingBottom:'2.5%', alignItems:'center', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={alarmStatus === "0x0" ? greenStyle : redStyle }  checked={true} disabled={true} value="Alarm" />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
             {props.data.data.language.alarm}
            </Typography> 
            </div>       
         <Divider style={{height:'2px',}} />
         <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',paddingBottom:'2.5%',  alignItems:'center', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={temperStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true}  />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
              {props.data.data.language.tamper}
            </Typography> 
            </div>       
         <Divider />
         <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={panicStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true}  />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
            {props.data.data.language.panic}
            </Typography> 
            </div>  
            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={batteryLowStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true}  />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
           
            {props.data.data.language.batteryLow}
            </Typography> 
            </div> 
            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={mainFailStatus === "0x0" ? greenStyle : redStyle }  checked={true} disabled={true}  />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
            {props.data.data.language.mainsFail}
            </Typography> 
            </div> 
            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={lineFailStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true}  />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
            
            {props.data.data.language.lineFail}
            </Typography> 
            </div> 
            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={armFailStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true}  />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >          
            {props.data.data.language.armFail}
            </Typography> 
            </div>      
         <Divider />
         <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={fireStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true}  />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
            {props.data.data.language.fire}
            </Typography> 
            </div>      
         <Divider />
         <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={gasStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true} />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
            {props.data.data.language.gas}
            </Typography> 
            </div>      
         <Divider />
         <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', flexGrow:'.5',  alignItems:'center', paddingBottom:'2.5%', paddingTop:'2.5%' }} > 
        <Radio size="medium" style={otherStatus === "0x0" ? greenStyle : redStyle } checked={true} disabled={true} />
            <Typography
              variant="h5"
              style={{ alignSelf: "end" }}
              className={classes.headerText1}
            >
            {props.data.data.language.other}
            </Typography> 
            </div>      
         <Divider />
     
      
    </div>
  );
};


export default EditSecurityWidgetFeature;
