import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 52,
    height: 28,
    padding: 0,
    },
  switchBase: {
    padding: 0,
    '&$checked': {
      transform: 'translateX(25px)',
      thumb:{
        color:'red',
      },
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#92E300',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '2px solid #fff',
      },
  },
  thumb: {
    width: 25,
    height: 26,
    color:'grey',
    border:'1px solid black',
    '& .bar': {
        // display: inline-block !important;
        height: 14,
        width: 2,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
  },
  track: {
    borderRadius: 20,
    // border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#606060',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border', 'thumb']),
  },
  
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        
      }}
      {...props}
    />
  );
});

export default IOSSwitch;