import React, { useState } from "react";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/js/all-styles/views/profileStyle.js";

// import img1 from "assets/img/googleimage.jpg";

import "assets/css/testApp.css";

const useStyles = makeStyles(styles);

class ImageEditdrawer extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    previewUri: "",
    cropMask: undefined,
  };

  _handleCrop(cropMask) {
    this.setState({ cropMask });
    if (cropMask) {
      getCroppedImageUrl(this.props.img1, cropMask).then((result) => {
        this.setState({
          previewUri: result,
        });
      });
    } else {
      this.setState({ previewUri: "" });
    }
  }

  _handleImageChange = (e) => {
  
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
  
    reader.onload = () => {
      this.props.setFile(newFile);
      this.props.setimageUrlNew(reader.result);
    };

    if (newFile) {
      reader.readAsDataURL(newFile);
      
 
    }
  };

  

  render() {
   
    let { previewUri, cropMask } = this.state;
    let preview = null;
    let actualAspectRatio = cropMask
      ? cropMask.width / cropMask.height
      : "None";
    let requestedAspectRatio = 2 / 1; // 16/9;
    let maxChunkSize = 30;

    // let {classes} = useStyles();

    if (previewUri) {
      preview = (
        <img
          src={previewUri}
          alt="Crop Preview"
          style={{ maxWidth: "100%", border: "1px solid black" }}
        />
      );
    }

    return (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            background:
              "radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%)",
          }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h3
              style={{
                color: "#fff",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Edit profile picture
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Cropper
                src={this.props.img1}
                onCrop={this._handleCrop.bind(this)}
                aspectRatio={requestedAspectRatio}
                aspectRatioMaxChunkSize={maxChunkSize}
                aggressiveCallbacks={false}
              />
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{
              background:
                "radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%)",
              boxShadow: "-5px 0 .4em black",
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                paddingTop: "20px",
                paddingLeft: "25px",
                justifyContent: "center"
              }}
            >
              {/* <button onClick={props.toggleDrawerImage(false)} className={classes.imgeditsave}>Cancel</button>
                    <button onClick={props.toggleDrawerImage(false)} className={classes.imgeditsave}>Save</button> */}
              <button onClick={this.props.handleCancelButton.bind(this)}  style={{
                  color: "#fff",
                  border: "0",
                  cursor: "pointer",
                  height: "42px",
                  width:"110px",
                  background:
                    "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
                  borderTop: "1px solid #a5a5a5",
                  fontWeight: "600",
                  borderStyle: "solid",
                  marginRight: "25px",
                }}>
                {this.props.language.cancel}
              </button>
              <button onClick={this.props.updateimageUrl(previewUri)} style={{
                  color: "#fff",
                  border: "0",
                  cursor: "pointer",
                  height: "42px",
                  width:"110px",
                  background:
                    "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
                  borderTop: "1px solid #a5a5a5",
                  fontWeight: "600",
                  borderStyle: "solid",
                  marginRight: "25px",
                }}>
              {this.props.language.save}
               
              </button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <hr
                style={{
                  border: "1px solid black",
                  paddingTop: "15px",
                  borderTopColor: "transparent",
                }}
              />
            </Grid>

            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
               
                display: "flex",
    justifyContent: "center"
              }}
            >
              {/* <button className={classes.changeimagebtn}>Change image</button> */}
              {/* <button>Change image</button> */}
              <>
              
              <div>
                  <a
                    style={{
                      margin: "0",
                      background:
                        "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
                      height: "44px",                     
                      position: "relative",
                      color: "#fff",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "244px",
                    }}
                  >
                    <input
                      type="file"
                      style={{
                        opacity: "0",
                        cursor: "pointer",
                        display: "block",
                        height: "100%",
                        left: "0",
                        position: "absolute",
                        top: "0",
                       width:"200px",

                        background:
                          "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
                      }}
                      // onClick={(e) => this.props.handleImageChange(e, this.props.profiledata)}
                      // onClick={(e) =>
                      //   this._handleChangeUploadimg(e, this.props.profiledata)
                      // }
                      onChange = {(e)=>this._handleImageChange(e, this.props.profileData)}
                    />
                    Change image
                  </a>
                </div>
              </>
            </Grid>

            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                paddingTop: "25px",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ marginBlock: "20px" }}>
                <span style={{ color: "#737373", fontWeight: "600" }}>
                  Preview
                </span>
              </div>

              {/* <div className={classes.imgcontainer}> */}
              <div>
                <>
                  <div
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "80px",
                        height: "80px",
                        border: "3px solid #538c0d",
                        color: "fff",
                        margin: "5px auto",
                        overflow: "hidden",
                        borderRadius: "50%",
                      }}
                    >
                      {/* <img src={props.img1}   alt="logo" className={classes.img} /> */}

                      {preview}
                    </div>
                  </div>
                </>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const preloadImage = (url, crossOrigin = "anonymous") =>
  new Promise((resolve, reject) => {
    let img = new Image();

    img.crossOrigin = crossOrigin;
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.setAttribute("src", url);
  });

const getCroppedImageUrl = (url, cropMask) =>
  preloadImage(url).then((image) => {
    let canvas = document.createElement("canvas");
    canvas.setAttribute("width", cropMask.width);
    canvas.setAttribute("height", cropMask.height);

    let context = canvas.getContext("2d");
    context.drawImage(
      image,
      cropMask.x,
      cropMask.y,
      cropMask.width,
      cropMask.height,
      0,
      0,
      cropMask.width,
      cropMask.height
    );
    return canvas.toDataURL("image/png");
  });

/**
 * #### BEGIN COMPONENT CODE
 */

const CROP_HANDLE_NE = "crop-handle-ne";
const CROP_HANDLE_SE = "crop-handle-se";
const CROP_HANDLE_SW = "crop-handle-sw";
const CROP_HANDLE_NW = "crop-handle-nw";
const CROP_HANDLE_DIRECTIONS = [
  CROP_HANDLE_NE,
  CROP_HANDLE_SE,
  CROP_HANDLE_SW,
  CROP_HANDLE_NW,
];
const MINIMUM_CROP_DIMENSION = 30;
const DIRECTION_TO_CLASSNAME_MAP = {
  [CROP_HANDLE_NE]: "cropper__handle--ne",
  [CROP_HANDLE_SE]: "cropper__handle--se",
  [CROP_HANDLE_SW]: "cropper__handle--sw",
  [CROP_HANDLE_NW]: "cropper__handle--nw",
};

class Cropper extends React.Component {
  static defaultProps = {
    crossOrigin: "anonymous",
    aggressiveCallbacks: false,
    altText: "",
    aspectRatioMaxChunkSize: 10,
  };

  state = {
    naturalWidth: 0,
    naturalHeight: 0,
    cropMask: undefined,
    scale: 1,
  };

  componentWillMount() {
    this._handleWindowSize = this._handleWindowSize.bind(this);

    addWindowHandler("resize", this._handleWindowSize);
  }

  componentWillUnmount() {
    removeWindowHandler("resize", this._handleWindowSize);
  }

  _handleWindowSize() {
    this._setScale();
  }

  _handleImageLoaded(event) {
    let {
      onCrop,
      aspectRatio,
      defaultCropMask: cropMask,
      aspectRatioMaxChunkSize,
    } = this.props;
    let { naturalWidth, naturalHeight } = this.refs.image;

    if (cropMask) {
      cropMask = constrainCropMask(
        naturalWidth,
        naturalHeight,
        cropMask,
        aspectRatio,
        aspectRatioMaxChunkSize
      );
    } else {
      cropMask = centerCropMask();
    }

    this.setState({
      naturalWidth,
      naturalHeight,
      cropMask,
    });
    this._setScale();
    onCrop(cropMask);
  }

  _getScale() {
    let scale = 1;

    // window could be scaled before image loads
    if (this.refs.image) {
      let { clientWidth, naturalWidth } = this.refs.image;

      scale = getScale(clientWidth, naturalWidth);
    }
    return scale;
  }

  _setScale() {
    this.setState({
      scale: this._getScale(),
    });
  }

  _handlePortholeMouseDown(event) {
    if (event.target.getAttribute("data-handle")) {
      return;
    }

    event.preventDefault();

    let { cropMask } = this.state;
    let handleMouseMove = this._handlePortholeMouseMove.bind(
      this,
      getEventCoords(event),
      cropMask
    );
    let handleMouseUp = this._handlePortholeMouseUp.bind(this);

    // if the user dragged the mouse into some other context (like a frame) then mouseup,
    // it's possible they could get in a state in which they're still moving things.
    // clicking should release their drag action.
    if (this._finishMoveAction) {
      this._finishMoveAction();
    }
    this._finishMoveAction = () => {
      removeMoveHandler(handleMouseMove);
      removeActionEndHandler(handleMouseUp);
      this._finishMoveAction = () => {};

      let { onCrop } = this.props;
      let { cropMask } = this.state;

      // invoke oncrop here in case the scenario above plays out.
      // when they click to release the drag action, invoke their callback.
      // if we do this in the mouseUp, it could be missed if they mouseUp in
      // a different context.
      onCrop(cropMask);
    };

    addMoveHandler(handleMouseMove);
    addActionEndHandler(handleMouseUp);
  }

  _handlePortholeMouseMove(dragStartPosition, dragStartCrop, event) {
    event.preventDefault();

    let { aspectRatio, aggressiveCallbacks, onCrop } = this.props;
    let { naturalWidth, naturalHeight, scale } = this.state;
    let unconstrainedNextCropMask = getNextMovedCropMask(
      dragStartPosition,
      dragStartCrop,
      getEventCoords(event),
      scale
    );
    let cropMask = constrainCropMaskXY(
      naturalWidth,
      naturalHeight,
      unconstrainedNextCropMask
    );

    this.setState({ cropMask });
    if (aggressiveCallbacks) {
      onCrop(cropMask);
    }
  }

  _handlePortholeMouseUp(event) {
    event.preventDefault();
    this._finishMoveAction();
  }

  _handleHandleMouseDown(direction, event) {
    event.preventDefault();

    let { cropMask } = this.state;
    let handleMouseMove = this._handleHandleMouseMove.bind(
      this,
      getEventCoords(event),
      cropMask,
      direction
    );
    let handleMouseUp = this._handleHandleMouseUp.bind(this);

    // if the user dragged the mouse into some other context (like a frame) then mouseup,
    // it's possible they could get in a state in which they're still moving things.
    // clicking should release their resize action.
    if (this._finishSizeAction) {
      this._finishSizeAction();
    }
    this._finishSizeAction = () => {
      removeMoveHandler(handleMouseMove);
      removeActionEndHandler(handleMouseUp);
      this._finishSizeAction = () => {};

      let { onCrop } = this.props;
      let { cropMask } = this.state;

      // invoke oncrop here in case the scenario above plays out.
      // when they click to release the resize action, invoke their callback.
      // if we do this in the mouseUp, it could be missed if they mouseUp in
      // a different context.
      onCrop(cropMask);
    };
    addMoveHandler(handleMouseMove);
    addActionEndHandler(handleMouseUp);
  }

  _handleHandleMouseMove(dragStartPosition, dragStartCrop, direction, event) {
    event.preventDefault();

    let {
      aspectRatio,
      aggressiveCallbacks,
      onCrop,
      aspectRatioMaxChunkSize,
    } = this.props;
    let { pageX: startPageX, pageY: startPageY } = dragStartPosition;
    let { pageX: newPageX, pageY: newPageY } = getEventCoords(event);
    let { x, y, width, height } = dragStartCrop;
    let { naturalWidth, naturalHeight, scale } = this.state;
    let invertWidth =
      direction === CROP_HANDLE_SW || direction === CROP_HANDLE_NW;
    let invertHeight =
      direction === CROP_HANDLE_NW || direction === CROP_HANDLE_NE;
    let widthAdjustment =
      (startPageX - newPageX) * scale * (invertWidth ? -1 : 1);
    let heightAdjustment =
      (startPageY - newPageY) * scale * (invertHeight ? -1 : 1);
    let adjustedWidth = Math.floor(width - widthAdjustment);
    let adjustedHeight = Math.floor(height - heightAdjustment);
    // the following prevents the box from getting larger when it hits the edge (still needs tweaking for resizing off the bottom)
    if (adjustedWidth + x > naturalWidth) {
      adjustedWidth = naturalWidth - x;
    }
    if (adjustedHeight + y > naturalHeight) {
      adjustedHeight = naturalHeight - y;
    }
    // the preceding prevents the box from getting larger when it hits the edge
    let sizeConstrainedMask = constrainCropMask(
      naturalWidth,
      naturalHeight,
      {
        x,
        y,
        width: adjustedWidth,
        height: adjustedHeight,
      },
      aspectRatio,
      aspectRatioMaxChunkSize
    );

    let xAdjustment = invertWidth ? sizeConstrainedMask.width - width : 0;
    let yAdjustment = invertHeight ? sizeConstrainedMask.height - height : 0;
    let cropMask = constrainCropMaskXY(naturalWidth, naturalHeight, {
      ...sizeConstrainedMask,
      x: x - xAdjustment,
      y: y - yAdjustment,
    });

    this.setState({ cropMask });
    if (aggressiveCallbacks) {
      onCrop(cropMask);
    }
  }

  _handleHandleMouseUp(event) {
    event.preventDefault();
    this._finishSizeAction();
  }

  render() {
    let { src, crossOrigin, altText } = this.props;
    let { naturalWidth, naturalHeight, cropMask } = this.state;
    let svgCropMask;
    let porthole;
    let scale = this._getScale();

    // image is loaded and we have a mask to show
    if (naturalWidth && naturalHeight && cropMask) {
      let scaledCropMask = getScaledCropMask(cropMask, scale);

      svgCropMask = (
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          className="cropper__cover"
        >
          <polygon
            className="cropper__mask"
            points={getCropMaskPolygon(
              naturalWidth,
              naturalHeight,
              cropMask,
              scale
            )}
          />
          <rect className="cropper__ants--dark" {...scaledCropMask} />
          <rect className="cropper__ants" {...scaledCropMask} />
        </svg>
      );

      let handles = CROP_HANDLE_DIRECTIONS.map((direction) => (
        <div
          className={"cropper__handle " + DIRECTION_TO_CLASSNAME_MAP[direction]}
          data-handle="true"
          onMouseDown={this._handleHandleMouseDown.bind(this, direction)}
          onTouchStart={this._handleHandleMouseDown.bind(this, direction)}
        />
      ));

      porthole = (
        <div
          className="cropper__porthole"
          style={pxToStyles(scaledCropMask)}
          onMouseDown={this._handlePortholeMouseDown.bind(this)}
          onTouchStart={this._handlePortholeMouseDown.bind(this)}
        >
          {handles}
        </div>
      );
    }

    return (
      <div className="cropper">
        {svgCropMask}
        {porthole}
        <img
          src={src}
          alt={altText}
          className="cropper__image"
          crossOrigin={crossOrigin}
          ref="image"
          onLoad={this._handleImageLoaded.bind(this)}
        />
      </div>
    );
  }
}

const getScaledCropMask = ({ x, y, width, height }, scale = 1) => ({
  x: Math.round(x / scale),
  y: Math.round(y / scale),
  width: Math.round(width / scale),
  height: Math.round(height / scale),
});

const pxToStyles = ({ x, y, width, height }) => ({
  left: `${x}px`,
  top: `${y}px`,
  width: `${width}px`,
  height: `${height}px`,
});

const getCropMaskPolygon = (
  naturalWidth,
  naturalHeight,
  cropMask,
  scale = 1
) => {
  let width = Math.round(naturalWidth / scale);
  let height = Math.round(naturalHeight / scale);
  let top = Math.round(cropMask.y / scale);
  let left = Math.round(cropMask.x / scale);
  let bottom = Math.round((cropMask.y + cropMask.height) / scale);
  let right = Math.round((cropMask.x + cropMask.width) / scale);

  return [
    "0,0",
    `${left},0`,
    `${left},${bottom}`,
    `${right},${bottom}`,
    `${right},${top}`,
    `${left},${top}`,
    `${left},0`,
    `${width},0`,
    `${width},${height}`,
    `0,${height}`,
    "0,0",
  ].join(" ");
};

const getDefaultCropMask = (width, height, multiplier = 0.1) => {
  let percentWidth = Math.floor(width * multiplier);
  let percentHeight = Math.floor(height * multiplier);
  console.log(
    {
      x: percentWidth,
      y: percentHeight,
      width: width - percentWidth * 2,
      height: height - percentHeight * 2,
    },
    "getDefaultCropMask"
  );

  return {
    x: 100,
    y: 100,
    width: 100,
    height: 100,
  };
};

const getScale = (currentWidth, naturalWidth) => naturalWidth / currentWidth;

const getNextMovedCropMask = (
  dragStartPosition,
  dragStartCrop,
  newPosition,
  scale
) => {
  let { pageX: startPageX, pageY: startPageY } = dragStartPosition;
  let { pageX: newPageX, pageY: newPageY } = newPosition;
  let { x, y, width, height } = dragStartCrop;

  return {
    x: Math.floor(x - (startPageX - newPageX) * scale),
    y: Math.floor(y - (startPageY - newPageY) * scale),
    width,
    height,
  };
};

const constrainCropMaskXY = (
  naturalWidth,
  naturalHeight,
  { x, y, width, height }
) => {
  if (x + width > naturalWidth) {
    x = naturalWidth - width;
  }
  if (y + height > naturalHeight) {
    y = naturalHeight - height;
  }
  return {
    x: Math.floor(Math.max(0, x)),
    y: Math.floor(Math.max(0, y)),
    width,
    height,
  };
};

const constrainCropMask = (
  naturalWidth,
  naturalHeight,
  { x, y, width, height },
  aspectRatio,
  maxChunkSize
) => {
  width = Math.max(MINIMUM_CROP_DIMENSION, Math.min(naturalWidth, width));
  height = Math.max(MINIMUM_CROP_DIMENSION, Math.min(naturalHeight, height));

  width = Math.floor(width);
  height = Math.floor(height);

  return {
    ...constrainCropMaskXY(naturalWidth, naturalHeight, {
      x,
      y,
      width,
      height,
    }),
    width,
    height,
  };
};

const centerCropMask = () => ({
  x: 0,
  y: 0,
  width: 100,
  height: 100,
});

const getEventCoords = ({ touches, pageX, pageY }) => {
  return touches
    ? {
        pageX: touches[0].pageX,
        pageY: touches[0].pageY,
      }
    : { pageX, pageY };
};

const HAS_WINDOW = typeof window !== "undefined";

const addWindowHandler = (eventName, handler) => {
  if (HAS_WINDOW) {
    window.addEventListener(eventName, handler);
  }
};
const removeWindowHandler = (eventName, handler) => {
  if (HAS_WINDOW) {
    window.removeEventListener(eventName, handler);
  }
};
const addMoveHandler = (handler) => {
  addWindowHandler("mousemove", handler);
  addWindowHandler("touchmove", handler);
};
const removeMoveHandler = (handler) => {
  removeWindowHandler("mousemove", handler);
  removeWindowHandler("touchmove", handler);
};
const addActionEndHandler = (handler) => {
  addWindowHandler("mouseup", handler);
  addWindowHandler("touchend", handler);
};
const removeActionEndHandler = (handler) => {
  removeWindowHandler("mouseup", handler);
  removeWindowHandler("touchend", handler);
};

export default ImageEditdrawer;
