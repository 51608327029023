import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import styles from "assets/js/all-styles/views/schedulesStyle.js";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

export default function SelectActiveDates(props) {
  
  let listOfNumber = [];
  const classes = useStyles();
  const [anyDateData, setAnyDateData] = useState([
    {
      name: "1",
      Value: 1,
      isSelected: false,
      key: 0,
    },
    {
      name: "2",
      Value: 2,
      isSelected: false,
      key: 1,
    },
    {
      name: "3",
      Value: 4,
      isSelected: false,
      key: 2,
    },
    {
      name: "4",
      Value: 8,
      isSelected: false,
      key: 3,
    },
    {
      name: "5",
      Value: 16,
      isSelected: false,
      key: 4,
    },
    {
      name: "6",
      Value: 32,
      isSelected: false,
      key: 5,
    },
    {
      name: "7",
      Value: 64,
      isSelected: false,
      key: 6,
    },
    {
      name: "8",
      Value: 128,
      isSelected: false,
      key: 7,
    },
    {
      name: "9",
      Value: 256,
      isSelected: false,
      key: 8,
    },
    {
      name: "10",
      Value: 512,
      isSelected: false,
      key: 9,
    },
    {
      name: "11",
      Value: 1024,
      isSelected: false,
      key: 10,
    },
    {
      name: "12",
      Value: 2048,
      isSelected: false,
      key: 11,
    },
    {
      name: "13",
      Value: 4098,
      isSelected: false,
      key: 12,
    },
    {
      name: "14",
      Value: 8192,
      isSelected: false,
      key: 13,
    },
    {
      name: "15",
      Value: 16384,
      isSelected: false,
      key: 14,
    },
    {
      name: "16",
      Value: 32768,
      isSelected: false,
      key: 15,
    },
    {
      name: "17",
      Value: 65536,
      isSelected: false,
      key: 16,
    },
    {
      name: "18",
      Value: 131072,
      isSelected: false,
      key: 17,
    },
    {
      name: "19",
      Value: 262144,
      isSelected: false,
      key: 18,
    },
    {
      name: "20",
      Value: 524288,
      isSelected: false,
      key: 19,
    },
    {
      name: "21",
      Value: 1048576,
      isSelected: false,
      key: 20,
    },
    {
      name: "22",
      Value: 2097152,
      isSelected: false,
      key: 21,
    },
    {
      name: "23",
      Value: 4194304,
      isSelected: false,
      key: 22,
    },
    {
      name: "24",
      Value: 8388608,
      isSelected: false,
      key: 23,
    },
    {
      name: "25",
      Value: 16777216,
      isSelected: false,
      key: 24,
    },
    {
      name: "26",
      Value: 33554432,
      isSelected: false,
      key: 25,
    },
    {
      name: "27",
      Value: 67108864,
      isSelected: false,
      key: 26,
    },
    {
      name: "28",
      Value: 134217728,
      isSelected: false,
      key: 27,
    },
    {
      name: "29",
      Value: 268435456,
      isSelected: false,
      key: 28,
    },
    {
      name: "30",
      Value: 536870912,
      isSelected: false,
      key: 29,
    },
    {
      name: "31",
      Value: 1073741824,
      isSelected: false,
      key: 30,
    }
  ]);

  

  useEffect(() => {
  
    let day = new Array(31).fill(false);
    day = parseIntArray(props.data, 31);
    
    let data = [...anyDateData];
    data.map((item, key) => item.isSelected = day[key]);

  }, []);


 



  useEffect(() => {
    let day_d = 0;
    let data = [...anyDateData];
    const result =  extractValue(data, "isSelected");
    day_d = parseArrayInt(result);
  
   
    
    props.setActiveDates(day_d.toString(16));
  }, [anyDateData]);

  function extractValue(arr, prop) {

    let extractedValue = [];

    for (let i=0; i < arr.length ; ++i) {

        // extract value from property
        extractedValue.push(arr[i][prop]);
    }
    return extractedValue;
}

  function parseIntArray(int_value, count) {
    var ret = new Array(count);
  
    var binary = intToBinary(int_value);
  
    while (binary.length < count)
      binary = "0" + binary;
    if (binary.length >= count)
      for (var i = 0; i < count; i++)
        ret[i] = (binary.charAt(count - 1 - i) == '1');
  
    return ret;
  }

  function parseArrayInt(array) {
    let ret = -1;
    let count = array.length - 1;
    let binary = "";
    for (let i = count; i >= 0; i--)
      binary += array[i] ? 1 : 0;
  
    if (binary.length > 0)
      ret = binaryToInt(binary);
    return ret;
  }
  
  function intToBinary(dec) {
      return (dec >>> 0).toString(2);
  }
  
  
  function binaryToInt(dec) {
      return parseInt((dec).toString(2), 2);
  }
 
  

 

  const selectDate = (key) => {
    let data = [...anyDateData];
    data[key].isSelected = !data[key].isSelected;
    setAnyDateData(data);
  };



  return (
    <div style={{ marginTop: "10px" }}>
     
      <ButtonGroup  
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {anyDateData.slice(0, 7).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.icondayButtonActiveSchedule
                  : classes.controlPanelIcondayButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ButtonGroup      
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {anyDateData.slice(7, 14).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.icondayButtonActiveSchedule
                  : classes.controlPanelIcondayButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ButtonGroup
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {anyDateData.slice(14, 21).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.icondayButtonActiveSchedule
                  : classes.controlPanelIcondayButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ButtonGroup
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {anyDateData.slice(21, 28).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.icondayButtonActiveSchedule
                  : classes.controlPanelIcondayButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ButtonGroup
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {anyDateData.slice(28, 31).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.icondayButtonActiveSchedule
                  : classes.controlPanelIcondayButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
    
    </div>
  );
}
