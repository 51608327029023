import ReactSpeedometer from "react-d3-speedometer"
import NowCo2RateConsumption from "../../PowerConsumption/co2/NowCo2RateConsumption"
import { useState, useEffect } from "react";


function NowSpeedo(props) {
const [meterValue, setMeterValue] =useState("");

//CarbonFootPrint Value
const carbonFootValue = props.energy.Project.Energy._attributes.CarbonFootprint;

let maxMeterValue = (Number(props.widget.params._attributes.max_hourly_w)/1000)*carbonFootValue;

let meterData = ((meterValue/1000)*carbonFootValue).toFixed(2);

let meterResult = meterData >= 9999 ? 9999 : meterData;

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.current_power); 
       }
    }
  }, [props.powerData]);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
         <h6 style={{margin: '0px' }}>{props.language.now}</h6>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxValue={maxMeterValue}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterResult} kg CO2`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={180}
                height={120}
               dimensionUnit="px"
/>

        <NowCo2RateConsumption
          powerData={props.powerData}
          powerDataConsumed={props.powerDataConsumed}
          data={props.data}
          widget={props.widget}
          energy={props.energy}
          language={props.language}
           />
  
        </div>
    );
}

export default NowSpeedo;