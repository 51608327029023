import React, { useEffect } from "react";
import { useState } from "react";
// Assets Import //
import scenes from "assets/img/scenes.png";
import {
  AirbnbSlider,
  AirbnbThumbComponent,
  ValueLabelComponent,
} from "components/Widgets/Lighting/slider.js";

// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography, Grid } from "@material-ui/core";

// CSS Style sheets imported locally //
import styles from "assets/widgets/widgetStyles/commonStyles";

const useStyles = makeStyles(styles);

//function //
export default function Editsceneslive({
  data,
  getAppConfigData,
  _attributes,
  livechanges,
  handleSocketEventChange,
  language,
  mainarraykey,
  setReturnValueUpdate,
  setLevelvalueupdate,
  setTotalDuration,
  cbusEvent,
  initialCbusEvent,
  socketEventDataChanger,
  listofItem,
  sceneKey,
  cancelbtn,
  isCancel,
  handleChangedItem
}) {

  const classes = useStyles();
  const initialData = initialCbusEvent.get(
    "0x" + Number(_attributes.app).toString(16)
  );
  const [levelvalue, setLevelvalue] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  let [durationHours, setDurationHours] = React.useState(0);
  let [durationMinutes, setDurationMinutes] = React.useState(
    0
  );
  let [durationSeconds, setDurationSeconds] = React.useState(0);
  const [hoursval, setHoursval] = React.useState(0);
  const [minval, setMinval] = React.useState(
    Math.round(0)
  );
  const [secval, setSecval] = React.useState(0);
  const [rampvalue, setRampvalue] = React.useState(0);
  const [returnvalue, setReturnvalue] = React.useState(0);

  useEffect(()=>{
    setLevelvalue( _attributes.Level === undefined
      ? 0
      : Math.round((parseInt(_attributes.Level) / 255) * 100));
      let newlevelforcheck = _attributes.Level === undefined
      ? 0
      : Math.round((parseInt(_attributes.Level) / 255) * 100); 
      
      
      let newduration = _attributes.Duration === undefined ? 0 : parseInt(_attributes.Duration);
      setDuration(_attributes.Duration === undefined ? 0 : parseInt(_attributes.Duration));
      setDurationHours(Math.round(newduration / 3600));
      setDurationMinutes(Math.round((newduration % 3600) / 60));
      setDurationSeconds(newduration % 60);
      setHoursval(Math.round(newduration / 3600));
  setMinval(
    Math.round((newduration % 3600) / 60)
  );
  setSecval(newduration % 60);
setRampvalue(
    _attributes.RampRate === undefined ? 0 : parseInt(_attributes.RampRate)
  );
setReturnvalue(
    _attributes.ReturnLevel === undefined
      ? 0
      : Math.round((parseInt(_attributes.ReturnLevel) / 255) * 100)
  );

  handleChangedItem(listofItem[sceneKey]);
  },[_attributes, cancelbtn, isCancel])
  let displayRampValue = [];
  displayRampValue[0] = `${language.instantaneous}`;
  displayRampValue[1] = `4${language.sec}`;
  displayRampValue[2] = `8${language.sec}`;
  displayRampValue[3] = `12${language.sec}`;
  displayRampValue[4] = `20${language.sec}`;
  displayRampValue[5] = `30${language.sec}`;
  displayRampValue[6] = `40${language.sec}`;
  displayRampValue[7] = `1${language.min}`;
  displayRampValue[8] = `1${language.min} 30${language.sec}`;
  displayRampValue[9] = `2${language.min}`;
  displayRampValue[10] = `3${language.min}`;
  displayRampValue[11] = `5${language.min}`;
  displayRampValue[12] = `7${language.min}`;
  displayRampValue[13] = `10${language.min}`;
  displayRampValue[14] = `15${language.min}`;
  displayRampValue[15] = `17${language.min}`;

  let rampCurrentValue = [];
  rampCurrentValue[0] = 0;
  rampCurrentValue[6.66] = 1;
  rampCurrentValue[13.32] = 2;
  rampCurrentValue[19.98] = 3;
  rampCurrentValue[26.64] = 4;
  rampCurrentValue[33.33] = 5;
  rampCurrentValue[39.96] = 6;
  rampCurrentValue[46.62] = 7;
  rampCurrentValue[53.28] = 8;
  rampCurrentValue[59.94] = 9;
  rampCurrentValue[66.6] = 10;
  rampCurrentValue[73.26] = 11;
  rampCurrentValue[79.92] = 12;
  rampCurrentValue[86.58] = 13;
  rampCurrentValue[93.24] = 14;
  rampCurrentValue[100] = 15;
  //let scenesData = [...data.Project.Scenes.Scene];
  // 
  const [iosSwitch, setIosSwitch] = React.useState({ checked: true });
  const handleIOSSwitch = (event) => {
    setIosSwitch(event.target.checked);
  };
  const handleHourChange = (event) => {
    setHoursval(event.target.value);
    setTotalDuration(event.target.value * 3600 + minval * 60 + secval);
    let totalTimeDuration = event.target.value * 3600 + minval * 60 + secval;
    if(!Array.isArray(listofItem[sceneKey].Items.Item)){ 
      listofItem[sceneKey].Items.Item._attributes.Duration = totalTimeDuration.toString();
    } else {
      listofItem[sceneKey].Items.Item[mainarraykey]._attributes.Duration = totalTimeDuration.toString();
    }
    handleChangedItem(listofItem[sceneKey]);
  };

  const handleMinChange = (event) => {
    setMinval(event.target.value);
    setTotalDuration(hoursval * 3600 + event.target.value * 60 + secval);
    let totalTimeDuration = hoursval * 3600 + event.target.value * 60 + secval;
    if(!Array.isArray(listofItem[sceneKey].Items.Item)){ 
      listofItem[sceneKey].Items.Item._attributes.Duration = totalTimeDuration.toString();
    } else {
      listofItem[sceneKey].Items.Item[mainarraykey]._attributes.Duration = totalTimeDuration.toString();
    }
    handleChangedItem(listofItem[sceneKey]);
  };

  const handleSecChange = (event) => {
    setSecval(event.target.value);
    setTotalDuration(hoursval * 3600 + minval * 60 + parseInt(event.target.value));
    let totalTimeDuration = hoursval * 3600 + minval * 60 + parseInt(event.target.value);
    if(!Array.isArray(listofItem[sceneKey].Items.Item)){ 
      listofItem[sceneKey].Items.Item._attributes.Duration = totalTimeDuration.toString();
    } else {
      listofItem[sceneKey].Items.Item[mainarraykey]._attributes.Duration = totalTimeDuration.toString();
    }
    handleChangedItem(listofItem[sceneKey]);
  };

  const handleLevelChange = (event, newValue) => {
    if (livechanges) {
      let newlevel = Math.round(newValue * 2.55);
      
      let _attributesNew = _attributes;
      let AppNumber = _attributes.AppNo;
      let GroupNumber = _attributes.GroupAdd;
      let newRampnumber = "0x" + 0;
      if(!Array.isArray(listofItem[sceneKey].Items.Item)){ 
        listofItem[sceneKey].Items.Item._attributes.Level = newlevel.toString();
      } else {
        listofItem[sceneKey].Items.Item[mainarraykey]._attributes.Level = newlevel.toString();
      }
      let levelChange = {
        cmd:
          '<cbus_cmd app="' +
          AppNumber +
          '" command="cbusSetLevel"  network="' +
          _attributes.Network +
          '" numaddresses="1" group="' +
          GroupNumber +
          '" level="' +
          newlevel +
          '" ramp="' +
          newRampnumber +
          '"/>',
      };

      
      handleSocketEventChange(levelChange);
    }
    let newlevel = Math.round(newValue * 2.55);
    if(!Array.isArray(listofItem[sceneKey].Items.Item)){ 
      listofItem[sceneKey].Items.Item._attributes.Level = newlevel.toString();
    } else {
      listofItem[sceneKey].Items.Item[mainarraykey]._attributes.Level = newlevel.toString();
    }
    handleChangedItem(listofItem[sceneKey]);
    setLevelvalue(newValue);
    setLevelvalueupdate(newlevel);
  };

  const handleNewRamp = (event, newValue) => {
    if (event.keyCode  === 37 || event.keyCode  === 38 || event.keyCode  === 39 || event.keyCode  === 40) {
      event.preventDefault();
    }
    else {
    let rampval;
    if (newValue === 0) {
      rampval = "0";
    } else if (newValue === 6.66) {
      rampval = "1";
    } else if (newValue === 13.32) {
      rampval = "2";
    } else if (newValue === 19.98) {
      rampval = "3";
    } else if (newValue === 26.64) {
      rampval = "4";
    } else if (newValue === 33.33) {
      rampval = "5";
    } else if (newValue === 39.96) {
      rampval = "6";
    } else if (newValue === 46.62) {
      rampval = "7";
    } else if (newValue === 53.28) {
      rampval = "8";
    } else if (newValue === 59.94) {
      rampval = "9";
    } else if (newValue === 66.6) {
      rampval = "10";
    } else if (newValue === 73.26) {
      rampval = "11";
    } else if (newValue === 79.92) {
      rampval = "12";
    } else if (newValue === 86.58) {
      rampval = "13";
    } else if (newValue === 93.24) {
      rampval = "14";
    } else {
      rampval = "15";
    }

    setRampvalue(rampval);
    if(!Array.isArray(listofItem[sceneKey].Items.Item)){ 
      listofItem[sceneKey].Items.Item._attributes.RampRate = rampval.toString();
    } else {
      listofItem[sceneKey].Items.Item[mainarraykey]._attributes.RampRate = rampval.toString();
    }
    handleChangedItem(listofItem[sceneKey]);
  }  
  };

  const handleReturnLevelChange = (event, newValue) => {
    let newlevel = Math.round(newValue * 2.55);
    if(!Array.isArray(listofItem[sceneKey].Items.Item)){ 
      listofItem[sceneKey].Items.Item._attributes.ReturnLevel = newlevel.toString();
    } else {
      listofItem[sceneKey].Items.Item[mainarraykey]._attributes.ReturnLevel = newlevel.toString();
    }
    handleChangedItem(listofItem[sceneKey]);
    setReturnvalue(newValue);
    setReturnValueUpdate(newlevel);
  };

  const marks = [
    {
      value: 0,
    },
    {
      value: 6.66,
      techval: 1,
    },
    {
      value: 13.32,
    },
    {
      value: 19.98,
    },
    {
      value: 26.64,
    },
    {
      value: 33.33,
    },
    {
      value: 39.96,
    },
    {
      value: 46.62,
    },
    {
      value: 53.28,
    },
    {
      value: 59.94,
    },
    {
      value: 66.6,
    },
    {
      value: 73.26,
    },
    {
      value: 79.92,
    },
    {
      value: 86.58,
    },
    {
      value: 93.24,
    },

    {
      value: 100,
    },
  ];

  const keyb  = function(e) {
    switch (e.keyCode) {
        case 37:
            alert('left');
            break;
        case 38:
            alert('up');
            break;
        case 39:
            alert('right');
            break;
        case 40:
            alert('down');
            break;
    }
  };

  // const handleKeyPress = (event) => {
  //   if(event.key === '37'){
  //     
  //   }
  //   if(event.key === '39'){
  //     
  //   }
  // }

  let newra =
    rampvalue === 0 || rampvalue === "0" || rampvalue === undefined
      ? 0
      : rampvalue;
  let newdisplay = displayRampValue[newra];

  let disphr =
    hoursval !== 0 ? hoursval + `${language.hour}` : `0${language.hour}`;
  let dispmin = minval !== 0 ? minval + `${language.min}` : `0${language.min}`;
  let dispsec = secval !== 0 ? secval + `${language.sec}` : `0${language.sec}`;

  React.useEffect(() => {
    if (cbusEvent) {
      if (cbusEvent.name === "cbus_event") {
        if (cbusEvent.attributes.name === "cbusSetLevel") {
          if (
            cbusEvent.attributes.group ===
            "0x" + Number(_attributes.GroupAdd).toString(16)
          ) {
            if (livechanges) {
              if (initialData) {
                
                initialData[_attributes.GroupAdd] = "0x0";
                let lightingData = new Map(initialCbusEvent);
                socketEventDataChanger(lightingData);
              }

              setLevelvalue(
                cbusEvent.attributes.level === undefined
                  ? 0
                  : Math.round(
                      (parseInt(cbusEvent.attributes.level, 16) / 255) * 100
                    )
              );
            }
          }
        }
      }
    }
  }, [cbusEvent, livechanges]);

  return (
    <>
      {_attributes && (
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="mainPannelSideedit"
        >
          <div className={classes.root}>
            <div className={classes.header}>
              <IconButton
                aria-label="previous"
                disabled="true"
                className={classes.headerIconButton}
              >
                <img src={scenes} className={classes.headerIcon} />
              </IconButton>
              <div className={classes.header1}>
                <Typography variant="h6" className={classes.headerText1}>
                  {_attributes.Name}
                </Typography>
              </div>
            </div>

            <div style={{ padding: "3%", borderBottom: "solid 2px black" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                <span>{language.level}</span>
                <span>{levelvalue + "%"}</span>
                {/* <span>0%</span> */}
              </div>
              <div style={{ padding: "0% 3% 1% 3%" }}>
                <AirbnbSlider
                  ThumbComponent={AirbnbThumbComponent}
                  ValueLabelComponent={ValueLabelComponent}
                  value={levelvalue}
                  //onChange={handleLevelChange}
                  onChangeCommitted={handleLevelChange}
                />{" "}
              </div>
            </div>

            <div style={{ padding: "3%", borderBottom: "solid 2px black" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                <span>{language.rampRate}</span>
                {/* <span>{(rampvalue === 0 || rampvalue === "0" || rampvalue === undefined)? displayRampValue[0] : displayRampValue[parseInt(rampvalue)]}</span> */}
                <span>{newdisplay}</span>
              </div>
              <div style={{ padding: "0% 3% 1% 3%" }}>
                {" "}
                <AirbnbSlider
                  ThumbComponent={AirbnbThumbComponent}
                  value={
                    rampvalue === 0 ||
                    rampvalue === "0" ||
                    rampvalue === undefined
                      ? 0
                      : (rampvalue / 15) * 100
                  }
                  //onChange={handleNewRamp}
                  onChangeCommitted={handleNewRamp}
                  //onKeyPress={keyb}
                  step={null}
                  marks={marks}
                />{" "}
              </div>
            </div>

            <div style={{ padding: "3%", borderBottom: "solid 2px black" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                <span>{language.duration}</span>
                <span>
                  {(hoursval === 0 ||
                    hoursval === "0" ||
                    hoursval === undefined) &&
                  (minval === 0 || minval === "0" || minval === undefined) &&
                  (secval === 0 || secval === "0" || secval === undefined)
                    ? "Instantaneous"
                    : `${disphr}:${dispmin}:${dispsec}`}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                <select
                  name="hours"
                  className="selectwithoptions"
                  value={hoursval}
                  onChange={handleHourChange}
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
                  }}
                >
                  <option value="0">00</option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>

                {":"}
                <select
                  name="min"
                  className="selectwithoptions"
                  value={minval}
                  onChange={handleMinChange}
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
                  }}
                >
                  <option value="0">00</option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                </select>
                {":"}
                <select
                  name="sec"
                  className="selectwithoptions"
                  value={secval}
                  onChange={handleSecChange}
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
                  }}
                >
                  <option value="0">00</option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                </select>
              </div>
            </div>

            <div style={{ padding: "3%", borderBottom: "solid 2px black" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                <span>{language.returnLevel}</span>
                <span>{returnvalue + "%"}</span>
              </div>
              <div style={{ padding: "0% 3% 1% 3%" }}>
                <AirbnbSlider
                  ThumbComponent={AirbnbThumbComponent}
                  value={returnvalue}
                  ValueLabelComponent={ValueLabelComponent}
                  //onChange={handleReturnLevelChange}
                  onChangeCommitted={handleReturnLevelChange}
                />
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
}
