import React from "react";
import { useState, useEffect } from "react";
import KWHCardSliderSet from './WidgetCardSlider/KWHCardSliderSet'
import DollarCardSliderSet from './WidgetCardSlider/DollarCardSliderSet'
import CO2CardSliderSet from './WidgetCardSlider/CO2CardSliderSet'
import BootstrapTooltip from 'assets/widgets/tooltip/tooltip'
import EditWidget from '../../editWidget'
//Assets //
import stats from '../../../../assets/img/stats-g24.png'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import classNames from "classnames"
import StarsIcon from '@material-ui/icons/Stars'
import EditIcon from '@material-ui/icons/Edit';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';


// style sheet imported locally // 
import styles from '../../../../assets/widgets/widgetStyles/commonStyles'
import PowerStats from "../PowerStats/PowerStats";
// import styles from "assets/js/all-styles/views/powerMeteringStyles.js";
const useStyles = makeStyles(styles);



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{padding: "0px 24px 24px"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function PowerMetering(props) {
  const initialData=props.initialCbusEvent.get("cbusInstantaneousPowerHistory"+props.widget.params._attributes.meterno);
  const initialData1=props.initialCbusEvent.get("meter"+props.widget.params._attributes.meterno);
  const classes = useStyles();
  const theme = useTheme();
  const [draweropen, setDraweropen] = React.useState(false);
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [favData, setFaData] =useState(props.favData);
  const [click1, setClick1]=useState(false);
  const [click2, setClick2]=useState(false);
  const [click3, setClick3]=useState(false);
  const [powerData, setPowerData] =useState("");
  const [powerDataConsumed, setPowerDataConsumed] = useState("");

  const defaultTab = props.widget.params._attributes.default_units

  const [value, setValue] = React.useState(defaultTab === "0" ? 0 : defaultTab === "1" ? 2 : 1 );

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const toggleDrawer1 = (open) => (event) => {
    setDraweropen1(open);
  };
  const updateFav = (fav) =>{
    setFaData(!fav);
    } 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClick1 =click=>setClick1(!click);
  
 const handleClick2 =(click)=>{
  setClick2(!click);
} 
const handleClick3 =(click)=>{
  setClick3(!click);
} 

useEffect(() => {
  setTimeout(() => {
    let meterIntialEvent = {
      cmd: "<cbus_cmd app=\"0x102\" command=\"cbusGetComboPowerHistory\" meter=\"" + props.widget.params._attributes.meterno + "\" />"
    };
    props.handleSocketEventChange(meterIntialEvent);
  }, []);   
  }, 500); 

useEffect(() => {
  // if (props.cbusEvent) {
  //   if (props.cbusEvent.name === "cbus_event") {
  //     if (props.cbusEvent.attributes.name === "cbusInstantaneousPowerHistory") {
       
  //       if (props.cbusEvent.attributes.meter === props.widget.params._attributes.meterno) {
  //         setPowerData(props.cbusEvent.attributes);
  //         if(initialData){
  //           props.initialCbusEvent.set("meter"+props.widget.params._attributes.meterno, props.cbusEvent.attributes);
  //           let powerData=new Map(props.initialCbusEvent);
  //           props.socketEventDataChanger(powerData);
  //         }
  //       }
  //     }
  //   }
  // }
  if(initialData){
    setPowerData(initialData);
  }
}, [initialData]);

useEffect(() => {
  if(initialData1){
    setPowerDataConsumed(initialData1);
  }
}, [initialData1]);


//Full Screen Drawer Info //
const scheduledrawer = () => (
  <>
    <div className={classes.powerStatsBg}>
      <div className={classes.powerStatsBody}>
        <div className={classes.powerStatsContent}>

  </div>
          
        <div className={classes.closeButton}>
          <button onClick={toggleDrawer1(false)} > 
            <CloseSharpIcon fontSize="small"/>
            {/* <CloseIcon onClick={()=>{props.toggleDrawer1(false)}} style={{display:'flex', alignSelf:'flex-end', width:'10%', color:'white' }} /> */}
            </button>
        </div>
      </div>
    
    <div className={classes.powerStatsWidget}>
      <PowerStats
       className="stats" 
       widgetCategory={props.widgetCategory}
       widget={props.widget}
       cbusEvent={props.cbusEvent}
       functionGroups={props.functionGroups}
       data={props.data}
       language={props.language}
       initialCbusEvent={props.initialCbusEvent}
       />,
      </div>
    </div>
  </>
);
  // Data assigned to consts from Redux Store // 
const id = props.widget._attributes.id;
const name = props.widget.params._attributes.label!=undefined ? props.widget.params._attributes.label:"";
const location = props.widget._attributes.location==="all" ||"none" ? "" : props.locations[Number(props.widget._attributes.location)]._attributes.Name;
const functionGroup = props.widget._attributes.functiongroup==="none" ? "" : props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name;


return (
  <>
  { props.data !== undefined &&
 <Grid item lg={4} md={6} sm={6} xs={12}  >
   <div className={classes.rootPowerMeter} >
   <BootstrapTooltip
         title={
           <React.Fragment>
             <IconButton
              className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
               aria-label="delete"
               onClick={() => {props.handleFavorite(id, props.widget._attributes.type);updateFav(favData);}}
               
             >
             <StarsIcon />
             </IconButton>
             <IconButton
               aria-label="delete"
               onClick={()=>{ toggleDrawer(true)}}
              
               style={{borderRadius: '0'}}
             >
               < EditIcon />
             </IconButton>
           </React.Fragment>
         }
       >

      <div className={classes.header} onClick={toggleDrawer1(true)}>
        <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton}>
          <img src={stats} alt={"stats"} className={classes.headerIcon}  />
        </IconButton>
        <div className={classes.header1} >
          <Typography variant='h6' align="left" className={classes.headerText1} >
           {name} 
          </Typography>
          <Typography variant='h6' align="left" className={classes.headerText2} >
          { props.widgetCategory==="functionGroup" ?  location : functionGroup }
          </Typography>
        </div>
      </div>

      </BootstrapTooltip>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
        className={classes.controlPanel}
        style={{ borderBottom: 'solid 2px #000',   marginBottom: '5px', width:'100%', '&:hover':{
    background: 'none',
  } }}
        TabIndicatorProps={{ style: { background: '#87d200' } }}
      >
        <Tab
          variant="contained"
          label={props.language.kWh}
          className={classes.controlPanelIconButton}
          {...a11yProps(0)}
          style={{ fontSize: '14px', flexGrow:'.33', minWidth: '10px', padding:'8% 0 8% 0', textTransform: 'none', fontWeight: '700', color: '#fff', borderRight:'solid 2px black' }}
        />

        <Tab
          label={props.widget.params._attributes.currency_units} 
          className={classes.controlPanelIconButton}
          {          ...a11yProps(1)}
          style={{ fontSize: '14px', minWidth: '10px', flexGrow:'.4', textTransform: 'none', padding:'8% 0 8% 0', fontWeight: '700', color: '#fff', borderRight:'solid 2px black' }}
        />
        <Tab
          label={props.language.co2}
          className={classes.controlPanelIconButton}
          {...a11yProps(2)}
          style={{ fontSize: '14px', minWidth: '10px', flexGrow:'.33', textTransform: 'none', fontWeight: '700', color: '#fff', borderRight:'none' }}
        />
      </Tabs>
    
        <TabPanel value={value} index={0} >
        <KWHCardSliderSet
        data={props.widget}
        widget={props.widget}
        powerData={powerData}
        powerDataConsumed={powerDataConsumed}
        energy={props.data}
        language={props.language}
         />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <DollarCardSliderSet 
         data={props.widget}
         widget={props.widget}
         powerData={powerData}
         powerDataConsumed={powerDataConsumed}
         energy={props.data}
         language={props.language}
        />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <CO2CardSliderSet
         data={props.widget}
         widget={props.widget}
         powerData={powerData}
         powerDataConsumed={powerDataConsumed}
         energy={props.data}
         language={props.language}
          />
        </TabPanel>
      <Drawer
          variant="temporary"
          anchor={"right"}
          open={draweropen}
          onClose={()=>{toggleDrawer(false)}}
          modal
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
        <EditWidget 
        saveEditedWidget={props.saveEditedWidget}
         data={props} 
         toggleDrawer={toggleDrawer} />
        </Drawer>
    </div>
    </Grid>
  }
        <Drawer
        variant="temporary"
        anchor={"right"}
        open={draweropen1}
        transitionDuration={{ enter: 700, exit: 1000 }}
        onClose={toggleDrawer1(false)}
        modal
        classes={{
          paper: classNames(classes.drawerPaper1),
        }}
        ModalProps={{
          keepMounted: false, // Better open performance on mobile.
        }}
      >
        {scheduledrawer()}
      </Drawer>
    </>
  );
}

