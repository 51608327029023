import React from 'react';
import { useState, useEffect } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
  import { curveCardinal } from 'd3-shape';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

const styles = {
  layout: {
    color: "#87d200",
    marginTop: "2vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
};


const DollarHourChart = (props) =>{
  const [data, setData] = React.useState(0);
  const [maxMeterValue,  setMaxMeterValue] = useState();
  const [tariff,  setTariff] = useState();

  let arrayOfAnalog = [];

  let arrayofPulse = [];

  let combineArray = [];

  if(props.energy.Project.MeasurementAppData !==undefined){
  props.energy.Project.MeasurementAppData.MeasurementChannel.map(obj => { 
    if(obj._attributes.Tariff!==undefined){
      arrayOfAnalog.push(obj._attributes.Tariff);
    }
  } ); }

  if(props.energy.Project.Energy.PulsePowerMeters !==undefined){
  props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter.map(obj => { 
    if(obj._attributes.Tariff!==undefined){
      arrayofPulse.push(obj._attributes.Tariff);
    }
  } ); }

  combineArray = [...arrayOfAnalog, ...arrayofPulse];

  useEffect(() => {
    if (props.energy) {
      if (props.energy.Project.Energy.Tariffs) {
        if (!Array.isArray(props.energy.Project.Energy.Tariffs.Tariff)) {
          let td = props.energy.Project.Energy.Tariffs.Tariff
          let tariffPrice = td._attributes.Price;
          setTariff(tariffPrice);
          let value =
            (props.widget.params._attributes.max_hourly_w) / 1000 *
            tariffPrice;
            setMaxMeterValue(+(value.toFixed(2)));
        } else {
          let tariffData = props.energy.Project.Energy.Tariffs.Tariff;
          let td = tariffData.filter(
            (x) =>
              x._attributes.id ===
              combineArray[+props.widget.params._attributes.meterno]
          );
          let tariffPrice = td[0]._attributes.Price;
          setTariff(tariffPrice);
          let value =
            (props.widget.params._attributes.max_hourly_w) / 1000 *
            tariffPrice;
            setMaxMeterValue(+(value.toFixed(2)));
        }
      }
    }
  }, []);

  //   //Tariff_general Value
  //   const tariff = props.energy.Project.Energy.Tariffs.Tariff[1]._attributes.Price;

  //   //Tariff_off Value
  // const tariff2 = props.energy.Project.Energy.Tariffs.Tariff[0]._attributes.Price;
  // const maxMeterValue = (Number(props.widget.params._attributes.max_hourly_w)/1000)*tariff;
  const midMeterValue = +((maxMeterValue/2).toFixed(2));
  useEffect(() => {
      if (props.powerDataHistory) {
      // let eventData= {"name":"cbus_event","attributes":{"app":"0x102","name":"cbusComboPowerHistory","type":"0","meter":"2","hourly_energy_history":"1800.00,1860.00,1850.00,1860.00,1700.00,1640.00,1680.00,1480.00,1120.00,1130.00,830.00,450.00,440.00,450.00,440.00,450.00,440.00,450.00,440.00,450.00,440.00,850.00,1130.00,1680.00,","daily_energy_history":"0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,11670.00,24620.00,6770.00,","weekly_energy_history":"0.00,0.00,0.00,43060.00,","monthly_energy_history":"0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,43060.00,","hourly_cost_history":"0.18,0.19,0.19,0.19,0.17,0.16,0.17,0.15,0.11,0.11,0.08,0.04,0.04,0.04,0.04,0.04,0.04,0.04,0.04,0.04,0.04,0.08,0.11,0.17,","daily_cost_history":"0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,1.17,2.46,0.68,","weekly_cost_history":"0.00,0.00,0.00,4.31,","monthly_cost_history":"0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,4.31,","current_power":"1139.24","current_cost":"0.11","current_power_hour":"1680.00","current_power_day":"6770.00","current_power_week":"43060.00","current_power_month":"43060.00","current_cost_hour":"0.17\"current_cost_day=\"0.68","current_cost_week":"4.31","current_cost_month":"4.31","predicted_power_hour":"1951.52","predicted_power_day":"22990.89","predicted_power_week":"125240.14","predicted_power_month":"76686.40","predicted_cost_hour":"0.20","predicted_cost_day":"2.30","predicted_cost_week":"12.52","predicted_cost_month":"7.67"},"children":[],"value":""};
      // let hourlyEnergy=eventData.attributes.hourly_energy_history.split(",");
        let hourlyEnergyReversed=props.powerDataHistory.hourly_cost_history.split(",").reverse();
        //hourlyEnergy.pop();
        let hourlyEnergy = hourlyEnergyReversed.slice(1,25);
        let hoursData=[];
        let hourz=[];
        for (let i = 0; i < 24; i++) {
           let hour = moment(new Date()).add(1, 'hours')
            .add(60 * i, 'minutes')
            .format('HH');
          hourz.push(hour);
        }
        hourz.reverse();
         for (let j = 0; j < hourlyEnergy.length; j++) {
          let hrsData = {
            name: null, $: null
          }
           hrsData.name = hourz[j]+":00";
           let $ = hourlyEnergy[j];
           if($>9999){
            hrsData.$ = maxMeterValue;
           }
          if($>0 && $<9999){
            hrsData.$ = $;
          }
           if($==0){
            hrsData.$ = 0;
           }
           hoursData.push(hrsData);
         }
        setData(hoursData.reverse());
    }
  }, [props.powerDataHistory]);
    const cardinal = curveCardinal.tension(0.2);
return (
    <div style={{ width: '120%', height: 150, fontSize: '12px' }}>
       <ResponsiveContainer>
     <AreaChart
        width={600}
        height={100}
        data={data}
        margin={{
          top: 0, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis 
        ticks={[0, midMeterValue, maxMeterValue]}
        domain={[0, maxMeterValue]} 
        />
        <Tooltip />
        <Area type="monotone" dataKey=" " stroke="#000" fill="#87d200" fillOpacity={0.3} />
        <Area type={cardinal} dataKey="$" stroke="#000" fill="#87d200" fillOpacity={0.3} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
)
}
export default DollarHourChart;


