/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { ICON_URL } from "../../../config/environment";
// Assets Import //
import bulbWhite from "../../../assets/img/bulbWhite.png";
import bulbGreen from "../../../assets/img/bulbGreen.png";
import IOSSwitch from "./switch";
import BootstrapTooltip from "../../../assets/widgets/tooltip/tooltip";
import {
  AirbnbThumbComponent,
} from "./slider";
// Components Imported Locally //
import EditWidget from "../editWidget";

// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  FormGroup,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";

// CSS Style sheets imported locally //
import styles from "../../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function //
export default function GeneralLightingRelay(props) {
  const initialData=props.initialCbusEvent.get("0x"+Number(props.widget.params._attributes.app).toString(16));
  const classes = useStyles();
  const [checkedC, setCheckedC] = React.useState(false)
  // const [checkedC, setCheckedC] = React.useState(
  //   initialData ?
  //   initialData[props.widget.params._attributes.ga]==="0x0" ? false : true
  //   :
  //   false
  // );
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };
  
  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
        if (props.cbusEvent.attributes.app ==="0x" + Number(props.widget.params._attributes.app).toString(16)) {
          if (props.cbusEvent.attributes.group === "0x"+Number(props.widget.params._attributes.ga).toString(16)) {
              if (props.cbusEvent.attributes.level == "0x0") {
                setCheckedC(false);
                if(initialData){
                  initialData[props.widget.params._attributes.ga]="0x0";
                  let lightingData=new Map(props.initialCbusEvent);
                  props.socketEventDataChanger(lightingData);
                }
              } else {
                setCheckedC(true);
                if(initialData){
                initialData[props.widget.params._attributes.ga]="0xff";
                let lightingData=new Map(props.initialCbusEvent);
                props.socketEventDataChanger(lightingData);
                }
              }
            }
          }
        }
      }
    }
    if(initialData){
      if(initialData[props.widget.params._attributes.ga]==="0x0") {
        setCheckedC(false);
      } else  {
        setCheckedC(true);
      }
    }
  }, [props.cbusEvent]);

  const updateFav = (fav) => {
    setFaData(!fav);
  };


  const handleSwitchChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    setCheckedC(event.target.checked);
    if (event.target.checked === true) {
      let on = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="255" ramp="0"/>',
      };
      props.handleSocketEventChange(on);
    } else {
      let off = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(off);
    }
  };
  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
  (props.widget._attributes.location === "all" || props.widget._attributes.location ==="none")
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;
  return (
    <>
      {props.data !== undefined && (
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
           
        >
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                     className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                    style={{
                      borderRight: "2px solid silver",
                      borderRadius: "0",
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                    style={{ borderRadius: "0" }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={ checkedC ?ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png" }
                    className={classes.headerIcon}
                  />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={
                       checkedC
                        ? `${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
                <FormGroup>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={0}
                    >
                      <Grid item>
                        <IOSSwitch
                          ThumbComponent={AirbnbThumbComponent}
                          checked={checkedC}
                          onChange={handleSwitchChange}
                          name="checkedC"
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </FormGroup>
              </div>
            </BootstrapTooltip>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
