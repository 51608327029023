
const editscenestyle = theme => ({
    root: {
            display: "flex !important",
            flexDirection:'column',
            margin: '5% 0%', 
            backgroundColor: '#000000',
            backgroundImage: 'linear-gradient(#393939, #1b1b1b) !important',
            textTransform: 'none',   
        },
    '&.Mui-selected': {
            outline: 'none',                                                                   
          }, 

          img: {
            width:"100%",
            height:"100%",
            borderRadius: "50%",
            //border: "3px solid #538c0d"
          },
          imgcontainer:{
            width: "80px",
            height: "80px",
            position:"relative",
            cursor:"pointer",
            textAlign:"center"
            
          },
          drawerPaper: {
            border: "none",
            position: "fixed",
            top: "0",
            bottom: "0",
            right: "0",
            zIndex: "1",
          
            width: 400,
            height: "100%",
            background:"#232323"
         
          },
        
          featureButton:{
            
                background: "none !important",
                border: "none",
                padding: "0 !important",
                color:"#fff",
                borderTop:"none",         
                textDecoration: "underline",
                cursor: "pointer",
                "&:disabled":{
                  color:"#8C8C8C",
                  cursor:"default"
                }
              
      
          },
          drawerbtn: {
            height:"42px",
            padding: "10px 60px",
            color: "#fff",    
            marginRight: "25px",
            background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
            borderStyle: "solid",
            border: "0",
            borderTop: "1px solid #a5a5a5",
            fontWeight: "600",
            cursor: "pointer",
            "&:disabled":{
              color:"#8C8C8C",
              cursor:"default"
            }
          },
    header:{
        display: "flex",
        flexDirection: "row",
        alignItems:'center',
        backgroundImage: 'linear-gradient(#393939, #1b1b1b)',
        borderTop:'solid 1px #747474',
        fontStyle: 'helvetica',
        padding:'2%',
        paddingLeft:'0',
        borderBottom:'solid 2px black' 
    },
    root: {
      display: "flex !important",
      flexDirection: "column",
      margin: "5%",
      backgroundColor: "#000000",
      background: "linear-gradient(0deg, rgba(27, 27, 27, 1) 0%, rgba(58, 57, 58, 1) 100% )",
      textTransform: "none",
    },
    headerOff:{
        display: "flex",
        flexDirection: "row",
        alignItems:'center',
        backgroundImage: 'linear-gradient()',
        borderTop:'solid 1px #747474',
        fontStyle: 'helvetica',
        padding:'2%',
        paddingLeft:'0',
        borderBottom:'solid 2px black' 
    },
    headerIconButton:{
        width:'15%', 
        padding:'0 !important',
        borderRadius:'none',
        borderRadius:'0'   
    },
    headerIcon: {
        height: '8%',
        paddingLeft:'0',
        width:'80%',
        },
    header1:{
        display:'flex',
        flexDirection: 'column',
        lineHeight:'1',
        paddingBottom:'.5%',
        flexGrow:'1',
        },
    headerText1:{
        color:'#bfbfbf',
        fontWeight:'520',
        textTransform:'none',
        padding:'none',
        width:'100%',
        alignItems:'left',
        justifyContent:'flex-start',
        justifyItems:'flex-start' 
        },
    headerText1Active:{
        color:'#86D100',
        fontWeight:'520',
        textTransform:'none',
        padding:'none',   
        width:'100%',
        alignItems:'left',
        justifyContent:'flex-start',
        justifyItems:'flex-start' 
            },
    headerText2:{
        color:'#737373',
        textTransform:'none',
        padding:'none',
        wordBreak:'break-all', 
        overflowWrap: 'break-word', 
        width:'100%'
        },
    headerRightIconButton:{
            width:'15%', 
            padding:'3%',
            disabled:'true',
            borderRadius:'none',
            borderRadius:'0',
            borderLeft:'2px solid black',
            '&:hover':{
              background: 'linear-gradient(#4C4C4C, #323232) !important',
            }         
          },
    headerRightIcon:{
            width:'100%', 
            padding:'3%',
            disabled:'true',
            borderRadius:'none',
            borderRadius:'0'         
          },
    bottomPanelIcon:{
          width:'10%',   
          paddingTop:'2% !important',
          paddingBottom:'2% !important',
          paddingLeft:'15%'
            },
    controlPanel:{
          display:'flex',
          flexDirection:'row',
          borderRadius:"0",
          backgroundImage:'linear-gradient(#393939, #1b1b1b) !important'
          },
    controlPanelIcon:{
        display:'flex', 
        width:'50%', 
        padding:'15%',
        borderRadius:'0' 
    },
    controlPanelIconButton:{
      display:'flex', 
      width:'25%', 
      padding:'0%',
      backgroundImage: 'linear-gradient(#393939, #1b1b1b) !important',
      borderRight:'solid 2px black',
      borderTop:'solid 1px #747474',
      borderBottom:'solid 2px black',
      borderRadius:'0',
      '&:hover':{
        background: 'linear-gradient(#4C4C4C, #323232) !important',
      }
    },
    controlPanelIconButtonActive:{
      display:'flex', 
      width:'25%', 
      padding:'0%',
      backgroundImage: 'linear-gradient(#181818, #679319) !important',
      borderRight:'solid 2px black',
      borderTop:'solid 1px #747474',
      borderBottom:'solid 2px black',
      borderRadius:'0',
      '&:hover':{
        background: 'linear-gradient( #393939, #578800) !important',
      }
    },
    numberControlPanelActive:{
      display:'flex',
      flexDirection:'row',
      backgroundColor:'#2b2b2b !important',
      backgroundImage:'#2b2b2b !important', 
      borderBottom:'solid 2px black', 
      borderTop:'solid 1px #747474', 
    },
    numberControlPanel:{
      display:'flex',
      flexDirection:'row',
      backgroundImage:'#151515 !important',
      backgroundColor:'#151515 !important',  
      borderBottom:'solid 2px black', 
      borderTop:'solid 1px #747474', 
    },
    musicPanelCentralIconButton:{
        display:'flex', 
        width:'25%', 
        padding:'0%',
     
        borderRadius:'0'
    },
    musicPanelCentralIcon:{
        width:'80%', 
        padding:'15%'
    },
    Icon1:{
          display:'flex', 
          width:'25%', 
          padding:'0%',
          borderRight:'solid 2px black',
          borderRadius:'0' 
        },
    Icon1Right:{
        display:'flex', 
        width:'25%', 
        padding:'0%',
        borderRight:'none',
        borderRadius:'0' 
    },
    Icon1Active:{
            display:'flex', 
            backgroundImage:'linear-gradient(black, lime)',
            borderRadius:'0',
            outlineColor:'black',
            borderRight:'solid 2px black',
            width:'25%', 
            padding:'0%' 
          },
    Icon1ActiveRight:{
            display:'flex', 
            backgroundImage:'linear-gradient(black, lime)',
            borderRadius:'0',
            outlineColor:'black',
            borderRight:'none',
            width:'25%', 
            padding:'0%' 
          },
    Icon2:{
          display:'flex', 
          outline:'medium !important', 
          width:'25%', 
          padding:'3%',
        },
    controlTypo1:{ 
         display:'flex',
         flexGrow:'1',
         justifyContent:'center', 
         color:'#727272', 
         align:'center' , 
         height:'20%', 
         width:'15%', 
         padding:'5%', 
         alignSelf:'auto'
        },
    controlTypo1Active:{ 
            display:'flex',
            flexGrow:'1',
            justifyContent:'center', 
            color:'#a9a9a9', 
            align:'center' , 
            height:'20%', 
            width:'15%', 
            padding:'5%', 
            alignSelf:'auto'
           },
    controlIcons:{
          display:'flex',
          height: '30%',
          width: '50%',
          color: '#747474',
          padding:'4%',
          },
    controlIconsActive:{
            display:'flex',
            height: '30%',
            width: '50%',
            color: 'white',
            padding:'4%',
            color:'#d1d1d1'
            },
    bottomPanel: {
              display:'flex',
              flexDirection:'row',
              paddingTop:'4%',
              paddingBottom:'4%',
              borderTop:'solid 1px #747474',
              borderBottom:'solid 1px black',
              backgroundImage: 'linear-gradient(#505050, #3b3b3b)',
              borderRadius:'0 !important',
              '&:hover':{
                background: 'linear-gradient(#515151, #414141) !important',
              }
              },
    bottomPanelActive: {
                display:'flex',
                flexDirection:'row',
                paddingTop:'4%',
                paddingBottom:'4%',
                borderTop:'solid 1px #747474',
                borderBottom:'solid 1px black',
                backgroundImage: 'linear-gradient(#060606, #252525) !important',
                borderRadius:'0 !important',
                borderRadius:'none',
                '&:hover':{
                  background: 'linear-gradient(#4C4C4C, #323232) !important',
                }
                },
    bottomPanelScenes: {
                display:'flex',
                flexDirection:'row',
                backgroundImage: 'linear-gradient(#393939, #1b1b1b)',
                borderBottom:'solid 2px black',
                },
    bottomPanel2Button:{
              width:'50%',
              borderRadius:'0',
              borderRight:'2px solid black',
              backgroundImage: 'linear-gradient(#393939, #1b1b1b)',
              textTransform: 'none',
              display:'flex',
              flexDirection:'row',
              padding:'7%',
              '&:hover':{
                background: 'linear-gradient(#4C4C4C, #323232) !important',
              }  
             },
    bottomPanel3Button:{
              width:'33%',
              borderRadius:'0',
              borderRight:'2px solid black',
              backgroundImage: 'linear-gradient(#393939, #1b1b1b)',
              textTransform: 'none',
              display:'flex',
              flexDirection:'row',
              padding:'7%',
              '&:hover':{
                background: 'linear-gradient(#4C4C4C, #323232) !important',
              }  
             },
    bottomPanel1Button:{
              width:'100%',
              borderRadius:'0',
              backgroundImage: 'linear-gradient(#393939, #1b1b1b)',
              textTransform: 'none',
              display:'flex',
              flexDirection:'row',
              padding:'6%',
              '&:hover':{
                background: 'linear-gradient(#4C4C4C, #323232) !important',
              } 
             },
    
    bottomPanel2ButtonIcon:{
     width:'25%',
     },
     bottomPanelIconAlarm:{
              width:'12%', 
              justifyContent:'flex-start',
              paddingLeft:'25%',
              paddingTop:'1%',
              paddingBottom:'1%'
              },
      mp3BottomPanel:{
          flexDirection:'row'
      },
    iconBG:{
          color:'#92E300',
         },
    iconBG2:{
          backgroundColor: null
        },
    drawerPaper: {
            border: "none",
            position: "fixed",
            top: "0",
            bottom: "0",
            right: "0",
            zIndex: "1",
            width: 460,
            height: "100%",
            background:"#232323"
          },
    featureButton:{
                background: "none !important",
                border: "none",
                padding: "0 !important",
                textDecoration: "underline",
                cursor: "pointer"
          },
    drawerInput:{  
            width: "70%",
            height: "34px",
            background: "#1f1f1f",
            color:'white'
          },
          drawerInputDiv:{
            display: "flex", 
            flexDirection: "column", 
            padding: "15px", 
            backgroundImage:'#1b1b1b' 
          },
          headerButton:{
            width:'25%', 
            paddingTop:'2%', 
            paddingBottom:'2%',
            alignSelf:'center', 
            textTransform:'none',
            borderRadius:"0 !important",
            backgroundImage: 'linear-gradient(#7C7C7C, #414141)',      
             '&:hover':{
              background: 'linear-gradient(#5A5A5A, #393939) !important',
            } 
          },
          topButton: {
            height:'80%',
            backgroundImage: 'linear-gradient(#393939, #1b1b1b) !important',
            backgroundColor: 'linear-gradient(#393939, #1b1b1b)',
            textTransform: 'none',
            display:'flex',
            flexDirection:'row',
            padding:'3%',
            alignSelf:'center',
            '&:hover':{
              background: 'linear-gradient(#4C4C4C, #323232) !important',
            }
           },
           topButtonText:{
            color:'#A9A9A9',
            textTransform: 'none',
           },
           bottomPanel2ButtonText:{
            color:'#A9A9A9',
            textTransform: 'none',
           },
           bottomButton: {
            height:'20%',
            backgroundImage: 'linear-gradient(#393939, #1b1b1b)',
            textTransform: 'none',
            display:'flex',
            flexDirection:'row',
            padding:'7%',  
            '&:hover':{
              background: 'linear-gradient(#4C4C4C, #323232) !important',
            } 
            },
            SwitchText:{
              textTransform:'none',
              color:'#A9A9A9',
              fontSize:'h3',
              
            },
            powerOn:{
              opacity:''
            },
            powerOff:{
              opacity:'40%'
            },
            editWidgetRoot:{
              background: "#535353" 
            },
            editWidgetHeader:{
              padding: "25px 10px 5px 15px"
            },
            editWidgetHeaderText1:{
              color: "#C0C0C0", 
              fontWeight: "600", 
              margin: "0" 
            },
            editWidgetHeaderText2:{
              color: "#707070", 
              fontWeight: "600", 
              margin: "0" 
            },
            editWidgetHeaderDivider:{
              margin: "10px 0" 
            },
            editWidgetButtonDiv:{
              display: "flex", 
              padding: "15px"
            },
            editWidgetButton1:{
              padding: "10px 30px",
              color: "#fff",
              background: "#616161",
              marginRight: "25px",
            },
            editWidgetButton2:{         
              padding: "10px 30px",
              color: "#898989",
              background: "#434343",
            },
            editWidgetPanel:{
              display: "flex", 
              flexDirection: "column", 
              padding: "15px", 
              backgroundImage: '#1b1b1b'
            },
            editWidgetLabel:{
              color: "#b4b4b4", 
              fontSize: '22px' 
            },
            editWidgetInput:{
              width: "70%",
              height: "32px",
              background: "#1f1f1f",
              color: 'white'
            },
            headerIconLogic:{
              height: '8%',
              padding:'15%',
              width:'80%',
    
    
            }
            
        
    
    
    });
    
    // export component// 
    export default editscenestyle;