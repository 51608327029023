import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { validate } from 'fast-xml-parser';
import { bindActionCreators } from 'redux';
import { getAppConfigData, socketEventDataChanger, cbusEventChanger } from '../redux/actions/action'
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import XMLParser from "react-xml-parser";
import { SOCKET_URL } from "../config/environment"
import { updateSocketEvent, connectToDevice } from "../services/xmlService";

const Main = (props) => {
  const hex2bin = (hex) => {
    return (parseInt(hex, 16).toString(2)).padStart(8, '0');
  }
  const handleSocketEvents = (appno, data) => {
    {
      switch (appno) {
        case "0x30":
        case "0x31":
        case "0x32":
        case "0x33":
        case "0x34":
        case "0x35":
        case "0x36":
        case "0x37":
        case "0x38":
        case "0x39":
        case "0x3a":
        case "0x3b":
        case "0x3c":
        case "0x3d":
        case "0x3e":
        case "0x3f":
        case "0x40":
        case "0x41":
        case "0x42":
        case "0x43":
        case "0x44":
        case "0x45":
        case "0x46":
        case "0x47":
        case "0x48":
        case "0x49":
        case "0x4a":
        case "0x4b":
        case "0x4c":
        case "0x4d":
        case "0x4e":
        case "0x4f":
        case "0x50":
        case "0x51":
        case "0x52":
        case "0x53":
        case "0x54":
        case "0x55":
        case "0x56":
        case "0x57":
        case "0x58":
        case "0x59":
        case "0x5a":
        case "0x5b":
        case "0x5c":
        case "0x5d":
        case "0x5e":
        case "0x5f":
        case "0x70":
        case "0x71":
        case "0x72":
        case "0x73":
        case "0x74":
        case "0x75":
        case "0x88":
          if (data.attributes.command === "cbusGetLevel") {
            props.initialEventData.set(data.attributes.app, data.attributes.level.split(","));
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          break;

        case '0xac':
          if (data.attributes.command === "cbusGetLevel") {
            props.initialEventData.set(data.attributes.app, data.attributes.level.split(","));
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          if (data.attributes.name === "hvac_zone_on" || data.attributes.name === "hvac_zone_off") {
            props.initialEventData.set("powerPlant", data);
            let hvacData = new Map(props.initialEventData);
            props.socketEventDataChanger(hvacData);
          } 
          if (data.attributes.name === "set_hvac_mode") {
              props.initialEventData.set(data.attributes.name, data);
              let hvacData = new Map(props.initialEventData);
              props.socketEventDataChanger(hvacData);
          } if (data.attributes.name === "hvac_plant" ) {
            const initialSetHvacMode = props.initialEventData.get("hvac_plant");
            if(initialSetHvacMode){
            if(data.attributes.hvac_mode == "0x0"){
              let binData = hex2bin(data.attributes.zone_list).split('').reverse();
              let rewriteInitialData = initialSetHvacMode;
              for (let i = 0; i < binData.length; i++) {
                if (binData[i] === "1") {
                  rewriteInitialData[i] = "0";
                }
              }
              props.initialEventData.set("hvac_plant", rewriteInitialData);
              let hvacData = new Map(props.initialEventData);
              props.socketEventDataChanger(hvacData);
            }else{
              let binData = hex2bin(data.attributes.zone_list).split('').reverse();
              let rewriteInitialData = initialSetHvacMode;
              for (let i = 0; i < binData.length; i++) {
                if (binData[i] === "1") {
                  rewriteInitialData[i] = "1";
                }
              }
              props.initialEventData.set("hvac_plant", rewriteInitialData);
              let hvacData = new Map(props.initialEventData);
              props.socketEventDataChanger(hvacData);
            }
          }else{
            let binData = hex2bin(data.attributes.zone_list).split('').reverse();
            props.initialEventData.set("hvac_plant", binData);
            let hvacData = new Map(props.initialEventData);
            props.socketEventDataChanger(hvacData);
          }
      }  if (data.attributes.name === "hvac_plant_status") {
            props.initialEventData.set(data.attributes.name, data);
            let hvacData = new Map(props.initialEventData);
            props.socketEventDataChanger(hvacData);
          }
          if (data.attributes.name === "zone_temp") {
            props.initialEventData.set(data.attributes.name, data);
            let hvacData = new Map(props.initialEventData);
            props.socketEventDataChanger(hvacData);
          }
          if (data.attributes.name === "cbusAirConRefresh") {
            props.initialEventData.set("airConRefresh", data);
            let hvacData = new Map(props.initialEventData);
            props.socketEventDataChanger(hvacData);
          }
          if (data.attributes.name === "cbusGetHVACZoneTemp") {
            props.initialEventData.set("HVACZoneTemp"+data.attributes.zone_list, data);
            let hvacData = new Map(props.initialEventData);
            props.socketEventDataChanger(hvacData);
          }
          break;

        case '0xc0':
          if (data.attributes.command === "cbusGetLevel") {
            props.initialEventData.set(data.attributes.app, data.attributes.level.split(","));
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          break;

        case '0xca':
          if (data.attributes.command === "cbusGetLevel") {
            props.initialEventData.set("cbusGetLevel" + data.attributes.app, data.attributes.level.split(","));
            let scenesTrigger = new Map(props.initialEventData)
            props.socketEventDataChanger(scenesTrigger);
          }
          if (data.attributes.command === "cbusGetTriggerStates") {
            props.initialEventData.set("cbusGetTriggerStates", data.attributes);
            let scenesTrigger = new Map(props.initialEventData)
            props.socketEventDataChanger(scenesTrigger);
          }
          if (data.attributes.command === "cbusGetTriggerStates") {
            props.initialEventData.set("analog", data.attributes.action_selectors.split(","));
            let analog = new Map(props.initialEventData)
            props.socketEventDataChanger(analog);
          }
          break;

        case '0xcb':
          if (data.attributes.command === "cbusGetLevel") {
            props.initialEventData.set(data.attributes.app, data.attributes.level.split(","));
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          break;

        case '0xcd':
          if (data.attributes.command === "cbusGetLevel") {
            props.initialEventData.set(data.attributes.app, data.attributes.level.split(","));
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          break;

        case "0xd0":
          if (data.attributes.command === "cbusSecGetStatuses") {
            props.initialEventData.set("cbusSecGetStatuses", data.attributes.statuses.split(","));
            let statusData = new Map(props.initialEventData)
            props.socketEventDataChanger(statusData);
          }
          if (data.attributes.command === "cbusSecGetArmedStatus") {
            props.initialEventData.set("cbusSecGetArmedStatus", data.attributes.arm_code);
            let armStatusData = new Map(props.initialEventData)
            props.socketEventDataChanger(armStatusData);
          }
          if (data.attributes.command === "cbusSecGetZoneStates") {
            props.initialEventData.set("cbusSecGetZoneStates", data.attributes.zone_states.split(","));
            let armStatusData = new Map(props.initialEventData)
            props.socketEventDataChanger(armStatusData);
          }
          break;

        case "0xdf":
          if (data.attributes.command === "cbusGetLevel") {
            props.initialEventData.set(data.attributes.app, data.attributes.level.split(","));
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          if (data.attributes.name === "cbusTimeChanged") {
            props.initialEventData.set("cbusTimeChanged", data);
            let cbusTimeChangedData = new Map(props.initialEventData)
            props.socketEventDataChanger(cbusTimeChangedData)
          }
          break;

        case "0x101":
          if (data.attributes.command === "cbusGetUserSystemIOBool") {
            props.initialEventData.set("usio_var" + data.attributes.sio_index, data.attributes);
            let logicIoData = new Map(props.initialEventData)
            props.socketEventDataChanger(logicIoData);
          }
          if (data.attributes.command === "cbusGetUserSystemIOInt") {
            props.initialEventData.set("usio_var" + data.attributes.sio_index, data.attributes);
            let logicIoData = new Map(props.initialEventData)
            props.socketEventDataChanger(logicIoData);
          }
          if (data.attributes.command === "cbusGetUserSystemIOStr") {
            //console.log(JSON.stringify(data.attributes) + "--" + new Date());
            props.initialEventData.set("usio_var" + data.attributes.sio_index, data.attributes);
            let logicIoData = new Map(props.initialEventData)
            props.socketEventDataChanger(logicIoData);
          }
          if (data.attributes.command === "cbusGetUserSystemIOReal") {
            props.initialEventData.set("usio_var" + data.attributes.sio_index, data.attributes);
            let logicIoData = new Map(props.initialEventData)
            props.socketEventDataChanger(logicIoData);
          }
          break;

        case "0x102":
          if (data.attributes.name === "cbusPowerHistoryTotals") {
            props.initialEventData.set(data.attributes.name, data.attributes);
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          if (data.attributes.name === "cbusPowerTotals") {
            props.initialEventData.set(data.attributes.name, data.attributes);
            let cbusPowerTotalsData = new Map(props.initialEventData)
            props.socketEventDataChanger(cbusPowerTotalsData);
          }
          if (data.attributes.name === "cbusComboPowerHistory") {
            props.initialEventData.set("meter" + data.attributes.meter, data.attributes);
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          if (data.attributes.name === "cbusInstantaneousPowerHistory") {
            props.initialEventData.set("cbusInstantaneousPowerHistory" + data.attributes.meter, data.attributes);
            let lightingData = new Map(props.initialEventData)
            props.socketEventDataChanger(lightingData);
          }
          break;

      }
    }
  }

  const handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
  };

  const cbusDataManagement = (data) => {
    
    if (validate(data) === true) {
      if(data.includes("net_status")){
        props.getAppConfigData();
     }
     if(data.includes("xml_event")){
      props.getAppConfigData();
     }
    //  if(data.includes("cbusGetHVACZoneTemp")){
    //   console.log(data);
    //  }
      if (data.includes("hvac_zone")) {

        let hvacdata = data.split('\x00');
        for (var m = 0; m < hvacdata.length; m++) {
          if (validate(hvacdata[m]) === true) {
            props.cbusEventChanger(m);
          }
        }
      }
      if (data.includes("cbusInstantaneousPowerHistory") || data.includes("cbusPowerTotals") || data.includes("cbusSetScene") || data.includes("cbusTimeChanged") || data.includes("cbusSetUserSystemIOStr") || data.includes("set_hvac_mode") || data.includes("hvac_plant") || data.includes("cbusGetHVACZoneTemp")) {
        let pow = [];
        let p = data.split('<')
        for (var i = 1; i < p.length; i++) {
          pow.push('<' + p[i]);
        }
        for (var j = 0; j < pow.length; j++) {
          if (validate(pow[j]) === true) {
            let eventData = new XMLParser().parseFromString(pow[j]);
            //  handleSocketEvents(eventData.attributes.app,eventData);
            if (eventData.attributes.name === 'cbusInstantaneousPowerHistory') {
              handleSocketEvents(eventData.attributes.app, eventData);
            }
            else if (eventData.attributes.name === 'cbusPowerTotals') {
              handleSocketEvents(eventData.attributes.app, eventData);
            }
           else  if (eventData.attributes.name === "cbusTimeChanged") {
             handleSocketEvents(eventData.attributes.app, eventData);
             props.cbusEventChanger(eventData);
           } else if (eventData.attributes.name === "set_hvac_mode") {
             handleSocketEvents(eventData.attributes.app, eventData);
             const widgetMode = Number(
               parseInt(eventData.attributes.hvac_mode, 16)
             );
             if (widgetMode != 0) {
               props.initialEventData.set("hvac_mode", widgetMode);
               let hvacModeData = new Map(props.initialEventData);
               props.socketEventDataChanger(hvacModeData);
               props.initialEventData.set("hvac_plant_fspeed", eventData);
               let fspeed = new Map(props.initialEventData);
               props.socketEventDataChanger(fspeed);
               if (widgetMode != 4) {
                 props.initialEventData.set("hvac_plant_temp", eventData);
                 let fspeed = new Map(props.initialEventData);
                 props.socketEventDataChanger(fspeed);
               }
             }
           } else if (eventData.attributes.name === "hvac_plant") {
             handleSocketEvents(eventData.attributes.app, eventData);
             props.cbusEventChanger(eventData);
           } else if (eventData.attributes.name === "hvac_zone_on") {
             handleSocketEvents(eventData.attributes.app, eventData);
             props.cbusEventChanger(eventData);
           } else if (eventData.attributes.name === "hvac_zone_off") {
             handleSocketEvents(eventData.attributes.app, eventData);
             props.cbusEventChanger(eventData);
           } else if (eventData.attributes.name === "cbusGetHVACZoneTemp") {
             handleSocketEvents(eventData.attributes.app, eventData);
             props.cbusEventChanger(eventData);
           } else {
             props.cbusEventChanger(eventData);
           }
          }
        }
      } else {
        let eventData = new XMLParser().parseFromString(data);
        handleSocketEvents(eventData.attributes.app, eventData);
        if (eventData.name === "cbus_resp") {
          props.cbusEventChanger(eventData);
         if (eventData.attributes.name === "cbusGetHVACZoneTemp") {
            handleSocketEvents(eventData.attributes.app, eventData);
          }
        }
        if (eventData.name === "cbus_event") {
          if (eventData.attributes.command === "cbusGetLevel") {
            handleSocketEvents(eventData.attributes.app, eventData);
          }
          else if (eventData.attributes.name === "cbusPowerHistoryTotals") {
            handleSocketEvents(eventData.attributes.app, eventData);
          }
          else if (eventData.attributes.name === "set_hvac_mode") {
            handleSocketEvents(eventData.attributes.app, eventData);
            const widgetMode = Number(parseInt(eventData.attributes.hvac_mode, 16));
              if(widgetMode!=0){
                props.initialEventData.set("hvac_mode", widgetMode);
                let hvacModeData = new Map(props.initialEventData);
                props.socketEventDataChanger(hvacModeData);
                props.initialEventData.set("hvac_plant_fspeed", eventData);
                let fspeed = new Map(props.initialEventData);
                props.socketEventDataChanger(fspeed);
                if (widgetMode != 4) {
                  props.initialEventData.set("hvac_plant_temp", eventData);
                  let fspeed = new Map(props.initialEventData);
                  props.socketEventDataChanger(fspeed);
                }
              }
          }
          else if (eventData.attributes.name === "hvac_plant") {
            handleSocketEvents(eventData.attributes.app, eventData);
          }
          else if (eventData.attributes.name === "zone_temp") {
            props.cbusEventChanger(eventData);
            handleSocketEvents(eventData.attributes.app, eventData);
          }
          else  if (eventData.attributes.name === "hvac_zone_on" || eventData.attributes.name === "hvac_zone_off") {
            handleSocketEvents(eventData.attributes.app, eventData);
          }
          else {
            props.cbusEventChanger(eventData);
            if (eventData.attributes.name === "cbusSetLevel"){
              let initialData = props.initialEventData.get(eventData.attributes.app);
              initialData[Number(parseInt(eventData.attributes.group, 16))] = eventData.attributes.level;
              let lightingData = new Map(props.initialEventData);
              props.socketEventDataChanger(lightingData);
            }
          }
        }
      }
    }
    else {
      if (data.includes("cbusInstantaneousPowerHistory") || data.includes("cbusPowerTotals") || data.includes("cbusSetScene")) {
        let pow = [];
        let p = data.split('<')
        for (var i = 1; i < p.length; i++) {
          pow.push('<' + p[i]);
        }
        for (var j = 0; j < pow.length; j++) {
          if (validate(pow[j]) === true) {
            let eventData = new XMLParser().parseFromString(pow[j]);
            //  handleSocketEvents(eventData.attributes.app,eventData);
            props.cbusEventChanger(eventData);
            if (eventData.attributes.name === 'cbusInstantaneousPowerHistory') {
              handleSocketEvents(eventData.attributes.app, eventData);
            }
            if (eventData.attributes.name === 'cbusPowerTotals') {
              handleSocketEvents(eventData.attributes.app, eventData);
            }
          }
        }
      }
      if (data.includes("cbusPowerHistoryTotals")) {
        props.initialEventData.set("powerTotal1", data);
        let powerTotal1Data = new Map(props.initialEventData)
        props.socketEventDataChanger(powerTotal1Data);
      }
      if (data.includes("predicted_total_cost_month")) {
        props.initialEventData.set("powerTotal2", data);
        let powerTotal2Data = new Map(props.initialEventData)
        props.socketEventDataChanger(powerTotal2Data);
      }
    }
  };

  let auth = sessionStorage.getItem("auth").replaceAll('=','');
  const wsConnect = () =>{
    const ws = new WebSocket(SOCKET_URL,auth);
    ws.onmessage = function (event) {
      if (event.data) {
        cbusDataManagement(event.data);
      }
    };
    ws.onopen = function (event) {
      Swal.close();
      setTimeout(() => {
        connectToDevice().then((res) => {
          
        }).catch((error) => {
          
        });
      }, 500)
    }
    ws.onclose = function (event) {
      Swal.fire({
				icon: 'error',
				text: 'Connection to Wiser lost, trying to reconnect.',
				showConfirmButton: false,
				showCancelButton: false,
				allowOutsideClick: false,
				buttons: false,
			});
      setTimeout(function() {
        wsConnect();
      }, 1000);
    };
    ws.onerror = function(err) {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      ws.close();
    };
  }

  useEffect(() => {
    wsConnect();
  }, []);

  return (
    <div className={props.classes.content}>
      <Grid item lg={12} container spacing={0}>
        {props.switchRoutes}
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  initialEventData: state.eventsReducer.socketEvents,
  appNos: state.reducer.appNos
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAppConfigData,socketEventDataChanger, cbusEventChanger
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Main);
