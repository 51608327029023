/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import React from "react";
import { useState, useEffect } from "react";
// Assets Import //
import bulbWhite from "../../../assets/img/bulbWhite.png";
import bulbGreen from "../../../assets/img/bulbGreen.png";
import IOSSwitch from "./switch";
import BootstrapTooltip from "../../../assets/widgets/tooltip/tooltip";
import { AirbnbThumbComponent } from "./slider";
import { rampRateToSeconds } from "../../../config/rampRateToSeconds";
import { ICON_URL } from "../../../config/environment";
// Components Imported Locally //
import EditWidget from "../editWidget";
// material-ui //
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  FormGroup,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
import Slider from "@material-ui/core/Slider";
// CSS Style sheets imported locally //
import styles from "../../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);
const AirbnbSlider = withStyles({
  root: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
  },
  thumb: {
    height: 32,
    width: 32,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 18,
      width: 2,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  rail: {
    color: "black",
    opacity: 1,
    height: 15,
    borderRadius: 8,
  },
  track: {
    color: "#BDEC2F",
    height: 15,
    borderRadius: 10,
  },
})(Slider);

//function //
export default function GeneralLightingRelay(props) {
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.widget.params._attributes.app).toString(16)
  );
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [checkedC, setCheckedC] = useState(false);
  const [draweropen, setDraweropen] = useState(false);
  const [count, setCount]=useState(0);
  const [onTimeInterval,setOnTimeInterval] = useState(null);
  const [offTimeInterval,setOffTimeInterval] = useState(null);
  const [levelTimeInterval,setLevelTimeInterval] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [lastValue, setLastValue] = useState(0);
  // const rampRatetoMillisoconds = rampRateToSeconds[Number(props.widget.params._attributes.ramprate)]/100;
  // const [count,setCount] = useState(1);
  var interval;


  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (props.cbusEvent.attributes.app === "0x" + Number(props.widget.params._attributes.app).toString(16)) {
            if (props.cbusEvent.attributes.group === "0x" + Number(props.widget.params._attributes.ga).toString(16)) {
              let rampRatetoMillisoconds = rampRateToSeconds[Number(parseInt(props.cbusEvent.attributes.ramp, 16))] / 100;
              clearInterval(onTimeInterval);
              clearInterval(levelTimeInterval);
              clearInterval(offTimeInterval);
              if (props.cbusEvent.attributes.level == "0x0") {
                setIsClicked(true);
                setCheckedC(false);
                //setLastValue(0);
                //setValue(0);
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count1 = lastValue;
                  if(lastValue>changedValue){
                  let interval = setInterval(() => {
                    count1--;
                    setValue(count1);
                    setCount(count1);
                    setLastValue(count1);
                    if (count1 == changedValue) {
                      clearInterval(interval);
                      setLastValue(changedValue);
                    }
                  }, rampRatetoMillisoconds);
                  setOffTimeInterval(interval);
                } 
              }
                else {
                setIsClicked(true);
                setCheckedC(false)
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0 ) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count2 = lastValue;
                  if(lastValue<changedValue){
                    let interval = setInterval(() => {
                      count2++;
                      setValue(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }else{
                    let interval = setInterval(() => {
                      count2--;
                      setValue(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }
                }
                else {
                  setValue(0);
                }
                if (initialData) {
                  initialData[props.widget.params._attributes.ga] = "0x0";
                  let lightingData = new Map(props.initialCbusEvent);
                  props.socketEventDataChanger(lightingData);
                }
               }
              } else if (props.cbusEvent.attributes.level == "0xff") {
                setIsClicked(true);
                setCheckedC(true);
                //setLastValue(100);
                //setValue(100);
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0) {
                  clearInterval(offTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count1 = lastValue;
                  if(lastValue<changedValue){
                  let interval = setInterval(() => {
                    count1++;
                    setValue(count1);
                    setCount(count1);
                    setLastValue(count1);
                    if (count1 == changedValue) {
                      clearInterval(interval);
                      setLastValue(changedValue);
                    }
                  }, rampRatetoMillisoconds);
                  setOnTimeInterval(interval);

                }
                }  else {
                setIsClicked(true);
                setCheckedC(true)
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0 ) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count2 = lastValue;
                  if(lastValue<changedValue){
                    let interval = setInterval(() => {
                      count2++;
                      setValue(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }else{
                    let interval = setInterval(() => {
                      count2--;
                      setValue(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }
                  
                }
                
                 else {
                  setValue(100);
                }

                if (initialData) {
                  initialData[props.widget.params._attributes.ga] = "0xff";
                  let lightingData = new Map(props.initialCbusEvent);
                  props.socketEventDataChanger(lightingData);
                }
              }
             } else {
                setIsClicked(true);
                setCheckedC(true)
                let changedValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16) / 2.55);
                if (rampRatetoMillisoconds > 0) {
                  clearInterval(onTimeInterval);
                  clearInterval(levelTimeInterval);
                  let count2 = lastValue;
                  if(lastValue<changedValue){
                    let interval = setInterval(() => {
                      count2++;
                      setValue(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }else{
                    let interval = setInterval(() => {
                      count2--;
                      setValue(count2);
                      setCount(count2);
                      setLastValue(count2);
                      if (count2 == changedValue) {
                        clearInterval(interval);
                        setLastValue(changedValue);
                      }
                    }, rampRatetoMillisoconds);
                    setOffTimeInterval(interval);
                  }
                  
                } else {
                  setValue(changedValue);
                  setLastValue(changedValue);
                }
                if (initialData) {
                  initialData[props.widget.params._attributes.ga] = props.cbusEvent.attributes.level;
                  let lightingData = new Map(props.initialCbusEvent);
                  props.socketEventDataChanger(lightingData);
                }
              }
            }
          }
        }
      }
    }
    if (initialData && !isClicked) {
      if (initialData[props.widget.params._attributes.ga] === "0x0") {
        setCheckedC(false);
        setValue(0);
        setCount(0);
        setLastValue(0);
      } else if (initialData[props.widget.params._attributes.ga] == "0xff") {
        setCheckedC(true);
        setValue(100);
        setCount(100);
        setLastValue(100);
      } else {
        let changedValue = Math.floor(parseInt(initialData[props.widget.params._attributes.ga], 16) / 2.55);
        setValue(changedValue);
        setCount(changedValue);
        setCheckedC(true);
        setLastValue(changedValue);
      }
      setIsClicked(true);
    }
  }, [props.cbusEvent]);

  const handleSwitchChange = (event, value) => {
    setIsClicked(true);
    setCheckedC(event.target.checked);
    {
      if (event.target.checked === false) {
        // if (rampRatetoMillisoconds > 0) {
        //   clearInterval(onTimeInterval);
        //   let count1 = count;
        //   let interval = setInterval(() => {
        //     count1--;
        //     setValue(count1);
        //     setCount(count1);
        //     if (count1 == 0) {
        //       clearInterval(interval);
        //     }
        //   }, rampRatetoMillisoconds);
        //   setOffTimeInterval(interval);
        // }else{
        //   setValue(0);
        // }
        let off = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params.param[1]._attributes.value +
            '" command="cbusSetLevel" network="' +
            props.widget.params.param[0]._attributes.value +
            '" numaddresses="1" group="' +
            props.widget.params.param[2]._attributes.value +
            '" level="0" ramp="' +
            props.widget.params._attributes.ramprate +
            '"  />',
        };
        //
        props.handleSocketEventChange(off);
      } else { 
      //   if(rampRatetoMillisoconds>0){
      //     clearInterval(offTimeInterval);
      //     let count1 = count;
      //     let interval = setInterval(() => {
      //       count1++;
      //       setValue(count1);
      //       setCount(count1);
      //       if (count1 == 99) {
      //         clearInterval(interval);
      //       }
      //     }, rampRatetoMillisoconds);
      //     setOnTimeInterval(interval);
 
      //  }else{
      //   setValue(100);
      //  }
        let on = {
          cmd:
            '<cbus_cmd app="' +
            props.widget.params.param[1]._attributes.value +
            '" command="cbusSetLevel" network="' +
            props.widget.params.param[0]._attributes.value +
            '" numaddresses="1" group="' +
            props.widget.params.param[2]._attributes.value +
            '" level="255" ramp="' +
            props.widget.params._attributes.ramprate +
            '" />',
        };
        props.handleSocketEventChange(on);
      }
    }
  };

  const handleCommittedChange = (event, newValue) => {
    if (newValue >= 1) setValue(newValue);
    if(newValue>1){
      const cmdValue = Math.floor(newValue * 2.55);
      
      const cmdValue1 = { level: cmdValue };
      let on = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="' +
          cmdValue1.level +
          '" ramp="0" />',
      };
      props.handleSocketEventChange(on);
    }else{
      const cmdValue1 = { level: 1 };
      let on = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="' +
          cmdValue1.level +
          '" ramp="0" />',
      };
      props.handleSocketEventChange(on);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setIsClicked(true);
    clearInterval(offTimeInterval);
    clearInterval(onTimeInterval);
    setCheckedC(true);
    if (newValue >= 1) {
      setValue(newValue);
      setCount(newValue);
    };
  };

  // 
  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    (props.widget._attributes.location === "all" ||
    props.widget._attributes.location === "none")
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
  (props.widget._attributes.functiongroup === "all" ||
  props.widget._attributes.functiongroup === "none")
    ? ""
    : props.functionGroups[Number(props.widget._attributes.functiongroup)]
      ._attributes.Name;

  return (
    <>
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                    style={{ borderRadius: "0" }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={checkedC ? ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png"}
                    className={classes.headerIcon}
                  />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={
                      checkedC ? `${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
                <FormGroup>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={0}
                    >
                      <Grid item>
                        <IOSSwitch
                          ThumbComponent={AirbnbThumbComponent}
                          checked={checkedC}
                          onChange={handleSwitchChange}
                          name="checkedC"
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </FormGroup>
              </div>
            </BootstrapTooltip>
            <div
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
              className={
                checkedC
                  ? classes.numberControlPanelActive
                  : classes.numberControlPanel
              }
            >
              <AirbnbSlider
                ThumbComponent={AirbnbThumbComponent}
                value={value}
                onChange={handleSliderChange}
                onChangeCommitted={handleCommittedChange}
              />
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{ paper: classNames(classes.drawerPaper) }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
