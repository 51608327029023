import React, {useEffect} from "react";
import { Grid } from "@material-ui/core";
import styles from "assets/js/all-styles/views/schedulesStyle.js";
import classNames from "classnames";
import calendar from "assets/img/calendar.png";
import calendargray from "assets/img/calendargray.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer
} from "@material-ui/core";
import { connect } from "react-redux";

import { getAppConfigData, profileUserChanger } from "redux/actions/action";
import { bindActionCreators } from "redux";
import { updateSocketEvent } from "../../services/xmlService";
// import Scheduledrawer1 from "views/Settings/Schedules/Scheduledrawer1";
import Scheduledrawer from "views/Settings/Schedules/Scheduledrawer";

const useStyles = makeStyles(styles);


const Schedules =(props)=>{
  const initialData = props.initialCbusEvent.get("0xcb");
  const classes = useStyles();

  const [schedulesData, setSchedulesData] = React.useState(props.data.Project.Schedules.Schedule);
  const [draweropen, setDraweropen] = React.useState(false);
  const [userValue, setUserValue] = React.useState();
  // const [groupAdd, setGroupAdd] = React.useState(props.data.Project.Schedules.Schedule[8]._attributes.EnableGroupAdd="1");
  // const [cbusData, setCbusData] = React.useState(props.cbusEvent.attributes.app="0xcb" );
  // const [onPress, setOnPress] = React.useState(false);
  const [disabledImg, setDisabledImg] = React.useState(calendargray);
  const [disabledColor, setDisabledColor] = React.useState(classes.scheduleButtonTextValOne);
  const [editScheduledata, setEditScheduledata] =  React.useState([]);



  const toggleDrawer = (open, user) => {
    setDraweropen(open);
    setUserValue(user);
   };

  //  useEffect(() => {
  //   if(groupAdd==="1" && ){
  //   if (props.cbusEvent) {
  //     if (props.cbusEvent.name === "cbus_event") {
  //       if (props.cbusEvent.attributes.name === "cbusSetLevel") {
  //       if (props.cbusEvent.attributes.app ==="0xcb") {
  //         if (props.cbusEvent.attributes.group === "0x1") {
  //             if (props.cbusEvent.attributes.level == "0xff") {
  //               setDisabledImg(calendar);
  //               setDisabledColor(classes.scheduleButtonText);
  //               setOnPress(true);
  //             } else {
  //               setDisabledImg(calendargray)
  //               setDisabledColor(classes.scheduleButtonTextValOne);
  //               setOnPress(false);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // }, [props.cbusEvent])

  // <cbus_event app="0xcb" name="cbusSetLevel" group="0x1" level="0xff" ramp="0x0" />
  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") { 
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
        if (props.cbusEvent.attributes.app === "0xcb") {
          if (props.cbusEvent.attributes.group === "0x1") {
            if(props.cbusEvent.attributes.level === "0xff" ){
            setDisabledImg(calendar);
            setDisabledColor(classes.scheduleButtonText);
            } 
            else
            {
              setDisabledImg(calendargray)
              setDisabledColor(classes.scheduleButtonTextValOne);

            }
          }
        }
      }
    }
  }
    // if(initialData){
    //   let lightStatusValue = 203;
    //   setDisabledImg(lightStatusValue);
    //   setDisabledColor();
    // }
  }, [props.cbusEvent])

  const handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
  };

  React.useEffect(()=>{ 
    if(props.data){
      if(props.data.Project.Schedules){
      if(!Array.isArray(props.data.Project.Schedules.Schedule)){ 
        let schedules = [];
        schedules.push(props.data.Project.Schedules.Schedule);
        setEditScheduledata(schedules);
        //setScenesedit(props.data.Project.Scenes.Scene);
     }else{
      setEditScheduledata(props.data.Project.Schedules.Schedule);
     }
    }
  }
  },[props.data])



  return(
   <>
  {props.data !== undefined && schedulesData !== undefined &&
   editScheduledata.map(
      ({ _attributes }, key) => (
        <>
        <Grid item lg={4} md={6} sm={12} xs={12}>
        <div className={classes.root}>
          <div className={classes.header}>
            <div
              onClick={()=>toggleDrawer(true, _attributes)}
              className={classes.paddingforschedule}
            >
              <div className={classes.imgcon}>
                <img
                  className={classes.imgwidth}
                  src={ _attributes.EnableGroupAdd!=="1"?calendar:calendargray}
                  alt={" Logo"}
                />
              </div>
              <h6 className={_attributes.EnableGroupAdd!=="1"?classes.scheduleButtonText: classes.scheduleButtonTextValOne}>
                {_attributes.Name}
              </h6>
            </div>
          </div>
        </div>
      </Grid>
         </>
      ))
        }
        <Drawer
        variant="temporary"
        anchor={"right"}
        open={draweropen}
        onClose={()=>toggleDrawer(false, userValue)}
        modal
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
        ModalProps={{
          keepMounted: false, // Better open performance on mobile.
        }}
      >
       <Scheduledrawer userValue={userValue} language={props.language} toggleDrawer={toggleDrawer} data ={props.data} handleSocketEventChange={handleSocketEventChange} />
      </Drawer>
   </>
  )

}

const mapStateToProps = (state) => ({
  data: state.reducer.data, 
  language: state.reducer.language,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      profileUserChanger,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);