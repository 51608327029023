import ReactSpeedometer from "react-d3-speedometer";
import { useState, useEffect } from "react";
import { connect } from "react-redux";

function Co2NowStatsSpeedo(props) {
  const [meterValue, setMeterValue] =useState("");

  //CarbonFootPrint Value
  const carbonFootValue = props.energy.Project.Energy._attributes.CarbonFootprint;

let maxMeterValue = (Number(props.widget.params._attributes.max_hourly_w)/1000)*carbonFootValue;

let meterData = ((meterValue/1000)*carbonFootValue).toFixed(2);

let meterResult = meterData >= 9999 ? 9999 : meterData;

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.current_power);
       }
    }
  }, [props.powerData]);

    return (
        <div  style={{ marginTop: '10px'}}>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxValue={maxMeterValue}
                value={meterResult >= maxMeterValue ? maxMeterValue : meterResult}
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterResult} kg CO2`}
                needleTransitionDuration={3000}
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={170}
                height={110}
               dimensionUnit="px"
                
/>
        </div>
    );
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});


export default connect(mapStateToProps)(Co2NowStatsSpeedo);