import React from 'react';
import { useState, useEffect } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
  import { curveCardinal } from 'd3-shape';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

const styles = {
  layout: {
    color: "#87d200",
    marginTop: "2vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
};

const useStyles = makeStyles(styles);

const DollarTotalWeekChart = (props) =>{
  const classes = useStyles();
  // //Tariff_offPeak Value
  // const tariff1 = props.energy.Project.Energy.Tariffs.Tariff[0]._attributes.Price;
  // //Tariff_general Value
  // const tariff2 = props.energy.Project.Energy.Tariffs.Tariff[1]._attributes.Price;

  let tariff, tariff1;
  
  let tariffData = props.energy.Project.Energy.Tariffs.Tariff;
  if(!Array.isArray(tariffData)){
   tariff = tariff1 = props.energy.Project.Energy.Tariffs.Tariff._attributes.Price
  }
  else{
      tariff = props.energy.Project.Energy.Tariffs.Tariff[0]._attributes.Price;
      tariff1 = props.energy.Project.Energy.Tariffs.Tariff[1]._attributes.Price;
  }

  const minMeterValue = (Number(props.widget.params._attributes.max_weekly_gen_w)/1000)*tariff1;
  const maxMeterValue = (Number(props.widget.params._attributes.max_weekly_con_w)/1000)*tariff;
  const weeks = () => {
    let dates=[];
    for (let i = 0; i < 4; i++) {
      let date = moment().isoWeekday(7);
      dates.push({ name: date.subtract(i, 'week').format('DD MMM') });
    }
    return dates;
 }  
const [data, setData] = React.useState(0);
// useEffect(() => {
//   if (props.powerStatTotal) {
//   // let data1='<cbus_event app="0x102" name="cbusPowerHistoryTotals" hourly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,45725572.00,718544896.00,2078.10,2074.90,2055.00,2115.00,2137.62,2159.00,17457.07,22095.74,25585.58,21744.13,18065.10,17181.04,19979.07,21302.35,22341.30,7745.02," daily_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," weekly_energy_totals="0.00,0.00,0.00,764453312.00," monthly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," hourly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,19204.79,301788.59,0.88,0.88,0.87,0.90,0.91,0.92,7.49,9.60,11.09,9.43,8.25,8.10,9.28,9.76,9.86,3.36," daily_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," weekly_cost_totals="0.00,0.00,0.00,321060.94," monthly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," current_total_power="28878.11" current_total_cost="12.47" current_total_power_hour="7745.02" current_total_power_day="764453312.00" current_total_power_week="764453312.00" current_total_power_month="764453312.00" current_total_cost_hour="3.36" current_total_cost_day="321060.94" current_total_cost_week="321060.94" current_total_cost_month="321060.94" predicted_total_power_hour="27654.87" predicted_total_power_day="764675328.00" predicted_total_power_week="1454278656.00" predicted_total_power_month="0.00" predicted_total_cost_hour="11.96" predicted_total_cost_day="321156.84" predicted_total_cost_week="610779.12" predicted_total_cost_month="0.00" />';
//   // let eventData = new XMLParser().parseFromString(data1);
//   // let weekEnergy=eventData.attributes.weekly_energy_totals.split(",");
//   if (props.powerStatTotal?.attributes?.daily_energy_totals) {
//   let weekEnergy=props.powerStatTotal.attributes.daily_energy_totals.split(",");
//   weekEnergy.pop();
//   let previousWeeks= weeks();
//   previousWeeks.reverse();
//   let weeksData=[];
//   for (let j = 0; j < previousWeeks.length; j++) {
//        let dData = {
//          name: null, $: null
//        }
//         dData.name = previousWeeks[j].name;
//         let $ = Math.round(weekEnergy[j])/1000;
//            if($>9999){
//             dData.$ = maxMeterValue;
//            }
//           if($>0 && $<9999){
//             dData.$ = $*tariff2;
//           }
//            if($==0){
//             dData.$ = 0;
//            }
//         weeksData.push(dData);
//      }
//      setData(weeksData);
//     }
//   }
// }, [props.powerStatTotal]);

useEffect(() => {
  if (props.totalPowerDataCharts) {
  // let data1='<cbus_event app="0x102" name="cbusPowerHistoryTotals" hourly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,45725572.00,718544896.00,2078.10,2074.90,2055.00,2115.00,2137.62,2159.00,17457.07,22095.74,25585.58,21744.13,18065.10,17181.04,19979.07,21302.35,22341.30,7745.02," daily_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," weekly_energy_totals="0.00,0.00,0.00,764453312.00," monthly_energy_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,764453312.00," hourly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,19204.79,301788.59,0.88,0.88,0.87,0.90,0.91,0.92,7.49,9.60,11.09,9.43,8.25,8.10,9.28,9.76,9.86,3.36," daily_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," weekly_cost_totals="0.00,0.00,0.00,321060.94," monthly_cost_totals="0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00,321060.94," current_total_power="28878.11" current_total_cost="12.47" current_total_power_hour="7745.02" current_total_power_day="764453312.00" current_total_power_week="764453312.00" current_total_power_month="764453312.00" current_total_cost_hour="3.36" current_total_cost_day="321060.94" current_total_cost_week="321060.94" current_total_cost_month="321060.94" predicted_total_power_hour="27654.87" predicted_total_power_day="764675328.00" predicted_total_power_week="1454278656.00" predicted_total_power_month="0.00" predicted_total_cost_hour="11.96" predicted_total_cost_day="321156.84" predicted_total_cost_week="610779.12" predicted_total_cost_month="0.00" />';
  // let eventData = new XMLParser().parseFromString(data1);
  // let weekEnergy=eventData.attributes.weekly_energy_totals.split(",");
  if (props.totalPowerDataCharts.weekly_cost_totals) {
  let weekEnergy=props.totalPowerDataCharts.weekly_cost_totals.split(",");
  weekEnergy.pop();
  let previousWeeks= weeks();
  previousWeeks.reverse();
  let weeksData=[];
  for (let j = 0; j < previousWeeks.length; j++) {
       let dData = {
         name: null, $: null
       }
        dData.name = previousWeeks[j].name;
        let $ = Math.round(weekEnergy[j]);
        if($>maxMeterValue){
          dData.$ = maxMeterValue;
         }
        if($<-minMeterValue){
          dData.$ = -minMeterValue;
        }
        if($<maxMeterValue && $>-minMeterValue){
          dData.$ = $;
        }
           if($==0){
            dData.$ = 0;
           }
        weeksData.push(dData);
     }
     setData(weeksData);
    }
  }
}, [props.totalPowerDataCharts]);

    const cardinal = curveCardinal.tension(0.2);
return (
    <div style={{ width: '120%', height: 150, fontSize: '12px' }}>
       <ResponsiveContainer>
     <AreaChart
        width={600}
        height={100}
        data={data}
        margin={{
          top: 0, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis 
        ticks={[-Math.round(minMeterValue),0, Math.round(maxMeterValue)]}
        domain={[-Math.round(minMeterValue), Math.round(maxMeterValue)]} />
        <Tooltip />
        <Area type="monotone" dataKey=" " stroke="#000" fill="#87d200" fillOpacity={0.3} />
        <Area type={cardinal} dataKey="$" stroke="#000" fill="#87d200" fillOpacity={0.3} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
)
}
export default DollarTotalWeekChart;



