/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
import { ICON_URL } from "../../config/environment";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit"; 
// Assets Import //
import circlesGreen from "../../assets/img/circlesGreen.png";
import circlesWhite from "assets/img/circlesWhite.png";
import sceneUpWhite from "assets/img/up.png";
import sceneDownWhite from "assets/img/down.png";
import sceneUpGreen from "assets/img/upGreen.png";
import sceneDownGreen from "assets/img/downGreen.png";
// Components Import //
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// CSS Style sheets imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function //
export default function Scenes(props) {
  const initialData = props.initialCbusEvent.get("cbusGetLevel0xca");
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  //const [click, setClick] = useState(true);
  const [start, setStart] = useState(false);
  const [isUp, setIsUp] = useState(false);
  const [isDown, setIsDown] = useState(false);
  const [scenesGa, setScenesGa] = useState();
  const [scenesLevel, setScenesLevel] = useState();
  const toggleDrawer = (open) => {
    setDraweropen(open);
  };
 

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const handleStart = () => {
    // setStart(true);
    let on = {
      cmd:
        '<cbus_cmd app="0x38" command="cbusSetScene"  scene_id="' +
        props.widget.params._attributes.scene_id +
        '" />',
    };
    props.handleSocketEventChange(on);
  };

  const handleUp = () => {
    // setIsUp(true);
    let up = {
      cmd:
        '<cbus_cmd app="0x38" command="cbusNudgeScene" scene_id="' +
        props.widget.params._attributes.scene_id +
        '" offset="' +
        props.widget.params._attributes.nudgerate +
        ' " ramp_rate="0" /> ',
    };
    props.handleSocketEventChange(up);
  };

  const handleDown = () => {
    // setIsDown(true);
    let down = {
      cmd:
        '<cbus_cmd app="0x38" command="cbusNudgeScene" scene_id="' +
        props.widget.params._attributes.scene_id +
        '" offset=" -' +
        props.widget.params._attributes.nudgerate +
        ' " ramp_rate="0" /> ',
    };
    props.handleSocketEventChange(down);
  };

  useEffect(() => {
    let scenesObject = props.data.Project.Scenes.Scene;
    for (let i = 0; i < scenesObject.length; i++) {
      if (
        scenesObject[i]._attributes.id ==
        props.widget.params._attributes.scene_id
      ) {
        setScenesGa(scenesObject[i]._attributes.GroupAdd);
        setScenesLevel(scenesObject[i]._attributes.Level);
      }
    }
  },[]);

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetScene") {
          if (
            props.cbusEvent.attributes.scene_id ==
            "0x" +
            Number(props.widget.params._attributes.scene_id).toString(16)
          ) {
            if (initialData) {
              initialData[scenesGa] = "0x"+Number(scenesLevel).toString(16);
              let scenesData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(scenesData);
            }
          }
        }
        // if (props.cbusEvent.attributes.name === "cbusSetLevel") {
        //   if (props.cbusEvent.attributes.ga == scenesGa) {
        //       if (initialData) {
        //         initialData[scenesGa] =
        //         props.cbusEvent.attributes.level;
        //         let scenesData = new Map(props.initialCbusEvent);
        //         props.socketEventDataChanger(scenesData);
        //       }
        //   }
        // }
        if (props.cbusEvent.attributes.name === "scene_kill") {
          if (
            props.cbusEvent.attributes.scene_id ==
            "0x" +
            Number(props.widget.params._attributes.scene_id).toString(16)
          ) {
            if (initialData) {
              initialData[scenesGa] ="0x0";
              let scenesData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(scenesData);
            }
          }
        }
      }
    }

    if(initialData){
     if(initialData[scenesGa]=="0x"+Number(scenesLevel).toString(16)){
      setStart(true);
     }else{
      setStart(false);
     }
    }
  }, [props.cbusEvent]);

  const startLabel = props.language.start;
  const stopLabel = props.language.on;
  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  return (
    <>
      {" "}
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={start ? ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png"}
                    className={classes.headerIcon}
                  />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={
                      start
                        ? `${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  className={
                    start
                      ? classes.controlPanelIconButtonActive
                      : classes.controlPanelIconButton
                  }
                  onClick={start ? "null" : () => handleStart(start)}
                  style={{ paddingTop: "2%", paddingBottom: "2%" }}
                >
                  <Typography
                    className={
                      start
                        ? classes.topButtonTextActive
                        : classes.topButtonText
                    }
                    variant="h6"
                  >
                    {start ? stopLabel : startLabel}
                  </Typography>
                </Button>
              </div>
            </BootstrapTooltip>
            <div className={classes.bottomPanelScenes}>
              <Button
                variant="contained"
                className={
                  isUp
                    ? classes.bottomPanel2ButtonActive
                    : classes.bottomPanel2Button
                }
                onMouseDown={handleUp}
                onMouseUp={() => setIsUp(false)}
              >
                <img
                  src={isUp ? sceneUpGreen : sceneUpWhite}
                  className={classes.bottomPanel2ButtonIcon}
                />
              </Button>
              <Button
                variant="contained"
                className={
                  isDown
                    ? classes.bottomPanel2ButtonActive
                    : classes.bottomPanel2Button
                }
                style={{ borderRight: "none" }}
                onMouseDown={handleDown}
                onMouseUp={() => setIsDown(false)}
              >
                <img
                  src={isDown ? sceneDownGreen : sceneDownWhite}
                  className={classes.bottomPanel2ButtonIcon}
                />
              </Button>
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
