import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  Radio,
  Divider,
  Drawer,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";

import cctvImage from "../../assets/img/homeCctv.png";
import EditSurveillanceWidget from "../../components/Widgets/editSurveillanceWidget";
import EditSurveillancePhotoSetup from "../../components/Widgets/editSurveillancePhotoSetup";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/SystemUtilities.css";
import styles from "assets/js/all-styles/views/systemUtilitiesStyle.js";
import { connect } from "react-redux";
import { updateXml, wifiScanner, changeNetworkSettings, imageGallery, getSystemIp, rebootDevice, letEncrypt, encryptInfo, setupSSL } from "../../services/xmlService";
import { getAppConfigData, setHeaderTitle } from "redux/actions/action";
import { bindActionCreators } from "redux";
import { rebootJson } from "../../config/rebootConstants";
import { set } from "lodash";

const useStyles = makeStyles(styles);

const SetupDetails = (props) => {
  function getFormattedDate(today) {
    var week = new Array(
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    );
    var day = week[today.getDay()];
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minu = today.getMinutes();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (minu < 10) {
      minu = "0" + minu;
    }

    return day + " - " + dd + "/" + mm + "/" + yyyy;
  }
  var date = new Date();
  var day = getFormattedDate(date);

  const [timer, setTimer] = React.useState(false);	
  const [callSSL, setCallSSL] = React.useState("");	
  const [checkSSL, setCheckSSL] = React.useState( (props.data.Project.Info.Wiser2DNS != undefined) && ( props.data.Project.Info.Wiser2DNS._attributes.Name != ""  )  	
       ? props.data.Project.Info.Wiser2DNS._attributes.Name	
       : window.location.origin	
 );	
  const [checkDNS, setCheckDNS] = React.useState((props.data.Project.Info.Wiser2DNS != undefined) && (props.data.Project.Info.Wiser2DNS._attributes.Name != "")  ? true : false);

  function getFormattedTime(today) {
    var week = new Array(
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    );
    var day = week[today.getDay()];
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minu = today.getMinutes();
    var sec = today.getSeconds();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (minu < 10) {
      minu = "0" + minu;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }

    return +hour + ":" + minu + ":" + sec;
  }

  var date = new Date();
  var time = getFormattedTime(date);

  const [curImage, setCurImage] = React.useState();
  const [dayDate, setDayDate] = React.useState();

  const setCurrentImage = (name) => {
    setCurImage(name);
  };


  const [discardUpdate, setDiscardUpdate] = React.useState(true);

  const [infoWiser, setInfoWiser] = React.useState();
  let preInforWiser, addressUrl, ipparts, subnetmaskparts, gatewayAddressparts, dnsallparts, pgdnspart1, pgdnspart2, pgdnspart3, ntpallparts, sambaserverWorkGroup, sambaserverEnabled, sambaclientEnabled, sambaclientUser, sambaclientPW, sambaclientDomain, sambaclientHost, sambaclientFolder, pgDDNSEnabled, pgDDNSUser, pgDDNSPW, pgDDNSServer, pgDDNSHost, constr, buff, decodedPassword, buff2, decodedPasswordSamba;
  const [pgIPAddress, setPgIPAddress] = React.useState();
  const [pgSubNetMask, setPgSubNetMask] = React.useState();
  const [pgGatewayAddress, setPgGatewayAddress] = React.useState();
  const [pgdns, setPgDns] = React.useState();
  const [pgntp, setPgNtp] = React.useState();
  const [pgsambaserver, setPgSambaserver] = React.useState();
  const [pgsambaclient, setPgSambaclient] = React.useState();
  const [pgDDNS, setPgDDNS] = React.useState();
  const [state, setState] = React.useState({ selectedTimeType: "" });
  const [languageName, setLanguageName] = React.useState();
  const [SSID, setSSID] = React.useState({ SSID: " " });

  const [optainIPAddress, setOptainIPAddress] = React.useState("");
  const [wifi, setWifi] = React.useState("");
  const [wifiname, setWifiname] = React.useState([]);
  const [wifiSavedName, setWifiSavedName] = React.useState();

  const [passphrase, setPassphrase] = React.useState();

  const [passphrasedecoded, setPassphrasedecoded] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [encryptOpen, setEncryptOpen] = React.useState(false);
  const [openSSL, setOpenSSL] = React.useState(false); 
  const [encryptMessage, setEncryptMessage] = React.useState();
  const [encryptStatus, setEncryptStatus] = React.useState(false);
  const [imageList, setImageList] = React.useState([]);

  const [iPAddress, setIPAddress] = React.useState({
    ipaddresspart1: "",
    ipaddresspart2: "",
    ipaddresspart3: "",
    ipaddresspart4: "",
  });

  const [subnetMask, setSubnetMask] = React.useState({
    subnetMaskpart1: "",
    subnetMaskpart2: "",
    subnetMaskpart3: "",
    subnetMaskpart4: ""
  });

  const [gateway, setGateway] = React.useState({
    gatewaypart1: "",
    gatewaypart2: "",
    gatewaypart3: "",
    gatewaypart4: ""
  });

  const [dns1, setDNS1] = React.useState({
    dns1part1: "",
    dns1part2: "",
    dns1part3: "",
    dns1part4: "",
  });

  const [dns2, setDNS2] = React.useState({
    dns2part1: "",
    dns2part2: "",
    dns2part3: "",
    dns2part4: ""
  });

  const [dns3, setDNS3] = React.useState({
    dns3part1: "",
    dns3part2: "",
    dns3part3: "",
    dns3part4: ""
  });

  const [ntpCheck, setNtpCheck] = React.useState();

  const [ntp, setNtp] = React.useState({
    ntppart1: "",
    ntppart2: "",
    ntppart3: "",
    ntppart4: "",
  });

  const [sambaserver, setSambaServer] = React.useState();

  const [sambaservercheck, setSambaservercheck] = React.useState("");
  const [sambaclientcheck, setSambaclientcheck] = React.useState("");
  const [samba, setSamba] = React.useState({});

  let passen = "wifienabled";

  const [dynamicDNS, setDynamicDNS] = React.useState({
    ddns: "",
    dnshost: "",
    dnsusername: "",
    dnspassword: "",
  });

  const [dynamicDNSCheck, setDynamicDNSCheck] = React.useState();

  React.useEffect(() => {
    setInfoWiser(props.data.Project.Info.Wiser);
    preInforWiser = JSON.parse(JSON.stringify(props.data.Project.Info.Wiser));

    setPgIPAddress(preInforWiser.PGIPAddress);
    addressUrl = "http://" + preInforWiser.PGIPAddress._text + "/firmwareupgrade.html";
    ipparts = preInforWiser.PGIPAddress._text != undefined ? preInforWiser.PGIPAddress._text.split(".")  : [];
    setPgSubNetMask(
      preInforWiser.PGSubNetMask
    );
    subnetmaskparts = preInforWiser.PGSubNetMask._text != undefined ? preInforWiser.PGSubNetMask._text.split(".") : [];
    setPgGatewayAddress(
      preInforWiser.PGGatewayAddress
    );
    gatewayAddressparts = preInforWiser.PGGatewayAddress._text != undefined ? preInforWiser.PGGatewayAddress._text.split(".") : [];
    setPgDns(preInforWiser.DNS);
    dnsallparts = preInforWiser.DNS._text != undefined ? preInforWiser.DNS._text.split("|") : [];
    pgdnspart1 = dnsallparts.length>0  ? dnsallparts[0].split(".") : [];
    pgdnspart2 = dnsallparts.length>0 ? dnsallparts[1].split(".") : [];
    pgdnspart3 = dnsallparts.length>0 ? dnsallparts[2].split(".") : [];

    setPgNtp(preInforWiser.NTP);
    ntpallparts = preInforWiser.NTP._attributes.URL.split("|");
    setPgSambaserver(
      preInforWiser.SAMBAServer
    );
    sambaserverWorkGroup = preInforWiser.SAMBAServer._attributes.WorkGroup;
    sambaserverEnabled = preInforWiser.SAMBAServer._attributes.Enabled;

    setPgSambaclient(
      preInforWiser.SAMBAClient
    );
    let pgsclient = preInforWiser.SAMBAClient;
    let wifiDetails = preInforWiser.WiFi;
    let sambServer = preInforWiser.SAMBAServer;
    let ddns = preInforWiser.DDNS;
    let ntp = preInforWiser.NTP;
    let dhcp = preInforWiser.DHCP;

    sambaclientEnabled = pgsclient._attributes.Enabled;
    sambaclientUser = pgsclient._attributes.User;
    sambaclientPW = pgsclient._attributes.PW;
    sambaclientDomain = pgsclient._attributes.Domain;
    sambaclientHost = pgsclient._attributes.Host;
    sambaclientFolder = pgsclient._attributes.Folder;

    setPgDDNS(preInforWiser.DDNS);
    let pgDDNSTemp = preInforWiser.DDNS;
    pgDDNSEnabled = pgDDNSTemp._attributes.Enabled;
    pgDDNSUser = pgDDNSTemp._attributes.User;
    pgDDNSPW = pgDDNSTemp._attributes.PW;
    pgDDNSServer = pgDDNSTemp._attributes.Server;
    pgDDNSHost = pgDDNSTemp._attributes.Host;

    setDynamicDNSCheck(
      pgDDNSEnabled === "1" ? "dynamicdnscheckenabled" : "dynamicdnscheckdisabled"
    );

    setDynamicDNS({
      ddns: pgDDNSServer,
      dnshost: pgDDNSHost,
      dnsusername: pgDDNSUser,
      dnspassword: pgDDNSPW,
    });

    setState({ selectedTimeType: "fixed" });
    setLanguageName(props.languageName);
    setSSID({ SSID: " " });

    setOptainIPAddress(
      preInforWiser.DHCP._text === "1"
        ? "optainIPAddressenabled"
        : "optainIPAddressdisabled"
    );
    setWifi(
      preInforWiser.WiFi._attributes.Enabled === "1"
        ? "wifienabled"
        : "wifidisabled"
    );
    setWifiSavedName(
      preInforWiser.WiFi._attributes.Name
    );

    passen = wifi === "wifienabled" ? "flex" : "none";

    setPassphrase(
      preInforWiser.WiFi._attributes.PW
    );

    constr = preInforWiser.WiFi._attributes.PW;
    buff = new Buffer(constr, "base64");
    decodedPassword = buff.toString("ascii");
    setPassphrasedecoded(
      decodedPassword
    );

    setIPAddress({
      ipaddresspart1: ipparts[0],
      ipaddresspart2: ipparts[1],
      ipaddresspart3: ipparts[2],
      ipaddresspart4: ipparts[3],
    });

    setSubnetMask({
      subnetMaskpart1: subnetmaskparts[0],
      subnetMaskpart2: subnetmaskparts[1],
      subnetMaskpart3: subnetmaskparts[2],
      subnetMaskpart4: subnetmaskparts[3],
    });

    setGateway({
      gatewaypart1: gatewayAddressparts[0],
      gatewaypart2: gatewayAddressparts[1],
      gatewaypart3: gatewayAddressparts[2],
      gatewaypart4: gatewayAddressparts[3],
    });

    setDNS1({
      dns1part1: pgdnspart1[0],
      dns1part2: pgdnspart1[1],
      dns1part3: pgdnspart1[2],
      dns1part4: pgdnspart1[3],
    });

    setDNS2({
      dns2part1: pgdnspart2[0],
      dns2part2: pgdnspart2[1],
      dns2part3: pgdnspart2[2],
      dns2part4: pgdnspart2[3],
    });

    setDNS3({
      dns3part1: pgdnspart3[0],
      dns3part2: pgdnspart3[1],
      dns3part3: pgdnspart3[2],
      dns3part4: pgdnspart3[3],
    });

    setNtpCheck(
      preInforWiser.NTP._attributes.UseNTP === "1" ? "ntpenabled" : "ntpdisabled"
    );

    setNtp({
      ntppart1: ntpallparts[0],
      ntppart2: ntpallparts[1],
      ntppart3: ntpallparts[2],
      ntppart4: ntpallparts[3],
    });
    setSambaServer(preInforWiser.SAMBAServer._attributes.WorkGroup);

    setSambaservercheck(
      sambaserverEnabled === "1" ? "sambaserverenabled" : "sambaserverdisabled"
    );
    setSambaclientcheck(
      sambaclientEnabled === "1" ? "sambaclientenabled" : "sambaclientdisabled"
    );
    
    // let buff1 = new Buffer(sambaclientPW);
    // let encodedPassword = buff1.toString("base64");

    let buff2 = new Buffer(sambaclientPW, "base64");
    let decodedPasswordSamba = buff2.toString("ascii");

    setSamba({
      username: sambaclientUser,
      password: decodedPasswordSamba,
      domain: sambaclientDomain,
      host: sambaclientHost,
      clientfolder: sambaclientFolder,
    });

    rebootJson.ipaddr = preInforWiser.PGIPAddress._text != undefined ? preInforWiser.PGIPAddress._text : "";
    rebootJson.subnet = preInforWiser.PGSubNetMask._text != undefined ? preInforWiser.PGSubNetMask._text  : "";
    rebootJson.gwaddr = preInforWiser.PGCNIIPAddress._text != undefined ? preInforWiser.PGCNIIPAddress._text : "";
    rebootJson.dns = preInforWiser.DNS._text != undefined ? preInforWiser.DNS._text : "";
    rebootJson.dhcpen = dhcp._text != undefined ? dhcp._text : "";
    rebootJson.wifien = wifiDetails._attributes.Enabled != undefined ? wifiDetails._attributes.Enabled : "";
    rebootJson.wifiname = wifiDetails._attributes.Name != undefined ? wifiDetails._attributes.Name : "";
    rebootJson.wifisec = wifiDetails._attributes.Sec != undefined ? wifiDetails._attributes.Sec : "";
    rebootJson.wifipw = wifiDetails._attributes.PW != undefined ? wifiDetails._attributes.PW : "";
    rebootJson.smbclien = pgsclient._attributes.Enabled != undefined ? pgsclient._attributes.Enabled : "";
    rebootJson.smbcliuser = pgsclient._attributes.User != undefined ? pgsclient._attributes.User : "";
    rebootJson.smbclipw = pgsclient._attributes.PW != undefined ? pgsclient._attributes.PW  : "";
    rebootJson.smbclidomain = pgsclient._attributes.Domain != undefined ? pgsclient._attributes.Domain : "";
    rebootJson.smbclihost = pgsclient._attributes.Host != undefined ? pgsclient._attributes.Host : "";
    rebootJson.smbclifolder = pgsclient._attributes.Folder != undefined ? pgsclient._attributes.Folder : "";
    rebootJson.smbserven = sambServer._attributes.Enabled != undefined ? sambServer._attributes.Enabled : "";
    rebootJson.smbservworkgroup = sambServer._attributes.WorkGroup != undefined ? sambServer._attributes.WorkGroup : "";
    rebootJson.smbservfolder = "";

    rebootJson.dyndnsen = ddns._attributes.Enabled != undefined ? ddns._attributes.Enabled : "";
    rebootJson.dyndnsserver = ddns._attributes.Server != undefined ? ddns._attributes.Server : "";
    rebootJson.dyndnshost = ddns._attributes.Host != undefined ? ddns._attributes.Host : "";
    rebootJson.dyndnsuser = ddns._attributes.User != undefined ? ddns._attributes.User : "";
    rebootJson.dyndnspw = ddns._attributes.PW != undefined ? ddns._attributes.PW : "";
    rebootJson.ntpen = ntp._attributes.UseNTP != undefined ? ntp._attributes.UseNTP : "";
    rebootJson.ntpurl = ntp._attributes.URL != undefined ? ntp._attributes.URL : "";
 
  }, [discardUpdate]);

  React.useEffect(() => {
    if (props.language) {
      props.setHeaderTitle(props.language.setup);
    }
  }, [props.language]);
  const classes = useStyles();

  React.useEffect(() => {
    handleWiFiScan();
  }, []);

  React.useEffect(() => {
    imageGallery()
      .then((res) => {
        if (res.data) {
          
          let data = [];
          let imageData = res.data.split("DATA=")[1].split("\n").slice(0, -1);
          for (let i = 0; i < imageData.length; i++) {
            let fileData = {
              "name": "",
              "widgetId": "",
              "timestamp": ""
            }
            if (imageData[i].includes("cam")) {
              let camData = imageData[i].split("_");
              fileData.name = imageData[i];
              fileData.widgetId = camData[0].substring(3);
              fileData.timestamp = camData[1].substring(0, 14);
            } else {
              fileData.name = imageData[i];
              fileData.widgetId = "";
              fileData.widgetId = "";
            }
            data.push(fileData);

          }
          setImageList(data);
        }
      })
      .catch((error) => {
        
      });
  }, []);

  const datetimeextracter = (datetime) => {
    if (datetime) {
      let year = datetime.substring(0, 4);
      let month = datetime.substring(4, 6);
      let date = datetime.substring(6, 8);
      let hours = datetime.substring(8, 10);
      let minutes = datetime.substring(10, 12);
      let seconds = datetime.substring(12, 14);
      let datedata = date + "/" + month + "/" + year;
      let timestamp = "";
      if (hours > 12) {
        timestamp = hours + ":" + minutes + ":" + seconds + " PM";
      } else {
        timestamp = hours + ":" + minutes + ":" + seconds + " AM";
      }

      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Saturday'];
      let d = new Date(month + "/" + date + "/" + year);
      let dayName = days[d.getDay()];
      let finalresult = "<div>" + dayName + "</div>" + "<div>" + datedata + "</div>" + "<div>" + timestamp + "</div>";
      return finalresult;
    } else {
      return "";
    }
  }


  const handleChangePassphrase = (event) => {
    setPassphrasedecoded(event.target.value);
  };

  const handleChangeoptainIPAddress = (event) => {
    setOptainIPAddress(event.target.value);
    if(event.target.value==="optainIPAddressenabled"){
      getSystemIp()
      .then((res) => {
        
        let ipPart = res.data.systemIp.split(".");
        setIPAddress({
          ipaddresspart1: ipPart[0],
          ipaddresspart2: ipPart[1],
          ipaddresspart3: ipPart[2],
          ipaddresspart4: ipPart[3],
        });
        rebootJson.ipaddr = res.data.systemIp;
      })
      .catch((error) => {
        
      });
    }else{
      let wiserData = JSON.parse(JSON.stringify(props.data.Project.Info.Wiser));
      let ipPart = wiserData.PGIPAddress._text != undefined ? preInforWiser.PGIPAddress._text.split(".")  : [" "," "," "," "];
      rebootJson.ipaddr = wiserData.PGIPAddress._text != undefined ? preInforWiser.PGIPAddress._text : "";
      setIPAddress({
        ipaddresspart1: ipPart[0],
        ipaddresspart2: ipPart[1],
        ipaddresspart3: ipPart[2],
        ipaddresspart4: ipPart[3],
      });
    }
    
  };

  const handleChangeWifi = (event) => {
    setWifi(event.target.value);
    if (event.target.value === "wifienabled") {
      handleWiFiScan();
    }
  };

  const handleChangeIPAdreess = (event) => {
    const re = /^([0-9][0-9]{0,2}|256)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 255 && re.test(event.target.value))
    ) {
      setIPAddress({
        ...iPAddress,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeSubnetMask = (event) => {
    const re = /^([0-9][0-9]{0,2}|256)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 255 && re.test(event.target.value))
    ) {
      setSubnetMask({
        ...subnetMask,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeGateway = (event) => {
    const re = /^([0-9][0-9]{0,2}|256)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 255 && re.test(event.target.value))
    ) {
      setGateway({
        ...gateway,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeDNS1 = (event) => {
    const re = /^([0-9][0-9]{0,2}|256)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 255 && re.test(event.target.value))
    ) {
      setDNS1({
        ...dns1,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeDNS2 = (event) => {
    const re = /^([0-9][0-9]{0,2}|256)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 255 && re.test(event.target.value))
    ) {
      setDNS2({
        ...dns2,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeDNS3 = (event) => {
    const re = /^([0-9][0-9]{0,2}|256)$/;
    if (
      event.target.value === "" ||
      (event.target.value <= 255 && re.test(event.target.value))
    ) {
      setDNS3({
        ...dns3,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeNTP = (event) => {
    setNtp({
      ...ntp,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeNTPCheck = (event) => {
    setNtpCheck(event.target.value);
  };



  const handleChangeSambaclientCheck = (event) => {
    setSambaclientcheck(event.target.value);
  };

  const handleChangeSambaserverCheck = (event) => {
    setSambaservercheck(event.target.value);
  };

  const handleChangeSambaServer = (event) => {
    setSambaServer(event.target.value);
  };

  const handleChangeSamba = (event) => {
    setSamba({
      ...samba,
      [event.target.name]: event.target.value,
    });
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
	  // React.useEffect(() => {	
  //   setCallSSL(window.location.origin + "/setupssl.pl");	
  //  }, []);	
  const handleClickSSL = () => {	
    setOpenSSL(true);
    setupSSL()
    .then((res) => {
     
    })
    .catch((error) => {     

    });
    setTimeout(() => {	
			setTimer(true);	
		}, 180000) 	
	
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePopupClose = () => {
    setEncryptOpen(false);
  };

  const handleSSLPopupClose = () => {
    setOpenSSL(false);
  };

  const handleRebootNow = () => {
    handlesavechanges();
  };

  const handlesavechanges = () => {

      setOpen(false);
      let tdns1 =
        dns1.dns1part1 +
        "." +
        dns1.dns1part2 +
        "." +
        dns1.dns1part3 +
        "." +
        dns1.dns1part4;
      let tdns2 =
        dns2.dns2part1 +
        "." +
        dns2.dns2part2 +
        "." +
        dns2.dns2part3 +
        "." +
        dns2.dns2part4;
      let tdns3 =
        dns3.dns3part1 +
        "." +
        dns3.dns3part2 +
        "." +
        dns3.dns3part3 +
        "." +
        dns3.dns3part4;
      let urlparts =
        ntp.ntppart1 +
        "|" +
        ntp.ntppart2 +
        "|" +
        ntp.ntppart3 +
        "|" +
        ntp.ntppart4;
 
      infoWiser.WiFi._attributes.Enabled = wifi !== undefined ? "0" : ("wifienabled" ? "1" : "0");
      rebootJson.wifien = wifi == "wifienabled" ? "1" : "0";
      let decodestr = passphrasedecoded;
      let buff1 = new Buffer(decodestr);
      let stringtobase = buff1.toString("base64");
      infoWiser.WiFi._attributes.PW = stringtobase;
      rebootJson.wifipw = stringtobase;
      infoWiser.WiFi._attributes.Name = wifiSavedName;
      rebootJson.wifiname = wifiSavedName;
      infoWiser.DHCP._text = optainIPAddress === "optainIPAddressenabled" ? "1" : "0";
      rebootJson.dhcpen = optainIPAddress === "optainIPAddressenabled" ? "1" : "0";
      infoWiser.PGIPAddress._text =
        iPAddress.ipaddresspart1 +
        "." +
        iPAddress.ipaddresspart2 +
        "." +
        iPAddress.ipaddresspart3 +
        "." +
        iPAddress.ipaddresspart4;
      rebootJson.ipaddr =
        iPAddress.ipaddresspart1 +
        "." +
        iPAddress.ipaddresspart2 +
        "." +
        iPAddress.ipaddresspart3 +
        "." +
        iPAddress.ipaddresspart4;

      infoWiser.PGSubNetMask._text =
        subnetMask.subnetMaskpart1 +
        "." +
        subnetMask.subnetMaskpart2 +
        "." +
        subnetMask.subnetMaskpart3 +
        "." +
        subnetMask.subnetMaskpart4;

      rebootJson.subnet = subnetMask.subnetMaskpart1 === undefined ? "" :
        subnetMask.subnetMaskpart1 +
        "." +
        subnetMask.subnetMaskpart2 +
        "." +
        subnetMask.subnetMaskpart3 +
        "." +
        subnetMask.subnetMaskpart4;

      infoWiser.PGGatewayAddress._text =
        gateway.gatewaypart1 +
        "." +
        gateway.gatewaypart2 +
        "." +
        gateway.gatewaypart3 +
        "." +
        gateway.gatewaypart4;

      rebootJson.gwaddr = gateway.gatewaypart1 === undefined ? "" :
        gateway.gatewaypart1 +
        "." +
        gateway.gatewaypart2 +
        "." +
        gateway.gatewaypart3 +
        "." +
        gateway.gatewaypart4;

      infoWiser.DNS._text = tdns1 + "|" + tdns2 + "|" + tdns3;

      rebootJson.dns =   tdns1 + "|" + tdns2 + "|" + tdns3 ;
      
      infoWiser.SAMBAServer._attributes.Enabled = sambaservercheck === "sambaserverenabled" ? "1" : "0";
      rebootJson.smbserven = sambaservercheck === "sambaserverenabled" ? "1" : "0";
      infoWiser.SAMBAServer._attributes.WorkGroup = sambaserver;
      rebootJson.smbservworkgroup = sambaserver;
      infoWiser.SAMBAClient._attributes.Enabled = sambaclientcheck === "sambaclientenabled" ? "1" : "0";
      rebootJson.smbclien = sambaclientcheck === "sambaclientenabled" ? "1" : "0";
      infoWiser.SAMBAClient._attributes.User = samba.username;
      rebootJson.smbcliuser = samba.username;

      let decodestrSambaPW = samba.password;
      let buff2 = new Buffer(decodestrSambaPW);
      let sambaDecode = buff2.toString("base64");
      infoWiser.SAMBAClient._attributes.PW = sambaDecode;
      rebootJson.smbclipw = sambaDecode;
      infoWiser.SAMBAClient._attributes.Domain = samba.domain;
      rebootJson.smbclidomain = samba.domain;
      infoWiser.SAMBAClient._attributes.Host = samba.host;
      rebootJson.smbclihost = samba.host;
      infoWiser.SAMBAClient._attributes.Folder = samba.clientfolder;
      rebootJson.smbclifolder = samba.clientfolder;
      infoWiser.DDNS._attributes.Enabled = (dynamicDNSCheck === "dynamicdnscheckenabled") ? "1" : "0";
      rebootJson.dyndnsen = (dynamicDNSCheck === "dynamicdnscheckenabled") ? "1" : "0";
      let decodestrDNSPW = dynamicDNS.dnspassword;
      let buff3 = new Buffer(decodestrDNSPW);
      let dnsDecode = buff3.toString("base64");

      infoWiser.DDNS._attributes.User = dynamicDNS.dnsusername;
      rebootJson.dyndnsuser = dynamicDNS.dnsusername;
      infoWiser.DDNS._attributes.PW = dnsDecode;
      rebootJson.dyndnspw = dnsDecode;
      infoWiser.DDNS._attributes.Server = dynamicDNS.ddns;
      rebootJson.dyndnsserver = dynamicDNS.ddns;
      infoWiser.DDNS._attributes.Host = dynamicDNS.dnshost;
      rebootJson.dyndnshost = dynamicDNS.dnshost;

      infoWiser.NTP._attributes.UseNTP = ntpCheck === "ntpenabled" ? "1" : "0";
      rebootJson.ntpen = ntpCheck === "ntpenabled" ? "1" : "0";
      infoWiser.NTP._attributes.URL = urlparts;
      rebootJson.ntpurl = String(urlparts);
      props.data.Project.Info.Wiser = infoWiser;

      changeNetworkSettings(rebootJson).then((result) => {
        
        if(result.status==200){
          // rebootDevice().then((res) => {
          //   
          // }).catch((error) => {
          //   
          // });
        }
      }).catch((error) => {
        
      });
  };

  const wifiExtractor = (wifi) =>{
    if (wifi) {
      let wifiArray = wifi.split('&').slice(0, -1);
      
      let res = wifiArray.reduce(function (result, item, index, array) {
        result[item.split("=")[0].replace(/['"]+/g, '')] = item.split("=")[1].replace(/['"]+/g, '');
        return result;
      }, {})
      let result = [];
      let counter = 0;
      while (res['ESSID' + counter] != undefined) {
        let item = { 'ssid': res['ESSID' + counter], 'ENCTYPE': res['ENCTYPE' + counter] }
        result.push(item);
        counter++;
      }
      return result;
    }
    return [];
  } 


  const handleWiFiScan = () => {
    wifiScanner()
      .then((res) => {
        setWifiname(wifiExtractor(res.data.trim()));
      })
      .catch((error) => {
        
      });
  };

  const handleWiFiScanSave = (event) => {
    setWifiSavedName(event.target.value);
  };

  const handlediscardchanges = () => {
    setDiscardUpdate(!discardUpdate);

  };


  // const handleEncrypt = () => {
  //   setEncryptOpen(true);
  //   letEncrypt()
  //     .then((res) => {
  //       console.log("result", res);
  //       if(res.status === 200){
  //         setEncryptStatus(true);
  //         if(res.data.toString().includes("on_issue_success")){
  //           console.log("Lets Encrypt Certification created Successfully!");
  //           setEncryptMessage("Lets Encrypt Certification created Successfully!");
  //         }
  //         else{
  //           console.log("Lets Encrypt Certification generation failed. Please try again later or alternatively you can use Self Signed Certificate!");
  //           setEncryptMessage("Lets Encrypt Certification generation failed. Please try again later or alternatively you can use Self Signed Certificate!");
  //         }
  //       } 
  //     })
  //     .catch((error) => {
  //       console.log("Something went wrong. Please try again later");
  //       setEncryptMessage("Something went wrong. Please try again later");
  //     });
  // };


  // const handleEncrypt = () => {
  //   setEncryptOpen(true);
  //   letEncrypt()
  //     .then((res) => {

  //     })
  //     .catch((error) => {     

  //     });

  //     setTimeout(() => {
  //       encryptInfo().then((res) => {
  //         if(res.data.toString().includes("_on_issue_success")){
  //           setEncryptStatus(true); 
  //           console.log("Lets Encrypt Certification created Successfully!");
  //           setEncryptMessage("Lets Encrypt Certification created Successfully!");
  //         }
  //         if(res.data.toString().includes("_on_issue_err")){
  //           setEncryptStatus(true);
  //           console.log("Lets Encrypt Certification generation failed. Please try again later or alternatively you can use Self Signed Certificate!");
  //           setEncryptMessage("Lets Encrypt Certification generation failed. Please try again later or alternatively you can use Self Signed Certificate!");
  //         }   
  //         else {
  //           setEncryptStatus(true);
  //           console.log("Something went wrong. Please try again later");
  //           setEncryptMessage("Something went wrong. Please try again later");

  //         }
  //       }).catch((error) => {
  //           setEncryptStatus(true);
  //           console.log("Something went wrong. Please try again later");
  //           setEncryptMessage("Something went wrong. Please try again later");      
  //       });

  //     }, 5000 )
  // };

    const handleEncrypt = () => {
    setEncryptOpen(true);
    letEncrypt()
      .then((res) => {
       
      })
      .catch((error) => {     

      });

      setTimeout(() => {	
        handleGetEncrypt();
      }, 180000) 

  };

  
  const handleGetEncrypt = () => {
  setTimeout(() => {
    encryptInfo().then((res) => {
      if(res.data.toString().includes("_on_issue_success")){
        setEncryptStatus(true); 
        console.log("Lets Encrypt Certification created Successfully!");
        setEncryptMessage("Lets Encrypt Certification created Successfully!");
      }
      else if(res.data.toString().includes("_on_issue_err")){
        setEncryptStatus(true);
        console.log("Lets Encrypt Certification generation failed. Please try again later or alternatively you can use Self Signed Certificate!");
        setEncryptMessage("Lets Encrypt Certification generation failed. Please try again later or alternatively you can use Self Signed Certificate!");
      }   
      else {
        setEncryptStatus(true);
        setEncryptMessage("Please wait while we connect to Lets Encrypt server to generate your certificate...");
        handleGetEncrypt()
      }
    }).catch((error) => {
        setEncryptStatus(true);
        console.log("Something went wrong. Please try again later");
        setEncryptMessage("Something went wrong. Please try again later");      
    });

  }, 5000 )
}

  

  const handleChangeDynamicDNSCheck = (event) => {
    setDynamicDNSCheck(event.target.value);
  };

  const handleChangeDynamicDNS = (event) => {
    setDynamicDNS({
      ...dynamicDNS,
      [event.target.name]: event.target.value,
    });
  };
  const handleLanguageCahnge = (option) => {
    setLanguageName(option);

    props.data.Project.Info.Wiser.language._attributes.name = option;
    updateXml(props.data)
      .then((result) => {
        if (result.status === 200) {
          props.getAppConfigData();
        }
      })
      .catch((error) => {
        
      });
  };

  let renderRepeatDays = () => {
    return (
      <>
        <select
          className="setuppagedropdown"
          onChange={(e) => handleLanguageCahnge(e.target.value)}
          value={languageName}
          style={{ marginRight: "20px" }}
        >
          <option value="English">English</option>
          <option value="简体中文">简体中文</option>
          {props.languageNames &&
            props.languageNames.map((language) => <option value={language}>{language}</option>)}
        </select>
      </>
    );
  };

  let renderSSID = () => {
    return (
      <>
        <select
          className="setuppagedropdown"
          onClick={handleWiFiScanSave}
          style={{ marginRight: "20px" }}
          disabled={wifi === "wifidisabled"}
        >
           {wifiname !== wifiname.length &&
            wifiname.map((v) => <option value={v.ssid}>{v.ssid}</option>)}
        </select>
      </>
    );
  };
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [draweropen2, setDraweropen2] = React.useState(false);

  const toggleDrawer1 = (open) => (event) => {
    setDraweropen1(open);
  };

  const openGalleryImg = (open, name, timestamp) => (event) => {
    setCurrentImage(name);
    setDayDate(timestamp);
    setDraweropen2(open);
  };

  const Setupdrawer1 = () => {
    return (
      <>
        <div
          style={{
            overflowX: "hidden",
            background:
              "radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%)",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "25px 10px 5px 15px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <p style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>
                  Storage
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                padding: "15px",
              }}
            >
              <button
                className={classes.ulhome2setup}
                onClick={toggleDrawer1(false)}
              >
                X
              </button>
            </div>
          </div>

          <hr
            style={{
              border: "1px solid black",
              margin: "0",
              borderTopColor: "transparent",
            }}
          />
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {imageList && imageList.map(
              ({ name, wigetId, timestamp }, key) => (
                <div style={{ width: "15%", padding: "3%" }}>
                  <img
                    src={window.location.origin + "/getsnap.pl?name=" + name}
                    onClick={openGalleryImg(true, name, timestamp)}
                    style={{ height: "46%", width: "100%", alignSelf: "center", border: "4px solid #555" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      className={classes.headerTextGallery}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      {key + 1}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={classes.headerTextGallery}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: datetimeextracter(timestamp) }} />
                    </Typography>
                  </div>
                </div>
              )
            )}

          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <h3 style={{ color: "#737373", fontWeight: "600" }}>
            {props.language.basicSetup}
          </h3>
          <Divider
            style={{ margin: "10px 0", borderBottom: "1px solid #000" }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <span style={{ fontWeight: "600", color: "#fff" }}>
            {props.language.language}
          </span>
        </Grid>
        <Grid
          item
          lg={6}
          md={3}
          sm={12}
          xs={12}
          style={{ color: "#737373", fontWeight: "600" }}
        >
          {renderRepeatDays()}
        </Grid>
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <h3 style={{ color: "#737373", fontWeight: "600" }}>
            {props.language.networkSetup}
          </h3>
          <Divider
            style={{ margin: "10px 0", borderBottom: "1px solid #000" }}
          />
        </Grid>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <span
              style={{ fontWeight: "600", fontSize: "22px", color: "#fff" }}
            >
              <span>{props.language.lanSettings}</span>
            </span>
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.obtainIPAddress}
            </span>
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={optainIPAddress === "optainIPAddressenabled"}
              onChange={handleChangeoptainIPAddress}
              value="optainIPAddressenabled"
              name="optainIPAddress"
              className={
                optainIPAddress === "optainIPAddressenabled"
                  ? classes.colorSecondary
                  : ""
              }
            />{" "}
            {props.language.enabled}
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={optainIPAddress === "optainIPAddressdisabled"}
              onChange={handleChangeoptainIPAddress}
              value="optainIPAddressdisabled"
              name="optainIPAddress"
              className={
                optainIPAddress === "optainIPAddressdisabled"
                  ? classes.colorSecondary
                  : ""
              }
            />{" "}
            {props.language.disabled}
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.ipAddress}
            </span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              value={iPAddress.ipaddresspart1}
              onChange={handleChangeIPAdreess}
              name={"ipaddresspart1"}
              className={classes.setuppageinput}
              disabled={optainIPAddress === "optainIPAddressenabled"}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={iPAddress.ipaddresspart2}
              onChange={handleChangeIPAdreess}
              name={"ipaddresspart2"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={iPAddress.ipaddresspart3}
              onChange={handleChangeIPAdreess}
              name={"ipaddresspart3"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={iPAddress.ipaddresspart4}
              onChange={handleChangeIPAdreess}
              name={"ipaddresspart4"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.subnetMask}
            </span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={subnetMask.subnetMaskpart1}
              onChange={handleChangeSubnetMask}
              name={"subnetMaskpart1"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={subnetMask.subnetMaskpart2}
              onChange={handleChangeSubnetMask}
              name={"subnetMaskpart2"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={subnetMask.subnetMaskpart3}
              onChange={handleChangeSubnetMask}
              name={"subnetMaskpart3"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={subnetMask.subnetMaskpart4}
              onChange={handleChangeSubnetMask}
              name={"subnetMaskpart4"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.gateway}
            </span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={gateway.gatewaypart1}
              onChange={handleChangeGateway}
              name={"gatewaypart1"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={gateway.gatewaypart2}
              onChange={handleChangeGateway}
              name={"gatewaypart2"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={gateway.gatewaypart3}
              onChange={handleChangeGateway}
              name={"gatewaypart3"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={gateway.gatewaypart4}
              onChange={handleChangeGateway}
              name={"gatewaypart4"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.dns1}
            </span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns1.dns1part1}
              onChange={handleChangeDNS1}
              name={"dns1part1"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns1.dns1part2}
              onChange={handleChangeDNS1}
              name={"dns1part2"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns1.dns1part3}
              onChange={handleChangeDNS1}
              name={"dns1part3"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns1.dns1part4}
              onChange={handleChangeDNS1}
              name={"dns1part4"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.dns2}
            </span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns2.dns2part1}
              onChange={handleChangeDNS2}
              name={"dns2part1"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns2.dns2part2}
              onChange={handleChangeDNS2}
              name={"dns2part2"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns2.dns2part3}
              onChange={handleChangeDNS2}
              name={"dns2part3"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns2.dns2part4}
              onChange={handleChangeDNS2}
              name={"dns2part4"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.dns3}
            </span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns3.dns3part1}
              onChange={handleChangeDNS3}
              name={"dns3part1"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns3.dns3part2}
              onChange={handleChangeDNS3}
              name={"dns3part2"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns3.dns3part3}
              onChange={handleChangeDNS3}
              name={"dns3part3"}
              className={classes.setuppageinput}
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            style={{ textAlign: "center", height: "34px" }}
          >
            <span style={{ color: "#737373" }}> {"  -  "}</span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <input
              type="text"
              disabled={optainIPAddress === "optainIPAddressenabled"}
              value={dns3.dns3part4}
              onChange={handleChangeDNS3}
              name={"dns3part4"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>
      </Grid>

      {props.data.Project.Info.ProjectType._text === "11" && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider
              style={{ margin: "10px 0", borderBottom: "1px solid #000" }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "22px",
                marginBottom: "10px",
                color: "#fff",
              }}
            >
              <span>{props.language.wifiSettings}</span>
            </span>
            {/* <h3 style={{color:"#fff", fontWeight: "600"}}>WiFI Settings</h3> */}
          </Grid>
        </Grid>
      )}

      {props.data.Project.Info.ProjectType._text === "11" && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.enableWifi}
            </span>
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={wifi === "wifienabled"}
              onChange={handleChangeWifi}
              value="wifienabled"
              name="wifi"
              className={wifi === "wifienabled" ? classes.colorSecondary : ""}
            />{" "}
            {props.language.enabled}
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={wifi === "wifidisabled"}
              onChange={handleChangeWifi}
              value="wifidisabled"
              name="wifi"
              className={wifi === "wifidisabled" ? classes.colorSecondary : ""}
            />{" "}
            {props.language.disabled}
          </Grid>
        </Grid>
      )}

      {props.data.Project.Info.ProjectType._text === "11" && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.ssid}
            </span>
          </Grid>
          <Grid
            item
            lg={6}
            md={5}
            sm={12}
            xs={12}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            {renderSSID()}
          </Grid>
        </Grid>
      )}

      {props.data.Project.Info.ProjectType._text === "11" && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.grid12Center}
          style={{ display: passen }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.passphrase}
            </span>
          </Grid>
          <Grid
            item
            lg={4}
            md={3}
            sm={12}
            xs={12}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <input
              value={passphrasedecoded}
              onChange={handleChangePassphrase}
              name={"passphrasedecoded"}
              type="password"
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider
            style={{ margin: "10px 0", borderBottom: "1px solid #000" }}
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: "10px" }}
        >
          <span style={{ fontWeight: "600", fontSize: "22px", color: "#fff" }}>
            {props.language.ntp}
          </span>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.useNtpToMaintain}
            </span>
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={ntpCheck === "ntpenabled"}
              onChange={handleChangeNTPCheck}
              value="ntpenabled"
              name="ntpCheck"
              className={
                ntpCheck === "ntpenabled" ? classes.colorSecondary : ""
              }
            />{" "}
            {props.language.enabled}
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={ntpCheck === "ntpdisabled"}
              onChange={handleChangeNTPCheck}
              value="ntpdisabled"
              name="ntpCheck"
              className={
                ntpCheck === "ntpdisabled" ? classes.colorSecondary : ""
              }
            />{" "}
            {props.language.disabled}
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.ntp1Url}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={ntpCheck === "ntpdisabled"}
                value={ntp.ntppart1}
                onChange={handleChangeNTP}
                name={"ntppart1"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.ntp2Url}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={ntpCheck === "ntpdisabled"}
                value={ntp.ntppart2}
                onChange={handleChangeNTP}
                name={"ntppart2"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.ntp3Url}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={ntpCheck === "ntpdisabled"}
                value={ntp.ntppart3}
                onChange={handleChangeNTP}
                name={"ntppart3"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.ntp4Url}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={ntpCheck === "ntpdisabled"}
                value={ntp.ntppart4}
                onChange={handleChangeNTP}
                name={"ntppart4"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider
            style={{ margin: "10px 0", borderBottom: "1px solid #000" }}
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: "10px" }}
        >
          <span style={{ fontWeight: "600", fontSize: "22px", color: "#fff" }}>
            {props.language.sambaConfiguration}
          </span>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.enableSambaServer}
            </span>
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={sambaservercheck === "sambaserverenabled"}
              onChange={handleChangeSambaserverCheck}
              value="sambaserverenabled"
              name="sambaservercheck"
              className={
                sambaservercheck === "sambaserverenabled"
                  ? classes.colorSecondary
                  : ""
              }
            />{" "}
            {props.language.enabled}
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={sambaservercheck === "sambaserverdisabled"}
              onChange={handleChangeSambaserverCheck}
              value="sambaserverdisabled"
              name="sambaservercheck"
              className={
                sambaservercheck === "sambaserverdisabled"
                  ? classes.colorSecondary
                  : ""
              }
            />{" "}
            {props.language.disabled}
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.workgroup}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={sambaservercheck === "sambaserverdisabled"}
                value={sambaserver}
                onChange={handleChangeSambaServer}
                name={"workgroup"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.enableSambaClient}
            </span>
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={sambaclientcheck === "sambaclientenabled"}
              onChange={handleChangeSambaclientCheck}
              value="sambaclientenabled"
              name="sambaclientcheck"
              className={
                sambaclientcheck === "sambaclientenabled"
                  ? classes.colorSecondary
                  : ""
              }
            />{" "}
            {props.language.enabled}
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sm={3}
            xs={4}
            style={{ color: "#737373", fontWeight: "600" }}
          >
            <Radio
              checked={sambaclientcheck === "sambaclientdisabled"}
              onChange={handleChangeSambaclientCheck}
              value="sambaclientdisabled"
              name="sambaclientcheck"
              className={
                sambaclientcheck === "sambaclientdisabled"
                  ? classes.colorSecondary
                  : ""
              }
            />{" "}
            {props.language.disabled}
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.username}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={sambaclientcheck === "sambaclientdisabled"}
                value={samba.username}
                onChange={handleChangeSamba}
                name={"username"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.password}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={sambaclientcheck === "sambaclientdisabled"}
                value={samba.password}
                onChange={handleChangeSamba}
                name={"password"}
                type="password"
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.domain}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={sambaclientcheck === "sambaclientdisabled"}
                value={samba.domain}
                onChange={handleChangeSamba}
                name={"domain"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.host}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={sambaclientcheck === "sambaclientdisabled"}
                value={samba.host}
                onChange={handleChangeSamba}
                name={"host"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>

          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ alignItems: "center", marginBottom: "10px" }}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <span style={{ color: "#737373", fontWeight: "600" }}>
                {props.language.clientFolder}
              </span>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <input
                disabled={sambaclientcheck === "sambaclientdisabled"}
                value={samba.clientfolder}
                onChange={handleChangeSamba}
                name={"clientfolder"}
                className={classes.setuppageinput}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      ></Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider style={{ margin: "10px 0", borderBottom: "1px solid #000" }} />
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ marginBottom: "10px" }}
      >
        <span style={{ fontWeight: "600", fontSize: "22px", color: "#fff" }}>
          {props.language.dns}
        </span>
        {/* <h3 style={{color:"#fff", fontWeight: "600"}}>DNS</h3>
          <Divider style={{ margin: "10px 0", borderBottom: "1px solid #000" }} /> */}
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <span style={{ color: "#737373", fontWeight: "600" }}>
            {props.language.dynamicDnsEnabled}
          </span>
        </Grid>
        <Grid
          item
          lg={2}
          md={3}
          sm={3}
          xs={4}
          style={{ color: "#737373", fontWeight: "600" }}
        >
          <Radio
            checked={dynamicDNSCheck === "dynamicdnscheckenabled"}
            onChange={handleChangeDynamicDNSCheck}
            value="dynamicdnscheckenabled"
            name="dynamicDNSCheck"
            className={
              dynamicDNSCheck === "dynamicdnscheckenabled"
                ? classes.colorSecondary
                : ""
            }
          />{" "}
          {props.language.enabled}
        </Grid>
        <Grid
          item
          lg={2}
          md={3}
          sm={3}
          xs={4}
          style={{ color: "#737373", fontWeight: "600" }}
        >
          <Radio
            checked={dynamicDNSCheck === "dynamicdnscheckdisabled"}
            onChange={handleChangeDynamicDNSCheck}
            value="dynamicdnscheckdisabled"
            name="dynamicDNSCheck"
            className={
              dynamicDNSCheck === "dynamicdnscheckdisabled"
                ? classes.colorSecondary
                : ""
            }
          />{" "}
          {props.language.disabled}
        </Grid>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.ddnsService}
            </span>
          </Grid>
          <Grid item lg={4} md={3} sm={6} xs={12}>
            <input
              disabled={dynamicDNSCheck === "dynamicdnscheckdisabled"}
              value={dynamicDNS.ddns}
              onChange={handleChangeDynamicDNS}
              name={"ddns"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.hostName}
            </span>
          </Grid>
          <Grid item lg={4} md={3} sm={6} xs={12}>
            <input
              disabled={dynamicDNSCheck === "dynamicdnscheckdisabled"}
              value={dynamicDNS.dnshost}
              onChange={handleChangeDynamicDNS}
              name={"dnshost"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.username}
            </span>
          </Grid>
          <Grid item lg={4} md={3} sm={6} xs={12}>
            <input
              disabled={dynamicDNSCheck === "dynamicdnscheckdisabled"}
              value={dynamicDNS.dnsusername}
              onChange={handleChangeDynamicDNS}
              name={"dnsusername"}
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#737373", fontWeight: "600" }}>
              {props.language.password}
            </span>
          </Grid>
          <Grid item lg={4} md={3} sm={6} xs={12}>
            <input
              disabled={dynamicDNSCheck === "dynamicdnscheckdisabled"}
              value={dynamicDNS.dnspassword}
              onChange={handleChangeDynamicDNS}
              name={"dnspassword"}
              type="password"
              className={classes.setuppageinput}
            />
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "20px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#fff", fontWeight: "600" }}>Storage</span>
          </Grid>
          <Grid item lg={4} md={3} sm={6} xs={12}>
            <button
              className={classes.ulhome2setup}
              onClick={toggleDrawer1(true)}
            >
              Manage files
            </button>
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignItems: "center", marginBottom: "20px" }}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <span style={{ color: "#fff", fontWeight: "600" }}>Upgrade</span>
          </Grid>
          <Grid item lg={4} md={4} sm={8} xs={12}>
            <a
              href={window.location.origin + "/firmwareupgrade.html"}
              target="_blank"
              rel="noreferrer"
              className={classes.ulhome2setup}
            >
              Wiser Manual Upgrade
            </a>
            <Button
              className={classes.ulhome2setupButton}
              onClick={handleEncrypt}
              style={{ marginLeft: "15px" }}
              disabled={!checkDNS}
            >
              Let's Encrypt
            </Button>

            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen1}
              onClose={toggleDrawer1(false)}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: false, // Better open performance on mobile.
              }}
            >
              {Setupdrawer1()}
            </Drawer>

            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen2}
              onClose={openGalleryImg(false)}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: false, // Better open performance on mobile.
              }}
            >
              <EditSurveillancePhotoSetup
                functionGroups={props.data.functionGroups}
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                curImage={curImage}
                dayDate={dayDate}
                openGalleryImg={openGalleryImg}
              />
            </Drawer>
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            alignItems: "center",
            marginBottom: "20px",
            justifyContent: "center",
          }}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <button className={classes.ulhome2setup} onClick={handleClickSSL}>
              <a
                // href={window.location.origin + "/setupssl.pl"}
                // target="_blank"
                // rel="noreferrer"
                className={classes.ulhome2setup}
              >
                Setup SSL
              </a>
            </button>

            <button
              className={classes.ulhome2setup}
              style={{ marginLeft: "15px" }}
            >
              <a
                href={window.location.origin + "/certificatesupload.html"}
                target="_blank"
                rel="noreferrer"
                className={classes.ulhome2setup}
              >
                CA Cert Upload
              </a>
            </button>
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            alignItems: "center",
            marginBottom: "20px",
            justifyContent: "center",
          }}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <button
              className={classes.ulhome2setup}
              onClick={handlediscardchanges}
            >
              {props.language.discardChanges}
            </button>
            <button
              className={classes.ulhome2setup}
              style={{ marginLeft: "15px" }}
              onClick={handleClickOpen}
            >
              {props.language.saveChanges}
            </button>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.language.rebootContinueDescription}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className={classes.drawerbtn}>
            {props.language.cancel}
          </Button>
          <Button
            onClick={handleRebootNow}
            className={classes.drawerbtn}
            autoFocus
          >
            {props.language.confirm}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={encryptOpen}
        onClose={handlePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!encryptStatus ? (
          <DialogTitle id="alert-dialog-title">
            {
              "Please wait while we connect to Let's Encrypt servers to generate the certificate. This might take up to 3 minutes..."
            }
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{encryptMessage}</DialogTitle>
        )}

        <DialogActions>
          <Button
            disabled={!encryptStatus}
            onClick={handlePopupClose}
            className={classes.drawerbtn}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={openSSL}
        onClose={handleSSLPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {!timer
            ? "Please wait while we generate self signed certificate. This may take up to 3 minutes..."
            : "Download Wiser2 Root crt file using " +
              JSON.stringify(checkSSL).replace(/['"]+/g, "") +
              "/rootcert" +
              " and Client crt file using " +
              JSON.stringify(checkSSL).replace(/['"]+/g, "") +
              "/sslcert"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleSSLPopupClose}
            disabled={!timer}
            className={classes.drawerbtn}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      setHeaderTitle,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SetupDetails);
