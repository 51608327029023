/*eslint-disable*/
import React, { useState } from "react";
import axios from 'axios'
import classNames from "classnames";
import { NavLink, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
//import PerfectScrollbar from "perfect-scrollbar";

import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import MenuSelectImg from "assets/img/Menu-Select-Img.png";

import "assets/js/all-styles/components/sidebarCustomStyle.css";
import styles from "assets/js/all-styles/components/sidebarStyle.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  functionGroupIdUpdate,
  locationIdUpdate,
  setHeaderTitle,
  favouriteOrStatesChanger
} from "../../redux/actions/action";

const useStyles = makeStyles(styles);


const SidebarWrapper =(props)=>{
  
  let sidebarWrapper = React.createRef();
  const { className, links, brand } = props;
  let ps;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebarWrapper.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [sidebarWrapper]);

  return (
    <div className={className} ref={sidebarWrapper}>
      {brand}
      {links}
    </div>
  );
}

const Sidebar = (props) => {

  const classes = useStyles();
  const [func, setFunc] = useState(false);
  const [location,setLocation] = useState(false);
  const [setting,setSetting] = useState(false);
  const [active,setActive] = useState({});
  const [currentUserUrl,setCurrentUserUrl] = useState("");
  const [currentUserName,setCurrentUserName] = useState("");
  const [scenes,setScenes] = useState("1");
  const mainPanel = React.createRef();
  const handleLocation=()=> {
    setLocation(!location);
    setFunc(false);
    setSetting(false);
    setActive(props.data.locations[0]._attributes.id);
    props.setHeaderTitle(props.data.locations[0]._attributes.Name);
    props.locationIdUpdate(props.data.locations[0]._attributes.id);
    localStorage.setItem("locationId", props.data.locations[0]._attributes.id);
    localStorage.setItem("functionId", null);
    localStorage.setItem("headerTitle", props.data.locations[0]._attributes.Name);
    localStorage.setItem("active", props.data.locations[0]._attributes.id);
    localStorage.setItem("function", false);
    localStorage.setItem("location", !location);
    localStorage.setItem("setting", false);
  };
  const handleFunc = () => {
    setFunc(!func);
    setLocation(false);
    setSetting(false);
    setActive(props.data.functionGroups[0]._attributes.id);
    props.setHeaderTitle(props.data.functionGroups[0]._attributes.Name);
    props.functionGroupIdUpdate(props.data.functionGroups[0]._attributes.id);
    localStorage.setItem("locationId", null);
    localStorage.setItem("functionId", props.data.functionGroups[0]._attributes.id);
    localStorage.setItem("headerTitle", props.data.functionGroups[0]._attributes.Name);
    localStorage.setItem("active", props.data.functionGroups[0]._attributes.id);
    localStorage.setItem("function", true);
    localStorage.setItem("location", false);
    localStorage.setItem("setting", false);
  };
  const handleSetting = () => {
    setSetting(!setting);
    setFunc(false);
    setLocation(false);
    props.setHeaderTitle(props.data.language.profiles);
    localStorage.setItem("headerTitle", props.data.language.profiles);
    localStorage.setItem("function", false);
    localStorage.setItem("location", false);
    localStorage.setItem("setting", true);
  }

  const handleSettings = (name) => {
    if (name === "profile") {
      props.setHeaderTitle(props.data.language.profiles);
      localStorage.setItem("headerTitle", "profile");
    }
    if (name === "schedules") {
      props.setHeaderTitle(props.data.language.schedules);
      localStorage.setItem("headerTitle", "schedules");
    }
    if (name === "editScenes") {
      props.setHeaderTitle(props.data.language.editScenes);
      localStorage.setItem("headerTitle", "editScenes");
    }
    if (name === "setup") {
      props.setHeaderTitle(props.data.language.setup);
      localStorage.setItem("headerTitle", "setup");
    }
    if (name === "systemUtilities") {
      props.setHeaderTitle(props.data.language.systemUtilities);
      localStorage.setItem("headerTitle", "systemUtilities");
    }
  }
  const handleHome = () =>{
    setSetting(false);
    setFunc(false);
    setLocation(false);
    props.setHeaderTitle(props.data.language.home);
    localStorage.setItem("headerTitle", props.data.language.home);
    localStorage.setItem("function", false);
    localStorage.setItem("location", false);
    localStorage.setItem("setting", false);
    props.favouriteOrStatesChanger(true);
  }
  const handleActive = (name,functionGroup) =>{
    setActive(functionGroup);
    props.setHeaderTitle(name);
    props.functionGroupIdUpdate(functionGroup);
    localStorage.setItem("functionId", functionGroup);
    localStorage.setItem("headerTitle", name);
    localStorage.setItem("active", functionGroup);
  };
  const handleLocationActive = (name, location) => {
    setActive(location);
    props.setHeaderTitle(name);
    props.locationIdUpdate(location);
    localStorage.setItem("locationId", location);
    localStorage.setItem("headerTitle", name);
    localStorage.setItem("active", location);
  };
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  // const activeStyle = { backgroundC: "#307D15" };

  React.useEffect(() => {
    if(props.history.location.pathname==="/home/favorite" || props.history.location.pathname==="/home/stats"){
      props.setHeaderTitle("Home");
    }else{
    props.setHeaderTitle(localStorage.getItem("headerTitle"));
    setActive(localStorage.getItem("active"));
    props.functionGroupIdUpdate(localStorage.getItem("functionId"));
    props.locationIdUpdate(localStorage.getItem("locationId"));
    if(localStorage.getItem("function")==="true"){
      setFunc(true);
    }else{
      setFunc(false);
    }
    if(localStorage.getItem("location")==="true"){
      setLocation(true);
    }else{
      setLocation(false);
    }
    if(localStorage.getItem("setting")==="true"){
      setSetting(true);
    }else{
      setSetting(false);
    }
    }

  }, []);

  const profileaccess = (hex) => {
    if (hex !== undefined) {
      let profileAccessList = hex2bin(hex).split("").reverse();
      if (props.remoteUserDetails) {
        if (profileAccessList[Number(props.remoteUserDetails.id)] === '1') {
          return true
        } else {
          return false;
        }

      } else {
        return false
      }
    } else {
      return true;
    }
  };

  const hex2bin = (hex) => {
      return (parseInt(hex, 16).toString(2)).padStart(16, '0').toString();
  }

  React.useEffect(() => {
    if (props.remoteUserDetails) {
      setCurrentUserUrl(window.location.origin + "/getsnap.pl?name=" + "profile_" + props.remoteUserDetails.User + "_" + props.remoteUserDetails.id + ".jpg");
      setCurrentUserName(props.remoteUserDetails.Name)
    }
  }, [props.remoteUserDetails]);
  
  const activeStyle = {
    background:
      "linear-gradient( rgba(64,128,12,1) 0%, rgba(72,150,12,1) 50%, rgba(72,150,12,1) 51%,  rgba(64,128,12,1) 100%)",
  };
  const { color, logo, image, logoText, routes, icon } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <div>
            { prop.name==="Home" &&
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="sideMenuActive"
            key={key}
          >
            <ListItem button onClick={handleHome} className={classes.itemLink + listItemClasses}>
              {<div className={classes.imgcon}><img className={classes.imgwidth} src={prop.icon} alt={prop.name + " Logo"} /></div>}
              <ListItemText
                primary={props.data.language.home}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
            }
           { prop.name==="Functions" &&
          <Link
            to={"/home/functions"}
            className={activePro + classes.item}
            key={key}
          >
            <ListItem button style={{backgroundColor: "transparent"}} onClick={handleFunc} className={classes.itemLink + listItemClasses}>
              {<div className={classes.imgcon}><img className={classes.imgwidth} src={prop.icon} alt={prop.name + " Logo"} /></div>}
              <ListItemText
                primary={props.data.language.functions}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
              { func ? <IconExpandMore className={classNames(classes.itemText, whiteFontClasses)} /> : <IconExpandLess className={classNames(classes.itemText, whiteFontClasses)} />}
            </ListItem>
              <Collapse in={func} timeout="auto" unmountOnExit>
                <Divider />
                <List component="div" disablePadding>
                  {props.data.functionGroups != undefined && props.data.functionGroups.map((f, key) => {
                    return (
                      <Link
                        to={"/home/functions"}
                        className={activePro + classes.item}
                        key={key}
                        style={active === f._attributes.id ? activeStyle : null}
                        onClick={() => handleActive(f._attributes.Name, f._attributes.id)}
                      >

                        {
                          profileaccess(f._attributes.Profiles) &&
                          <ListItem button className={classes.menuItem}>
                            <ListItemText
                              inset
                              primary={f._attributes.Name}
                              className={classNames(
                                classes.itemText,
                                whiteFontClasses
                              )}
                            />
                          </ListItem>
                        }

                      </Link>
                    )
                  })
                  }
                </List>
              </Collapse>
          </Link>
            }
          { prop.name==="Locations" &&
          <NavLink
            to={"/home/locations"}
            className={activePro + classes.item}
            key={key}
          >
            <ListItem style={{backgroundColor: "transparent"}} button onClick={handleLocation} className={classes.itemLink + listItemClasses}>
              {<div className={classes.imgcon}><img className={classes.imgwidth} src={prop.icon} alt={prop.name + " Logo"} /></div>}
              <ListItemText
                primary={props.data.language.locations}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
              { location ? <IconExpandMore className={classNames(classes.itemText, whiteFontClasses)} /> : <IconExpandLess className={classNames(classes.itemText, whiteFontClasses)} />}
            </ListItem>
            <Collapse in={location} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
               { props.data.locations !=undefined && props.data.locations.map((location, key) => {
                 return(
                     <NavLink
                       to={"/home/locations"}
                       className={activePro + classes.item}
                       style={active === location._attributes.id ? activeStyle : null}
                       onClick={()=>handleLocationActive(location._attributes.Name, location._attributes.id)}
                       key={key}
                     >
                         {
                            profileaccess(location._attributes.Profiles) &&
                            <ListItem button className={classes.menuItem}>
                              <ListItemText inset primary={location._attributes.Name} className={classNames(classes.itemText, whiteFontClasses)} />
                            </ListItem>
                         }
                     </NavLink>
                 )
               })
              }
              </List>
            </Collapse>
          </NavLink>
            }
            { prop.name==="Settings" && 
          <NavLink
            to={"/home/profile"}
            className={activePro + classes.item}
            activeClassName="active"
            
            key={key}
          >
            <ListItem button onClick={handleSetting} className={classes.itemLink + listItemClasses}>
              {<div className={classes.imgcon}><img className={classes.imgwidth} src={prop.icon} alt={prop.name + " Logo"} /></div>}
              <ListItemText
                primary={props.data.language.settings}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
              { setting ? <IconExpandMore  className={classNames(classes.itemText, whiteFontClasses)} /> : <IconExpandLess className={classNames(classes.itemText, whiteFontClasses)} />}
            </ListItem>

            <Collapse in={setting} timeout="auto" unmountOnExit>
              <Divider />
                <List component="div" disablePadding>
                  <NavLink
                    to={"/home/profile"} 
                    className={activePro + classes.item}
                    activeClassName="sideMenuActive"
                    onClick={()=>{handleSettings("profile")}}
                  >
                    <ListItem button className={classes.menuItem}>
                      <ListItemText inset primary={props.data.language.profiles} className={classNames(classes.itemText, whiteFontClasses)} />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    to={"/home/editscenes"}
                    //className= {activePro + classes.item}
                    className= {props.remoteUserDetails ? (props.remoteUserDetails.Scenes === "1" ? activePro + classes.item : classes.itemDisable): activePro + classes.item}
                    activeClassName="sideMenuActive"
                    onClick={()=>{handleSettings("editScenes")}}                    
                  >
                  <ListItem button className={classes.menuItem}>
                    <ListItemText inset primary={props.data.language.editScenes} className={classNames(classes.itemText, whiteFontClasses)} />
                  </ListItem>
                  </NavLink>
                  <NavLink
                    to={"/home/Schedules"}
                    //className= {activePro + classes.item}
                    className={props.remoteUserDetails ? (props.remoteUserDetails.Schedules === "1" ? activePro + classes.item : classes.itemDisable) : activePro + classes.item}
                    activeClassName="sideMenuActive"
                    onClick={()=>{handleSettings("schedules")}}
                  >
                  <ListItem button className={classes.menuItem}>
                    <ListItemText inset primary={props.data.language.schedules} className={classNames(classes.itemText, whiteFontClasses)} />
                  </ListItem>
                  </NavLink>
                  <NavLink
                    to={"/home/setup"}
                    //className= {activePro + classes.item}
                    className={props.remoteUserDetails ? (props.remoteUserDetails.Tools === "1" ? activePro + classes.item : classes.itemDisable) : activePro + classes.item }
                    activeClassName="sideMenuActive"
                    onClick={()=>{handleSettings("setup")}}
                  >
                  <ListItem button className={classes.menuItem}>
                    <ListItemText inset primary={props.data.language.setup} className={classNames(classes.itemText, whiteFontClasses)} />
                  </ListItem>
                  </NavLink>
                  <NavLink
                    to={"/home/systemutilities"}
                    //className= {activePro + classes.item}
                    className={props.remoteUserDetails ? (props.remoteUserDetails.Time === "1" ? activePro + classes.item : classes.itemDisable) : activePro + classes.item }
                    activeClassName="sideMenuActive"
                    onClick={()=>{handleSettings("systemUtilities")}}
                  >
                  <ListItem button className={classes.menuItem}>
                    <ListItemText inset primary={props.data.language.systemUtilities} className={classNames(classes.itemText, whiteFontClasses)} />
                  </ListItem>
                  </NavLink>
                  {/* <NavLink
                    to={"/home/stats"}
                    className={activePro + classes.item}
                    activeClassName="sideMenuActive"
                    onClick={()=>{}}
                    style={{display:"none"}}
                  >
                  <ListItem button className={classes.menuItem}>
                    <ListItemText inset primary={props.data.language.stats} className={classNames(classes.itemText, whiteFontClasses)} />
                  </ListItem>
                  </NavLink> */}
                </List>
            </Collapse>
          </NavLink>
            }
          </div>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logoandtext}>
      <div className={classes.imgcontainer}>
        {/* <img src={window.location.origin +"/getsnap.pl?name="+props.data.userUrl}  onError={(e) =>{ e.target.src = 'https://c1.staticflickr.com/4/3896/14550191836_cc0675d906.jpg'}} alt="logo" className={classes.img} /> */}
        <img
          src={currentUserUrl} 
          onError={(e) => {
            e.target.src =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUkAAADcCAYAAAD9VlPsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAALRSURBVHhe7dQxAYBAEMCwAznn3x8sjE8VJEsd9NrdZwA4ur8CcGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAYJJAgSTBAgmCRBMEiCYJEAwSYBgkgDBJAGCSQIEkwQIJgkQTBIgmCRAMEmAYJIAwSQBgkkCBJMECCYJEEwSIJgkQDBJgGCSAMEkAX7NvBS6AzUpvTQCAAAAAElFTkSuQmCC";
          }}
          alt="logo"
          className={classes.img}
        />
      </div>
      <div>
        <Typography variant="h6" className={classes.logoh6text}>
          {currentUserName}
        </Typography>
      </div>
    </div>
  );
  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SidebarWrapper
            className={classes.sidebarWrapper}
            links={links}
            brand={brand}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          <SidebarWrapper
            className={classes.sidebarWrapper}
            links={links}
            brand={brand}
          />
        </Drawer>
      </Hidden>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      functionGroupIdUpdate,
      locationIdUpdate,
      setHeaderTitle,
      favouriteOrStatesChanger
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Sidebar);
