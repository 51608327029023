import ReactSpeedometer from "react-d3-speedometer"
import WeekKwhRateConsumption from "../../PowerConsumption/kwh/WeekKwhRateConsumption"
import { useState, useEffect } from "react";
import { connect } from "react-redux";

function KwhWeekSpeedoTotal(props) {
  const [meterValue, setMeterValue] =useState("");

  let minMeterValue = Number(props.widget.params._attributes.max_weekly_gen_w)/1000;

  let maxMeterValue = Number(props.widget.params._attributes.max_weekly_con_w)/1000;

//let meterData = (meterValue/1000).toFixed(2).replace("-", " ");

let meterData = (meterValue/1000).toFixed(2);

let meterResult = meterData >= 9999 ? 9999 : meterData;

let meterText = (meterValue/1000).toFixed(2).replace("-", " ");

useEffect(() => {
  if (props.totalPowerData) {
      if (props.totalPowerData.name === "cbusPowerTotals") {
       setMeterValue(props.totalPowerData.current_total_power_week);
    }
  }
}, [props.cbusEvent]);


    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
      <h6 style={{margin: '0px' }}>{props.language.week}</h6>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxSegmentLabels={1}
                minValue={-minMeterValue}
                maxValue={maxMeterValue}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                customSegmentStops={[-minMeterValue, 0, maxMeterValue]}
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterText} kWh`}
                needleTransitionDuration={3000}
                startColor="green"
                endColor="red"
                segmentColors={["#85cb06", "#f0c20a"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={180}
                height={120}
               dimensionUnit="px"

        />
        <WeekKwhRateConsumption
          powerData={props.powerData}
          totalPowerDataHistory={props.totalPowerDataHistory}
          data={props.data}
          widget={props.widget}
          energy={props.energy}
          language={props.language} />
      </div>
    );
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(KwhWeekSpeedoTotal);