import React from "react";
import "assets/css/SystemUtilities.css";
import SysUtil from "views/Settings/SysUtil.js";
import { connect } from "react-redux";
import { getAppConfigData, profileUserChanger } from "redux/actions/action";
import { bindActionCreators } from "redux";
import { updateSocketEvent } from "../../services/xmlService";

function SystemUtilities(props) {

  const [cbusdata, setCbusdata] = React.useState();

  const handleSocketEventChange = (data) => {
    updateSocketEvent(data)
      .then((res) => {
        
      })
      .catch((error) => {
        
      });
  };




  return (
    <div className="mainPannelSide">
     {props.data&&
     <>
       <SysUtil data = {props.data} cbusEvent ={props.cbusEvent} initialCbusEvent={props.initialCbusEvent} cbusdata={cbusdata} handleSocketEventChange={handleSocketEventChange} language={props.language} getAppConfigData={getAppConfigData} />
          </>

            }
    
    </div>
            
  );
}


const mapStateToProps = (state) => ({
  data: state.reducer.data,
  cbusEvent: state.reducer.cbusEvent,
  initialCbusEvent: state.eventsReducer.socketEvents,
  language: state.reducer.language
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData,
      profileUserChanger,
    },
    dispatch
  );



export default connect(mapStateToProps, mapDispatchToProps)(SystemUtilities);