import ReactSpeedometer from "react-d3-speedometer"
import WeekKwhRateConsumption from "../../PowerConsumption/kwh/WeekKwhRateConsumption"
import { useState, useEffect } from "react";


function Speedo(props) {
  const [meterValue, setMeterValue] =useState("");

let meterData = (meterValue/1000).toFixed(2);

let maxMeterValue = (Number(props.widget.params._attributes.max_weekly_w)/1000);

let meterResult = meterData >= 9999 ? 9999 : meterData;

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.current_power_week);
       }
    }
  }, [props.powerData]);


    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center'}}>
      <h6 style={{margin: '0px' }}>{props.language.week}</h6>
            <ReactSpeedometer 
                labelFontSize={'14px'}
                valueTextFontSize={'16px'}
                paddingHorizontal={4}
                paddingVertical={4}
                maxValue={maxMeterValue}
                value={ meterResult >= maxMeterValue ? maxMeterValue : meterResult }
                needleColor="#969696"
                needleHeightRatio={0.70}
                currentValueText= {`${meterResult} kWh`}
                needleTransitionDuration={3000}
                startColor="green"
                endColor="red"
                segmentColors={["#85cb06", "#f0c20a", "#f62406"]}
                segments={2}
                textColor="#fff"
                ringWidth={6}
                width={180}
                height={120}
               dimensionUnit="px"

        />
        <WeekKwhRateConsumption
          powerData={props.powerData}
          powerDataConsumed={props.powerDataConsumed}
          data={props.data}
          widget={props.widget}
          energy={props.energy} />
      </div>
    );
}

export default Speedo;