import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import DollarNowStatsTotalSpeedo from "../PowerStatsTotalGaugeSet/dollar/DollarNowStatsTotalSpeedo";
import DollarTodayStatsTotalSpeedo from "../PowerStatsTotalGaugeSet/dollar/DollarTodayStatsTotalSpeedo";
import DollarWeekStatsTotalSpeedo from "../PowerStatsTotalGaugeSet/dollar/DollarWeekStatsSpeedo";
import DollarMonthStatsTotalSpeedo from "../PowerStatsTotalGaugeSet/dollar/DollarMonthStatsTotalSpeedo";
import DollarTotalChartSet from "../PowerStatTotalCharts/DollarTotalChartSet";

// style sheet imported locally // 
import styles from '../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

export const DollarPowerStatsTotalSet = (props) => {
  const classes = useStyles();
  return (
    <div>
     <Grid container spacing={0} className={classes.powerStatsTablayout}>
       <Divider variant="fullWidth" className={classes.powerStatsTabDivider} />
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.now}
           <DollarNowStatsTotalSpeedo
            powerData={props.powerData} 
            powerStatTotal={props.powerStatTotal}
            data={props.data} 
            widget={props.widget}
            energy={props.energy}/>
        </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
           {props.language.today}
           <DollarTodayStatsTotalSpeedo 
            powerData={props.powerData} 
            powerStatTotal={props.powerStatTotal}
            data={props.data} 
            widget={props.widget}
            energy={props.energy} />
           </Grid>
       <Grid items md={3} className={classes.PowerStatsTabHeaderText}>
           {props.language.thisWeek}
           <DollarWeekStatsTotalSpeedo 
            powerData={props.powerData} 
            powerStatTotal={props.powerStatTotal}
            data={props.data} 
            widget={props.widget}
            energy={props.energy}/>
           </Grid>
       <Grid items md={3}  className={classes.PowerStatsTabHeaderText}>
       {props.language.thisMonth}
       <DollarMonthStatsTotalSpeedo 
        powerData={props.powerData} 
        powerStatTotal={props.powerStatTotal}
        data={props.data} 
        widget={props.widget}
        energy={props.energy}/>
       </Grid>

       <Grid items md={12}>
       <DollarTotalChartSet
       widget={props.widget}
       powerData={props.powerData}
       powerStatTotal={props.powerStatTotal}
       totalPowerDataCharts={props.totalPowerDataCharts}
        data={props.data} 
        energy={props.energy}
        language={props.language} />
       </Grid>

      </Grid>
      
    </div>
  );
};

export default DollarPowerStatsTotalSet;