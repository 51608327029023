import React from 'react';
import { useState, useEffect } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
  import { curveCardinal } from 'd3-shape';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import XMLParser from "react-xml-parser";

const styles = {
  layout: {
    color: "#87d200",
    marginTop: "2vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
};

const useStyles = makeStyles(styles);

const KwhTotalMonthsChart = (props) =>{
  const minMeterValue = Number(props.widget.params._attributes.max_monthly_gen_w)/1000;
  const maxMeterValue = Number(props.widget.params._attributes.max_monthly_con_w)/1000;
  const months = () => {
    let dates=[];
    for (let i = 0; i < 24; i++) {
      let date = moment();
      dates.push({ name: date.subtract(i, 'month').format('MMM') });
    }
    return dates;
   }
  const [data, setData] = React.useState(0);
//   useEffect(() => {
//     if (props.powerStatTotal) {
//       if (props.powerStatTotal?.attributes?.monthly_energy_totals) {
//     let monthEnergy=props.powerStatTotal.attributes.monthly_energy_totals.split(",");
//     monthEnergy.pop();
//     let currentYearMonths = months();
//     currentYearMonths.reverse();
//     let monhtsData=[];
//     for (let j = 0; j < monthEnergy.length; j++) {
//        let dData = {
//          name: null, kwh: null
//        }
//         dData.name = currentYearMonths[j].name;
//         let kwh = Math.round(monthEnergy[j])/1000;
//            if(kwh>9999){
//             dData.kwh = maxMeterValue;
//            }
//           if(kwh>0 && kwh<9999){
//             dData.kwh = kwh;
//           }
//            if(kwh==0){
//             dData.kwh = 0;
//            }
//         // dData.kwh = monthEnergy[j];
//         monhtsData.push(dData);
//      }
//     setData(monhtsData);
//     }
//   }
// }, []);


useEffect(() => {
  if (props.totalPowerDataCharts) {
    if (props.totalPowerDataCharts.monthly_energy_totals) {
  //let data = "4010060.00,435550.00,-84930.00,-1809420.00,-2796780.00,-140910.00,2001360.00,3076460.00,164880.02,-200970.00,-3076580.00,-1503650.00,41860.00,3405680.25,1654020.00,65690.00,-479110.00,-3699050.00,-637690.00,431070.00,3913570.25,848760.00,-3550.00,127197.06,";
  let monthEnergy=props.totalPowerDataCharts.monthly_energy_totals.split(",");
  // let monthEnergy=data.split(",");
  //monthEnergy.pop();
let mEnergyData = monthEnergy.slice(12,24);
  let currentYearMonths = months();
  currentYearMonths.reverse();
  let monhtsData=[];
  for (let j = 0; j < mEnergyData.length; j++) {
     let dData = {
       name: null, kwh: null
     }
      dData.name = currentYearMonths[j].name;
      let kwh = Math.round(mEnergyData[j])/1000;
      if(kwh>maxMeterValue){
        dData.kwh = maxMeterValue;
       }
      if(kwh<-minMeterValue){
        dData.kwh = -minMeterValue;
      }
      if(kwh<maxMeterValue && kwh>-minMeterValue){
        dData.kwh = kwh;
      }
      if(kwh==0){
        dData.kwh = 0;
       }
      // dData.kwh = monthEnergy[j];
      monhtsData.push(dData);
   }
  setData(monhtsData);
  }
}
}, []);

  const classes = useStyles();
  const cardinal = curveCardinal.tension(0.2);
return (
    <div style={{ width: '120%', height: 150, fontSize: '12px' }}>
       <ResponsiveContainer>
     <AreaChart
        width={600}
        height={100}
        data={data}
        margin={{
          top: 0, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
        // tickFormatter={MONTHS} 
        // type="date" 
        dataKey="name" />
        <YAxis 
        ticks={[-minMeterValue,0, maxMeterValue]}
        domain={[-minMeterValue, maxMeterValue]} />
        <Tooltip />
        <Area type="monotone" dataKey=" " stroke="#000" fill="#87d200" fillOpacity={0.3} />
        <Area type={cardinal} dataKey="kwh" stroke="#000" fill="#87d200" fillOpacity={0.3} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
)
}
export default React.memo(KwhTotalMonthsChart);



