import React, { Component } from 'react';
// import Carousel from 'react-elastic-carousel';
import '../style.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Co2MonthSpeedo from '../GaugeSet/co2/Co2MonthSpeedo';
import Co2NowSpeedo from '../GaugeSet/co2/Co2NowSpeedo';
import Co2TodaySpeedo from '../GaugeSet/co2/Co2TodaySpeedo';
import Co2WeekSpeedo from '../GaugeSet/co2/Co2WeekSpeedo';


export default class CO2CardSliderSet extends Component {
  
    render () {
      return (
        <div>
      <AwesomeSlider
      organicArrows={false}
      fillParent={false}
     >
         <div>
          <Co2NowSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language} />
          </div>

          <div>
          <Co2TodaySpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language} />
            </div>

          <div>
          <Co2WeekSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language} />
          </div>

          <div>
          <Co2MonthSpeedo
            powerData={this.props.powerData}
            powerDataConsumed={this.props.powerDataConsumed}
            data={this.props.data}
            widget={this.props.widget}
            energy={this.props.energy}
            language={this.props.language} />
          </div>
             </AwesomeSlider>
        </div>
      )
    }
  }
