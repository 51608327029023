import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import './../../style.css';
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);;

const NowKwhRateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] =useState("");
  const [rateInfo, setRateInfo] =useState("");

  //const [generatorType, setGeneratorType]= useState("");

  let arrayOfAnalog = [];

  let arrayofPulse = [];

  let combineArray = [];

  if(props.energy.Project.MeasurementAppData !==undefined){
  props.energy.Project.MeasurementAppData.MeasurementChannel.map(obj => { 
    if(obj._attributes.Generator!==undefined){
      arrayOfAnalog.push(obj._attributes.Generator);
    }
  } ); }

  if(props.energy.Project.Energy.PulsePowerMeters !==undefined){
   props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter.map(obj => { 
    if(obj._attributes.Generator!==undefined){
      arrayofPulse.push(obj._attributes.Generator);
    }
  } ); }

  combineArray = [...arrayOfAnalog, ...arrayofPulse];

  


//


  //const generation = props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter[0]._attributes.Generator;

//   const generatorValue =(generation)=>{
//     if(generation==1) {
//  return "Rate of Generation"
//     } else if (generation==0){
//       return "Rate of Consumption"
//     } else if (generation==undefined){
//       return " Rate of Generation/ Rate of Consumption "
//     }
//   }

  // const generation = props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter[0]._attributes.Generator;
  //let consumption = props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter[1]._attributes.Generator;

  // let generatorType = ( generation === 1? "Rate of Generation" :(generation===0)? "Rate of Consumption": "Rate of Generation/ Rate of Consumption ")

  //estimated values
  let meterData = (meterValue/1000).toFixed(2);

  let meterResult = meterData >= 9999 ? 9999 : meterData;
  
  //consumption and genetated values
  let meterDataInfo = (rateInfo/1000).toFixed(2);

  let meterResult1 = meterDataInfo >= 9999 ? 9999 : meterDataInfo;

  // let energy_dcvalue = props.powerDataConsumed.hourly_energy_history.split(",").reverse();
  //props.powerDataConsumed.hourly_energy_history.split(",").reverse();

  // let energyResult = energy_value[1];

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.predicted_power_hour);
       }
    }
  }, [props.powerData]);

  useEffect(() => {
    if (props.powerDataConsumed !== undefined ) {
       if(props.powerDataConsumed.meter===props.widget.params._attributes.meterno){
        let hourlyEnergy = props.powerDataConsumed.hourly_energy_history.split(",").reverse();
        let hourlyValue = hourlyEnergy[2];
        setRateInfo(hourlyValue);
       }
    }
  }, [props.powerDataConsumed]);

  return (
    <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    {combineArray[props.widget.params._attributes.meterno] === "1" ? "Rate of Generation" :
    combineArray[props.widget.params._attributes.meterno] === "0" ? "Rate of Consumption" :
    "Rate of Consumption/Generation" }
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this {props.language.hour}<span style={{wordSpacing:'3px'}}> {meterResult} {props.language.kWh}</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {combineArray[props.widget.params._attributes.meterno] === "1" ? "Generated last hr" :
    combineArray[props.widget.params._attributes.meterno] === "0" ? "Consumed last hr" :
    "Generated last hr" }<span style={{wordSpacing:'3px'}}> {meterResult1} {props.language.kWh}</span>
    </Typography>
      </div>
  )
}

export default NowKwhRateConsumption;


