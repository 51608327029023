/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {Typography, Button, Drawer } from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import styles from "../../assets/widgets/widgetStyles/commonStyles";
import SurveillanceGallery from "./surveillanceGallery.js";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { vidUrl, imageGallery, takePhotoUploadFile } from "../../services/xmlService";
const useStyles = makeStyles(styles);


const EditSurveillanceWidget = (props) => {
  const classes = useStyles();
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [imageListData, setImageListData] = useState();
  const [name, setName] = useState();
  const [functionGroup, setFunctionGroup] = useState();
  const [location, setLocation] = useState();
  const [widget, setWidget] = useState();
  const [imageSrc, setImageSrc] = useState();
  const history = useHistory();


  useEffect(() => {
    let interval = null;
    if (props.widget) {
      let refreshRate = Number(props.widget.params._attributes.refreshrate);
      vidUrl(props.widget._attributes.id).then((result) => {
        
        setImageSrc(result.data.imageFeed);
      }).catch((error) => {
        
      });
      interval = setInterval(async () => {
        await vidUrl(props.widget._attributes.id).then((result) => {
          
          setImageSrc(result.data.imageFeed);
        }).catch((error) => {
          
        });
      }, refreshRate);
    }
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    imageGallery()
      .then((res) => {
        if (res.data) {
          setImageListData(res.data);
        }
      })
      .catch((error) => {
        
      });
  }, []);

  const imageGalleryRefresh = () =>{
    imageGallery()
    .then((res) => {
      if (res.data) {
        setImageListData(res.data);
      }
    })
    .catch((error) => {
      
    });
  }

  useEffect(() => {
    if(props.widget){
      props.widget._attributes.label != undefined ? setName(props.widget._attributes.label) : setName("");
  
      props.widget._attributes.functiongroup === "none" ? setFunctionGroup("") : setFunctionGroup(props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name);

      (props.widget._attributes.location === "all" || props.widget._attributes.location === "none")  ? setLocation("") : setFunctionGroup(props.locations[Number(props.widget._attributes.location)]._attributes.Name);
    }
  }, []);

  const toggleDrawer1 = (open) => {
    // props.gallaryDrawer(open);
    setDraweropen1(open);
  };

  const closeComponent = () =>{
    history.push("/home/functions");
  }

  // let [name, setName] = useState(
  //   props.data.widget.params._attributes.label != undefined
  //     ? props.data.widget.params._attributes.label
  //     : ""
  // );
  // let [functionGroup, setFunctionGroup] = useState(
  //   props.data.widget._attributes.functiongroup === "none"
  //     ? ""
  //     : props.data.functionGroups[
  //       Number(props.data.widget._attributes.functiongroup)
  //     ]._attributes.Name
  // );
  // let [location, setLocation] = useState(
  //   (props.data.widget._attributes.location === "all" || props.data.widget._attributes.location === "none")
  //     ? ""
  //     : props.data.locations[Number(props.data.widget._attributes.location)]
  //       ._attributes.Name
  // );

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const takePhoto = () => {
    let datetimestamp = new Date().toISOString().replace('-', '').replace('-', '').replace('T', '').replace(':', '').replace(':', '').replace(':', '').split(".")[0];
    let profileName = "cam" + props.widget._attributes.id + "_" + datetimestamp + ".jpg";
    vidUrl(props.widget._attributes.id).then((result) => {
      
      let file = dataURLtoFile('data:image/png;base64,'+result.data.imageFeed , profileName);
      takePhotoUploadFile(file, profileName)
        .then((result) => {
          
          imageGallery()
            .then((res) => {
              if (res.data) {
                 imageGalleryRefresh();
              }
            })
        })
        .catch((error) => {
          
        });
    }).catch((error) => {
        
    });
  }

  return (
    <>
      {props.data !== undefined && (
        <div
          className={classes.editWidgetRoot}
          style={{ display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(#3b3b3b, #202020)" }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse"
              }}>
              <CloseIcon
                onClick={() => {
                  closeComponent();
                }}
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  color: 'white',
                  marginBottom: "1%",
                  marginTop: "1%",
                  marginRight: "1%"
                }}
              />
            </div>
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={-200}
              defaultPositionY={-150}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "2px solid black"
                    }}>

                    <div style={{ width: "20%", paddingLeft: '2%' }} >
                      <Typography
                        variant="h4"
                        className={classes.headerTextNew1}
                      >
                        {name}
                      </Typography>
                      <Typography
                        variant="h6"
                        className={classes.headerText1location}
                      >
                        {props.data.widgetCategory === "functionGroup" ? functionGroup : location}
                      </Typography>
                    </div>
                    <div style={{ width: "35%" }}>
                      <Button
                        variant="contained"
                        style={{
                          width: "60%",
                          alignSelf: "center",
                          margin: "1% 70%",
                          borderRadius: "0",
                          padding: "2%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px"

                        }}
                        className={classes.controlPanelIconButton}
                        onClick={() => {
                          toggleDrawer1(true);
                        }}
                      >
                        <Typography className={classes.headerText2New}>
                          Photo Library
                        </Typography>
                      </Button>
                    </div>

                    <div style={{ width: "35%" }}>
                      <Button
                        variant="contained"
                        style={{
                          width: "60%",
                          alignSelf: "center",
                          margin: "5% 35%",
                          borderRadius: "0",
                          padding: "2%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px"

                        }}
                        className={classes.controlPanelIconButton}
                        onClick={() => {
                          takePhoto();
                        }}
                      >
                        <CameraAltIcon style={{ color: "white", width: "12%" }} />
                        <Typography className={classes.headerText2New}>
                          Take a Photo
                        </Typography>
                      </Button>
                    </div>

                    <div style={{ width: "10%" }}>
                      <button className={classes.controlPanelIconButton1}>
                        <ZoomInIcon style={{ fontWeight: "600", color: "white" }} onClick={zoomIn} fontSize="large" />
                      </button>
                      <button className={classes.controlPanelIconButton1}>
                        <ZoomOutIcon style={{ fontWeight: "600", color: "white" }} onClick={zoomOut} fontSize="large" />
                      </button>
                    </div>
                  </div>
                  <TransformComponent
                    style={{
                      width: "100%",
                      maxHeight: `900px`,
                      marginTop: "25px"
                    }}
                  >
                    <div
                      align="center"
                      style={{
                        zIndex: -1,
                        width: `800px`,
                        height: `500px`,


                      }}
                    >
                      <img style={{ width: "100%", marginTop: "70px" }}
                        src={`data:image/png;base64,${imageSrc}`}
                        alt="Feed Loading..." />
                    </div>
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>

          <Drawer
            variant="temporary"
            anchor={"right"}
            open={draweropen1}
            onClose={() => {
              toggleDrawer1(false);
            }}
            modal
            classes={{
              paper: classNames(classes.drawerPaper2),
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <SurveillanceGallery
              functionGroups={props.data.functionGroups}
              saveEditedWidget={props.saveEditedWidget}
              data={props}
              imageGalleryRefresh = {imageGalleryRefresh}
              imageListData={imageListData}
              wiserIpAddress={props.wiserIpAddress}
              widgets={props.data.Project.Widgets.widget}
              toggleDrawer={toggleDrawer1}
            />
          </Drawer>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.reducer.data,
  locations: state.reducer.locations,
  functionGroups: state.reducer.functionGroups,
  widget: state.reducer.widget
});

export default connect(mapStateToProps, null)(EditSurveillanceWidget);
