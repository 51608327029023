import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// creates a beautiful scrollbar
//import PerfectScrollbar from "perfect-scrollbar";
//import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { connect } from "react-redux";
import Main from"./Main";

import "react-perfect-scrollbar/dist/css/styles.css";
import routes from "routes.js";
import styles from "assets/js/all-styles/layouts/adminStyle.js";
import logo from "assets/img/reactlogo.jpg";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}
    {/* <Redirect from="/home" to="/home/favorite" /> */}
  </Switch>
);


const useStyles = makeStyles(styles);

const Admin = ({ ...rest }) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions

  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={rest.userName}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />

      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <Main classes={classes} switchRoutes={switchRoutes} />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: state.reducer,
  userName: state.reducer.userName,
  userUrl: state.reducer.userUrl,
  isFavorite: state.reducer.isFavorite,
  remoteUserDetails: state.reducer.remoteUserDetails
});
export default connect(mapStateToProps)(Admin);
