import React from "react";
export default function RepeatDates(props) {


    const [state, setState] = React.useState({selectedDays: ( props.data.userValue.RepeatDays===0 || props.data.userValue.RepeatDays==="0")?2:props.data.userValue.RepeatDays});
    
    const [repeatOffsetvalue, setRepeatOffsetvalue] = React.useState({selectedRepeatStartType: props.data.userValue.RepeatOffset?props.data.userValue.RepeatOffset:0});

   

    const handleRepeatOffset =(event)=>{
     
      setRepeatOffsetvalue({selectedRepeatStartType:event.target.value});

      props.setRepeatDays(state.selectedDays);
    
      props.setRepeatOffset(event.target.value);
   
      props.setDateType("32");
    }

    const handleRepeatDates =(event)=>{

      setState({selectedDays:event.target.value});
   
      props.setRepeatDays(event.target.value);
      props.setRepeatOffset(repeatOffsetvalue.selectedRepeatStartType);
      props.setDateType("32");
    }

    let renderRepeatDays = () => {
      let optionList = [];
      

      for (let j = 2; j < 256; j++) {
        // optionList.push(<option key={j}>{j}</option>)
        optionList.push(j)
    
       
     
      }
        return (
            <div>
                <span style={{marginRight:"20px", color:"#fff", fontWeight:"400"}}>{props.language.every}</span>
        
            <select className="selectineditschedulesoptions"
              onChange={handleRepeatDates}
              style={{marginRight:"20px"}}
              // value={+state.selectedDays?0:+state.selectedDays}
              value={(state.selectedDays===undefined)?0:+state.selectedDays}
             
              >            
             
              {optionList.map((val, key)=>{
               return <option value={val}>{val}</option>
              })}
               
            </select>

            <span style={{ color:"#fff", fontWeight:"400"}}>{props.language.days}</span>
          </div>
        );
      }
      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [day, month, year].join('-');
      }
  
      let renderRepeatedDates = (selectedCardType)=> {


        
        var a = parseInt(selectedCardType);
        let listDates =[];
      
    
        for (let i = 0; i < a; i++) {
         
          var option = document.createElement("option");
          var date = new Date();
          var dateInsert = date.setDate(date.getDate() + i);
         
          listDates.push(formatDate(dateInsert));

         
        
        }
      
          return (
            <>
        
            <select className="selectineditschedulesdraweroptions"
             value={+repeatOffsetvalue.selectedRepeatStartType?(+repeatOffsetvalue.selectedRepeatStartType):2}
             onChange={handleRepeatOffset}
             >            
                {listDates.map((val, key)=>{
               return <option value={key}>{val}</option>
              })}
            </select>
          </>
          )
     
        
        
      
    
      }
    


    return (

        <div style={{marginTop:"10px",display: "flex",flexDirection: "column"}}>
            <div style={{marginBottom:"20px"}}>{renderRepeatDays()}</div>
            <span style={{ color:"#fff", fontWeight:"400"}}>{props.language.startingOn}</span>
            <div>{renderRepeatedDates(state.selectedDays===undefined?2:+state.selectedDays)}</div>
            
            

        </div>
    )

    


}