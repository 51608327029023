import React, { useState, useEffect } from "react";
import convertRampToSecs from "./functions/convertRampToSecs";
import { ICON_URL } from "../../config/environment";
// Material-UI //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
// Components imported locally //
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
import bulbWhite from "../../assets/img/bulbWhite.png";
import bulbGreen from "assets/img/bulbGreen.png";
// CSS Style sheets imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

// Function //
const EnablePreset = (props) => {
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.widget.params._attributes.app).toString(16)
  );
  const classes = useStyles();
  const [click, setClick] = useState();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [isclick, setIsclick] = useState(false);

  // 
  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };



  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (props.cbusEvent.attributes.app ==="0x" + Number(props.widget.params._attributes.app).toString(16)) {
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.ga).toString(16)
          ) {            
             if (
              parseInt(props.cbusEvent.attributes.level, 16) ==
              props.widget.params._attributes.level
            ) {
             
              presetStart();
             
              if (initialData) {
                initialData[props.widget.params._attributes.ga] =
                  props.cbusEvent.attributes.level;
                let lightingData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(lightingData);
              }
            } else {
              setClick(false);
              if (initialData) {
                initialData[props.widget.params._attributes.ga] =
                  props.cbusEvent.attributes.level;
                let lightingData = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(lightingData);
              }
            }
          }
        }
      }
      }
    }
    if (initialData && !isclick) {
      if (
        parseInt(initialData[props.widget.params._attributes.ga], 16) ==
        props.widget.params._attributes.level
      ) {
        setClick(true);
      } else {
        setClick(false);
      }
    }
  }, [props.cbusEvent]);

  const presetValue = Math.round(
    (props.widget.params._attributes.level / 255) * 100
  );
  const rampValue = props.widget.params._attributes.ramprate;
  const rampInArray = [
    0,
    4000,
    8000,
    12000,
    20000,
    30000,
    40000,
    60000,
    90000,
    120000,
    180000,
    300000,
    420000,
    600000,
    900000,
    1020000,
  ];
  const rampValueInSecs = rampInArray[rampValue]*presetValue/100 ;
  // const rampValueInSecs=convertRampToSecs(rampValue)*1000

const presetStart = ()=>{
  setTimeout(() => {
    setClick(true);
  }, rampValueInSecs);
}

  const handleChange = () => {
    setIsclick(true);
      let on = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params.param[1]._attributes.value +
        '" command="cbusSetLevel" network="' +
        props.widget.params.param[0]._attributes.value +
        '" numaddresses="1" group="' +
        props.widget.params.param[2]._attributes.value +
        '" level="' +
        props.widget.params.param[3]._attributes.value +
        '" ramp="' +
        props.widget.params.param[4]._attributes.value +
        '" />',
    };

    props.handleSocketEventChange(on);
    

    setTimeout(() => {
      setClick(true);
    }, rampValueInSecs);
  };

 

  
  
  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "all" ||
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  return (
    <>
      {" "}
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={click ? ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png"}
                    className={classes.headerIcon}
                    alt={"bulb"}
                  />
                </IconButton>
                <div className={classes.header1} style={{ flexGrow: "1" }}>
                  <Typography
                    variant="h6"
                    className={
                      click ? `${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>
            <Button
              variant="contained"
              className={click?classes.numberControlPanelHvac:classes.bottomPanel}
              style={{ height: "20%", flex: "1", padding: "8%", borderRadius:'0' }}
              onClick={() => handleChange(click)}
              disabled={click ? true : false}
                    >
              <Typography
                className={
                  click ? classes.headerText1Active : classes.headerText1
                }
                variant="h5"
              >
                {presetValue} %
              </Typography>
            </Button>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
};

export default EnablePreset;
