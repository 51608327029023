import React from 'react';
import { useState, useEffect } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
  import { curveCardinal } from 'd3-shape';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import XMLParser from "react-xml-parser";

const styles = {
  layout: {
    color: "#87d200",
    marginTop: "2vh",
    marginBottom: "0vh"
  },
  LayoutHeaderText: {
    fontSize: "10px",
    fontWeight: "600"
  },
};

const useStyles = makeStyles(styles);

const KwhTotalDaysChart = (props) =>{
  const minMeterValue = Number(props.widget.params._attributes.max_daily_gen_w)/1000;
  const maxMeterValue = Number(props.widget.params._attributes.max_daily_con_w)/1000;

  const [data, setData] = React.useState(0);

  const generateDatesOfAMonth = () => {
    let dates=[];
    for (let i = 0; i < 31; i++) {
      let date = moment();
      dates.push({ name: date.subtract(i, 'day').format('dddd') });
    }
    return dates;
  }

  // useEffect(() => {
  //   if (props.powerStatTotal) {
  //     if(props.powerStatTotal?.attributes?.daily_energy_totals){
  //     let dayEnergy=props.powerStatTotal.attributes.daily_energy_totals.split(",");
  //     dayEnergy.pop();
  //     let daysData=[];
  //     let days = generateDatesOfAMonth();
  //     days.reverse();
  //      for (let j = 0; j < dayEnergy.length; j++) {
  //         let dData = {
  //           name: null, kwh: null
  //         }
  //          dData.name = days[j].name;
  //          let kwh = Math.round(dayEnergy[j])/1000;
  //          if(kwh>9999){
  //           dData.kwh = maxMeterValue;
  //          }
  //         if(kwh>0 && kwh<9999){
  //           dData.kwh = kwh;
  //         }
  //          if(kwh==0){
  //           dData.kwh = 0;
  //          }
  //         //  dData.kwh = dayEnergy[j];
  //          daysData.push(dData);
  //       }
  //      let filteredData= daysData.slice((daysData.length - 7), daysData.length);
  //      setData(filteredData);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (props.totalPowerDataCharts) {
      if(props.totalPowerDataCharts.daily_energy_totals){
      //let data = "1800.00,1640.00,1490.00,1350.00,1230.00,1120.00,-1230.00,-1350.00,-1490.00,-1640.00,-1800.00,-1980.00,-2180.00,-2390.00,-2630.00,-2900.00,-3190.00,-3500.00,-3860.00,-4240.00,-4670.00,-5130.00,-5640.00,-6210.00,-6830.00,-7510.00,-8260.00,-9090.00,217449.95,8921.84,-50508.26,";
      let dayEnergy=props.totalPowerDataCharts.daily_energy_totals.split(",");
      //let dayEnergy= data.split(",");
      dayEnergy.pop();
      let daysData=[];
      let days = generateDatesOfAMonth();
      days.reverse();
       for (let j = 0; j < dayEnergy.length; j++) {
          let dData = {
            name: null, kwh: null
          }
           dData.name = days[j].name;
           let kwh = Math.round(dayEnergy[j])/1000;
           if(kwh>maxMeterValue){
            dData.kwh = maxMeterValue;
           }
          if(kwh<-minMeterValue){
            dData.kwh = -minMeterValue;
          }
          if(kwh<maxMeterValue && kwh>-minMeterValue){
            dData.kwh = kwh;
          }
           if(kwh==0){
            dData.kwh = 0;
           }
          //  dData.kwh = dayEnergy[j];
           daysData.push(dData);
        }
       let filteredData= daysData.slice((daysData.length - 7), daysData.length);
       setData(filteredData);
      }
    }
  }, []);

  const classes = useStyles();
  const cardinal = curveCardinal.tension(0.2);
return (
    <div style={{ width: '120%', height: 150, fontSize: '12px' }}>
    <ResponsiveContainer>
     <AreaChart
        width={600}
        height={100}
        data={data}
        margin={{
          top: 0, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis  
        dataKey="name" 
        />
        <YAxis 
        ticks={[-minMeterValue,0, maxMeterValue]}
        domain={[-minMeterValue, maxMeterValue]} 
        />
        <Tooltip />
        <Area type="monotone" dataKey=" " stroke="#000" fill="#87d200" fillOpacity={0.3} />
        <Area type={cardinal} dataKey="kwh" stroke="#000" fill="#87d200" fillOpacity={0.3} />
      </AreaChart>
      </ResponsiveContainer>
    </div>
)
}
export default React.memo(KwhTotalDaysChart);



