import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./../../assets/widgets/widgetStyles/commonStyles";

const useStyles = makeStyles(styles);

const PasswordValidation = ({
  capsLetterFlag,
  pwdLengthFlag,
  specialNumberCharFlag,
  noSpaceCheckFlag,
  usernameFlag
}) => {    
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();


    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

  return (
    <div className={classes.validationText}>
    <p className={pwdLengthFlag}>Min 8 to 64 Max Characters.</p>
    <p className={capsLetterFlag}>1 Upper case and 1 Lower case.</p>
    <p className={specialNumberCharFlag}>1 Number or 1 Special Character.</p>
    <p className={noSpaceCheckFlag}> Not allow Spaces at the beginning or end.</p>
    <p className={usernameFlag}> Username not allowed.</p>
    </div>
    
  ) 
};

export default PasswordValidation;
