import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

const MonthCo2RateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] = useState("");
  const [rateInfo, setRateInfo] =useState("");

  //CarbonFootPrint Value
  const carbonFootValue = props.energy.Project.Energy._attributes.CarbonFootprint;

   //estimated values
  let meterData = ((meterValue/1000)*carbonFootValue).toFixed(2).replace("-", " ");

  let meterResult = meterData >= 9999 ? 9999 : meterData;

   //consumption and genetated values
  let meterDataInfo = ((rateInfo/1000)*carbonFootValue);

  let meterResult1 = meterDataInfo >= 9999 ? 9999 : meterDataInfo;

  useEffect(() => {
    if (props.totalPowerDataHistory) {
        if (props.totalPowerDataHistory.name === "cbusPowerHistoryTotals") {
         setMeterValue(props.totalPowerDataHistory.predicted_total_power_month); 
      }
    }
  }, [props.totalPowerDataHistory]);

  useEffect(() => {
    if (props.totalPowerDataHistory !== undefined ) {
       if(props.totalPowerDataHistory.name=== "cbusPowerHistoryTotals"){
        let monthlyEnergy = props.totalPowerDataHistory.monthly_energy_totals.split(",").reverse();
        let monthlyValue = monthlyEnergy[2];
        setRateInfo(monthlyValue);
       }
    }
  }, [props.totalPowerDataHistory]);

  return (
  <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    Rate of Consumption/Generation
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this month<span style={{wordSpacing:'0px'}}> {meterResult} kg CO2</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {meterResult1 > 0 ? "Consumed last month" :  "Generated last month" } 
   <span style={{wordSpacing:'3px'}}> {meterResult1.toString().replace("-", " ")} kg CO2</span>
    </Typography>
      </div>
  )
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(MonthCo2RateConsumption);
