import React from "react";
import { useState, useEffect } from "react";
//Components imported locally //
import EditWidget from '../editWidget';
import BootstrapTooltip from '../../../assets/widgets/tooltip/tooltip'
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Typography, Drawer, Grid } from '@material-ui/core';
import classNames from "classnames"
import StarsIcon from '@material-ui/icons/Stars'
import EditIcon from '@material-ui/icons/Edit';
import profileGreen from "../../../assets/img/profileGreen.png";
//import Sidebar1 from "components/Sidebar/Sidebar1.js";
// CSS Style sheets imported locally //
import styles from '../../../assets/widgets/widgetStyles/commonStyles'
//import EditLogic from "../editLogic";
import IODateLogic from "../IODateLogic";
import moment from 'moment';
import { updateSocketEvent, saveProject } from "../../../services/xmlService";
import { updateXml, rebootDevice } from "../../../services/xmlService";
import { getAppConfigData } from "redux/actions/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);


const UserSystemDate = (props) => {
  const initialData = props.initialCbusEvent.get("usio_var" + props.widget.params._attributes.usio_var);
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [sysDate, setSysDate] = useState(props.sysDate == undefined ? 0 : Math.floor(props.sysDate));
  //const [sysInt, setSysInt] =useState(props.sysInt==undefined?0: Math.floor(props.sysInt));
  const [editedsysDate, setEditedsysDate] = useState(props.sysDate == undefined ? 0 : Math.floor(props.sysDate));
  const [editableSysDate, setEditableSysDate] = useState(props.sysDate == undefined ? 0 : Math.floor(props.sysDate));
  const [editSysDate, setEditSysDate] = React.useState();
  const [editSysMonth, setEditSysMonth] = React.useState();
  const [editSysYear, setEditSysYear] = React.useState();
  const [userVariable, setUserVariable] = useState();

  

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const toggleDrawer1 = (open) => (event) => {
    setDraweropen1(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  }

  const handleEditSysDate = (date) => {
    setEditSysDate(date);
  }

  const handleEditSysMonth = (month) => {
    setEditSysMonth(month);
  }

  const handleEditSysYear = (year) => {
    setEditSysYear(year);
  }

  const handleChangeIPAdreess = (event) => {
    //  const re = /^[0-9\b]+$/;
    const re = /^([0-9][0-9]{0,2}|100)$/;
    if (event.target.value === '' || event.target.value <= 100 && re.test(event.target.value)) {
      setSysDate(event.target.value);
    }
  };

  const saveEdit = (cmd) => {
    
    updateSocketEvent(cmd)
      .then((res) => {
        
        saveProject()
          .then((result) => {
            
          })
          .catch((error) => {
            
          });

      })
      .catch((error) => {
        
      });
  }


  const handleSave = () => {
    setDraweropen1(false);
    let dateForming = editSysMonth + "/" + editSysDate + "/20" + editSysYear;
    let record_date = Date.parse(dateForming)
    let days = Math.round((record_date - new Date(1899, 11, 30)) / 8.64e7).toString();
    let savesio4 = { cmd: '<cbus_cmd app="257" command="cbusSetUserSystemIOInt" value="' + days + '" sio_index="' + props.widget.params._attributes.usio_var + '" status="0" />' };
    props.handleSocketEventChange(savesio4);
    let uservar = props.data.Project.UserVariables.UserVariable;
    for (let i = 0; i < uservar.length; i++) {
      if (uservar[i]._attributes.id == props.widget.params._attributes.usio_var) {
        props.data.Project.UserVariables.UserVariable[i]._attributes.IntegerValue = days;
        
      }
    }
    updateXml(props.data)
      .then((result) => {
        props.getAppConfigData();
        setSysDate(ExcelDateToJSDate(days));
      })
      .catch((error) => {
        
      });
  }

  const handleChange = (changedVal) => {
    setEditedsysDate(changedVal);
  }
  useEffect(() => {
    let data = props.data.Project.UserVariables.UserVariable;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]._attributes.id == props.widget.params._attributes.usio_var) {
          setUserVariable(data[i]._attributes);
          let intitialdatavalue = {
            value: data[i]._attributes.IntegerValue
          }
          props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, intitialdatavalue);
          let ioData = new Map(props.initialCbusEvent);
          props.socketEventDataChanger(ioData);
          setSysDate(ExcelDateToJSDate(data[i]._attributes.IntegerValue));
        }
      }
    }
  }, []);

  const ExcelDateToJSDate = (serial) => {
    var hours = Math.floor((serial % 1) * 24);
    var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
    if (isValidDate(new Date(Date.UTC(0, 0, serial, hours - 17, minutes)))) {
      return moment(new Date(Date.UTC(0, 0, serial, hours - 17, minutes))).format("DD/MM/YY");
    } else {
      return "00/00/00";
    }
  }

  const ExcelDateToJSDateToEdit = (serial) => {
    var hours = Math.floor((serial % 1) * 24);
    var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
    return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
  }

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }
  const editIOLogic = (sysDate, setSysDate) => (
    <>
      <div style={{ background: "#3b3b3b" }}>
        <div style={{ padding: "25px 10px 5px 15px" }}>
          <p style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>
            Edit Widget
          </p>
          <p style={{ color: "#707070", fontWeight: "600", margin: "0" }}>
            {name}
          </p>
        </div>
        <hr style={{ border: "1px solid black", margin: "0", borderTopColor: "transparent" }} />
        <div style={{ display: "flex", padding: "15px", justifyContent: "space-between" }}>
          <button className={classes.drawerbtn}
            onClick={toggleDrawer1(false)}
          >

            Cancel
          </button>
          <button
            className={classes.drawerbtn}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
        <hr style={{ border: "1px solid black", margin: "0", borderTopColor: "transparent" }} />
      </div>
      <IODateLogic widget={props.widget} userVariable={userVariable} sysDate={ExcelDateToJSDateToEdit(editableSysDate)} saveChanges={handleChange} handleEditSysDate={handleEditSysDate} handleEditSysMonth={handleEditSysMonth} handleEditSysYear={handleEditSysYear} />

    </>
  );

  // useEffect(() => {
  //   let initialEventTriger = {
  //     cmd:
  //     '<cbus_cmd app="257" command="cbusGetUserSystemIOStr" sio_index="' +
  //     props.widget.params._attributes.usio_var +
  //     '" nooofparams="0"/>',
  //   };
  //   props.handleSocketEventChange(initialEventTriger);
  // },[]);

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_resp") {
        if (props.cbusEvent.attributes.command === "cbusSetUserSystemIOInt") {
          if (props.cbusEvent.attributes.sio_index === props.widget.params._attributes.usio_var) {
            setSysDate(ExcelDateToJSDate(props.cbusEvent.attributes.value));
            setEditableSysDate(props.cbusEvent.attributes.value)
            if (initialData) {
              initialData.value = props.cbusEvent.attributes.value;
              props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, initialData);
              let ioData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(ioData);
            }
          }
        }
      }
    }
    if (initialData) {
      setSysDate(ExcelDateToJSDate(initialData.value));
      setEditableSysDate(initialData.value)
    }
  }, [props.cbusEvent]);

  // Data assigned to consts from Redux Store // 
  const id = props.widget._attributes.id;
  const name = props.widget.params._attributes.label != undefined ? props.widget.params._attributes.label : "";
  const location = props.widget._attributes.location === "none" ? "" : props.locations[Number(props.widget._attributes.location)]._attributes.Name;
  const functionGroup = props.widget._attributes.functiongroup === "none" ? "" : props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name;

  return (
    <> {props.data !== undefined &&
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <div className={classes.root} >
          <BootstrapTooltip
            title={
              <React.Fragment>
                <IconButton
                  className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
                  aria-label="delete"
                  onClick={() => { props.handleFavorite(id, props.widget._attributes.type); updateFav(favData); }}

                >
                  <StarsIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => { toggleDrawer(true) }}
                >
                  < EditIcon />
                </IconButton>
              </React.Fragment>
            }
          >
            <div className={classes.header} >
              <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton} >
                <img src={profileGreen} alt={"bulb"} className={classes.headerIconLogic} />
              </IconButton>
              <div className={classes.header1} >
                <Typography variant='h6' className={`${classes.headerTextLogicIO} ${classes.textTruncation} `} >
                  {name}
                </Typography>
              </div>
            </div>
          </BootstrapTooltip>
          <Button
            variant="contained"
            className={classes.logicIoBottomPanel}
            onClick={toggleDrawer1(true)}
            style={{ cursor: "pointer" }}
          >
            <Typography className={classes.bottomPanel2ButtonText} variant='h5' >
              {sysDate}
            </Typography>
          </Button>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={draweropen}
            onClose={() => { toggleDrawer(false) }}
            modal
            classes={{
              paper: classNames(classes.drawerPaper),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <EditWidget saveEditedWidget={props.saveEditedWidget} data={props} toggleDrawer={toggleDrawer} />
          </Drawer>
        </div>
      </Grid>
    }
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={draweropen1}
        onClose={() => { toggleDrawer1(false) }}
        modal
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {editIOLogic(sysDate, setSysDate)}
      </Drawer>
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UserSystemDate);
