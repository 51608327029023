/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography, Drawer } from "@material-ui/core";
// Redux setup //
import { bindActionCreators } from "redux";
import { updateWidget } from "../../redux/actions/action";
import { connect } from "react-redux";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
import { getImage, uploadFile, vidUrl, authUrl } from "../../services/xmlService";

//react onerror img
import ReactImageFallback from "react-image-fallback";
// assets imported locally //
import cctv from "../../assets/img/cctv.png";
import cctvImage from "../../assets/img/homeCctv.png";
import defImage from "../../assets/img/defImage.png";
import defaultImage from "../../assets/img/def_Image.png"
import {API_URL} from "../../config/environment";

// components imported locally//
import EditWidget from "./editWidget";
import EditSurveillanceWidget from "./editSurveillanceWidget.js";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";

// style sheet imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

// Widget Component //
const SurveillanceWidget = (props) => {
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [imageSrc, setImageSrc] = useState();
  const refreshRate = Number(props.widget.params._attributes.refreshrate) < 3000 ? 5000 : Number(props.widget.params._attributes.refreshrate);
  const [count,setCount] = useState(1);
  const history = useHistory();

  // useEffect(() => {
  //   vidUrl(props.widget._attributes.id).then((result) => {
      
  //     setImageSrc(result.data.imageFeed);
  //     let county = count;
  //     setTimeout(() => {
  //       setCount(county+1);
  //     },1000); 
  //   }).catch((error) => {
      
  //   });
  //   if(count === 30){
  //     return () => {
        
  //       setCount(1);
  //       setImageSrc(null);
  //     };
  //   }
  // }, [count]);

  useEffect(() => {
    let initialimgurl = API_URL+'geturl.cgi?vid'+props.widget._attributes.id+"&"+new Date().getSeconds();;
    setImageSrc(initialimgurl);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setImageSrc(API_URL+'geturl.cgi?vid'+props.widget._attributes.id+"&"+new Date().getSeconds());
      // console.log(imgFeed);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };
  const toggleDrawer1 = (open) => {
    props.updateWidget(props.widget);
    history.push("/WebCam");
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;

  const [name, setName] = useState(
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : ""
  );

  const [location, setLocation] = useState(
    (props.widget._attributes.location === "all" || props.widget._attributes.location === "none")
      ? ""
      : props.locations[Number(props.widget._attributes.location)]
        ._attributes.Name
  );

  const [functionGroup, setFunctionGroup] = useState(
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
        ._attributes.Name
  );



  return (
    <>
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.rootSurveillance}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img src={cctv} className={classes.headerIcon} />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={`${classes.headerText1} ${classes.textTruncation} `}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="h6"
                    className={classes.headerText1locationwidget}
                  >
                    {props.data.widgetCategory === "functionGroup" ? functionGroup : location}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>

            <div style={{ height: "200px", width: "100%" }}>
              <img
                src={imageSrc}
                onError={(e) => {
                  e.target.src =
                    defaultImage
                }}
                alt="CCTV Feed is not available"
                className={classes.imgSurveillance}
                style={{ height: "100%", width: "inherit" }}
                onClick={() => {
                  toggleDrawer1(true);
                }}
              />
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateWidget
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SurveillanceWidget);