import React, { useState, useEffect } from "react";

//Assets //
import relay from "assets/img/relay.png";

// Components Imported Locally //
import EditWidget from "../editWidget";

// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";

// CSS Style sheets imported locally //
import styles from "../../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

// Tooltip Styles //

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "grey",
    placement: "right",
    maxHeight: "150px",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return (
    <Tooltip
      interactive
      enterDelay={300}
      leaveDelay={1000}
      placement="top-start"
      arrow
      classes={classes}
      {...props}
    />
  );
}

export default function Curtain2Button(props) {
  const classes = useStyles();
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.widget.params._attributes.app).toString(16)
  );
  const [drawerisOpen, setDrawerisOpen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerisOpen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const handleOpen = () => {
    // setIsOpen(true);
    // setIsClose(false);
    handleClick(true, false);
    
  };

  const handleClose = () => {
    // setIsOpen(false);
    // setIsClose(true);
    handleClick(false, true);
    
  };

  const handleStop = () => {
    // setIsOpen(false);
    // setIsClose(false);
    handleClick(false, false);
    
  };

  const handleClick = (isOpen, isClose) => {
    if (isOpen === true && isClose === false) {
      
      
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="255" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="0" ramp="0"/>',
      };

      props.handleSocketEventChange(buttonOpen2);
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen1);
      }, 500);
    } else if (isClose === true && isOpen === false) {
      
      
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="255" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(buttonOpen2);
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen1);
      }, 500);
    } else if (isOpen === false && isClose === false) {
      
      
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="0" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(buttonOpen1);
      props.handleSocketEventChange(buttonOpen2);
    }
  };

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.upga).toString(16)
          ) {
            if (props.cbusEvent.attributes.level == "0xff") {
              setIsOpen(true);
              setIsClose(false);
            } else {
              setIsOpen(false);
            }
            if (initialData) {
              initialData[props.widget.params._attributes.upga] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }

          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.downga).toString(16)
          ) {
            if (props.cbusEvent.attributes.level == "0xff") {
              setIsOpen(false);
              setIsClose(true);
            } else {
              setIsClose(false);
            }
            if (initialData) {
              initialData[props.widget.params._attributes.downga] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }
        }
      }
    }
    if (initialData) {
      if (initialData[props.widget.params._attributes.upga]) {
       
        if (
          parseInt(initialData[props.widget.params._attributes.upga], 16) ==
          "0xFF"
        ) {
          setIsOpen(true);
          setIsClose(false);
        } else {
          setIsOpen(false);
        }
      }

      if (initialData[props.widget.params._attributes.downga]) {
      
        if (
          parseInt(initialData[props.widget.params._attributes.downga], 16) ==
          "0xFF"
        ) {
          setIsOpen(false);
          setIsClose(true);
        } else {
          setIsClose(false);
        }
      }
    }
  }, [props.cbusEvent]);

  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.locations[Number(props.widget._attributes.location)]._attributes.Name;
  const functionGroup =
    props.functionGroups[Number(props.widget._attributes.functiongroup)]
      ._attributes.Name;

  return (
    <>
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img src={relay} alt="relay" className={classes.headerIcon} />
                </IconButton>
                <div className={classes.header1}>
                  <Typography variant="h6" className={ `${classes.headerText1} ${classes.textTruncation} `}>
                    {name}
                  </Typography>
                  <Typography variant="h6" className={ `${classes.headerText2} ${classes.textTruncation} `}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>
            <div className={classes.bottomPanelScenes}>
              <Button
                variant="contained"
                className={classes.bottomPanel2Button}
                onClick={isOpen ? handleStop : handleOpen}
              >
                <Typography
                  variant="h5"
                  className={classes.bottomPanel2ButtonText}
                >
                  {isOpen
                    ? props.widget.params._attributes.button1label
                    : props.widget.params._attributes.button0label}
                </Typography>
              </Button>
              <Button
                variant="contained"
                className={classes.bottomPanel2Button}
                style={{ borderRight: "none" }}
                onClick={isClose ? handleStop : handleClose}
              >
                <Typography
                  className={classes.bottomPanel2ButtonText}
                  variant="h5"
                >
                  {isClose
                    ? props.widget.params._attributes.button1label
                    : props.widget.params._attributes.button2label}
                </Typography>
              </Button>
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={drawerisOpen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better isOpen performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
