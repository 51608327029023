import React from "react";
// import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import FavIcon from "assets/img/fav-g24.png";
import StatsIcon from "assets/img/stats-g24.png";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { favouriteOrStatesChanger } from '../../redux/actions/action';
import FavgrayIcon from "assets/img/fav-gray24.png";
import StatsgrayIcon from "assets/img/stats-gray24.png";
import styles from "assets/js/all-styles/components/headerStyle.js";


const useStyles = makeStyles(styles);

const Header=(props) =>{
  const classes = useStyles();
  let name;
  function headerTitle(){
    props.routes.map(prop=>{
      if(window.location.href.indexOf(prop.layout+prop.path)!==-1){
        name=prop.name;
      }
      return null;
    });
    return name;
  }
const handleActive=(e)=>{
  if(e==="stats"){
    props.favouriteOrStatesChanger(false);
  }else{
    props.favouriteOrStatesChanger(true);
  } 
}

React.useEffect(() => {
  if(props.history.location.pathname==="/home/favorite"){
    props.favouriteOrStatesChanger(true);
  }else{
    props.favouriteOrStatesChanger(false);
  }
}, []);

name = headerTitle();
const activeStyle = { color:"#83cb02"};
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        
        <Hidden mdUp className={classes.mobileheader} implementation="css">
          <div className={classes.mobileheaderflex}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
           <div className={classes.homeflex}>
            <ul className={classes.ulhome}>
              <li className={classes.headerText}>{props.headerTitle}</li>
            </ul>
            
              { props.headerTitle===props.data.language.home &&
               <ul className={classes.ulhome2}>
                    <li className={classes.ulhome2li1, "homeHover"}>
                      <div className={classes.ulhome2img}>
                        <Link to="/home/favorite" className={classes.ulhome2ang}>
                          <img src={props.data.isFavorite ? FavIcon:FavgrayIcon} alt="Fav logo"></img>
                        </Link>
                      </div>
                    </li>
                    <li className="statsbtnli homeHover">
                      <div className={classes.ulhome2img}>
                        <Link to="/home/stats" className={classes.ulhome2ang}>
                          <img src={props.data.isFavorite ? StatsgrayIcon:StatsIcon} className={classes.headerimg} alt="stats logo"></img>
                        </Link>
                      </div>
                    </li>
                </ul>
              }
          </div>
          </div>
        </Hidden>
        <Hidden smDown className={classes.homeHeader} implementation="css">
       <div className={classes.homeflex}>
            <ul className={classes.ulhome}>
              <li className={classes.headerText}>{props.headerTitle}</li>
            </ul>
          
            { props.headerTitle===props.data.language.home &&
                  <ul className={classes.ulhome2}>
              <li className={classes.ulhome2li1}>
              <div className={classes.ulhome2img}>
                  <Link to="/home/favorite" 
                   className={classes.ulhome2ang}
                   style={ props.isFavorite ? activeStyle : null}
                   onClick={()=>handleActive("favorite")}
                   >
                     <img src={props.data.isFavorite ? FavIcon:FavgrayIcon} alt="Fav logo"></img>
                     <span className={classes.headerimg}>{props.data.language.favorites}</span>
                   </Link>
              </div>
              </li>
              <li className="statsbtnli">
              <div className={classes.ulhome2img}>
                 <Link to="/home/stats" 
                  className={classes.ulhome2ang}
                  style={ !props.isFavorite ? activeStyle : null}
                  onClick={()=>handleActive("stats")}
                  >
                  <img src={props.data.isFavorite ? StatsgrayIcon:StatsIcon} alt="stats logo"></img>
                  <span className={classes.headerimg}>{props.data.language.stats}</span>
                  </Link>
                </div>
              </li>
              </ul>
           }
        </div>
          
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps= state =>({ 
  headerTitle: state.reducer.headerTitle
})
const mapDispatchToProps = dispatch => bindActionCreators({
  favouriteOrStatesChanger
},dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Header);
