import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

const MonthDollarRateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] =useState("");
  const [rateInfo, setRateInfo] =useState("");

  //CurrencyUnit Value
  const currencyUnit = props.widget.params._attributes.currency_units;

   //estimated values
  let meterData = meterValue;

   //consumption and genetated values
   let meterData1 = rateInfo;

  useEffect(() => {
    if (props.totalPowerDataHistory) {
        if (props.totalPowerDataHistory.name === "cbusPowerHistoryTotals") {
         setMeterValue(props.totalPowerDataHistory.predicted_total_cost_month); 
      }
    }
  }, [props.totalPowerDataHistory]);

  useEffect(() => {
    if (props.totalPowerDataHistory !== undefined ) {
       if(props.totalPowerDataHistory.name=== "cbusPowerHistoryTotals"){
        let monthlyCost = props.totalPowerDataHistory.monthly_cost_totals.split(",").reverse();
        let monthlyValue = monthlyCost[2];
        setRateInfo(monthlyValue);
       }
    }
  }, [props.totalPowerDataHistory]);
  
    
  return (
  <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    Rate of Consumption/Generation
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this month<span style={{wordSpacing:'3px'}}> {meterData} {currencyUnit}</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {meterData1 > 0 ? "Consumed last month" :  "Generated last month" } 
    <span style={{wordSpacing:'3px'}}> {meterData1.toString().replace("-", " ")} {currencyUnit}</span>
    </Typography>
      </div>
  )
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(MonthDollarRateConsumption);
