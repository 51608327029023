import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: 'grey',
      placement:'right',
      maxHeight:'150px',
    },
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
    },
  }));
  
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip interactive enterDelay={1000} leaveDelay={300}   placement="top-start"   arrow classes={classes} {...props} />;
  }

  export default BootstrapTooltip;