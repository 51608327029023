import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);

const WeekKwhRateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] =useState("");
  const [rateInfo, setRateInfo] =useState("");

  //estimated values
  let meterData = (meterValue/1000).toFixed(2).replace("-", " ");

  let meterResult = meterData >= 9999 ? 9999 : meterData;

  //consumption and genetated values
   let meterDataInfo = (rateInfo/1000).toFixed(2);

   let meterResult1 = meterDataInfo >= 9999 ? 9999 : meterDataInfo;

   useEffect(() => {
    if (props.totalPowerDataHistory) {
        if (props.totalPowerDataHistory.name === "cbusPowerHistoryTotals") {
         setMeterValue(props.totalPowerDataHistory.predicted_total_power_week); 
      }
    }
  }, [props.totalPowerDataHistory]);

  useEffect(() => {
    if (props.totalPowerDataHistory !== undefined ) {
       if(props.totalPowerDataHistory.name=== "cbusPowerHistoryTotals"){
        let weeklyEnergy = props.totalPowerDataHistory.weekly_energy_totals.split(",").reverse();
        let weeklyValue = weeklyEnergy[2];
        setRateInfo(weeklyValue);
       }
    }
  }, [props.totalPowerDataHistory]);
  
    
  return (
  <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    Rate of Consumption/Generation
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this week<span style={{wordSpacing:'3px'}}> {meterResult}  {props.language.kWh}</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {meterResult1 > 0 ? "Consumed last week" :  "Generated last week" } 
    <span style={{wordSpacing:'3px'}}> {meterResult1.toString().replace("-", " ")} {props.language.kWh}</span>
    </Typography>
      </div>
  )
}

const mapStateToProps = (state) => ({
  cbusEvent: state.reducer.cbusEvent,
});

export default connect(mapStateToProps)(WeekKwhRateConsumption); 
