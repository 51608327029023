/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
// Assets Import //
import bulbWhite from "../../assets/img/bulbWhite.png";
import bulbGreen from "../../assets/img/bulbGreen.png";
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
//Styles imported locally//
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function //
export default function LightingRelay2StateEnable(props) {
  const initialData=props.initialCbusEvent.get("0x"+Number(props.widget.params._attributes.app).toString(16));
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [click, setClick] = useState(false);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };
  const updateFav = (fav) => {
    setFaData(!fav);
  };

   useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (props.cbusEvent.attributes.app ==="0x" + Number(props.widget.params._attributes.app).toString(16)) {
          if (props.cbusEvent.attributes.group === "0x"+Number(props.widget.params._attributes.ga).toString(16)) {
            if (props.cbusEvent.attributes.level == "0x0") {
              setClick(false);
              if(initialData){
                initialData[props.widget.params._attributes.ga]=props.cbusEvent.attributes.level;
                let lightingData=new Map(props.initialCbusEvent);
                props.socketEventDataChanger(lightingData);
              }
            } else {
              setClick(true);
              if(initialData){
              initialData[props.widget.params._attributes.ga]=props.cbusEvent.attributes.level;
              let lightingData=new Map(props.initialCbusEvent);
              props.socketEventDataChanger(lightingData);
              }
            }
          }
          }  
        }
      }
    }
    if(initialData){
      if(initialData[props.widget.params._attributes.ga]==="0x0") {
        setClick(false);
      } else  {
        setClick(true);
      }
    }
  }, [props.cbusEvent]);

  const handleChange = (click) => {
    setClick(!click);
    
    handleClick(!click);
  };

  const handleClick =(click)=>{
    if (click === true) {
      let on = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="255" ramp="0"/>',
      };
      props.handleSocketEventChange(on);
      
    } else if(click==false) {
      let off = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params.param[1]._attributes.value +
          '" command="cbusSetLevel" network="' +
          props.widget.params.param[0]._attributes.value +
          '" numaddresses="1" group="' +
          props.widget.params.param[2]._attributes.value +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(off);
      
    }
  }

  const on = props.widget.params._attributes.onicon; 
  const off = props.widget.params._attributes.officon;

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
  (props.widget._attributes.location === "all" || props.widget._attributes.location ==="none")
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  return (
    <>
      {" "}
      {props.data !== undefined && (
       <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img src={click?bulbGreen:bulbWhite} className={classes.headerIcon} />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={
                      click ? classes.headerText1Active : classes.headerText1
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  className={click? classes.controlPanelIconButtonActive:classes.controlPanelIconButton}
                  onClick={()=>handleChange(click)}
                  style={{paddingTop:'2%', paddingBottom:'2%'}}
                >
                  <Typography variant="h6" className={click?classes.topButtonTextActive :classes.topButtonText}>
                    {click ? on : off  }
                  </Typography>
                </Button>
              </div>
            </BootstrapTooltip>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
