import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import styles from "assets/js/all-styles/views/schedulesStyle.js";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

export default function SelectDaysOfWeek(props) {
  
  let listOfNumber = [];
  const classes = useStyles();
  const [weekData, setWeekData] = useState([
    {
      name: "Sun",
      Value: 1,
      isSelected: false,
      key: 0,
    },
    {
      name: "Mon",
      Value: 2,
      isSelected: false,
      key: 1,
    },
    {
      name: "Tue",
      Value: 4,
      isSelected: false,
      key: 2,
    },
    {
      name: "Wed",
      Value: 8,
      isSelected: false,
      key: 3,
    },
    {
      name: "Thu",
      Value: 16,
      isSelected: false,
      key: 4,
    },
    {
      name: "Fri",
      Value: 32,
      isSelected: false,
      key: 5,
    },
    {
      name: "Sat",
      Value: 64,
      isSelected: false,
      key: 6,
    }
  ]);

  const [allWeek, setAllWeek] = useState(false);

  // useEffect(() => {
  //   let SelectedData = subsetSum(
  //     [1, 2, 4, 8, 16, 32, 64],
  //     props.data
  //   );
  //   let data = [...weekData];
  //   data.map((x) => {
  //     if (SelectedData.includes(x.Value)) {
  //       x.isSelected = true;
  //     }
  //   });
  //   
  //   setWeekData(data);
  //   

    

  // }, []);

  useEffect(() => {
    let day = new Array(7).fill(false);
    day = parseIntArray(props.data, 7);
    
    let data = [...weekData];
    data.map((item, key) => item.isSelected = day[key]);


    

  }, []);

  // useEffect(() => {
  //   let data = [...weekData];
  //   let dataCount = data.filter((val) => val.isSelected);
  //   let sumOfSelectedVal = 0;
   

  //   if (dataCount.length === 7) {
  //     setAllWeek(true);
  //   } else {
  //     setAllWeek(false);
  //   }
    
  //   props.setWeekOfDays(Number(sumOfSelectedVal).toString(16));
  // }, [weekData, allWeek]);

  useEffect(() => {
    let day_d = 0;
    let data = [...weekData];
    let dataCount = data.filter((val) => val.isSelected);
    const result =  extractValue(data, "isSelected");
    day_d = parseArrayInt(result);

    if (dataCount.length === 7) {
      setAllWeek(true);
    } else {
      setAllWeek(false);
    }
   
    
    props.setWeekOfDays(day_d.toString(16));
  }, [weekData, allWeek]);

  function extractValue(arr, prop) {

    let extractedValue = [];

    for (let i=0; i < arr.length ; ++i) {

        // extract value from property
        extractedValue.push(arr[i][prop]);
    }
    return extractedValue;
}
  function parseIntArray(int_value, count) {
    var ret = new Array(count);
  
    var binary = intToBinary(int_value);
  
    while (binary.length < count)
      binary = "0" + binary;
    if (binary.length >= count)
      for (var i = 0; i < count; i++)
        ret[i] = (binary.charAt(count - 1 - i) == '1');
  
    return ret;
  }

  function parseArrayInt(array) {
    let ret = -1;
    let count = array.length - 1;
    let binary = "";
    for (let i = count; i >= 0; i--)
      binary += array[i] ? 1 : 0;
  
    if (binary.length > 0)
      ret = binaryToInt(binary);
    return ret;
  }
  
  function intToBinary(dec) {
      return (dec >>> 0).toString(2);
  }
  
  
  function binaryToInt(dec) {
      return parseInt((dec).toString(2), 2);
  }
 
  const selectDate = (key) => {
    let data = [...weekData];
    data[key].isSelected = !data[key].isSelected;
    setWeekData(data);
  };

  const handleFullChange = (event) => {
    let data = [...weekData];
    data.forEach((val, index) => {
      val.isSelected = allWeek ? false : true;
    });
    setAllWeek(!allWeek);
    setWeekData(data);
  };

  return (
    <div>
      <h1 style={{ color: "white", fontWeight: "600", fontSize: "20px" }}>
        {props.language.weekdays}
      </h1>
      <Divider style={{ margin: "0" }} />
      <p style={{ color: "white", fontWeight: "400", fontSize: "16px" }}>
        {props.language.selectActiveDays}
      </p>
      <ButtonGroup
        // orientation="vertical"
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {weekData.slice(0, 4).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.iconButtonActiveSchedule
                  : classes.controlPanelIconButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ButtonGroup
        // orientation="vertical"
        color="#fff"
        backgroundColor="#232323"
        variant="contained"
      >
        {weekData.slice(4, 7).map((val, key) => {
          return (
            <Button
              onClick={() => selectDate(val.key)}
              key={val.key}
              className={
                val.isSelected
                  ? classes.iconButtonActiveSchedule
                  : classes.controlPanelIconButton
              }
            >
              {val.name}
            </Button>
          );
        })}
      </ButtonGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={allWeek}
            onChange={handleFullChange}
            name="allWeek"
          />
        }
        label={props.language.selectAllWeekdays}
      />
    </div>
  );
}
