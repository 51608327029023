import axios from 'axios'
import { API_URL } from "../config/environment";

export function updateXml(data) {
    return axios({
        method: 'post',
        url: API_URL+'picedXml/updateAppConfig',
        data: data
      }); 
}

export function updateFavorites(data){
  return axios({
    method: 'post',
    url: API_URL+'favorites/addFavorites',
    data: data
  }); 
}

export function updateSocketEvent(data){
  return axios({
    method: 'post',
    url: API_URL+'socketEvents',
    data: data
  }); 
}

export function powerMeterParser(data){
  return axios({
    method: 'post',
    url: API_URL+'picedXml/powerMeterParser',
    data: data
  }); 
}

export function uploadFile(data,profile){
  var formData = new FormData();
  formData.append("name", "profile_" + profile.User + "_" + profile.id + ".jpg");
  formData.append("file", data);
  let config= {
    headers: {
        'content-type': 'multipart/form-data'
    }
  }
  return axios({
    method: 'post',
    url: API_URL+"upload",
    data: formData,
    config
  });
}

export function takePhotoUploadFile(data,profile){
  var formData = new FormData();
  formData.append("name", profile);
  formData.append("file", data);
  let config= {
    headers: {
        'content-type': 'multipart/form-data'
    }
  }
  return axios({
    method: 'post',
    url: API_URL+"upload",
    data: formData,
    config
  });
}

export function changeNetworkSettings(data){
  return axios({
    method: 'get',
    url: API_URL + "change_network_settings.pl",
    params: data
  }); 
}

export function rebootDevice(){
  return axios({
    method: 'get',
    url: API_URL + "reboot.pl",
  }); 
}

export function letEncrypt(){
  return axios({
    method: 'get',
    url: API_URL + "encrypt.pl",
  }); 
}

export function setupSSL(){
  return axios({
    method: 'get',
    url: API_URL + "setupssl.pl",
  }); 
}

export function whoimi(){
  return axios({
    method: 'get',
    url: API_URL + "whoami.cgi",
  }); 
}

export function vidUrl(id){
  return axios({
    method: 'get',
    url: API_URL + "reactgeturl.cgi?vid"+id,
  }); 
}

export function saveProject(){
  return axios({
    method: 'get',
    url: API_URL + "saveproject.sh",
  }); 
}

export function authUrl(vidUrl, user, pass){
  return axios({
    method: 'get',
    url: vidUrl,
    auth: {
      username: user,
      password: pass
    }
  }); 
}

export function getImage(imageUrl){
  return axios({
    method: 'get',
    url: imageUrl,
  }); 
}

export function deleteImage(data){
  return axios({
    method: 'post',
    url: API_URL + "delsnap.pl?name="+data.name
  }); 
}

export function wifiScanner(){
  return axios({
    method: 'get',
    url: API_URL + "wlan_scan.pl",
  }); 
}

export function getSystemIp(){
  return axios({
    method: 'get',
    url: API_URL+'systemIp',
  }); 
}

export function imageGallery(url){
  return axios({
    method: 'get',
    url:  API_URL + "getsnap.pl?name=list",
  }); 
}

export function connectToDevice(data){
  return axios({
    method: 'post',
    url: API_URL+'socketConnection',
    data: data
  }); 
}
export function disconnectWithDevice(data){
  return axios({
    method: 'post',
    url: API_URL+'socketDisconnect',
    data: data
  }); 
}

export function updateProfile(data) {
  return axios({
      method: 'post',
      url: API_URL+'update_profile.pl',
      data: data
    }); 
}


export function login(username,password){
  return axios({
    method: 'get',
    url: API_URL + "clipsal/resources/projectorkey.xml?login=reactlogin",
    headers: {
      'Authorization': `Basic ${window.btoa(username + ':' + password)}`,
     }
  }); 
}

export function loginCheck(credentials){
  return axios({
    method: 'get',
    url: API_URL + "clipsal/resources/projectorkey.xml?login=reactlogin",
    headers: {
      'Authorization': `Basic ${credentials}`,
     }
  }); 
}

export function passwordReset(data,username,password) {
  return axios({
      method: 'post',
      url: API_URL+'picedXml/passwordReset?login=reactlogin',
      headers: {
        'Authorization': `Basic ${window.btoa(username + ':' + password)}`,
       },
      data: data
    }); 
}

export function passwordClientReset(data,username,password) {
  return axios({
      method: 'post',
      url: API_URL+'picedXml/passwordReset',
      headers: {
        'Authorization': `Basic ${window.btoa(username + ':' + password)}`,
       },
      data: data
    }); 
}

export function versionInfo(){
  return axios({
    method: 'get',
    url: API_URL+'WiserVer',
  }); 
}

export function encryptInfo(){
  return axios({
    method: 'get',
    url: API_URL+'getLetsEncrypt',
  }); 
}