/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setSecurityStatus
} from "../../redux/actions/action";
//Assets //
import securityGreen from "../../assets/img/securityGreen.png";
import lockOpenWhite from "../../assets/img/lockOpenWhite.png";
import armedImg from "../../assets/img/armed.png";
import securityAlert from "../../assets/img/securityAlert.png";
//Components imported locally //
import EditWidget from "./editWidget";
import EditSecurityWidget from "./editSecurityWidget.js";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
// Styles imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//Function //
const SecurityWidget = (props) => {
  const initialData=props.initialCbusEvent.get("cbusSecGetArmedStatus");
  const initialStatusesData=props.initialCbusEvent.get("cbusSecGetStatuses");
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [armStatus, setArmStatus] = useState("0x0");
  const [alarmCount, setAlarmCount] = useState(0);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };
  const toggleDrawer1 = (open) => {
    setDraweropen1(open);
  };
  const updateFav = (fav) => {
    setFaData(!fav);
  };


  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "system_armed") {
          // setArmStatus(props.cbusEvent.attributes.arm_code);
          if(initialData){
            props.initialCbusEvent.set("cbusSecGetArmedStatus", parseInt(props.cbusEvent.attributes.arm_code, 16));
            let armData=new Map(props.initialCbusEvent);
            props.socketEventDataChanger(armData);
          }
        }
        if (props.cbusEvent.attributes.name === "entry_delay_started") {
          props.setSecurityStatus(props.cbusEvent.attributes.name);
          setArmStatus(props.cbusEvent.attributes.name);
          if(initialData){
            props.initialCbusEvent.set("cbusSecGetArmedStatus", props.cbusEvent.attributes.name);
            let armData=new Map(props.initialCbusEvent);
            props.socketEventDataChanger(armData);
          }
        }
        if (props.cbusEvent.attributes.name === "exit_delay_started") {
          props.setSecurityStatus(props.cbusEvent.attributes.name);
          setArmStatus(props.cbusEvent.attributes.name);
          if(initialData){
            props.initialCbusEvent.set("cbusSecGetArmedStatus", props.cbusEvent.attributes.name);
            let armData=new Map(props.initialCbusEvent);
            props.socketEventDataChanger(armData);
          }
        }
      }
    }
    if(initialData){
      if(initialData==="entry_delay_started"){
        setArmStatus(initialData);
      }else if(initialData==="exit_delay_started"){
        setArmStatus(initialData);
      }else{
        
        setArmStatus("0x" + Number(initialData).toString(16));
    
    }
    }
    if(initialStatusesData){
      let countdata=initialStatusesData.reduce((b,c)=>((b[b.findIndex(d=>d.el===c)]||b[b.push({el:c,count:0})-1]).count++,b),[]);
      if(statusExists(countdata,"0x1")){
        for(let j=0;j<countdata.length;j++){
          if(countdata[j].el=="0x1"){
            setAlarmCount(countdata[j].count);
          }
        }
      }else{
        setAlarmCount(0);
      }
    }
  }, [props.cbusEvent]);

  const statusExists = (arr,element) => {
    return arr.some(function(e) {
      return e.el === element;
    }); 
  }

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  return (
    <>
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header} 
              style={{cursor: "pointer" }}
              onClick={() => {
                toggleDrawer1(true);
              }} >
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img src={securityGreen} className={classes.headerIcon} />
                </IconButton>
                <div className={classes.header1}>
                  <Typography variant="h6" className={`${classes.headerText1} ${classes.textTruncation} `}>
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>

            <Button
              variant="contained"
              className={classes.bottomPanel}
              style={{ padding: "4%" }}
              onClick={() => {
                toggleDrawer1(true);
              }}
            >
              <Typography
                className={classes.headerTextSecurity}
                variant="h5"
                style={{ justifyItems: "flex-start" }}
              >
                {alarmCount>0 &&
                   <>{props.language.alarm }({alarmCount})</> 
                }
                {alarmCount==0 &&
                <>
                {armStatus === '0x0' &&
                  <>{props.language.disarmed}</>
                }
                {armStatus === '0x1' &&
                  <>{props.language.armed}</>
                }
                {armStatus === '0x2' &&
                  <>{props.language.monitor}</>
                }
                {armStatus === 'entry_delay_started' &&
                  <>{props.language.entryDelay}</>
                }
                {armStatus === 'exit_delay_started' &&
                  <>{props.language.exitDelay}</>
                }
                </>
               }
              </Typography>
              {alarmCount>0 &&
                <img src={securityAlert} className={classes.bottomPanelIcon} />
              }
              {alarmCount==0 &&
              <>
               { armStatus==='0x0' && 
               <img src={lockOpenWhite} className={classes.bottomPanelIcon} />
               }
               { armStatus==='0x1' && 
               <img src={armedImg} className={classes.bottomPanelIcon} />
               }
                { armStatus==='0x2' && 
               <img src={armedImg} className={classes.bottomPanelIcon} />
               }
               { armStatus==='entry_delay_started' && 
                <img src={lockOpenWhite} className={classes.bottomPanelIcon} />
               }
               { armStatus==='exit_delay_started' && 
               <img src={lockOpenWhite} className={classes.bottomPanelIcon} />
               }
               </>
               }
            </Button>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen1}
              onClose={() => {
                toggleDrawer1(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditSecurityWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                location={location}
                functionGroup={functionGroup}
                widgetCategory={props.widgetCategory}
                cbusEvent={props.cbusEvent}
                initialCbusEvent={props.initialCbusEvent}
                socketEventDataChanger={props.socketEventDataChanger}
                handleSocketEventChange={props.handleSocketEventChange}
                toggleDrawer={toggleDrawer}
                toggleDrawer1={toggleDrawer1}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  securityStatus: state.reducer.securityStatus
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSecurityStatus
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SecurityWidget);
