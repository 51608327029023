import {
  drawerWidth,
  transition,
  container
} from "assets/js/base-styles.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  [theme.breakpoints.down("sm")]: {
    content: {
      
      marginLeft:"0px !important"
   
    },
    headerText:{
      fontSize: "20px !important"
    },
    ulhome:{
      width:"100% !important"
    }

  },
  content: {
    // marginTop:"78px",
    minHeight: "calc(100vh - 78px)",
    marginLeft:"28px"
    // padding: "30px 30px",
    // minHeight: "calc(100vh - 78px)"
    // height:"100vh"
  },
  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
