import React, { useState, useEffect } from "react";
// Material-UI Style //
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography, Drawer, Grid } from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
// Assets imported locally //

import playGreen from "../../assets/img/playGreen.png";
// Components imported locally //
import EditWidget from "./editWidget";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
// CSS Style sheets imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);
// import BootstrapTooltip from '../../assets/widgets/tooltip'

// Function //

const Analog = (props) => {
  const classes = useStyles();
  const initialData = props.initialCbusEvent.get("analog");
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [sourceList, setSourceList] = useState([]);
  const [sourceSelected, setSourceSelected] = useState();
  const [source, setSource] = useState();
  const [img1, setImg1] = useState();
  const [img1Green, setImg1Green] = useState();
  const [img2, setImg2] = useState();

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const handleButton1 = (source) => {
    
    setSourceSelected(source);
    let on = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params._attributes.app +
        '" command="cbusSetLevel" network="' +
        props.widget.params._attributes.network +
        '" numaddresses="1" group="' +
        props.widget.params._attributes.ga +
        '" level="' +
        source +
        '" ramp="0" />',
    };
    props.handleSocketEventChange(on);
    
  };
  

  

  useEffect(() => {
    let sourceListFull = props.widget.params._attributes.sources.split("|");
    let sourceLists = [];
    let sourceName = null;
    let sourceValue = null;
    for (let i = 0; i < sourceListFull.length; i++) {
      let source = { sourceName: null, sourceValue: null };
      if (i % 2 !== 1) {
        sourceName = sourceListFull[i];
      }
      if (i % 2 === 1) {
        sourceValue = sourceListFull[i];
      }
      if (sourceName && sourceValue) {
        source.sourceName = sourceName;
        source.sourceValue = sourceValue;
        sourceLists.push(source);
        sourceName = null;
        sourceValue = null;
      }
    }
    let sources = [];
    for (let i = 0; i < sourceLists.length; i++) {
      let src = {
        sourceName: null,
        sourceImage: null,
        sourceImageGreen: null,
        sourceValue: null,
      };
      src.sourceName = sourceLists[i].sourceName;
      src.sourceImage = require("../../assets/img/" +
        sourceLists[i].sourceName +
        ".png");
      src.sourceImageGreen = require("../../assets/img/" +
        sourceLists[i].sourceName +
        "Green.png");
      src.sourceValue = sourceLists[i].sourceValue;
      sources.push(src);
    }
    setSourceList(sources);

    if (sourceList.length > 0) {
      setImg1(sourceList[0].sourceName);
      
      setImg1Green(sourceList[0].sourceName + "Green");
      
    }
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.ga).toString(16)
          ) {
            let changedSourceValue = Math.floor(parseInt(props.cbusEvent.attributes.level, 16));
            
            setSourceSelected(changedSourceValue);
            if (initialData) {
              initialData[props.widget.params._attributes.ga] = changedSourceValue;
              let analogData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(analogData);
            }
          }
        }
      }
    }
    if (initialData) {
    if (initialData[props.widget.params._attributes.ga]) {
        let changedSourceValue = parseInt(
          initialData[props.widget.params._attributes.level],
          16
        );
        for (let k = 0; k < sourceList.length; k++) {
          if (sourceList[k].sourceValue == changedSourceValue) {
            setSource(k);
            setSourceSelected(changedSourceValue)
          }
        }
      }
    }
  }, [props.cbusEvent]);

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
        .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
        ._attributes.Name;

  
  
  return (
    <>
      {" "}
      {props.data !== undefined && sourceList !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={playGreen}
                    alt={"play"}
                    className={classes.headerIcon}
                  />
                </IconButton>

                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={classes.headerText1Active}
                  >
                    {name}
                  </Typography>

                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>
            <div className={classes.controlPanel} style={{ flexWrap: "wrap" }}>
              {sourceList.map((source, key) => (
                <IconButton
                  aria-label="previous"
                  fontSize="small"
                  style={{ borderRight: "none" }}
                  className={classes.controlPanelIconButton}
                  onClick={() => handleButton1(source.sourceValue)}
                >
                  <img
                    src={
                      sourceSelected == source.sourceValue
                        ? source.sourceImageGreen.default
                        : source.sourceImage.default
                    }
                    alt={source.sourceName}
                    style={{
                      width: "50%",
                      padding: "15%",
                      borderRight: "none",
                    }}
                  />
                </IconButton>
              ))}
            </div>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
};

export default Analog;
