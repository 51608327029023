import React from "react";
export default function Randomtime(props) {
  let [state, setState] = React.useState({ startTime: props.data.userValue.StartTime===undefined?0:props.data.userValue.StartTime, endTime: props.data.userValue.EndTime===undefined?0:props.data.userValue.EndTime });
  let [hours, setHours] = React.useState(Math.floor(state.startTime/ 3600));

  let [minutes, setMinutes] = React.useState(((state.startTime)%3600)/60);


  let [endingHours, setEndingHours] = React.useState(Math.floor(state.endTime/ 3600));
  let [endingMinutes, setEndingMinutes] = React.useState(((state.endTime)%3600)/60);


  
  const startinghourscalculation = (event)=>{
    setHours(event.target.value);
    
    props.setTotalStartTimeSeconds(((event.target.value)*3600)+(minutes*60));
    props.setTimeType("4");

  }
  
  const startingminutescalculation = (event)=>{

    setMinutes(event.target.value);
    props.setTotalStartTimeSeconds((hours*3600)+((event.target.value)*60));
    props.setTimeType("4");
  }


  const endinghourscalculation = (event)=>{
    setEndingHours(event.target.value);
    props.setTotalEndTimeSeconds(((event.target.value)*3600)+(endingMinutes*60));
    props.setTimeType("4");

  }
  
  const endingminutescalculation = (event)=>{

    setEndingMinutes(event.target.value);
    props.setTotalEndTimeSeconds((endingHours*3600)+((event.target.value)*60));
    props.setTimeType("4");
  }

  let renderHours = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={startinghourscalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {hours}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
        </select>
      </>
    );
  };

  let renderMinutes = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={startingminutescalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {minutes}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
              <option value ="24">24</option>
              <option value ="25">25</option>
              <option value ="26">26</option>
              <option value ="27">27</option>
              <option value ="28">28</option>
              <option value ="29">29</option>
              <option value ="30">30</option>
              <option value ="31">31</option>      
              <option value ="32">32</option>
              <option value ="33">33</option>
              <option value ="34">34</option>
              <option value ="35">35</option>
              <option value ="36">36</option>
              <option value ="37">37</option>
              <option value ="38">38</option>
              <option value ="39">39</option>
              <option value ="40">40</option>
              <option value ="41">41</option>
              <option value ="42">42</option>
              <option value ="43">43</option>
              <option value ="44">44</option>
              <option value ="45">45</option>
              <option value ="46">46</option>
              <option value ="47">47</option>
              <option value ="48">48</option>
              <option value ="49">49</option>
              <option value ="50">50</option>
              <option value ="51">51</option>
              <option value ="52">52</option>
              <option value ="53">53</option>
              <option value ="54">54</option>
              <option value ="55">55</option>
              <option value ="56">56</option>
              <option value ="57">57</option>
              <option value ="58">58</option>
              <option value ="59">59</option>
             
        </select>
      </>
    );
  };

  let renderEndingHours = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={endinghourscalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {endingHours}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
        </select>
      </>
    );
  };

  let renderEndingMinutes = () => {
    return (
      <>
        <select className="selectineditschedulesoptions"
          onChange={endingminutescalculation}
          style={{ color: "#fff", backgroundColor: "#535353" }}
          value = {endingMinutes}
        >
          <option value ="0">00</option>
              <option value ="1">01</option>      
              <option value ="2">02</option>
              <option value ="3">03</option>
              <option value ="4">04</option>
              <option value ="5">05</option>
              <option value ="6">06</option>
              <option value ="7">07</option>
              <option value ="8">08</option>
              <option value ="9">09</option>
              <option value ="10">10</option>
              <option value ="11">11</option>
              <option value ="12">12</option>
              <option value ="13">13</option>
              <option value ="14">14</option>
              <option value ="15">15</option>
              <option value ="16">16</option>
              <option value ="17">17</option>
              <option value ="18">18</option>
              <option value ="19">19</option>
              <option value ="20">20</option>
              <option value ="21">21</option>
              <option value ="22">22</option>
              <option value ="23">23</option>
              <option value ="24">24</option>
              <option value ="25">25</option>
              <option value ="26">26</option>
              <option value ="27">27</option>
              <option value ="28">28</option>
              <option value ="29">29</option>
              <option value ="30">30</option>
              <option value ="31">31</option>      
              <option value ="32">32</option>
              <option value ="33">33</option>
              <option value ="34">34</option>
              <option value ="35">35</option>
              <option value ="36">36</option>
              <option value ="37">37</option>
              <option value ="38">38</option>
              <option value ="39">39</option>
              <option value ="40">40</option>
              <option value ="41">41</option>
              <option value ="42">42</option>
              <option value ="43">43</option>
              <option value ="44">44</option>
              <option value ="45">45</option>
              <option value ="46">46</option>
              <option value ="47">47</option>
              <option value ="48">48</option>
              <option value ="49">49</option>
              <option value ="50">50</option>
              <option value ="51">51</option>
              <option value ="52">52</option>
              <option value ="53">53</option>
              <option value ="54">54</option>
              <option value ="55">55</option>
              <option value ="56">56</option>
              <option value ="57">57</option>
              <option value ="58">58</option>
              <option value ="59">59</option>
             
        </select>
      </>
    );
  };
  




    return (

        <div style={{display: "flex", alignItems:"center"}}>
            <p style={{marginRight:"20px", color:"#fff"}}>{props.language.startingAt}</p>
            <div style={{marginRight:"20px", color:"#fff"}}>{renderHours()}</div>
            <p style={{marginRight:"20px", color:"#fff"}}>:</p>
            <div style={{marginRight:"20px", color:"#fff"}}>{renderMinutes()}</div>  
            <p style={{marginRight:"20px", color:"#fff"}}>{props.language.endingAt}</p>
            <div style={{marginRight:"20px", color:"#fff"}}>{renderEndingHours()}</div>
            <p style={{marginRight:"20px", color:"#fff"}}>:</p>
            <div style={{color:"#fff"}}>{renderEndingMinutes()}</div>        
         

        </div>
    )

    


}