import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/js/all-styles/views/profileStyle.js";
import { passwordClientReset, rebootDevice } from "services/xmlService";
import { Drawer } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Featuredrawer from "views/Settings/Profiles/Featuredrawer";
import sha256 from 'crypto-js/sha256';
import PasswordValidation from "./../../../components/Widgets/passwordValidation";

import "assets/css/profileStyle.css";

const useStyles = makeStyles(styles);



const Userdetails = ({ userName, setDraweropen, _attributes, draweropen, toggleDrawer, userProps, userValue, handleUdateUser, handleChange, handleInitialCHange, handleAdminChange, state, key, language }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (key, open) => {
    rebootDevice().then((res) => {
      setOpen(false);
    }).catch((error) => {
      
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [userField, setUserField] = useState({
    username: _attributes.User,
    currentPassword: _attributes.PW,
    stValue: _attributes.ST,
    editpassword: _attributes.EditPW,
    password: "",
    newpassword: "",
    repeatpassword: ""

  }, []);

  useEffect(() => {
    setUserField({
      username: _attributes.User,
      currentPassword: _attributes.PW,
      stValue: _attributes.ST,
      editpassword: _attributes.EditPW,
      password: "",
      newpassword: "",
      repeatpassword: ""

    });

  }, [_attributes.PW]);
  let passwordforvalidation;
  const [samePassword, setSamePassword] = useState(false);
  const [sameCurrentPasword, setSameCurrentPasword] = useState(false);
  const [passwordStyle, setPasswordStyle] = useState(true);
  const [newpasswordStyle, setNewPasswordStyle] = useState(true);
  const [newRepeatPWStyle, setNewRepeatPWStyle] = useState(false);

  const [savedisabled, setSavediabled] = useState(false);
  const [validPassword, setValidPassword] = useState();

  const [passwordValidation, setPasswordValidation] = useState(false);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
    specialNumberCharCheck: false,
    noSpaceCheck: false,
    usernameCheck: false
  });

  const [password, setPassword] = React.useState();

  const handleOnFocus = () => {
    setPasswordValidation(true)
  }

  const handleOnBlur = () => {
    setPasswordValidation(false)
  }

  const handleOnKeyUp = (e) => {
    const { value } = e.target;
    const capsLetterCheck = /(?=.*[a-z])(?=.*[A-Z])/.test(value);
    const pwdLengthCheck = (value.length >= 8) && (value.length <= 64);
    const specialNumberCharCheck = /[~!@#$%\^&*"'`)}|\[\]\/\\?><;,:{(+=._-]|[0-9]/.test(value);
    const noSpaceCheck = /^[^\s]+(\s+[^\s]+)*$/.test(value);
    const usernameCheck = value.toLowerCase().includes(userField.username.toLowerCase());
    setChecks({
      capsLetterCheck,
      pwdLengthCheck,
      specialNumberCharCheck,
      noSpaceCheck,
      usernameCheck
    })
  }

  useEffect(() => {



    if (userField.newpassword !== "" && (userField.newpassword === userField.repeatpassword) && (decodedPassword === validPassword) && (userField.password !== userField.newpassword)) {
      setSavediabled(true);

    } else {
      setSavediabled(false);

    }
    if (userField.newpassword !== "" && (userField.newpassword === userField.repeatpassword)) {
      setNewRepeatPWStyle(true);

    } else {
      setNewRepeatPWStyle(false);

    }


  }, [samePassword, sameCurrentPasword, userField.newpassword, userField.repeatpassword, userField.password])

  let constr = userField.currentPassword;
  // let buff = new Buffer(constr, "base64");
  let decodedPassword = constr;

  //encode
  let decodestr = decodedPassword;
  //let buff1 = new Buffer(decodestr);
  //let stringtobase = buff1.toString("base64");
  let userCurrentDecodedPassword = decodestr;
  const handleUserDetails = (event) => {
    setUserField({ ...userField, [event.target.name]: event.target.value });
  };


  const handleCurrentPasswordCheck = (event) => {
    
    setUserField({ ...userField, [event.target.name]: event.target.value });
    if (userField.repeatpassword !== null && userField.newpassword === event.target.value) {
      setSameCurrentPasword(true);
      setNewRepeatPWStyle(true);
      if (samePassword) {

        setSavediabled(true);
      } else {
        setNewRepeatPWStyle(false);
        setSavediabled(false);
      }

    } else {
      setSavediabled(false);
      setNewRepeatPWStyle(false);
    }
    setSameCurrentPasword(false);
  };

  const handlePasswordValid = (decodedPassword, passwordforvalidation) => {

    if (decodedPassword === passwordforvalidation) {
      setSamePassword(true);


    } else {
      setSamePassword(false);

    }



  }

  const handleNewPassword = (event) => {
    setNewPasswordStyle(false);

    setUserField({ ...userField, [event.target.name]: event.target.value })
  }

  const handlePasswordCheck = (event) => {
    // handleUserDetails(event);
    setPasswordStyle(false);
    setUserField({ ...userField, [event.target.name]: event.target.value })

    let userPW = event.target.value;
    let shaPW = sha256(userPW).toString();
    passwordforvalidation = sha256(shaPW + userField.stValue).toString();
    setValidPassword(passwordforvalidation);

    // 
    // 
    handlePasswordValid(decodedPassword, passwordforvalidation);

  }

  const handleSavePassword = () => {
    let decodestr = userField.newpassword;
    let encodedPassword = sha256(decodestr).toString();
    let data = {
      name: userField.username,
      currentPassword: password,
      newPassword: encodedPassword
    }
    passwordClientReset(data, userField.User, sha256(password).toString()).then((res) => {
      if (res.status == 200 || res.status == 304) {
        setOpen(true);
      }
    });


    setUserField({
      ...userField,
      password: "",
      newpassword: "",
      repeatpassword: ""
    });
    setPasswordStyle(true);
    setSavediabled(false);
    setNewPasswordStyle(true);

  }

  return (
    <>
      <div className="profileRow">
        <div style={{ width: "40%" }}>
          <span>{language.username}</span>
        </div>

        <div style={{ width: "60%" }}>
          <input
            type="text"
            value={userField.username}
            style={{ fontWeight: "600" }}
            name="username"
            onChange={handleUserDetails}
            disabled={
              userName === _attributes.User
            }
          />
        </div>
      </div>
      <div className="profileRow">
        <div style={{ width: "40%" }}>
          <span>{language.currentPassword}</span>
        </div>

        <div style={{ width: "60%" }}>
          <input type="password"
            value={userField.password}
            name="password"
            style={passwordStyle ? {} : (samePassword ? { borderRight: "3px solid #538c0d" } : { borderRight: "3px solid red" })}
            onChange={handlePasswordCheck}


          // classNames= {samePassword===true?classes.colorchange:""}
          />
        </div>
      </div>
      <div className="profileRow">
        <div style={{ width: "40%" }}>
          <span>{language.newPassword}</span>
        </div>

        <div style={{ width: "60%" }}>
          <input type="password"
            name="newpassword"
            value={userField.newpassword}
            style={{ fontWeight: "600" }}
            onChange={handleNewPassword}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onKeyUp={handleOnKeyUp}
          // onChange = {(event)=>setUserField({ ...userField, [event.target.name]: event.target.value })}
          />
          {passwordValidation ? <PasswordValidation
            capsLetterFlag={checks.capsLetterCheck ? "valid" : "invalid"}
            pwdLengthFlag={checks.pwdLengthCheck ? "valid" : "invalid"}
            specialNumberCharFlag={checks.specialNumberCharCheck ? "valid" : "invalid"}
            noSpaceCheckFlag={checks.noSpaceCheck ? "valid" : "invalid"}
            usernameFlag={checks.usernameCheck ? "invalid" : "valid"}
          />
            : null}
        </div>
      </div>


      <div className="profileRow">
        <div style={{ width: "40%" }}>
          <span>{language.repeatPassword}</span>
        </div>

        <div style={{ width: "60%" }}>
          <input type="password"
            name="repeatpassword"
            value={userField.repeatpassword}
            onChange={handleCurrentPasswordCheck}
            style={newpasswordStyle ? {} : (newRepeatPWStyle ? { borderRight: "3px solid #538c0d" } : { borderRight: "3px solid red" })}
          // style={{ fontWeight: "600" }} 
          />
        </div>
      </div>
      <div className="profileRow">
        <div style={{ width: "40%" }}></div>

        <div style={{ width: "60%" }}>

          <button className="saveDisableButton"
            disabled={!savedisabled}
            //  disabled={!samePasword && !sameCurrentPasword}
            // onClick={handleSavePassword}
            onClick={handleSavePassword}
          >
            {language.save}
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          color: "#535353",
          fontWeight: "600",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div style={{ width: "40%" }}></div>

        <div style={{ width: "60%" }}>
          <button
            className={classes.featureButton}
            onClick={toggleDrawer(true, _attributes, key)}
            // style={{ color: "#8C8C8C", fontWeight: "600" }}
            disabled={
              _attributes.Admin === "1" &&
              (userName === _attributes.User)
            }
          >
            {language.featurePermissions}

          </button>
        </div>
      </div>
      {/* <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={draweropen}
                  onClose={toggleDrawer(false, _attributes)}
                  modal
                  classes={{
                    paper: classNames(classes.drawerPaper),
                  }}
                  ModalProps={{
                    keepMounted: false, // Better open performance on mobile.
                  }}
                >
                  <Featuredrawer
                    _attributes={_attributes}
                    key={key}
                    userValue={userValue}
                    handleUdateUser={handleUdateUser}
                    toggleDrawer={toggleDrawer}
                    handleChange={handleChange}
                    handleInitialCHange={handleInitialCHange}
                    handleAdminChange={handleAdminChange}
                    state={state}
                    setDraweropen={()=>setDraweropen()}
                    language={language}

                  />
                </Drawer> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {language.rebootContinueDescription}
          {/* {"You need to reboot for these changes to take effect"} */}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} className={classes.drawerbtn} >
            {language.rebootLater}

          </Button>
          <Button onClick={handleClickOpen} className={classes.drawerbtn} autoFocus>
            {language.rebootNow}

          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Userdetails;