import React from "react";
import { useState, useEffect } from "react";
import EditWidget from '../editWidget';
import IOTimeLogic from '../IOTimeLogic';
import BootstrapTooltip from '../../../assets/widgets/tooltip/tooltip'
import profileGreen from "../../../assets/img/profileGreen.png";

// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Grid, Typography, Drawer } from '@material-ui/core';
import classNames from "classnames"
import StarsIcon from '@material-ui/icons/Stars'
import EditIcon from '@material-ui/icons/Edit';
import { updateXml } from "../../../services/xmlService";
import { getAppConfigData } from "redux/actions/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import styles from '../../../assets/widgets/widgetStyles/commonStyles'
const useStyles = makeStyles(styles);

const UserSystemTime = (props) => {
  const initialData = props.initialCbusEvent.get("usio_var" + props.widget.params._attributes.usio_var);
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [draweropen1, setDraweropen1] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [min, setMin] = useState(props.min == undefined ? 0 : Math.floor(props.min));
  const [editedTime, setEditedTime] = useState(props.min == undefined ? 0 : Math.floor(props.min));
  const [sysInt, setSysInt] = useState(props.sysInt == undefined ? 0 : Math.floor(props.sysInt));
  const [userVariable, setUserVariable] = useState();
  const [isDateValidation, setIsDateValidation] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [ampm, setAmpm] = useState('am');

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const toggleDrawer1 = (open) => (event) => {
    setDraweropen1(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  }

  const timer = (int, timeFormat24) => {
    var sec_num = parseInt(int, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    let time = hours + ':' + minutes + ':' + seconds;
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      if (!parseInt(timeFormat24)) {
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
    }
    return time.join(''); // return adjusted time or original string
  }

  const handleSave = () => {
    setDraweropen1(false);
    setSysInt(editedTime);
    let hrs = 0;
    if (ampm == 'pm') {
      hrs = Number(hours) + 12;
    } else {
      hrs = Number(hours);
    }
    let newsysint = hrs * 3600 + Number(minutes) * 60 + Number(seconds);
    let savesio4 = { cmd: '<cbus_cmd app="257" command="cbusSetUserSystemIOInt"  value="' + newsysint + '" sio_index="' + props.widget.params._attributes.usio_var + '" status="0" />' };
    props.handleSocketEventChange(savesio4);

    let uservar = props.data.Project.UserVariables.UserVariable;
    for (let i = 0; i < uservar.length; i++) {
      if (uservar[i]._attributes.id == props.widget.params._attributes.usio_var) {
        props.data.Project.UserVariables.UserVariable[i]._attributes.IntegerValue = newsysint;
        
      }
    }
    updateXml(props.data)
      .then((result) => {
        props.getAppConfigData();
        setMin(newsysint);
      })
      .catch((error) => {
        
      });

  }

  const handleChangeTime = (changedVal, type) => {
    if (type == 'hours') {
      setHours(changedVal);
    }
    if (type == 'minutes') {
      setMinutes(changedVal);
    }
    if (type == 'seconds') {
      setSeconds(changedVal);
    }
    if (type == 'ampm') {
      setAmpm(changedVal);
    }
  }
  const dataValidition = (isDateValid) => {
    setIsDateValidation(isDateValid);
  }
  useEffect(() => {
    let data = props.data.Project.UserVariables.UserVariable;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]._attributes.id == props.widget.params._attributes.usio_var) {
          setUserVariable(data[i]._attributes);
          let intitialdatavalue = {
            value: data[i]._attributes.IntegerValue
          }
          props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, intitialdatavalue);
          let ioData = new Map(props.initialCbusEvent);
          props.socketEventDataChanger(ioData);
          setMin(data[i]._attributes.IntegerValue);
        }
      }
    }
  }, []);

  const editIOLogic = () => (
    <>
      <div style={{ background: "#3b3b3b" }}>
        <div style={{ padding: "25px 10px 5px 15px" }}>
          <p style={{ color: "#C0C0C0", fontWeight: "600", margin: "0" }}>
            Edit Widget
          </p>
          <p style={{ color: "#707070", fontWeight: "600", margin: "0" }}>
            {name}
          </p>
        </div>
        <hr style={{ border: "1px solid black", margin: "0", borderTopColor: "transparent" }} />
        <div style={{ display: "flex", padding: "15px", justifyContent: "space-between" }}>
          <button className={classes.drawerbtn}
            onClick={toggleDrawer1(false)}

          >
            {props.language.cancel}
          </button>
          <button
            className={classes.drawerbtn}
            disabled={isDateValidation}
            onClick={handleSave}
          >
            {props.language.save}
          </button>
        </div>
        <hr style={{ border: "1px solid black", margin: "0", borderTopColor: "transparent" }} />
      </div>
      <IOTimeLogic widget={props.widget} data={props.data} dataValidition={dataValidition} userVariable={userVariable} min={timer(min)} saveChanges={handleChangeTime} />

    </>
  );

  // useEffect(() => {
  //   let initialEventTriger = {
  //     cmd:
  //     '<cbus_cmd app="257" command="cbusGetUserSystemIOInt" sio_index="' +
  //     props.widget.params._attributes.usio_var +
  //     '" nooofparams="0"/>',
  //   };
  //   props.handleSocketEventChange(initialEventTriger);
  // },[]);

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_resp") {
        if (props.cbusEvent.attributes.command === "cbusSetUserSystemIOInt") {
          if (props.cbusEvent.attributes.sio_index === props.widget.params._attributes.usio_var) {
            setMin(timer(props.cbusEvent.attributes.value));
            if (initialData) {
              initialData.value = props.cbusEvent.attributes.value;
              props.initialCbusEvent.set("usio_var" + props.widget.params._attributes.usio_var, initialData);
              let ioData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(ioData);
            }
          }
        }
      }
    }
    if (initialData) {
      setMin(initialData.value);
    }
  }, [props.cbusEvent]);

  // Data assigned to consts from Redux Store // 
  const id = props.widget._attributes.id;
  const name = props.widget.params._attributes.label != undefined ? props.widget.params._attributes.label : "";
  const location = props.widget._attributes.location === "none" ? "" : props.locations[Number(props.widget._attributes.location)]._attributes.Name;
  const functionGroup = props.widget._attributes.functiongroup === "none" ? "" : props.functionGroups[Number(props.widget._attributes.functiongroup)]._attributes.Name;


  return (
    <> {props.data !== undefined &&
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <div className={classes.root} style={{ display: 'flex' }} >
          <BootstrapTooltip
            title={
              <React.Fragment>
                <IconButton
                  className={favData ? classes.toolTipFavIconSelected : classes.toolTipFavIcon}
                  aria-label="delete"
                  onClick={() => { props.handleFavorite(id, props.widget._attributes.type); updateFav(favData); }}

                >
                  <StarsIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => { toggleDrawer(true) }}
                >
                  < EditIcon />
                </IconButton>
              </React.Fragment>
            }
          >
            <div className={classes.header} >
              <IconButton aria-label="previous" disabled='true' className={classes.headerIconButton} >
                <img src={profileGreen} alt={"bulb"} className={classes.headerIconLogic} />
              </IconButton>
              <div className={classes.header1} >
                <Typography variant='h6' className={`${classes.headerTextLogicIO} ${classes.textTruncation} `} >
                  {name}
                </Typography>
              </div>
            </div>
          </BootstrapTooltip>

          <Button
            variant="contained"
            className={classes.logicIoBottomPanel}
            onClick={toggleDrawer1(true)}
            style={{ cursor: "pointer" }}
          >
            {props.data.Project.Info.TimeFormat._attributes.Hour === "0" ?
              <>
                <Typography className={classes.bottomPanel2ButtonText} variant='h5' >
                  {timer(min, props.data.Project.Info.TimeFormat._attributes.Hour)}
                </Typography>
              </> :
              <Typography className={classes.bottomPanel2ButtonText} variant='h5' >
                {timer(min, props.data.Project.Info.TimeFormat._attributes.Hour)}
              </Typography>
            }
          </Button>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={draweropen}
            onClose={() => { toggleDrawer(false) }}
            modal
            classes={{
              paper: classNames(classes.drawerPaper),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <EditWidget saveEditedWidget={props.saveEditedWidget} data={props} toggleDrawer={toggleDrawer} />
          </Drawer>
        </div>
      </Grid>
    }
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={draweropen1}
        onClose={() => { toggleDrawer1(false) }}
        modal
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {editIOLogic()}
      </Drawer>
    </>

  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAppConfigData
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UserSystemTime);