const profileStyle = (theme) => ({
  root: {
    display: "flex !important",
    flexDirection: "column",
    margin: "5%",
    backgroundColor: "#000000",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
    textTransform: "none",
  },
  rootPowerMeter: {
    display: "flex !important",
    flexDirection: "column",
    margin: "5%",
    backgroundColor: "#2b2b2b",
    textTransform: "none",
    // height: "435px",
    borderBottom: "solid 2px black",
  },
  rootSurveillance: {
    display: "flex !important",
    flexDirection: "column",
    margin: "5%",
    backgroundColor: "#000000",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
    textTransform: "none",
    //height: "100%"
  },
  rootLogic: {
    display: "flex !important",
    flexDirection: "column",
    margin: "4%",
    // backgroundColor: "#000000",
    // backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
    textTransform: "none",
  },
  "&.Mui-selected": {
    outline: "none",
  },
  MuiButtonBase: {
    // The properties to apply
    disableRipple: true, // No more ripple, on the whole application 💣!
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundImage: "linear-gradient(#393939, #1b1b1b)",
    borderTop: "solid 1px #747474",
    fontStyle: "helvetica",
    padding: "2%",
    paddingLeft: "0",
    borderBottom: "solid 2px black",
  },
  headerMinderPro: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundImage: "linear-gradient(#393939, #1b1b1b)",
    borderTop: "solid 1px #747474",
    fontStyle: "helvetica",
    padding: "1%",
    paddingLeft: "0",
    borderBottom: "solid 2px black",
  },
  headerOff: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundImage: "linear-gradient()",
    borderTop: "solid 1px #747474",
    fontStyle: "helvetica",
    padding: "2%",
    paddingLeft: "0",
    borderBottom: "solid 2px black",
  },
  headerIconButton: {
    width: "17%",
    padding: "0 !important",
    borderRadius: "none",
    borderRadius: "0",
  },
  headerIcon: {
    height: "8%",
    padding: "15%",
    width: "80%",
  },
  header1: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1",
    paddingBottom: ".5%",
    flexGrow: "1",
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    width:'50%'
  },
  headerText1: {
    color: "#bfbfbf",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    // whiteSpace: "nowrap",
    //  overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  headerTextGallery: {
    color: "#bfbfbf",
    fontWeight: "600",
    fontSize: "1rem",
    textTransform: "none",
    paddingTop: "0%",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    // whiteSpace: "nowrap",
    //  overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  headerTextLogicIO: {
    color: "#86D100",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    // whiteSpace: "nowrap",
    //  overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  headerTextDelete:  {
    color: "#fff",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
  
  },
  textTruncation:{
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }, 
  headerText1SecurityZone: {
    color: "#bfbfbf",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    marginTop: "6px",
  },
  headerText1Active: {
    color: "#86D100",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerTextNew1:{
    color: "#fff",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
  },
  headerText1location:{
    color: "#bfbfbf",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",

  },
  headerText1locationwidget:{
    color: "#737373",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    padding: "none",
    width: "100%",
    alignItems: "left",
    justifyContent: "flex-start",
    justifyItems: "flex-start",

  },
  headerText2: {
    color: "#737373",
    textTransform: "none",
    padding: "none",
    fontWeight: "600",
    fontSize: "1.25rem",
    wordBreak: "break-all",
    overflowWrap: "break-word",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }, 
  headerText2New: {
    color: "#fff",
    textTransform: "none",
    padding: "none",
    fontWeight: "600",
    fontSize: "1.25rem",
    wordBreak: "break-all",
    overflowWrap: "break-word",
    width: "100%",
   whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerTextSecurity: {
    color: "#737373",
    textTransform: "none",
    padding: "none",
    fontWeight: "600",
    fontSize: "1.25rem",
    wordBreak: "break-all",
    overflowWrap: "break-word",
    width: "100%",
    textAlign: "left",
    fontSize: "25px",
  },
  headerRightIconButton: {
    width: "15%",
    padding: "3%",
    disabled: "true",
    borderRadius: "0px !important",
    borderLeft: "2px solid black",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  headerRightIcon: {
    width: "100%",
    padding: "3%",
    disabled: "true",
    borderRadius: "none",
  },
  headerRightIconArrow: {
    width: "100%",
    padding: "3%",
    disabled: "true",
    borderRadius: "none",
  },
  bottomPanelIcon: {
    width: "12%",
    paddingTop: "2% !important",
    paddingBottom: "2% !important",
    paddingLeft: "15%",
  },
  musicPanelIcon: {
    width: "12%",
    paddingTop: "2% !important",
    paddingBottom: "2% !important",
  },
  controlPanel: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "0",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
  },
  controlPanelIcon: {
    display: "flex",
    width: "50%",
    padding: "17.5%",
    borderRadius: "0",
  },
  controlPanelIconButton: {
    display: "flex",
    width: "25%",
    padding: "0%",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
    borderRight: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 1px black",
    borderRadius: "0",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  controlPanelIconButtonActive: {
    display: "flex",
    width: "25%",
    padding: "0%",
    backgroundImage: "linear-gradient(#181818, #679319) !important",
    borderRight: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 2px black",
    borderRadius: "0",
    "&:hover": {
      background: "linear-gradient( #393939, #578800) !important",
    },
  },
  securitycontrolPanelIconButtonActive: {
    display: "flex",
    width: "25%",
    padding: "0%",
    color: "green",
    backgroundImage: "linear-gradient(#151515, #1b1b1b) !important",
    borderRight: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 2px black",
    borderRadius: "0"
  },
  controlPanelIconButtonDelete:{
    display: "flex",
    width: "25%",
    padding: "0%",
    backgroundColor: "red",
    borderRight: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 1px black",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  controlPanelIconButton1: {
  borderLeft:"0px",
    width: "42%",
    marginTop:"4%",
    marginRight: "5%",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
    // borderRight: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 1px black",
    borderRadius: "0",
    paddingBottom: "2px",
    paddingTop: "8px",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  controlPanelIconButton1Active: {
    width: "42%",
    marginRight: "5%",
    backgroundImage: "linear-gradient(#181818, #679319) !important",
    borderRight: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 2px black",
    borderRadius: "0",
    "&:hover": {
      background: "linear-gradient( #393939, #578800) !important",
    },
  },
  controlPanelIconButton1Delete:{
    width: "42%",
    marginRight: "5%",
    backgroundColor: "red",
    borderRight: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 1px black",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  numberControlPanelActive: {
    display: "flex",
    flexDirection: "row",
    padding: "7.2%",
    backgroundColor: "#2b2b2b !important",
    backgroundImage: "#2b2b2b !important",
    borderBottom: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderRadius: "0 !important",
  },
  numberControlPanel: {
    display: "flex",
    flexDirection: "row",
    padding: "7.2%",
    backgroundImage: "#151515 !important",
    backgroundColor: "#151515 !important",
    borderBottom: "solid 2px black",
    borderTop: "solid 1px #747474",
  },
  numberControlPanelHvac: {
    display: "flex",
    flexDirection: "row",
    backgroundImage: "#151515 !important",
    backgroundColor: "#151515 !important",
    borderBottom: "solid 2px black",
    borderTop: "solid 1px #747474",
  },
  numberControlPanelActiveHvac: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#2b2b2b !important",
    backgroundImage: "#2b2b2b !important",
    borderBottom: "solid 2px black",
    borderTop: "solid 1px #747474",
    borderRadius: "0 !important",
  },

  musicPanelCentralIconButton: {
    display: "flex",
    width: "25%",
    padding: "0%",

    borderRadius: "0",
  },
  musicPanelCentralIcon: {
    width: "80%",
    padding: "15%",
  },
  Icon1: {
    display: "flex",
    width: "25%",
    padding: "0%",
    borderRight: "solid 2px black",
    borderRadius: "0",
  },
  Icon1Right: {
    display: "flex",
    width: "25%",
    padding: "0%",
    borderRight: "none",
    borderRadius: "0",
  },
  Icon1Active: {
    display: "flex",
    backgroundImage: "linear-gradient(black, lime)",
    borderRadius: "0",
    outlineColor: "black",
    borderRight: "solid 2px black",
    width: "25%",
    padding: "0%",
  },
  Icon1ActiveRight: {
    display: "flex",
    backgroundImage: "linear-gradient(black, lime)",
    borderRadius: "0",
    outlineColor: "black",
    borderRight: "none",
    width: "25%",
    padding: "0%",
  },
  Icon2: {
    display: "flex",
    outline: "medium !important",
    width: "25%",
    padding: "3%",
  },
  controlTypo1: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    color: "#727272",
    align: "center",
    height: "20%",
    width: "15%",
    padding: "4%",
    alignSelf: "auto",
  },
  controlTypo1Active: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    color: "#a9a9a9",
    align: "center",
    height: "20%",
    width: "15%",
    padding: "4%",
    alignSelf: "auto",
  },
  controlTypo2: {
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    color: "#727272",
    align: "center",
    height: "20%",
    width: "15%",
    padding: "4%",
    alignSelf: "auto",
    // fontSize:'10px'
  },
  controlIcons: {
    display: "flex",
    height: "30%",
    width: "50%",
    color: "#747474",
    padding: "4%",
  },
  controlIconsActive: {
    display: "flex",
    height: "30%",
    width: "50%",
    padding: "4%",
    color: "#d1d1d1",
  },
  bottomPanel: {
    width: "100%",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "6.35%",
    backgroundImage: "linear-gradient(#505050, #3b3b3b)",
    borderRadius: "0 !important",
    "&:hover": {
      background: "linear-gradient(#515151, #414141) !important",
    },
  },
  bottomPanelMinderPro: {
    width: "100%",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "4.35%",
    fontSize: "25px",
    backgroundImage: "linear-gradient(#505050, #3b3b3b)",
    borderRadius: "0 !important",
    "&:hover": {
      background: "linear-gradient(#515151, #414141) !important",
    },
  },
  bottomPanelActive: {
    display: "flex",
    flexDirection: "row",
    padding: "6.35%",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 1px black",
    backgroundImage: "linear-gradient(#060606, #252525) !important",
    borderRadius: "0 !important",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  bottomPanelScenes: {
    display: "flex",
    flexDirection: "row",
    backgroundImage: "linear-gradient(#393939, #1b1b1b)",
    borderBottom: "solid 2px black",
  },
  bottomPanelScenesActive:{
    display: "flex",
    flexDirection: "row",
    backgroundImage: "linear-gradient(#181818, #679319) !important",
    borderBottom: "solid 2px black",
  },
  bottomPanel2Button: {
    width: "50%",
    borderRadius: "0",
    borderRight: "2px solid black",
    backgroundImage: "linear-gradient(#393939, #1b1b1b)",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    padding: "7%",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  bottomPanel2ButtonActive: {
    width: "50%",
    borderRadius: "0",
    borderRight: "2px solid black",
    backgroundImage: "linear-gradient(#181818, #679319) !important",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    padding: "7%",
   
  },
  bottomPanel3Button: {
    width: "33%",
    borderRadius: "0",
    borderRight: "2px solid black",
    backgroundImage: "linear-gradient(#393939, #1b1b1b)",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    padding: "7%",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  bottomPanel3ButtonActive: {
    width: "33%",
    borderRadius: "0",
    borderRight: "2px solid black",
    backgroundImage: "linear-gradient(#181818, #679319) !important",
    background:"linear-gradient(#181818, #679319) !important",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    padding: "7%",
   },
  bottomPanel1ButtonActive: {
    width: "100%",
    borderRadius: "0",
    backgroundImage: "linear-gradient(#181818, #679319) !important",
    background:"linear-gradient(#181818, #679319) !important",
    backgroundColor:"linear-gradient(#181818, #679319) !important",
     textTransform: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "6.35%",
      },
  bottomPanel1Button: {
    width: "100%",
    borderRadius: "0",
    backgroundImage: "linear-gradient(#393939, #1b1b1b)",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "6.35%",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },

  bottomPanel2ButtonIcon: {
    width: "25%",
  },
  bottomPanelIconAlarm: {
    width: "12%",
  },
  mp3BottomPanel: {
    flexDirection: "row",
  },
  iconBG: {
    color: "#92E300",
  },
  iconBG2: {
    backgroundColor: null,
  },
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "0",
    zIndex: "1",
    width: 460,
    height: "100%",
    background: "#232323",
  },
  drawerPaper1: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "0",
    zIndex: "1",
    width: 460,
    height: "100%",
    background: "#232323",
  },
  drawerPaper2: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "0",
    zIndex: "1",
    width: "100%",
    height: "100% vh",
    background: "#232323",
  },
  featureButton: {
    background: "none !important",
    border: "none",
    padding: "0 !important",
    textDecoration: "underline",
    cursor: "pointer",
  },
  drawerInput: {
    width: "70%",
    height: "34px",
    background: "#1f1f1f",
    color: "white",
  },
  drawerInputDiv: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    backgroundImage: "#1b1b1b",
  },
  drawerbtn: {
    height: "42px",
    padding: "10px 60px",
    color: "#fff",
    marginRight: "25px",
    background:
      "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
    borderStyle: "solid",
    border: "0",
    borderTop: "1px solid #a5a5a5",
    fontWeight: "600",
    cursor: "pointer",
    "&:disabled": {
      color: "#8C8C8C",
      cursor: "default",
    },
  },
  // drawerbtnCancel: {
  //   height:"42px",
  //   padding: "10px 60px",
  //   color: "#fff",
  //   marginRight: "25px",
  //   background: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% )",
  //   borderStyle: "solid",
  //   border: "0",
  //   borderTop: "1px solid #a5a5a5",
  //   fontWeight: "600",
  //   cursor: "pointer",
  //   "&:disabled":{
  //     color:"#8C8C8C",
  //     cursor:"default"
  //   }
  // },
  headerButton: {
    width: "25%",
    paddingTop: "2%",
    paddingBottom: "2%",
    alignSelf: "center",
    textTransform: "none",
    borderRadius: "0 !important",
    backgroundImage: "linear-gradient(#7C7C7C, #414141)",
    "&:hover": {
      background: "linear-gradient(#5A5A5A, #393939) !important",
    },
  },
  topButton: {
    height: "80%",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
    backgroundColor: "linear-gradient(#393939, #1b1b1b)",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    padding: "3%",
    alignSelf: "center",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  topButtonText: {
    display:'flex',
    color: "#A9A9A9",
    textTransform: "none",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    justifyContent:'center'
  },
  topButtonTextActive: {
    display:'flex',
    color: "#86D100",
    textTransform: "none",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    justifyContent:'center'
  },
  bottomPanel2ButtonText: {
    color: "#A9A9A9",
    textTransform: "none",
    padding: ".83%",
 
    whiteSpace: "nowrap",
    overflow: "hidden",
    //textOverflow: "ellipsis",
  },
  bottomPanel2ButtonTextActive: {
    color: "#86D100",
    textTransform: "none",
    padding: ".83%",

    whiteSpace: "nowrap",
    overflow: "hidden",
    //textOverflow: "ellipsis",
  },
  bottomButton: {
    height: "20%",
    backgroundImage: "linear-gradient(#393939, #1b1b1b)",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    padding: "7%",
    "&:hover": {
      background: "linear-gradient(#4C4C4C, #323232) !important",
    },
  },
  SwitchText: {
    textTransform: "none",
    color: "#A9A9A9",
    fontSize: "h3",
  },
  powerOn: {
    opacity: "",
  },
  powerOff: {
    opacity: "40%",
  },
  editWidgetRoot: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: "linear-gradient(#3b3b3b, #202020)",
  },
  editWidgetHeader: {
    padding: "15px 10px 5px 15px",
    backgroundColor: "#3b3b3b",
  },
  editWidgetHeaderText1: {
    color: "#C0C0C0",
    fontWeight: "600",
    margin: "0",
    fontSize: "1.25rem",
  },
  editWidgetHeaderText2: {
    color: "#707070",
    fontWeight: "600",
    margin: "0",
  },
  editWidgetHeaderDivider: {
    margin: "10px 0",
  },
  photoLibraryDivider: {
    margin: "30px 0",
  },
  editWidgetButtonDiv: {
    display: "flex",
    padding: "15px",
    backgroundColor: "#3b3b3b",
  },
  editWidgetButton1: {
    padding: "10px 30px",
    color: "#fff",
    background: "#616161",
    marginRight: "25px",
  },
  editWidgetButton2: {
    padding: "10px 30px",
    color: "#898989",
    background: "#434343",
  },
  editWidgetPanel: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    backgroundImage: "#1b1b1b",
  },
  editWidgetLabel: {
    color: "#b4b4b4",
    fontSize: "22px",
    textTransform: "none",
    paddingBottom: "2%",
    paddingTop: "2%",
  },
  editWidgetInput: {
    width: "80%",
    height: "34px",
    background:
      "linear-gradient(0deg, rgba(3,3,3,1) 0%, rgba(27,27,27,1) 100%)",
    borderStyle: "none",
    borderBottom: "#626262 solid 1px",
    color: "#bfbfbf",
    fontSize: "18px",
    fontWeight: "600",
    paddingBottom: "2%",
    paddingTop: "2%",
    // marginBottom:'5%'
  },
  editWidgetInput1: {
    width: "100%",
    height: "34px",
    background:
      "linear-gradient(0deg, rgba(3,3,3,1) 0%, rgba(27,27,27,1) 100%)",
    borderStyle: "none",
    borderBottom: "#626262 solid 1px",
    color: "#bfbfbf",
    fontSize: "18px",
    fontWeight: "600",
    paddingBottom: "2%",
    paddingTop: "2%",
    // marginBottom:'5%'
  },
  headerIconLogic: {
    height: "8%",
    padding: "15%",
    width: "80%",
  },
  musicTreblePanelActive: {
    display: "none",
  },
  musicPanelPowerButton: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "4%",
    paddingBottom: "4%",
    borderTop: "solid 1px #747474",
    borderBottom: "solid 1px black",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
    // backgroundColor: 'red',
    borderRadius: "0 !important",
    "&:hover": {
      background: "linear-gradient(#515151, #414141) !important",
    },
    musicPanelPowerButtonActive: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "4%",
      paddingBottom: "4%",
      borderTop: "solid 1px #747474",
      borderBottom: "solid 1px black",
      backgroundImage: "red",
      backgroundColor: "red",
      background: "red",
      borderRadius: "0 !important",
      "&:hover": {
        background: "linear-gradient(#4C4C4C, #323232) !important",
      },
    },
  },
  musicTreblePanel: {
    display: "flex",
    padding: "2%",
    paddingLeft: "10%",
    paddingRight: "10%",
    borderBottom: "2px solid black",
    flexDirection: "column",
    backgroundColor: "#2b2b2b !important",
    backgroundImage: "#2b2b2b !important",
  },
  toolTipFavIconSelected: {
    color: "#92E300",
    borderRight: "2px solid silver",
    borderRadius: "0",
  },
  toolTipFavIcon: {
    backgroundColor: null,
    borderRight: "2px solid silver",
    borderRadius: "0",
  },
  bottomTextActive: {
    color: "#86D100",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    paddingLeft: "10px",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bottomText: {
    color: "#bfbfbf",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    paddingLeft: "10px",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bottomTextSecurity: {
    color: "#86D100",
    fontWeight: "600",
    fontSize: "1.25rem",
    textTransform: "none",
    paddingLeft: "10px",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logicIoBottomPanel: {
    color: "#a9a9a9",
    width: "100%",
    borderRadius: "0",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "6.35%",
    backgroundColor: "#000000",
    backgroundImage: "linear-gradient(#393939, #1b1b1b) !important",
  },
  // drawer css
  drawerPaper1: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "0",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
    },
  },
  // power meter chart css
  chartLayout: {
    width: "100%",
    height: 130,
    fontSize: "12px",
  },

  // powermetering widget css
  powerStatsBg: {
     background: "radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%)", 
     height:"100%"
   },
  powerStatsBody: {
     display: "flex", 
     padding: "0px" , 
     justifyContent: "space-between",
     alignItems:"center"
  },
  powerStatsContent: {
    display: "flex", 
    alignItems:"center"
  },
  closeButton: {
    display: "flex",
    padding: "10px", 
  },
  powerStatsWidget: {
    display: "flex", 
    alignItems:"center", 
    justifyContent: "center"
  },

  // powermetering widget rate consumption css

  rateConsumptionHeaderText: {
    fontSize: '12px',
    color: '#b2b2b2',
    fontWeight: '700',
    textAlign: 'left',
    wordSpacing: '1px'
  },

  // powermetering stats css

  powerStatsLayout: {
    width: '980px',
    padding: "2vw",
    fontSize: "12px",
    textAlign: "center",
    color: "#87d200",
    whiteSpace: "nowrap",
    background: "#1b1b1b",
    marginTop: "3vh",
    marginLeft: "3vh"
  },
  powerStatsLayoutHeaderText: {
    fontSize: "16px",
    fontWeight: "700"
  },
  PowerTab: {
    '&:hover': {
      color: '#87d200 !important',
      opacity: 1,
   },
 '&:focus': {
  color: '#87d200 !important',
},
'&:active': {
  color: '#87d200 !important',
},
'&$selected': {
      color: '#87d200 !important'
  },
  },
  powerStatsTabs: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },

  // powermetering stats Tabs css
  powerStatsTablayout: {
    width: '810px',
    padding: "1vw",
    fontSize: "12px",
    textAlign: "center",
    color: "#87d200",
    whiteSpace: "nowrap",
    marginTop: "-30px",
    marginBottom: "2vh"
  },
  powerStatsTabDivider: {
    width: '100%',
    color: "#fff",
    marginTop: "0vh",
    marginBottom: "2vh",
    marginBLeft: "2vh",
    backgroundImage: 'linear-gradient(#000, #373737) !important',
    height: "3px"
  },
  PowerStatsTabHeaderText: {
    width: '100%',
    textAlign: "center",
    color: "#fff",
    fontSize: "16px",
    textTransform: 'capitalize',
    fontWeight: "600",
  },

  // login portal page css
  loginPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: "100vh",
    minHeight: "100vh",
    backgroundColor: "#000000",
    //backgroundImage: "linear-gradient(#307d15,#6cbc0a) !important", 
    textTransform: "none",
  },
  loginField: {
    display: "flex !important",
    flexDirection: "column",
    height: "30px",
    width: "80%",
    margin: "4%",
    backgroundColor: "#2a9c16",
    textTransform: "none",
    marginRight: "7%",
    marginLeft: "7%",
    paddingLeft: "3%",
    boxShadow: "inset 1px 1px 3px 0px #2a5522",
    "&:hover": {
      background: "linear-gradient(#2ba117, #2a9917) !important",
      outline: "none",
      boxShadow: "0px 0px 2px #6cda28"
    },
  },
  loginButton: {
    color: "#fff",
    margin: "1%",
    backgroundImage: 'linear-gradient(#358227, #2a5522) !important',
    //backgroundColor: "#337a27",
    borderRadius: "0px",
    boxShadow: "0px -1px 2px #2a5522",
    "&:hover": {
      backgroundColor: "#6cda28",
    },
  },
  loginLogo: {
  width: "35vh",
  margin: "0% 0% 10% 0%"
}, 
versionInfo: {
  position: "absolute",
  bottom: "0",
  right: "0",
  padding: "1%"
}, 
valid: {
  color: "whitesmoke"
},
invalid: {
  color: "#808080"
},

error:{
  position: "fixed",
  top: "44%",
  right: "20%",
  width: "22%",
  color: "whitesmoke",
  fontSize: "12px",
  fontWeight: "bold",
  backgroundColor: "red",
  border: "2px dotted white",
  borderRadius: "20px"
},
perror:{
  position: "fixed",
  top: "47%",
  left: "59%",
  width: "19%",
  color: "whitesmoke",
  fontSize: "12px",
  fontWeight: "bold",
  backgroundColor: "red",
  border: "2px dotted white",
  borderRadius: "20px"
},
validationText:{
  position: "fixed",
  top: "42%",
  left: "20%",
  textAlign: "center",
  height: "20vh",
  width: "19%",
  fontSize: "12px",
  fontWeight: "bold",
  lineHeight:"1.3",
  backgroundColor: "#A0522D",
  border: "2px dotted white",
  borderRadius: "40px 30px 10px 40px"
}
});

// export component//
export default profileStyle;
