/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
// React-Redux//
import EditWidget from "./editWidget";
import IOSSwitch from "./Lighting/switch";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
import { AirbnbThumbComponent } from "./Lighting/slider";
// Material-UI //
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

// import Assets locally //
import thermo from "../../assets/img/thermo.png";
import sunWhite from "../../assets/img/sunWhite.png";
import sunLime from "../../assets/img/sunLime.png";
import coolerWhite from "../../assets/img/coolerWhite.png";
import coolerLime from "../../assets/img/coolerLime.png";
import coolerOffWhite from "../../assets/img//coolerOffWhite.png";
import coolerOffLime from "../../assets/img//coolerOffLime.png";
import fanWhite from "../../assets/img/fanWhite.png";
import powerButton from "../../assets/img/powerButton.png";
import powerButtonWhite from "../../assets/img/powerButtonWhite.png";
import fanLime from "../../assets/img/fanLime.png";
import thermoWhite from "../../assets/img/thermoWhite.png";
import sceneDownWhite from "../../assets/img/down.png";
import sceneUpWhite from "../../assets/img/up.png";
import styles from "../../assets/widgets/widgetStyles/commonStyles";
import { node } from "prop-types";
const useStyles = makeStyles(styles);

// Climate Widget Component //
const Hvac = (props) => {
  const getZone = (z) =>{
     if(z == 0){
       return '1'
     }else if(z == 1){
      return '2'
     }else if(z == 2){
      return '4'
     }else if( z==3 ){
      return '8'
     }else{
      return '10'
     }
  }
  const classes = useStyles();
  const initialDataHvacPowerPlant = props.initialCbusEvent.get("powerPlant");
  const initialDataHvacMode = props.initialCbusEvent.get("hvac_mode");
  const initialDataZoneTemp = props.initialCbusEvent.get("zone_temp");
  const initialDataHvacPlantZoneGroup = props.initialCbusEvent.get("hvac_plant");
  const initialPlantFspeed = props.initialCbusEvent.get("hvac_plant_fspeed");
  const initialDataHvacLiveTemp = props.initialCbusEvent.get("HVACZoneTemp0x" + getZone((Number(props.widget.params._attributes.zone)).toString(16)));
  const initialSetHvacMode = props.initialCbusEvent.get("set_hvac_mode");
  const initialTemp = props.initialCbusEvent.get("hvac_plant_temp");
  const [count, setCount] = useState(0);
  const [powerToggle, setPowerToggle] = useState(false);
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [checkedC, setCheckedC] = React.useState(false);
  const [mode, setMode] = useState();
  const [fanName, setFanName] = useState();
  const [liveTemp, setLiveTemp] = useState(0);
  const [setModeTemp, setSetModeTemp] =  useState();
  const [setTemp, setSetTemp] = useState();
  const [zoneList, setZoneList] = useState(0);
  const [fanSpeedValue, setFanSpeedValue] = useState();
  const [zoneGroup, setZoneGroup] = useState([]);
  const [minSetTemp, setMinSetTemp] = useState();
  const [maxSetTemp, setMaxSetTemp] = useState();
  const [switchPower, setSwitchPower] = useState(false);
  const [flags, setFlags] = useState();
  const [hvacType, setHvacType] = useState(3);
  const [isClicked, setIsClicked] = useState(true);

  // Modes disable or enable//
  const [modeEnable, setModeEnable] = useState(false);
  const [fanSpeedMinusEnable, setFanSpeedMinusEnable] = useState(false);
  const [fanSpeedPlusEnable, setFanSpeedPlusEnable] = useState(false);
  const [tempMinusEnable, setTempMinusEnable] = useState(true);
  const [tempPlusEnable, setTempPlusEnable] = useState(true);

  const setTempInHex = setModeTemp * 256;
  const fanSpeed = props.widget.params._attributes.fanspeeds;
  const widgetZone = props.widget.params._attributes.zone;

    useEffect(() => {
      let zoneInitial = {
        cmd:
          '<cbus_cmd app="0xac" command="cbusGetHVACZoneTemp" net="254" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" zone_list="' +
          "0x"+getZone(Number(props.widget.params._attributes.zone)) +
          '" />',
      };
    setTimeout(() => {  
      props.handleSocketEventChange(zoneInitial);
    }, 1000 ) 
  }, []);



  // Text Change in Power Button //
  const powerOn = " Power off plant";
  const powerOff = "Power on plant";

  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;


  useEffect(() => {
    if (!Array.isArray(props.data.Project.HVACData.HVACZoneGroups.ZoneGroup)) {
      setZoneGroup([
        ...zoneGroup,
        props.data.Project.HVACData.HVACZoneGroups.ZoneGroup,
      ]);
    } else {
      setZoneGroup(props.data.Project.HVACData.HVACZoneGroups.ZoneGroup);
    }
  }, [props.data.Project.HVACData]);

  useEffect(() => {
    if (zoneGroup.length !== 0) {
      setMinSetTemp(zoneGroup[0]._attributes.Min);
      setMaxSetTemp(zoneGroup[0]._attributes.Max);
    }
  }, [zoneGroup]);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const hex2bin = (hex) => {
    return (parseInt(hex, 16).toString(2)).padStart(8, '0');
  }

  const handlePowerButton = (powerPlant) => {
    if (powerPlant == 0) {
      setCount(0);
      setFanName("Off");
      let off = {
        cmd:
          '<cbus_cmd app="172" command="cbusSetZoneGroup" network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" status="0" />',
      }
      props.handleSocketEventChange(off);

      let off1 = {
        cmd:
        '<cbus_cmd app="0xac" command="cbusAirConRefresh" numaddresses="1" network="254" zone_group="' +
        props.widget.params._attributes.zone_group + '" />'
      }
      props.handleSocketEventChange(off1);
   
    }
    if (powerPlant == 1) {
      setCount(21);
      handleFanName(21);
      let on = {
        cmd:
          '<cbus_cmd app="172" command="cbusSetZoneGroup" network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" status="1" />'
      };
      props.handleSocketEventChange(on);
      
      let on1 = {
      cmd:
      '<cbus_cmd app="0xac" command="cbusAirConRefresh" numaddresses="1" network="254" zone_group="' +
      props.widget.params._attributes.zone_group + '" />'
      }
      props.handleSocketEventChange(on1);
    }
  };

  const handleTempChange = (temp) => {
    if (temp == 0) {
      setSetTemp(setTemp - 1);
      setHvacTemp((setTemp - 1) * 256);
    } else if (temp == 1) {
      setSetTemp(setTemp + 1);
      setHvacTemp((setTemp + 1) * 256);
    }
  };

  const setHvacTemp = (value) => {
    let on = {
      cmd:
        '<cbus_cmd app="0xac"  command="cbusSetHVACMode" network="' +
        props.widget.params._attributes.network +
        '" zone_group="' +
        props.widget.params._attributes.zone_group +
        '" zone_list="' +
        zoneList +
        '" hvac_mode="' +
        mode +
        '" hvac_type="3" temp="' +
        value +
        '" flags=" ' +
        flags +
        '" fan_mode="0" fan_speed="' +
        fanSpeedValue +
        '" />',
    };
    props.handleSocketEventChange(on);
  };

  const handleMode = (node, f, t) => {
    setMode(node);
    if (node == 1) {
      setMode(1);
      let on = {
        cmd:
          '<cbus_cmd app="0xac"  command="cbusSetHVACMode" network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" zone_list="' +
          zoneList +
          '" hvac_mode="1" hvac_type="' +
          hvacType +
          '" temp="' +
          setTempInHex +
          '" flags=" ' +
          flags +
          '" fan_mode="0" fan_speed="' +
          f +
          '" />',
      };
      props.handleSocketEventChange(on);
      
    } else if (node == 2) {
      setMode(2);
      let on = {
        cmd:
          '<cbus_cmd app="0xac"  command="cbusSetHVACMode" network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" zone_list="' +
          zoneList +
          '" hvac_mode="2" hvac_type=" ' +
          hvacType +
          '" temp="' +
          setTempInHex +
          '" flags=" ' +
          flags +
          '" fan_mode="0" fan_speed="' +
          f +
          '" />',
      };
      props.handleSocketEventChange(on);
      
    } else if (node == 3) {
      setMode(3);
      let on = {
        cmd:
          '<cbus_cmd app="0xac"  command="cbusSetHVACMode" network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" zone_list="' +
          zoneList +
          '" hvac_mode="3" hvac_type="' +
          hvacType +
          '" temp="' +
          setTempInHex +
          '" flags=" ' +
          flags +
          '" fan_mode="0" fan_speed="' +
          f +
          '" />',
      };
      props.handleSocketEventChange(on);
      
    } else if (node == 4) {
      setMode(4);
      // handleFanName();
      let on = {
        cmd:
          '<cbus_cmd app="0xac"  command="cbusSetHVACMode" network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" zone_list="' +
          zoneList +
          '" hvac_mode="4" hvac_type="' +
          hvacType +
          '" temp="' +
          (t == null ? 10750 : t) +
          '" flags="' +
          72 +
          '" fan_mode="0" fan_speed="0" />',
      };
      props.handleSocketEventChange(on);
    }
  };


  const handleSwitchChange = (event) => {
    let flag = flags;
    if(mode === 4){
      flag = 72;
    }
    setCheckedC(event.target.checked);
    setIsClicked(false);
    if (event.target.checked) {
      let sendMode = 0;
      if (initialSetHvacMode) {
        sendMode = initialDataHvacMode;
      }
      let switchOn = {
        cmd:
          '<cbus_cmd app="0xac" command="cbusSetHVACMode" network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" zone_list="' +
          zoneList +
          '" hvac_mode="' +
          sendMode +
          '"  hvac_type="3" temp="' +
          setTempInHex +
          '" flags="' + flag + '" fan_mode="0" fan_speed="' +
          fanSpeedValue +
          '" />',
      };
      props.handleSocketEventChange(switchOn);
      
    } else {
      let switchOff = {
        cmd:
          '<cbus_cmd app="0xac" command="cbusSetHVACMode"  network="' +
          props.widget.params._attributes.network +
          '" zone_group="' +
          props.widget.params._attributes.zone_group +
          '" zone_list="' +
          zoneList +
          '" hvac_mode="0" hvac_type="3" temp="' +
          0 +
          '" flags="' + flag + '" fan_mode="0" fan_speed="'+
          fanSpeedValue+'" />',
      };
      props.handleSocketEventChange(switchOff);
    }
    setTimeout(() => {
      setIsClicked(true);
    }, 1000);
  };

  //fan speed increase and decrease implementation start
  const handleFanMode = (fan) => {
    if(fanName != 'Auto'){
      if (fan === 1 && Number(fanName) > 0) {
        let temp = null;
        if(mode == 4){
          temp =  Math.round((Number(parseInt(initialPlantFspeed.attributes.temp, 16)))) -10750;
        }
   
        let changedFspeed = Number(fanName) - 1 ;
        let speedy = (63 / props.widget.params._attributes.fanspeeds) * changedFspeed;
        if(changedFspeed == 0){
          setFanSpeedMinusEnable(true);
          if(mode == 4){
            setFanName(1);
          }else{
            handleMode(mode, speedy, temp);
            setFanName("Auto");
          }
          
        }else{
          handleMode(mode, speedy, temp);
          setFanSpeedMinusEnable(false);
          setFanName(changedFspeed);
        }
      } else if (fan === 2 && Number(fanName) < Number(props.widget.params._attributes.fanspeeds)) {
        let temp = null;
        if(mode == 4){
          temp =  Math.round((Number(parseInt(initialPlantFspeed.attributes.temp, 16)))) +10750;
        }
   
        let changedFspeed = Number(fanName) + 1 ;
        let speedy = (63 / props.widget.params._attributes.fanspeeds) * changedFspeed;
        handleMode(mode, speedy, temp);
        setFanName(changedFspeed);
        if( Number(props.widget.params._attributes.fanspeeds) == changedFspeed){
          setFanSpeedPlusEnable(true);
        }else{
          setFanSpeedPlusEnable(false);
        }
      }
    }else{ 
      let speedy = (63 / props.widget.params._attributes.fanspeeds) * 1;
      handleMode(mode, speedy, null);
      setFanName(1);
    }
  };
    //fan speed increase and decrease implementation end

  const reverseString = (str) => {
    let newString = "";
    for (let i = str.length - 1; i >= 0; i--) {
      newString += str[i];
    }
    return newString;
  };

  const handleFanName = (count) => {
    if (mode != 4) {
      if (count === 0) {
        setFanName("Off");
      } else if (count === 1) {
        setFanName("Auto");
      } else
        for (let i = 21; i <= fanSpeed + 21; i++) {
          if (count === i) {
            setFanName(count - 21);
          }
        }
    } else {
      for (let i = 1; i <= fanSpeed; i++) {
        if (count === i) {
          setFanName(count);
        }
      }
    }
  };

  useEffect(() => {
    if (powerToggle == true) {
      handleFanName(count);
    } else {
      setCount(0);
      handleFanName(count);
    }
    if (mode === 4) {
      setFlags(72);
    } else setFlags(64);
  }, []);

  useEffect(() => {
    let binaryCount = "";
    for (
      let i = 0;
      i < props.data.Project.HVACData.HVACZones.HVACZone.length;
      i++
    ) {
      if (
        props.data.Project.HVACData.HVACZones.HVACZone[i]._attributes
          .ZoneGroup == props.widget.params._attributes.zone_group
      ) {
        if (
          props.data.Project.HVACData.HVACZones.HVACZone[i]._attributes.Zone ==
          props.widget.params._attributes.zone
        ) {
          binaryCount += "1";
        } else {
          binaryCount += "0";
        }
      }
    }
    setZoneList('0x'+parseInt(reverseString(binaryCount), 2).toString(16));

    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
      
        if (props.cbusEvent.attributes.name === "hvac_zone_on" || props.cbusEvent.attributes.name === "hvac_zone_off") {
          if (
            props.cbusEvent.attributes.zone_group ===
            "0x" +
            Number(props.widget.params._attributes.zone_group).toString(16)
          ) {
            if (initialDataHvacPowerPlant) {
              initialDataHvacPowerPlant['powerPlant'] = props.cbusEvent;
              let dataHvacPowerPlant = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(dataHvacPowerPlant);
            }
          }
        }

        if (props.cbusEvent.attributes.name === "zone_temp") {
          setIsClicked(true);
          if (
            props.cbusEvent.attributes.zone_group ===
            "0x" +
            Number(props.widget.params._attributes.zone_group).toString(16)
          ) {
            let zoneInitial = {
              cmd:
                '<cbus_cmd app="0xac" command="cbusGetHVACZoneTemp" net="254" zone_group="' +
                props.widget.params._attributes.zone_group +
                '" zone_list="' +
                "0x"+getZone(Number(props.widget.params._attributes.zone)) +
                '" />',
            };
            props.handleSocketEventChange(zoneInitial);
            if (initialDataZoneTemp) {
              if (initialDataZoneTemp.attributes.name === "zone_temp") {
                let hvacZoneTemp = new Map(props.initialCbusEvent);
                props.socketEventDataChanger(hvacZoneTemp);
              }
            }
          }
        }
      }
    }

    if(isClicked){

    if (initialDataHvacPowerPlant && initialSetHvacMode && initialDataHvacPlantZoneGroup) {

          if (initialDataHvacPowerPlant.attributes.name === "hvac_zone_on") {
            setMode(initialDataHvacMode);
            setModeEnable(false);
            setFanSpeedPlusEnable(false);
            setFanSpeedMinusEnable(false);
            setPowerToggle(true);
            setSwitchPower(true);
            let binData = initialDataHvacPlantZoneGroup;
            if (binData[props.widget.params._attributes.zone] === '1') {
              setCheckedC(true);
              if (initialPlantFspeed) {
                if (initialDataHvacMode != 4) {
                  let fspeed = Math.round((Number(parseInt(initialPlantFspeed.attributes.fan_speed, 16)) / 63) * props.widget.params._attributes.fanspeeds);
                  if (fspeed === 0) {
                    setFanName("Auto");
                    setFanSpeedValue(0);

                  } else {
                    setFanName(fspeed);
                    let speedy = (63 / Number(props.widget.params._attributes.fanspeeds)) * fspeed;
                    setFanSpeedValue(speedy);

                  }
                } else {
                  let fspeed = Math.round((Number(parseInt(initialPlantFspeed.attributes.temp, 16)) * props.widget.params._attributes.fanspeeds) / 32767);
                  setFanName(fspeed);
                  let speedy = (63 / props.widget.params._attributes.fanspeeds) * fspeed;
                  setFanSpeedValue(speedy);

                }
              }
            } else {
              //setIsClicked(true);
              setModeEnable(true);
              setFanSpeedPlusEnable(true);
              setFanSpeedMinusEnable(true);
              setCheckedC(false);
              setFanName("Off")
            }
          }
          else {
            //setIsClicked(true);
            setModeEnable(true);
            setFanSpeedPlusEnable(true);
            setFanSpeedMinusEnable(true);
            setPowerToggle(false);
            setSwitchPower(false);
            setCheckedC(false);
            setMode(0);
          }
    }

    if (initialDataHvacLiveTemp) {
      setLiveTemp(Math.round(initialDataHvacLiveTemp.attributes.temperature));
    }

    if (initialTemp) {
      setSetTemp(parseInt(initialTemp.attributes.temp, 16) / 256);
      if(mode != 4){
        setSetModeTemp(parseInt(initialTemp.attributes.temp, 16) / 256);
      }
    }
  }


  }, [props.cbusEvent]);

    return (
    <>
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
         
          <div className={classes.root}>
             {/* Tooltip implementation ----start----  */}
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                    style={{ borderRadius: "0" }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div
                className={powerToggle ? classes.header : classes.headerOff}
              >
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >

               {/* Thermo icon implementation ----start----  */}

               { widgetZone == 0 ? (<img
                    src={powerToggle ? thermo : thermoWhite}
                    className={classes.headerIcon}
                  />) :  (<img
                    src={checkedC ? thermo : thermoWhite}
                    className={classes.headerIcon}
                  />) }
              {/* Thermo icon implementation ----end----  */}

                </IconButton>

                <div className={classes.header1} style={{ flexGrow: "1" }}>
                  <Typography
                    variant="h6"
                    className={
                      checkedC
                        ? classes.headerText1Active
                        : classes.headerText1
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>

               {/* Switch implementation ----start----  */}
               { widgetZone !=0 &&
                <IOSSwitch
                  disabled={switchPower ? false : true}
                  ThumbComponent={AirbnbThumbComponent}
                  checked={checkedC}
                  onChange={handleSwitchChange}
                  name="checkedC"
                />
               }
              {/* Switch implementation ----end----  */}


              </div>
            </BootstrapTooltip>
             {/* Tooltip implementation ----end----  */}

          
            <div className={checkedC ? classes.powerOn : classes.powerOff}>
              {/* Mode implementation ----start----  */}
              <div className={classes.controlPanel}>
                <IconButton
                  aria-label="previous" disabled={modeEnable && !checkedC} className={(mode === 1 && checkedC) ? classes.controlPanelIconButtonActive : classes.controlPanelIconButton}
                  onClick={() => handleMode(1, fanSpeedValue, null)}>
                  <img src={(mode === 1 && checkedC) ? sunLime : sunWhite} className={classes.controlPanelIcon}/>
                </IconButton>

                <IconButton
                  aria-label="previous" disabled={modeEnable && !checkedC } className={(mode === 2 && checkedC) ? classes.controlPanelIconButtonActive : classes.controlPanelIconButton}
                  onClick={() => handleMode(2, fanSpeedValue, null)}>
                  <img src={(mode === 2 && checkedC) ? coolerLime: coolerWhite} className={classes.controlPanelIcon}/>
                </IconButton>

                <IconButton
                  aria-label="previous" disabled={modeEnable && !checkedC } className={(mode === 3 && checkedC) ? classes.controlPanelIconButtonActive : classes.controlPanelIconButton}
                  onClick={() => handleMode(3, fanSpeedValue, null)}>
                  <img src={(mode === 3 && checkedC) ? coolerOffLime : coolerOffWhite} className={classes.controlPanelIcon}/>
                </IconButton>

                <IconButton
                  aria-label="previous" disabled={modeEnable && !checkedC } className={(mode === 4 && checkedC) ? classes.controlPanelIconButtonActive : classes.controlPanelIconButton}
                  onClick={() => handleMode(4, fanSpeedValue, null)}>
                  <img src={(mode === 4 && checkedC) ? fanLime : fanWhite} className={classes.controlPanelIcon}/>
                </IconButton>
              </div>
                 {/* Mode implementation ----end----  */}

                 {/* Fan speed implementation ----start----  */}
              <div
                className={
                  checkedC
                    ? classes.numberControlPanelHvac
                    : classes.numberControlPanelActiveHvac
                }
              >
                <IconButton
                  aria-label="previous"
                  className={classes.Icon2}
                  disabled={fanSpeedMinusEnable}
                  onClick={() => handleFanMode(1)}
                >
                  <RemoveIcon
                    className={
                      powerToggle === true
                        ? classes.controlIconsActive
                        : classes.controlIcons
                    }
                  />
                </IconButton>
                <Typography
                  variant="h4"
                  className={
                   powerToggle === true
                      ? classes.controlTypo1Active
                      : classes.controlTypo1
                  }
                >
                  {fanName}
                </Typography>
                <IconButton
                  aria-label="previous"
                  className={classes.Icon2}
                  disabled={fanSpeedPlusEnable}
                  onClick={() => {
                    handleFanMode(2);
                  }}
                >
                  <AddIcon
                    className={
                      powerToggle === true
                        ? classes.controlIconsActive
                        : classes.controlIcons
                    }
                  />
                </IconButton>
              </div>
              {/* Fan speed implementation ----end----  */}

              {/* Temp set point ----start----  */}
              <div
                className={
                  checkedC
                    ? classes.numberControlPanelHvac
                    : classes.numberControlPanelActiveHvac
                }
              >
                <IconButton
                  aria-label="previous"
                  className={classes.Icon2}
                  onClick={() => handleTempChange(0)}
                >
                  <RemoveIcon className={
                      powerToggle === true  && mode !== 4
                        ? classes.controlIconsActive
                        : classes.controlIcons
                    } />
                </IconButton>

                  <Typography variant="h4" className={
                    powerToggle === true && mode !== 4
                       ? classes.controlTypo1Active
                       : classes.controlTypo1
                   }>
                    {liveTemp}°C
                  </Typography>
                
                <div
                  style={{
                    display:
                      powerToggle == false || mode == 4 ? "none" : "flex",
                    flexDirection: "column",
                    alignSelf: "flex-end",
                  }}
                >
                  {!checkedC ? null : <img
                    src={
                      setTemp < liveTemp
                        ? sceneDownWhite
                        : setTemp > liveTemp
                          ? sceneUpWhite
                          : null
                    }
                    style={{ width: "50%" }}
                  />}
                  {!checkedC ? null : <Typography variant="h6" className={classes.controlTypo1}>
                    { setTemp }°C
                  </Typography>}
                </div>

                <IconButton
                  aria-label="previous"
                  className={classes.Icon2}
                  onClick={() => handleTempChange(1)}
                >
                  <AddIcon className={
                      powerToggle === true  && mode !== 4
                        ? classes.controlIconsActive
                        : classes.controlIcons
                    } />
                </IconButton>
              </div>
            </div>
            {/* Temp set point ----end----  */}

            {/* Power button ----start----  */}
            <Button
              variant="contained"
              onClick={
                powerToggle
                  ? () => handlePowerButton(0)
                  : () => handlePowerButton(1)
              }
              className={
                powerToggle ? classes.bottomPanelActive : classes.bottomPanel
              }
            >
              <img
                src={powerToggle ? powerButton : powerButtonWhite}
                className={classes.bottomPanelIconAlarm}
              />
              <Typography
                className={
                  powerToggle ? classes.bottomTextActive : classes.bottomText
                }
                variant="h5"
                style={{ paddingRight: "12%" }}
              >
                {powerToggle ? powerOn : powerOff}
              </Typography>
            </Button>
           {/* Power button ----end----  */}

            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{ paper: classNames(classes.drawerPaper) }}
              ModalProps={{ keepMounted: true }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
};
export default Hvac;