import React, { useState, useEffect } from "react";
//Assets imported Locally //
import relayGreen from "../../../assets/img/relay.png";
import relayWhite from "../../../assets/img/relayWhite.png";

// Components Imported Locally //
import EditWidget from "../editWidget";

// material-ui //
import { makeStyles} from "@material-ui/core/styles";
import {
  Tooltip,
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";

// CSS Style sheets imported locally //
import styles from "../../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

// Tooltip Styles //

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "grey",
    placement: "right",
    maxHeight: "150px",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return (
    <Tooltip
      interactive
      enterDelay={300}
      leaveDelay={1000}
      placement="top-start"
      arrow
      classes={classes}
      {...props}
    />
  );
}

export default function Curtain1Button(props) {
  const initialData = props.initialCbusEvent.get(
    "0x" + Number(props.widget.params._attributes.app).toString(16)
  );
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [click, setClick] = useState(false);
  const [count, setCount] = useState(0);
  const [label, setLabel] = useState();
  const [flicker, setFlicker] = useState(false);

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };
  // 

  const handleClick = () => {
    if (count === 3) {
      //setCount(0);
      handleCount(0);
    } else {
      //setCount(count + 1);
      handleCount(count);
    }
  };
  const handleLabel = (count) => {
    if (count === 0) {
     setLabel(button0Label);
    } else if (count === 1 || count === 3) {
    setLabel(button1Label);
    } else if (count === 2) {
     setLabel(button2Label);
    }
  };

  let button0Label = props.widget.params._attributes.button0label;
  let button1Label = props.widget.params._attributes.button1label;
  let button2Label = props.widget.params._attributes.button2label;
  // 
  // 

  const handleCount = () => {
    setFlicker(true);
    if (count === 0) {
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="255" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="0" ramp="0"/>',
      };
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen1);
      }, 100);
      props.handleSocketEventChange(buttonOpen2);
      
      
    } else if (count === 1) {
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="0" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="0" ramp="0"/>',
      };

      props.handleSocketEventChange(buttonOpen1);
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen2);
      }, 100);

      
      
    } else if (count === 2) {
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="0" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="255" ramp="0"/>',
      };
      props.handleSocketEventChange(buttonOpen1);
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen2);
      }, 100);

      
      
    } else if (count === 3) {
      let buttonOpen1 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.downga +
          '" level="0" ramp="0"/>',
      };
      let buttonOpen2 = {
        cmd:
          '<cbus_cmd app="' +
          props.widget.params._attributes.app +
          '" command="cbusSetLevel" network="' +
          props.widget.params._attributes.network +
          '" numaddresses="1" group="' +
          props.widget.params._attributes.upga +
          '" level="0" ramp="0"/>',
      };
      props.handleSocketEventChange(buttonOpen2);
      setTimeout(() => {
        props.handleSocketEventChange(buttonOpen1);
      }, 100);
      
      
    } else if (count === 4) {
    }
  };
  
  

  useEffect(() => {
    // handleLabel(count);

    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          setFlicker(true);
          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.upga).toString(16)
          ) {
            if (props.cbusEvent.attributes.level == "0x0") {
              if (count === 1) {
                setCount(2);
                setLabel(button2Label);
              }
            } else {
              setCount(1);
              setLabel(button1Label);
            }
            if (initialData) {
              initialData[props.widget.params._attributes.upga] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }

          if (
            props.cbusEvent.attributes.group ===
            "0x" + Number(props.widget.params._attributes.downga).toString(16)
          ) {
            if (props.cbusEvent.attributes.level == "0xff") {
              setCount(3);
              setLabel(button1Label)
            } else {
              if (count === 3) {
                setCount(0);
                setLabel(button0Label);
              }
            }
            if (initialData) {
              initialData[props.widget.params._attributes.downga] =
                props.cbusEvent.attributes.level;
              let mraData = new Map(props.initialCbusEvent);
              props.socketEventDataChanger(mraData);
            }
          }
        }
      }
    }

  if (initialData && !flicker) {
      if (initialData[props.widget.params._attributes.upga]) { 
        if (
         initialData[props.widget.params._attributes.upga] ==
          "0xff"
        ) {
          setLabel(button1Label);
        }
      }

      if (initialData[props.widget.params._attributes.downga]) {  
        if (
          initialData[props.widget.params._attributes.downga]==
          "0xff"
        ) {
          setLabel(button1Label);
        } 

        if (initialData[props.widget.params._attributes.upga] =="0x0" && initialData[props.widget.params._attributes.downga]=="0x0"){
          setLabel(button0Label);
        }

      }
    }
  }, [props.cbusEvent]);

  // Strings used in Buttons//
  const on = props.language.on;
  const off = props.language.off;
  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.locations[Number(props.widget._attributes.location)]._attributes.Name;
  const functionGroup =
    props.functionGroups[Number(props.widget._attributes.functiongroup)]
      ._attributes.Name;

  return (
    <>
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={click ? relayWhite : relayGreen}
                    alt="relay"
                    className={classes.headerIcon}
                  />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={
                   `${classes.headerText1} ${classes.textTruncation} `
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={`${classes.headerText2} ${classes.textTruncation} `}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
              </div>
            </BootstrapTooltip>
            <Button
              variant="contained"
              className={classes.bottomPanel1Button}
              onClick={handleClick}
            >
              <Typography
                className={classes.bottomPanel2ButtonText}
                variant="h5"
              >
                {label}
              </Typography>
            </Button>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
