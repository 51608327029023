import React, { useState, useEffect } from "react";
import BootstrapTooltip from "../../assets/widgets/tooltip/tooltip";
import { ICON_URL } from "../../config/environment";
// material-ui //
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Typography,
  Drawer,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import StarsIcon from "@material-ui/icons/Stars";
import EditIcon from "@material-ui/icons/Edit";
// Assets Import //
import scenesGreen from "../../assets/img/scenesGreen.png";
import scenesWhite from "../../assets/img/scenesWhite.png";
import EditWidget from "./editWidget";
// CSS Style sheets imported locally //
import styles from "../../assets/widgets/widgetStyles/commonStyles";
const useStyles = makeStyles(styles);

//function //
export default function ScenesTrigger(props) {
  const initialData = props.initialCbusEvent.get("cbusGetTriggerStates");
  const classes = useStyles();
  const [draweropen, setDraweropen] = React.useState(false);
  const [favData, setFaData] = useState(props.favData);
  const [start, setStart] = useState(false);
  //const[lightingStatus, setLightingStatus] = useState();

  const toggleDrawer = (open) => {
    setDraweropen(open);
  };

  const updateFav = (fav) => {
    setFaData(!fav);
  };

  const handleStart = () => {
    let start = {
      cmd:
        '<cbus_cmd app="' +
        props.widget.params.param[1]._attributes.value +
        '" command="cbusSetLevel" network="' +
        props.widget.params.network +
        '" numaddresses="1" group="' +
        props.widget.params._attributes.ga +
        '" level="' +
        props.widget.params._attributes.as +
        '" ramp="0"/>',
    };
    props.handleSocketEventChange(start);
    setStart(true);
  };

  useEffect(() => {
    if (props.cbusEvent) {
      if (props.cbusEvent.name === "cbus_event") {
        if (props.cbusEvent.attributes.name === "cbusSetLevel") {
          if (
            props.cbusEvent.attributes.group ==
            "0x" + Number(props.widget.params._attributes.ga).toString(16)
          ) {
            if (
              props.cbusEvent.attributes.level ==
              "0x" + Number(props.widget.params._attributes.as).toString(16)
            ) {
              setStart(true);
            } else setStart(false);
          }
        }
      }
    }
    if (initialData) {
      if (
        parseInt(initialData[props.widget.params._attributes.ga], 16) ==
        props.widget.params._attributes.level
      ) {
      } else {
      }
    }
  }, [props.cbusEvent]);

  // Text Consts //
  const Start = props.language.start;
  const Stop = props.language.on;
  // Data assigned to consts from Redux Store //
  const id = props.widget._attributes.id;
  const name =
    props.widget.params._attributes.label != undefined
      ? props.widget.params._attributes.label
      : "";
  const location =
    props.widget._attributes.location === "none"
      ? ""
      : props.locations[Number(props.widget._attributes.location)]._attributes
          .Name;
  const functionGroup =
    props.widget._attributes.functiongroup === "none"
      ? ""
      : props.functionGroups[Number(props.widget._attributes.functiongroup)]
          ._attributes.Name;

  // function returns //
  return (
    <>
      {" "}
      {props.data !== undefined && (
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className={classes.root}>
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <IconButton
                    className={
                      favData
                        ? classes.toolTipFavIconSelected
                        : classes.toolTipFavIcon
                    }
                    aria-label="delete"
                    onClick={() => {
                      props.handleFavorite(id, props.widget._attributes.type);
                      updateFav(favData);
                    }}
                  >
                    <StarsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      toggleDrawer(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <div className={classes.header}>
                <IconButton
                  aria-label="previous"
                  disabled="true"
                  className={classes.headerIconButton}
                >
                  <img
                    src={start ? ICON_URL+"green_"+props.widget.params._attributes.icon+".png" : ICON_URL+"white_"+props.widget.params._attributes.icon+".png"}
                    alt={"sceneTrigger"}
                    className={classes.headerIcon}
                  />
                </IconButton>
                <div className={classes.header1}>
                  <Typography
                    variant="h6"
                    className={
                      start
                        ? `${classes.headerText1Active} ${classes.textTruncation} `
                        : `${classes.headerText1} ${classes.textTruncation} `
                    }
                  >
                    {name}
                  </Typography>
                  <Typography variant="h6" className={classes.headerText2}>
                    {props.widgetCategory === "functionGroup"
                      ? location
                      : functionGroup}
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  style={{ paddingTop: "2%", paddingBottom: "2%" }}
                  className={
                    start
                      ? classes.controlPanelIconButtonActive
                      : classes.controlPanelIconButton
                  }
                  onClick={start ? null : handleStart}
                >
                  <Typography
                    className={
                      start
                        ? classes.topButtonTextActive
                        : classes.topButtonText
                    }
                    variant="h6"
                  >
                    {start ? Stop : Start}
                  </Typography>
                </Button>
              </div>
            </BootstrapTooltip>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={draweropen}
              onClose={() => {
                toggleDrawer(false);
              }}
              modal
              classes={{
                paper: classNames(classes.drawerPaper),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <EditWidget
                saveEditedWidget={props.saveEditedWidget}
                data={props}
                toggleDrawer={toggleDrawer}
              />
            </Drawer>
          </div>
        </Grid>
      )}
    </>
  );
}
