import React, { Component } from "react";
import {
  Drawer,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";

import cctvImage from "assets/img/homeCctv.png";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/js/all-styles/views/schedulesStyle.js";
const useStyles = makeStyles(styles);

let imgPreview;

const ImageUploadComponent = () => {
  const classes = useStyles();

  const ImageEditdrawer = () => (
   
      <>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{display:"flex", background:"radial-gradient(circle,rgba(61,61,61,100) 0%, rgba(24,24,24,100) 100%)"}}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h3
              style={{
                color: "#737373",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Edit Profile picture
            </h3>
            <div
              style={{
             
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src={state.file}
                alt="CCTV Feed is not available"
                style={{ width: "80%" }}
              />
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{
              boxShadow: "-9px -3px 5px 0px rgba(0,0,0,0.75) !important",
            }}
          >
            <div
             
            >
              <div>
                <button onClick={toggleDrawer(false)}>Cancel</button>
                <button onClick={toggleDrawer(false)}>Save</button>
              </div>
              <div>
                <button>Change image</button>
              </div>
              <div>
                <span style={{ color: "#737373", fontWeight: "600" }}>
                  Preview
                </span>

                <div
                  style={{
                    width: "120px",
                    height: "120px",
                    border: "3px solid #538c0d",
                    color: "fff",
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                >
                  <img
                    src={state.file}
                    alt="CCTV Feed is not available"
                    style={{ alignSelf: "center" }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    
  );

  const [state, setState] = React.useState({ file: null });

  const [draweropen, setDraweropen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    setDraweropen(open);
  };

  const uploadSingleFile = (e) => {
    setState({
      file: URL.createObjectURL(e.target.files[0]),
    });

    if (state.file) {
      imgPreview = <img src={state.file} alt="" />;
    }

    setDraweropen(true);
  };

  const upload = (e) => {
    e.preventDefault();
    
  };

  return (
  
      

      <Drawer
        variant="temporary"
        anchor={"right"}
        open={draweropen}
        onClose={toggleDrawer(false)}
        modal
        ModalProps={{
          keepMounted: false, // Better open performance on mobile.
        }}
      >
        {ImageEditdrawer()}
      </Drawer>
   
  );
};

export default ImageUploadComponent;
