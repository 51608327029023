import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import styles from '../../../../../../assets/widgets/widgetStyles/commonStyles'

const useStyles = makeStyles(styles);;

const MonthDollarRateConsumption = (props) => {
  const classes = useStyles();
  const [meterValue, setMeterValue] =useState("");
  const [rateInfo, setRateInfo] =useState("");
  let arrayOfAnalog = [];

  let arrayofPulse = [];

  let combineArray = [];

  if(props.energy.Project.MeasurementAppData !==undefined){
  props.energy.Project.MeasurementAppData.MeasurementChannel.map(obj => { 
    if(obj._attributes.Generator!==undefined){
      arrayOfAnalog.push(obj._attributes.Generator);
    }
  } ); }

  if(props.energy.Project.Energy.PulsePowerMeters !==undefined){
  props.energy.Project.Energy.PulsePowerMeters.PulsePowerMeter.map(obj => { 
    if(obj._attributes.Generator!==undefined){
      arrayofPulse.push(obj._attributes.Generator);
    }
  } ); }

  combineArray = [...arrayOfAnalog, ...arrayofPulse];

  //CurrencyUnit Value
  const currencyUnit = props.widget.params._attributes.currency_units;

  //estimated values
  let meterData = meterValue;

  //let meterResult = meterData >= 9999 ? 9999 : meterData;

  //consumption and genetated values

  let meterDataInfo = rateInfo;
  
  //let meterResult1 = meterDataInfo >= 9999 ? 9999 : meterDataInfo;

  useEffect(() => {
    if (props.powerData) {
       if(props.powerData.meter===props.widget.params._attributes.meterno){
         setMeterValue(props.powerData.predicted_cost_month);
       }
    }
  }, [props.powerData]);

  useEffect(() => {
    if (props.powerDataConsumed !== undefined ) {
       if(props.powerDataConsumed.meter===props.widget.params._attributes.meterno){
        let monthlyCost = props.powerDataConsumed.monthly_cost_history.split(",").reverse();
        let monthlyValue = monthlyCost[2];
        setRateInfo(monthlyValue);
       }
    }
  }, [props.powerDataConsumed]);
   

  return (
  <div>
    <Typography className={classes.rateConsumptionHeaderText} >
    {combineArray[props.widget.params._attributes.meterno] === "1" ? "Rate of Generation" :
    combineArray[props.widget.params._attributes.meterno] === "0" ? "Rate of Consumption" :
    "Rate of Consumption/Generation" }
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    Estimated this month<span style={{wordSpacing:'3px'}}> {meterData} {currencyUnit}</span>
    </Typography>
    <Typography className={classes.rateConsumptionHeaderText} >
    {combineArray[props.widget.params._attributes.meterno] === "1" ? "Generated last month" :
    combineArray[props.widget.params._attributes.meterno] === "0" ? "Consumed last month" :
    "Generated last month" } <span style={{wordSpacing:'3px'}}> {meterDataInfo} {currencyUnit}</span>
    </Typography>
      </div>
  )
}

export default MonthDollarRateConsumption;
